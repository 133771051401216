import React, { Component } from "react";
import { connect } from "react-redux";

import "./ApplicationDemo.scss";

import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../redux/store";
import ApplicationSidenav from "../../components/ApplicationSidenav/ApplicationSidenav";
import { Dropdown, Icon, Nav } from "rsuite";
import {
  withRouter,
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  RouteComponentProps
} from "react-router-dom";
import DemoDashboardView from "./DemoDashboardView/DemoDashboardView";
import DemoComponentsView from "./DemoComponentsView/DemoComponentsView";
import { WithRouterProps } from "react-router";

type Props = {} & RouteComponentProps;

type States = {};

class ApplicationDemo extends Component<Props, States> {
  onNavigationItemClick(route) {
    this.props.history.push(route);
  }
  render() {
    const { match, location } = this.props;

    return (
      <div className="view-application-demo app-layout">
        <div className={"sidenav"}>
          <ApplicationSidenav
            onKeySelect={key => this.onNavigationItemClick(key)}
            noTransition={true}
            activeKey={location.pathname}
          >
            <Nav>
              <Nav.Item
                eventKey={`${match.url}/dashboard`}
                icon={<Icon icon="dashboard" />}
              >
                Dashboard
              </Nav.Item>
              <Dropdown
                placement="rightStart"
                eventKey={`${match.url}/components`}
                title="Components"
                icon={<Icon icon="magic" />}
              >
                <Dropdown.Item eventKey={`${match.url}/components/forms`}>
                  Forms
                </Dropdown.Item>
                <Dropdown.Item eventKey={`${match.url}/components/buttons`}>
                  Buttons
                </Dropdown.Item>
              </Dropdown>
            </Nav>
          </ApplicationSidenav>
        </div>
        <div className={"app-layout-content"}>
          <Switch>
            <Route
              path={`${match.path}/components`}
              component={DemoComponentsView}
            />
            <Route
              path={`${match.path}/dashboard`}
              component={DemoDashboardView}
            />

            <Route render={() => <Redirect to={`${match.path}/dashboard`} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(setUserData(user, apps, permissions)) }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationDemo)
);
