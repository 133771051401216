import React, { CSSProperties, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Log from "../../debug/Log";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import { SubmitMessage, SubmitResponse } from "../../services/SubmitService";
import { DataBusSubKeys } from "../../utils/Constants";
import { GenericFormsLayoutProps } from "../generic-forms/BaseElement";
import GenericForms from "../generic-forms/GenericForms";
import { JsonProperties } from "../generic-forms/util/JsonValidation";

type StandardFormsWrapperProps = {
	formDefinitionKey: string;
	selector: string;
	formId: string;
	submitType: string;

	style?: CSSProperties;
} & GenericFormsLayoutProps;
export const StandardFormsWrapper: React.FC<StandardFormsWrapperProps> = props => {
	const { formId, submitType, formDefinitionKey, selector, style } = props;

	//mount
	useEffect(() => {
		const subscriptionId = DataBus.subscribe<SubmitResponse>(DataBusSubKeys.SUBMIT_RESPONSE, response => {
			if (response.id === formId) {
				if (response.success) {
				}
			}
		});

		//will unmount
		return () => {
			DataBus.unsubscribe(subscriptionId);
		};
	}, []);

	const { t } = useTranslation();
	const formDefinition = useSelector((state: AppState) => state.global.config.standardForms[formDefinitionKey]);
	const applicationCache = useSelector((state: AppState) => state.application.cache);

	const formValue = applicationCache[selector] ? applicationCache[selector].data : null;

	Log.warning("DEPRECATED USAGE OF StandardFormsWrapper");
	if (formValue) {
		return (
			<GenericForms
				style={style}
				translateFunc={t}
				formValue={formValue}
				formDefinition={formDefinition}
				onFormSubmit={data => {
					DataBus.emit(DataBusSubKeys.SUBMIT, {
						id: formId,
						type: submitType,
						data,
						pushToCache: true
					} as SubmitMessage);
				}}
				onFormCancel={() => Log.debug("canceled")}
			/>
		);
	} else {
		return null;
	}
};

type GenericFormsWrapperProps = {
	subIdSubmit: string;
	formId: string;
	submitType: string;
	properties: JsonProperties;
	layout: { [key: string]: any };
	selector?: string;
	initialValue?: { [key: string]: any };
	style?: CSSProperties;
} & GenericFormsLayoutProps;
export const GenericFormsWrapper: React.FC<GenericFormsWrapperProps> = props => {
	const { formId, submitType, properties, layout, selector, initialValue, style, subIdSubmit } = props;
	const { t } = useTranslation();

	const application = useSelector((state: AppState) => state.application);

	let formValues = initialValue;

	let fail = false;
	let step = application;
	if (selector) {
		const steps = selector.split(".");
		for (let i = 0; i < steps.length && !fail; i++) {
			step = step[steps[i]];
			if (!step) {
				fail = true;
			}
		}
		formValues = step[0];
	}
	Log.warning("DEPRECATED USAGE OF GenericFormsWrapper");
	if (!selector || (!fail && step.length === 1)) {
		return (
			<GenericForms
				style={style}
				translateFunc={t}
				formValue={formValues}
				formDefinition={{ properties, layout }}
				onFormSubmit={data => {
					DataBus.emit(DataBusSubKeys.SUBMIT, {
						id: formId,
						type: submitType,
						data
					} as SubmitMessage);
				}}
				onFormCancel={() => Log.debug("canceled")}
			/>
		);
	} else {
		return null;
	}
};
