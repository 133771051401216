import React, { Component } from "react";
import { connect } from "react-redux";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { setTableFulltext } from "./../../../redux/actions/application/application-actions";
import SearchField from "./SearchField";

type Props = {
  appearance: "on-white" | "default";
  placeholderKey?: string;
  placeholder?: string;
  tableIdentifier: string;
  sizes?: {
    breakpoint?: string;
    focusWidth: string | number;
    blurWidth: string | number;
  }[];
  fulltextSearch: string;
  setTableFulltext: (tableIdentifier: string, fulltextSearch: string) => void;
  animate: "left" | "right" | "expand";
};

type States = {};

class TableSearchField extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  omponentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  getSnapshotBeforeUpdate(prevProps: Props, prevState: States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    const {
      tableIdentifier,
      setTableFulltext,
      fulltextSearch,
      ...fieldProps
    } = this.props;
    return (
      <SearchField
        {...fieldProps}
        onChanged={value => setTableFulltext(tableIdentifier, value)}
        value={fulltextSearch}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH],
  fulltextSearch:
    state.application.tables[props.tableIdentifier] &&
    state.application.tables[props.tableIdentifier].fulltextSearch
      ? state.application.tables[props.tableIdentifier].fulltextSearch
      : ""
});

export default connect(mapStateToProps, {
  setTableFulltext
})(TableSearchField);
