import React, { Component } from "react";
import { PlacementAll } from "../../common/Placements";

import { Whisper } from "rsuite";

type Props = {
  container?: HTMLElement | (() => HTMLElement); //	Sets the rendering container
  delay?: number; //	Delay Time
  delayHide?: number; //	Hidden delay Time
  delayShow?: number; //	Show Delay Time
  onBlur?: () => void; //	Lose Focus callback function
  onClick?: () => void; //	Click on the callback function
  onFocus?: () => void; //	Callback function to get focus
  onMouseOut?: () => void; //	Mouse leave callback function
  placement?: PlacementAll; // PlacementAll ('right')	Dispaly placement
  speaker: any; //	union: Tooltip, Popover	Displayed component
  trigger?: "click" | "hover" | "focus" | "active"; //union: 'click', 'hover', 'focus', 'active' (['hover','focus'])

  triggerRef?: React.Ref<any>;
  open?: boolean;
};

type States = {};

class BFWhisper extends Component<Props, States> {
  render() {
    return <Whisper {...this.props}>{this.props.children}</Whisper>;
  }
}

export default BFWhisper;
