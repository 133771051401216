import React, { Component } from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Drawer } from "rsuite";
import { Application } from "../../model/db/Application";
import { User } from "../../model/db/User";
import BFAccordion from "../../modules/abstract-ui/data/accordion/BFAccordion";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import "./HelpDrawer.scss";

type Props = {
  setUiConfig: (key: string, value: any) => void;
  active: boolean;
  activeApplication: Application;
  user: User;
} & WithTranslation;
type States = {};

class HelpDrawer extends Component<Props, States> {
  render() {
    const { user, activeApplication } = this.props;

    let context = "Default";

    if (user === null) {
      context = "Login";
    } else if (activeApplication === null) {
      context = "AppDrawer";
    } else {
      context = activeApplication.component;
    }

    let translations = this.props.i18n.getResourceBundle(
      this.props.i18n.language,
      null
    );

    if (!translations) {
      translations = this.props.i18n.getResourceBundle("en", null);
    }
    let contextTranslations = translations["HelpDrawer"]
      ? translations["HelpDrawer"][context]
      : undefined;

    if (!contextTranslations) {
      context = "Default";
      contextTranslations =
        translations["HelpDrawer"] && translations["HelpDrawer"][context]
          ? translations["HelpDrawer"][context]
          : {};
    }
    const faqs: { question: string; answer: string }[] = contextTranslations[
      "Questions"
    ]
      ? Object.values(contextTranslations["Questions"])
      : [];

    return (
      <Drawer
        className="help-drawer"
        show={context !== null && this.props.active}
        onHide={() =>
          this.props.setUiConfig(DefaultUIConfigs.HELP_DRAWER_ACTIVE, false)
        }
      >
        <Drawer.Header>
          <Drawer.Title>
            <Trans>{`HelpDrawer.${context}.Title`}</Trans>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {faqs.map((faq, index) => (
            <BFAccordion key={index} title={faq.question}>
              {faq.answer}
            </BFAccordion>
          ))}
        </Drawer.Body>
      </Drawer>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  activeApplication: state.uiConfig.activeApplication,
  user: state.global.user,
  active: state.uiConfig.general[DefaultUIConfigs.HELP_DRAWER_ACTIVE]
});

export default connect(mapStateToProps, { setUiConfig })(
  withTranslation()(HelpDrawer)
);

// withRouter()withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ApplicationContainer);
