import {
	SET_APPICATION_CACHE_DATA,
	SET_APPICATION_CACHE_DEPRECATED,
	SET_APPICATION_CACHE_ERROR,
	SET_APPICATION_CACHE_LOADING
} from "./../redux/actions/application/application-action-types";
import { store } from "./../redux/store";
import { HTTP } from "./../utils/Http";
import DataBus from "./DataBus";

export const CacheSubs = {
	CACHE_DEPRECATE: "CACHE_DEPRECATE",
	CACHE_LOAD_DATA: "CACHE_LOAD_DATA"
};

export interface CacheDeprecate {
	oType: "group" | "user" | "asset";
	assetType?: string;
	id: string;
}
export interface CacheLoadData {
	oType: "group" | "user" | "asset";
	assetType?: string;
	id: string;
	forceReload?: boolean;
}

export const CACHE_TTL = 1000 * 30;
class CacheServiceClass {
	init() {
		DataBus.subscribe<CacheDeprecate>(CacheSubs.CACHE_DEPRECATE, data => this.setDataDeprecated(data));
		DataBus.subscribe<CacheLoadData>(CacheSubs.CACHE_LOAD_DATA, data => this.getData(data));
	}

	setDataDeprecated(param: CacheDeprecate) {
		store.dispatch({
			type: SET_APPICATION_CACHE_DEPRECATED,
			oType: param.oType,
			id: param.id,
			assetType: param.assetType
		});
	}

	getData(param: CacheLoadData) {
		return new Promise<any>((resolve, reject) => {
			const currentCachedType = store.getState().application.cache[param.oType !== "asset" ? param.oType : param.assetType];
			let currentCachedData = currentCachedType ? currentCachedType[param.id] : null;

			let reloadData = true;
			if (currentCachedData) {
				if (currentCachedData.state === "loading") {
					reloadData = false;
				} else if (currentCachedData.state === "cached") {
					if (!currentCachedData.deprecated && Number(new Date()) - currentCachedData.timestamp < currentCachedData.ttl) {
						reloadData = false;
					}
				}
			}

			if (param.forceReload || reloadData) {
				store.dispatch({
					type: SET_APPICATION_CACHE_LOADING,
					oType: param.oType,
					id: param.id,
					assetType: param.assetType
				});

				HTTP.get({
					url: `${param.oType !== "asset" ? param.oType : param.oType + "/" + param.assetType}/${param.id}`,
					withCredentials: true,
					headers: {
						"Content-Type": "application/json"
					}
				})
					.then(data => {
						store.dispatch({
							type: SET_APPICATION_CACHE_DATA,
							oType: param.oType,
							id: param.id,
							data,
							ttl: CACHE_TTL, //todo configurable value,
							assetType: param.assetType
						});
						resolve(data);
					})
					.catch(err => {
						store.dispatch({
							type: SET_APPICATION_CACHE_ERROR,
							oType: param.oType,
							id: param.id,
							error: err,
							assetType: param.assetType
						});
						reject(err);
					});
			} else {
				if (currentCachedData.state === "loading") {
					const intervalId = setInterval(() => {
						let currentCachedData = store.getState().application.cache[
							param.oType !== "asset" ? param.oType : param.assetType
						][param.id];
						if (currentCachedData.state === "error") {
							clearInterval(intervalId);
							reject(currentCachedData.error);
						} else if (currentCachedData.state === "cached") {
							clearInterval(intervalId);
							resolve(currentCachedData.data);
						}
					}, 100);
				} else if (currentCachedData.state === "cached") {
					resolve(currentCachedData.data);
				}
			}
		});
	}
}

const CacheService = new CacheServiceClass();

export default CacheService;
