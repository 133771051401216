import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Application } from "../../model/db/Application";
import { setActiveApplication, setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import { AbstractComponent } from "../../utils/abstracts/AbstractComponent";
import "./ApplicationContainer.scss";

type Props = {
	setUiConfig: (key: string, value: any) => void;
	setActiveApplication: (appConfig: Application) => void;
	applicationConfig: Application;
};
type States = {};

class ApplicationContainer extends AbstractComponent<Props, States> {
	componentDidMount(): void {
		DataBus.emit("__APP", this.props.applicationConfig, true);

		this.props.setUiConfig(DefaultUIConfigs.HEADER_APP_DRAWER, true);
		this.props.setActiveApplication(this.props.applicationConfig);
	}

	componentWillUnmount(): void {
		this.props.setUiConfig(DefaultUIConfigs.HEADER_APP_DRAWER, false);
		this.props.setActiveApplication(null);
	}

	render() {
		return (
			<div className="view-application-container">
				<Helmet>
					<title>{this.props.applicationConfig.displayName}</title>
				</Helmet>
				{this.props.children}
			</div>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	user: state.global.user
});

export default connect(mapStateToProps, { setUiConfig, setActiveApplication })(ApplicationContainer);
