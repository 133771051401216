import { css } from "emotion";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BfCard from "../../../modules/abstract-ui/layout/card/BfCard";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../../layouts/IComponent";

type Props = {
  component: IComponent;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class CardComponent extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    const { component } = this.props;
    if (!this.shoudBeRendered()) {
      return null;
    }
    return (
      <BfCard
        className={`${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        {ComponentsMapper.createElement(component)}
      </BfCard>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(CardComponent))
);
