import { ThunkDispatch } from "redux-thunk";
import { User } from "../model/db/User";
import { JsonProperties } from "../modules/generic-forms/util/JsonValidation";
import { setAssetTypeConfig, setMandatorData, setUserData } from "../redux/actions/global/global-actions";
import { MandatorData } from "../redux/reducers/global/GlobalInterface";
import { handleError } from "../utils/ErrorCodes";
import { HTTP } from "../utils/Http";

export const logoutUser = (onSuccess: () => void, onError: (err) => void) => {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		HTTP.post({
			url: "/auth/logout"
		})
			.then(data => {
				dispatch(setUserData(null));

				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(err => {
				if (onError) {
					onError(err);
				}
			});
	};
};

export const loginUser = (
	email: string,
	password: string,
	mandator: string,
	onSuccess: (data: User) => void,
	onError: (err: any) => void
) => {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		HTTP.post({
			url: "/auth/login",
			withCredentials: true,
			bodyParams: {
				email: email,
				mandator: mandator,
				password: password
			},
			headers: {
				"Content-Type": "application/json"
			}
			// withCredentials:true
		})
			.then(response => {
				dispatch(getUserData(onSuccess, onError));
			})
			.catch(err => {
				handleError(err);
				if (onError) {
					onError(err);
				}
			});
	};
};

export const getUserData = (onSuccess: (data: User) => void, onError: (err: any) => void) => {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		HTTP.get({
			url: "/user/info",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(data => {
				data = data as User;

				HTTP.get({
					url: "/mandator/privateInfo",
					queryParams: {
						param: {
							includePublicData: true
						}
					},
					withCredentials: true,
					headers: {
						"Content-Type": "application/json"
					}
				})
					.then(mandatorData => {
						mandatorData = mandatorData as MandatorData;

						dispatch(setMandatorData(mandatorData));

						HTTP.get({
							url: "/assettype/assettypes",
							withCredentials: true,
							headers: {
								"Content-Type": "application/json"
							}
						})
							.then(assetTypeData => {
								assetTypeData = assetTypeData as {
									name: string;
									formDefinition: { [key: string]: { [key: string]: any } };
									properties: JsonProperties;
								}[];

								dispatch(setAssetTypeConfig(assetTypeData));
								dispatch(setUserData(data));

								if (onSuccess) {
									onSuccess(data);
								}
							})
							.catch(err => {
								if (onError) {
									onError(err);
								}
							});
					})
					.catch(err => {
						if (onError) {
							onError(err);
						}
					});
			})
			.catch(err => {
				if (onError) {
					onError(err);
				}
			});
	};
};
