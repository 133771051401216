import React, { Component } from "react";
import { connect } from "react-redux";

import { WithTranslation, withTranslation } from "react-i18next";

import { AppState } from "../../../../redux/store";

import { RouteComponentProps, withRouter } from "react-router";
import { DashboardConfig } from "../../../../model/common/DashboardConfig";

type Props = {
  dashboardConfig: DashboardConfig;
} & RouteComponentProps &
  WithTranslation;

type States = {};

class TestDashboardComponent extends Component<Props, States> {
  render() {
    const { dashboardConfig } = this.props;

    return (
      <div
        className="test-dashboard-component"
        style={{ width: "100%", height: "100%", backgroundColor: "#fff" }}
      ></div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(TestDashboardComponent))
);
