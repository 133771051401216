import React, { Component } from "react";

import { FormSpy } from "react-final-form";
import ExpressionHelper from "./util/ExpressionHelper";

type Props = {
  conditions: string[];
  render: (replacedStrings: string[]) => React.ReactNode;
};

type States = {};

class GenericReplaceVariablesWrapper extends Component<Props, States> {
  render() {
    const { conditions, render } = this.props;

    let needFormSpy = false;
    if (
      conditions.filter(
        condition => ExpressionHelper.getVariableMatches(condition) !== null
      ).length !== 0
    ) {
      needFormSpy = true;
    }

    if (needFormSpy) {
      return (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            const replacedStrings = conditions.map(condition =>
              ExpressionHelper.replaceVariables(condition, values)
            );

            return render(replacedStrings);
          }}
        </FormSpy>
      );
    } else {
      return render(conditions);
    }
  }
}

export default GenericReplaceVariablesWrapper;
