import { IComponent } from "./../../../configurable/layouts/IComponent";

export const LayoutUserprofile: IComponent = {
	_component: "NavLayout",
	identifier: "profileModal",
	initialPage: "profile",
	appearance: "tabs",
	hideTextBreakpoint: 800,
	routeInterception: "${location}.split('__userprofile')[0] + '__userprofile/' + ${routeTo} ",
	subPages: {
		profile: {
			orderIndex: 1,
			textKey: "UserModal.Profile.Title",
			icon: {
				type: "bf",
				data: "single-neutral"
			},
			component: {
				_component: "Div",
				style: {
					paddingLeft: 20
				},
				components: {
					section1: {
						_component: "CardSection",
						titleKey: "UserModal.Profile.Title",
						contentComponents: {
							formElement: {
								_component: "form",
								oType: "user",
								formSelector: "selfProfile",
								formData: "#{userProps}",
								submitUrl: "'user'",
								submitMethod: "'patch'",
								identifier: "self-profile-form",
								handleErrorMessaging: true,
								onSubmitSuccess: [
									{
										eventKey: "'SET_USER_DATA'",
										data: '{"user": ${value}}'
									},
									{
										eventKey: "'TOAST'",
										data: {
											type: "success",
											textKey: "UserModal.Profile.SuccessfullyUpdated",
											duration: 3500
										}
									}
								],
								onSubmitError: []
							}
						},
						postHeaderComponents: {
							a1: {
								_component: "action",
								actionId: "selfProfileFormPurpose-edit",
								appearance: "primary",
								style: {
									marginTop: 5,
									marginBottom: 5,
									marginRight: 5
								},
								icon: {
									data: "pencil-write-1",
									type: "bf",
									style: {
										fontSize: "1.6em"
									}
								}
							}
						},
						footerComponents: {
							fill: {
								_component: "fill"
							},
							a3: {
								_component: "action",
								actionId: "selfProfileFormPurpose-abort",
								textKey: "Global.Buttons.abort",
								iconPosition: "left",
								appearance: "primary",
								style: {
									marginTop: 5,
									marginBottom: 5,
									marginRight: 5
								},
								icon: {
									data: "close",
									style: {
										marginRight: 10
									}
								}
							},
							a4: {
								_component: "action",
								actionId: "selfProfileFormPurpose-submit",
								textKey: "Global.Buttons.submit",
								iconPosition: "left",
								appearance: "primary",
								style: {
									marginTop: 5,
									marginBottom: 5,
									marginRight: 5
								},
								icon: {
									data: "save",
									style: {
										marginRight: 10
									}
								}
							}
						}
					},
					section2: {
						_component: "CardSection",
						titleKey: "UserModal.Profile.Public.Title",
						contentComponents: {
							formElement: {
								_component: "form",
								oType: "user",
								formSelector: "userPublic",
								formData: "#{userProps}",
								identifier: "user-form"
							}
						},
						postHeaderComponents: {
							a1: {
								_component: "action",
								actionId: "userPublicDataFormPurpose-edit",
								appearance: "primary",
								style: {
									marginTop: 5,
									marginBottom: 5,
									marginRight: 5
								},
								icon: {
									data: "pencil-write-1",
									type: "bf",
									style: {
										fontSize: "1.6em"
									}
								}
							}
						},
						footerComponents: {
							fill: {
								_component: "fill"
							},
							a3: {
								_component: "action",
								actionId: "userPublicDataFormPurpose-abort",
								textKey: "Global.Buttons.abort",
								iconPosition: "left",
								appearance: "primary",
								style: {
									marginTop: 5,
									marginBottom: 5,
									marginRight: 5
								},
								icon: {
									data: "close",
									style: {
										marginRight: 10
									}
								}
							},
							a4: {
								_component: "action",
								actionId: "userPublicDataFormPurpose-submit",
								textKey: "Global.Buttons.submit",
								iconPosition: "left",
								appearance: "primary",
								style: {
									marginTop: 5,
									marginBottom: 5,
									marginRight: 5
								},
								icon: {
									data: "save",
									style: {
										marginRight: 10
									}
								}
							}
						}
					}
				}
			}
		},
		organization: {
			orderIndex: 2,
			textKey: "UserModal.Organization.Title",
			icon: {
				type: "bf",
				data: "building-1"
			},
			component: {
				_component: "Div",
				style: {
					paddingLeft: 20
				},
				components: {
					section1: {
						_component: "CardSection",
						titleKey: "UserModal.Organization.Title",
						contentComponents: {
							formElement: {
								_component: "form",
								oType: "user",
								formSelector: "organizationView",
								formData: "#{mandator}",
								submitUrl: "'user'",
								submitMethod: "'patch'",
								identifier: "organization-form",
								handleErrorMessaging: true,
								onSubmitSuccess: [
									{
										eventKey: "'SET_ORGANIZATION_DATA'",
										data: '{"orga": ${value}}'
									},
									{
										eventKey: "'TOAST'",
										data: {
											type: "success",
											textKey: "UserModal.Organization.SuccessfullyUpdated",
											duration: 3500
										}
									}
								],
								onSubmitError: [],
								stateSubscriptions: ["mandator"]
							}
						}
					}
				}
			}
		},
		devices: {
			orderIndex: 2,
			textKey: "UserModal.Devices.Title",
			icon: {
				type: "bf",
				data: "mobile-phone-1"
			},
			component: {
				_component: "Div",
				style: {
					paddingLeft: 20
				},
				components: {
					a: {
						_component: "text",
						text: "devices"
					}
				}
			}
		},
		settings: {
			orderIndex: 3,
			textKey: "UserModal.Settings.Title",
			icon: {
				type: "bf",
				data: "cog-1"
			},
			component: {
				_component: "Div",
				style: {
					paddingLeft: 20
				},
				components: {
					a: {
						_component: "text",
						text: "settings"
					}
				}
			}
		},
		history: {
			orderIndex: 3,
			textKey: "UserModal.History.Title",
			icon: {
				type: "bf",
				data: "time-clock-circle"
			},
			component: {
				_component: "Div",
				style: {
					height: "100%"
				},
				components: {
					logContent: {
						_component: "log",
						className: "log-page",
						targetID: "#{userProps}._id",
						identifier: "profileHistoryLog",
						oType: ["user"],
						style: {
							height: "100%"
						}
					}
				}
			}
		}
	}
};

var colorPalette = ["#c12e34", "#e6b600", "#0098d9", "#2b821d", "#005eaa", "#339ca8", "#cda819", "#32a487"];

export const defaultChartConfiguration = {
	color: colorPalette,

	line: {
		lineStyle: {
			width: 6
		}
	}
};
