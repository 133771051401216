import React, { CSSProperties } from "react";
import { FormSpy } from "react-final-form";
import { WithTranslation, withTranslation } from "react-i18next";
import NavigationPrompt from "react-router-navigation-prompt";
import { Loader } from "rsuite";
import { AppState } from "../../../redux/store";
import { AbstractComponent } from "../../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../../utils/Constants";
import { GenericFormsLayoutProps } from "../../generic-forms/BaseElement";
import GenericLayoutComponent from "../../generic-forms/GenericLayoutComponent";
import "./GFAutosave.scss";

type Props = {
	autosaveDelay: number;
	style?: CSSProperties;
} & WithTranslation &
	GenericFormsLayoutProps;

type States = {
	saving: "idle" | "delay" | "request";
	values: any;
};

class GFAutosave extends AbstractComponent<Props, States> {
	static defaultProps = {
		autosaveDelay: 1000
	};
	readonly state: States = {
		saving: "idle",
		values: null
	};

	autosaveTimout: NodeJS.Timeout;

	componentDidMount() {
		this.subscribe(DataBusSubKeys.SUBMIT_START, data => {
			if (data.id) {
				if (data.id === (this.props.formProps as any).form.mutators.getIdentifier()) {
					this.setState({ saving: "request" });
				}
			}
		});

		this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, data => {
			if (data.id) {
				if (data.id === (this.props.formProps as any).form.mutators.getIdentifier()) {
					this.setState({ saving: "idle" });
				}
			}
		});
	}

	submitForm() {
		(this.props.formProps as any).form.submit();
	}

	render() {
		const { condition, stateSubscriptions, formProps, allProperties, style } = this.props;
		return (
			<GenericLayoutComponent
				stateSubscriptions={stateSubscriptions}
				condition={condition}
				style={style}
				render={styleProps => {
					return (
						<>
							<FormSpy subscription={{ errors: true, values: true }}>
								{({ errors, values }) => {
									if (this.state.values !== values) {
										if (this.autosaveTimout) {
											clearTimeout(this.autosaveTimout);
										}
										if (Object.keys(errors).length === 0) {
											this.setState({ saving: "delay", values: values });
											this.autosaveTimout = setTimeout(() => {
												this.submitForm();
											}, this.props.autosaveDelay);
										}
									}
									if (this.state.saving === "request") {
										return (
											<div className={`gf-autosave`} style={styleProps}>
												<Loader size="sm" />
											</div>
										);
									} else {
										return null;
									}
								}}
							</FormSpy>

							<NavigationPrompt
								when={(currentLocation, nextLocation) =>
									currentLocation.pathname !== nextLocation.pathname && this.state.saving !== "idle"
								}
							>
								{({ onConfirm, onCancel, isActive }) => {
									if (isActive) {
										this.submitForm();
										onConfirm();
									}

									return null;
								}}
							</NavigationPrompt>
						</>
					);
				}}
			/>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({});

export default withTranslation()(GFAutosave);
