import { Component, CSSProperties } from "react";
import {
  ActionData,
  ActionDataEvent,
  ActionDataStateChange
} from "../../model/common/DataBus/ActionData";
import ExpressionHelper from "../../modules/generic-forms/util/ExpressionHelper";
import DataBus from "../../services/DataBus";
import Tools, { StyleConfiguration } from "../Tools";
import { store } from "./../../redux/store";

export interface SendEvent {
  eventKey: string;
  condition?: string;
  isState?: boolean;
  data: any;
}

export type AbstractProps = {
  stateSubscriptions?: string[];
  actionIdMapping?: { [actionId: string]: string };
  identifier?: string;
  condition?: string;
  style?: StyleConfiguration;
  viewportWidth?: number;
  permission?: string[] | string;
};

export type AbstractStates = {
  params?: any;
  usedStyle?: CSSProperties;
};

export abstract class AbstractComponent<T, E> extends Component<
  T & AbstractProps,
  E & AbstractStates
> {
  private usedSubKeys = [];
  private populatedActions = new Set<string>();

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps: AbstractProps, nextState: AbstractStates) {
    //do not update on every viewport change
    if (nextState.usedStyle !== this.state.usedStyle) {
      return true;
    }

    if (nextProps.viewportWidth !== this.props.viewportWidth) {
      return false;
    }
    return true;
  }

  componentWillUnmount(): void {
    this.usedSubKeys.forEach(subId => DataBus.unsubscribe(subId));

    this.populatedActions.forEach(actionId => {
      this.populateButtonState(actionId, { hidden: true });
    });
  }

  componentWillMount(): void {
    let usedStyle = Tools.getActiveBreakpointProperties(
      this.props.viewportWidth,
      this.props.style
    );
    this.setState({
      usedStyle: usedStyle as any
    });

    if (this.props.stateSubscriptions) {
      this.props.stateSubscriptions.forEach(key =>
        this.subscribe(key, data => {
          this.setState(
            {
              params: {
                ...(this.state && this.state.params ? this.state.params : {}),
                [key]: data
              }
            },
            () => {}
          );
        })
      );
    }
  }

  componentWillReceiveProps(nextProps: AbstractProps) {
    if (Array.isArray(this.props.style)) {
      if (nextProps.viewportWidth !== this.props.viewportWidth) {
        this.setState({
          usedStyle: Tools.getActiveBreakpointProperties(
            nextProps.viewportWidth,
            nextProps.style
          ) as any
        });
      }
    }
  }

  shoudBeRendered() {
    if (this.props.permission) {
      const applicationPropsAll = store.getState().uiConfig.activeApplication;

      //TODO uncomment these lines
      // if(!applicationPropsAll.permissions.ADMIN) {

      if (!applicationPropsAll.permissions) {
        return false;
      }
      if (typeof this.props.permission === "string") {
        if ((!applicationPropsAll.permissions as any)[this.props.permission]) {
          return false;
        }
      } else {
        for (const permission of this.props.permission as string[]) {
          if ((!applicationPropsAll.permissions as any)[permission]) {
            return false;
          }
        }
      }
      // }
    }

    if (this.props.condition) {
      return this.evaluateExpression(this.props.condition);
    }
    return true;
  }

  protected replaceStringVariables(
    condition: string,
    values: { [key: string]: any } = {}
  ) {
    const applicationPropsAll = store.getState().uiConfig.activeApplication;
    const userPropsAll = store.getState().global.user;

    const params = this.state && this.state.params ? this.state.params : {};
    let paramsToEval = {
      ...params
    };
    if (applicationPropsAll) {
      const { config, permissions, ...appProps } = applicationPropsAll;

      paramsToEval = {
        appProps: appProps,
        ...paramsToEval
      };
    }
    if (userPropsAll) {
      const { permissions, mandator_info, ...userProps } = userPropsAll;

      paramsToEval = {
        userProps: userProps,
        permissions: permissions,
        ...paramsToEval
      };
    }

    return ExpressionHelper.replaceVariables(condition, values, paramsToEval);
  }

  protected evaluateExpression(
    condition: string,
    values: { [key: string]: any } = {}
  ) {
    const applicationPropsAll = store.getState().uiConfig.activeApplication;
    const userPropsAll = store.getState().global.user;
    const params = this.state && this.state.params ? this.state.params : {};
    let paramsToEval = {
      ...params
    };
    if (applicationPropsAll) {
      const { config, permissions, ...appProps } = applicationPropsAll;

      paramsToEval = {
        appProps: appProps,
        ...paramsToEval
      };
    }
    if (userPropsAll) {
      const { permissions, mandator_info, ...userProps } = userPropsAll;

      paramsToEval = {
        userProps: userProps,
        permissions: permissions,
        ...paramsToEval
      };
    }

    return ExpressionHelper.evaluateExpression(condition, values, paramsToEval);
  }

  protected subscribe<T>(key: string, callback: (T) => void) {
    const subId = DataBus.subscribe(key, callback);
    this.usedSubKeys.push(subId);
    return subId;
  }

  protected emit<T>(key: string, data: T, isState = false) {
    DataBus.emit(key, data, isState);
  }

  protected emitComponentEvent(data: any) {
    if (this.props.identifier) {
      DataBus.emit(this.props.identifier, data, false);
    } else {
      throw "populated a component state with no subId";
    }
  }

  protected populateComponentState(state: { [key: string]: any }) {
    if (this.props.identifier) {
      DataBus.emit(this.props.identifier, state, true);
    } else {
      throw "populated a component state with no subId";
    }
  }

  protected subscribeActionEvent(
    actionId: string,
    callback: (data: ActionDataEvent) => void
  ) {
    let mappedId = this.getMappingActionId(actionId);

    this.subscribe(mappedId, (subData: ActionData) => {
      if (subData.type === "click") {
        callback(subData);
      }
    });
  }

  protected populateButtonState(
    actionId,
    state: {
      hidden?: boolean;
      focus?: boolean;
      loading?: boolean;
      disabled?: boolean;
      toggled?: boolean;
    }
  ) {
    let mappedId = this.getMappingActionId(actionId);

    this.emit<ActionDataStateChange>(
      mappedId,
      {
        type: "state",
        ...state
      },
      true
    );
    this.populatedActions.add(mappedId);
  }

  protected getMappingActionId(actionId: string) {
    const { actionIdMapping } = this.props;
    let mappedId = actionId;
    if (actionIdMapping && actionIdMapping[actionId]) {
      mappedId = actionIdMapping[actionId];
    }
    return mappedId;
  }

  protected handleEvents(events: SendEvent[], values?: any) {
    if (events) {
      events.forEach(event => {
        let sendEvent = true;

        if (event.condition) {
          sendEvent = this.evaluateExpression(event.condition, values);
        }
        if (sendEvent) {
          this.emit(
            this.evaluateExpression(event.eventKey, values),
            typeof event.data === "string"
              ? this.evaluateExpression(event.data, values)
              : event.data,
            event.isState
          );
        }
      });
    }
  }
}
