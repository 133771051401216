import React, { CSSProperties } from "react";
import { AbstractComponent } from "../../../utils/abstracts/AbstractComponent";

type Props = {
  horizontal?: boolean;
  style?: CSSProperties;
};

type States = {};

class Separator extends AbstractComponent<Props, States> {
  render() {
    let { horizontal, style } = this.props;
    style = { ...style };

    style["width"] = style["width"] ? style["width"] : horizontal ? "100%" : 1;
    style["height"] = style["height"]
      ? style["height"]
      : horizontal
      ? 1
      : undefined;
    style["background"] = style["background"] ? style["background"] : "#ccc";
    style["marginLeft"] = style["marginLeft"] ? style["marginLeft"] : 5;
    style["marginRight"] = style["marginRight"] ? style["marginRight"] : 5;
    style["marginTop"] = style["marginTop"] ? style["marginTop"] : 5;
    style["marginBottom"] = style["marginBottom"] ? style["marginBottom"] : 5;

    return <div style={style} />;
  }
}

export default Separator;
