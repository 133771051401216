import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Notification } from "../../model/notifications/Notification";
import { AppState } from "../../redux/store";
import BFButton from "../abstract-ui/general/Button/BFButton";
import { clearNotifications } from "./../../redux/actions/notifications/notifications-actions";
import NotificationEntry from "./NotificationEntry";
import "./NotificationsMenu.scss";

type Props = {
	clearNotifications: () => void;
	notifications: Notification[];
	embedded?: boolean;
} & RouteComponentProps &
	WithTranslation;

type States = {};

class NotificationsMenu extends Component<Props, States> {
	static defaultProps = {};
	readonly state: States = {};

	// omponentDidMount() {}

	// componentWillUnmount() {}

	// componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

	// getSnapshotBeforeUpdate(prevProps: Props, prevState: States) {}

	shouldComponentUpdate(nextProps: Props, nextState: States) {
		return true;
	}

	render() {
		const { embedded, notifications, i18n } = this.props;
		return (
			<div className={`notifications-menu ${embedded ? "embedded" : ""}`}>
				<div className={`header`}>
					<div className={`title`}>{i18n.t("Notifications.Title")}</div>
					<BFButton
						onClick={() => this.props.clearNotifications()}
						disabled={!notifications || notifications.length === 0}
						appearance={"clear-on-white"}
						icon={{ type: "bf", data: "bin-1" }}
					/>
				</div>

				<div className={`content`}>
					{notifications && notifications.length > 0 ? (
						notifications.map(notification => <NotificationEntry key={notification.id} notification={notification} />)
					) : (
						<div className={`empty-label`}>{i18n.t("Notifications.EmptyText")}</div>
					)}
				</div>
				<div className={`footer`}></div>
			</div>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({
	notifications: state.notifications.entries
});

export default withRouter(
	connect(mapStateToProps, {
		clearNotifications
	})(withTranslation()(NotificationsMenu))
);
