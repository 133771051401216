import { Locale } from "./locale";

export const localeEN: Locale | any = {
  translation: {
    applicationTitle: "BenfaSoft - platform",

    common: {
      actions: {
        insertObject: "Insert object",
        updateObject: "Update object",
        deleteObject: "Delete object",
        viewObject: "View Object"
      }
    },

    errorCodes: {
      permissionMissing: {
        delete: "You dont have the permission to delete this object."
      }
    },

    dateLocales: {
      sunday: "Su",
      monday: "Mo",
      tuesday: "Tu",
      wednesday: "We",
      thursday: "Th",
      friday: "Fr",
      saturday: "Sa",
      ok: "OK",
      today: "Today",
      yesterday: "Yesterday",
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds"
    }
  }
};
