import flatten from "flat";
import { CSSProperties } from "react";
import Log from "../../../debug/Log";
import ExpressionHelper from "./ExpressionHelper";
import JsonValidationException from "./JsonValidationException";
import Validators, { ValidatorTypes } from "./Validatos";

export interface JsonProperties {
	[key: string]: JsonPropertyComponent | { type: string; [key: string]: any };
}

export interface Validator {
	type: ValidatorTypes;
	param?: any;
	condition?: string;
	text: string;
	level: "error" | "warning";
}

export interface JsonPropertyCommon {
	type: JsonPropertyTypes;
	condition?: string;
	validators?: Validator[];
	label?: string;
	containerStyleProps?: CSSProperties;
	defaultValue?: any;
	disabled?: boolean;
	submitType?: "difference" | "ignore" | "add";
}

export interface OptionEntry {
	label: string;
	value: any;
}

export interface OptionConditionGroup {
	condition: string;
	options: OptionEntry[];
}

export type JsonPropertyTypes =
	| "select"
	| "text"
	| "textarea"
	| "number"
	| "toggle"
	| "date"
	| "checkbox"
	| "checkbox-group"
	| "radio"
	| "mail"
	| "website"
	| string;

export interface JsonPropertySelect extends JsonPropertyCommon {
	type: "select";
	options: (OptionEntry | OptionConditionGroup)[];
	multiple: boolean;
	labelPosition?: "top" | "left";
	placeholder?: string;
	cleanable?: boolean;
}

export interface JsonPropertyText extends JsonPropertyCommon {
	type: "text";
	placeholder?: string;

	autoComplete?: string;
	autoCapitalize?: string;
	autoCorrect?: string;
	prefix?: string;
	suffix?: string;
	labelPosition?: "top" | "left";
}

export interface JsonPropertyTextArea extends JsonPropertyCommon {
	type: "textarea";
}

export interface JsonPropertyNumber extends JsonPropertyCommon {
	type: "number";
	min?: number;
	max?: number;
	decimalFixed?: number;
	placeholder?: string;
	prefix?: string;
	suffix?: string;
	labelPosition?: "top" | "left";
}

export interface JsonPropertyMail extends JsonPropertyCommon {
	type: "mail";
	placeholder?: string;

	autoComplete?: string;
	autoCapitalize?: string;
	autoCorrect?: string;
	prefix?: string;
	suffix?: string;
	labelPosition?: "top" | "left";
}

export interface JsonPropertyWebsite extends JsonPropertyCommon {
	type: "website";
	placeholder?: string;

	autoComplete?: string;
	autoCapitalize?: string;
	autoCorrect?: string;
	prefix?: string;
	suffix?: string;
	labelPosition?: "top" | "left";
}

export interface JsonPropertyToggle extends JsonPropertyCommon {
	type: "toggle";
	defaultValue?: boolean;
	labelPosition?: "left" | "right";
}

export interface JsonPropertyRadioGroup extends JsonPropertyCommon {
	type: "radio";
	appearance?: "default" | "picker";
	inline?: boolean;
	options: (OptionEntry | OptionConditionGroup)[];
}

export interface JsonPropertyDate extends JsonPropertyCommon {
	type: "date";
	labelPosition?: "top" | "left";
	min?: Date | string;
	max?: Date | string;
	cleanable?: boolean;
	placeholder?: string;
	format?: string;
}

export interface JsonPropertyCheckbox extends JsonPropertyCommon {
	type: "checkbox";
	defaultChecked?: boolean;
}

export interface JsonPropertyCheckboxGroup extends JsonPropertyCommon {
	type: "checkbox-group";
	inline?: boolean;
	options: (OptionEntry | OptionConditionGroup)[];
}

export type JsonPropertyComponent =
	| JsonPropertySelect
	| JsonPropertyText
	| JsonPropertyNumber
	| JsonPropertyToggle
	| JsonPropertyCheckbox
	| JsonPropertyDate
	| JsonPropertyTextArea
	| JsonPropertyCheckboxGroup
	| JsonPropertyMail
	| JsonPropertyWebsite
	| JsonPropertyRadioGroup;

class JsonValidationClass {
	reduceToValidInput(jsonValue: { [key: string]: any }, jsonProperties: JsonProperties) {
		const val = {};

		const flattenedInput = flatten(jsonValue, {
			safe: true,
			delimiter: "|"
		});

		Object.keys(jsonProperties).forEach(key => {
			val[key.replace("\\|", ".")] = flattenedInput[key];
		});
		const valFlattened = flatten.unflatten(val, {});

		Log.debug("reduceToValidInput", jsonValue, flattenedInput, valFlattened);
		return valFlattened;
	}

	/***
	 * validates a json value with given validation json allProperties, will return an object which holds the
	 * errors and warnings of the json
	 * @param jsonValue
	 * @param jsonProperties
	 */
	validateJson(jsonValue: { [key: string]: any }, jsonProperties: JsonProperties) {
		const output = {
			error: {},
			warning: {}
		};

		// set all props that were not set, but were expected by jsonProeprties to undefined,
		// that the validations can work on them too
		Object.keys(jsonProperties).forEach(key => {
			if (jsonValue[key] === undefined) {
				jsonValue[key] = undefined;
			}
		});

		Object.keys(jsonValue).forEach(key => {
			const value = jsonValue[key];
			const jsonPropValidation = jsonProperties[key];

			if (!jsonPropValidation) {
				if (key !== "_id") {
					throw new JsonValidationException(key, "no json validation prop available");
				}
			}

			let skipValidation = jsonPropValidation === undefined;
			if (
				jsonPropValidation &&
				jsonPropValidation.condition &&
				!ExpressionHelper.evaluateExpression(jsonPropValidation.condition, jsonValue)
			) {
				if (value !== undefined) {
					throw new JsonValidationException(key, "shouldn't be set by condition");
				} else {
					skipValidation = true;
				}
			}
			if (!skipValidation) {
				if (!Validators.validateType(value, jsonPropValidation as JsonPropertyComponent, jsonValue)) {
					// throw new JsonValidationException(key, "invalid type");
					output.error[key] = "__not_a_valid_type__";
				} else {
					if (jsonPropValidation.validators) {
						let failed = false;
						for (let i = 0; i < jsonPropValidation.validators.length && !failed; i++) {
							const validator = jsonPropValidation.validators[i];
							const success = Validators.validateValue(key, value, validator, jsonValue);

							if (!success) {
								output[validator.level][key] = validator.text;
								if (validator.level === "error") {
									failed = true;
								}
							}
						}
					}
				}
			}
		});

		return output;
	}
}

const JsonValidation = new JsonValidationClass();
export default JsonValidation;
