import echarts, {
  EChartOption,
  ECharts,
  EChartsResponsiveOption
} from "echarts";
import { css } from "emotion";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";

type Props = {
  options: EChartOption | EChartsResponsiveOption;
  viewportHeight: number;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

const CHART_CREATION_DELAY = 600;
const CHART_RESIZE_TIMEOUT = 200;

class ChartComponent extends AbstractStylableComponent<Props, States> {
  chart: ECharts;
  resizeTimeout: NodeJS.Timeout;

  _mounted;
  static defaultProps = {};
  readonly state: States = {};

  componentDidMount() {
    this._mounted = true;
    this.chart = echarts.init(
      document.querySelector("#" + this.props.identifier),
      "default"
    );

    this.chart.showLoading("default", {
      text: ""
    });
    setTimeout(() => {
      this.chart.resize();
    });
    setTimeout(() => {
      if (this._mounted) {
        this.chart.setOption(this.props.options);
        this.chart.hideLoading();
      }
    }, CHART_CREATION_DELAY);
  }

  componentWillUnmount() {
    this._mounted = false;
    this.chart.dispose();
    super.componentWillUnmount();
  }

  // componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  // getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    if (
      nextProps.viewportWidth !== this.props.viewportWidth ||
      nextProps.viewportHeight !== this.props.viewportHeight
    ) {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.chart.resize({
          silent: true
        });
        this.resizeTimeout = null;
      }, CHART_RESIZE_TIMEOUT);
    }
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: "100%", position: "relative" }}
        className={`ChartComponent ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        <div
          id={this.props.identifier}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH],
  viewportHeight: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_HEIGHT]
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ChartComponent))
);
