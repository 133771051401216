import { ThunkDispatch } from "redux-thunk";
import { Application } from "../model/db/Application";
import { setUiConfig } from "../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { initializeActions } from "./InitActionService";

export const initializeApplication = (
  application: Application,
  onSuccess?: () => void,
  onError?: (err) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(
      setUiConfig(DefaultUIConfigs.APPLICATION_IS_INITIALIZING, application)
    );
    dispatch(setUiConfig(DefaultUIConfigs.BLOCK_UI_INPUT, true));

    if (
      application &&
      application.config &&
      application.config["initActions"]
    ) {
      initializeActions(
        Object.values(application.config["initActions"]),
        dispatch
      ).then(() => {
        if (onSuccess) {
          onSuccess();
        }

        dispatch(
          setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, false)
        );
        dispatch(
          setUiConfig(DefaultUIConfigs.APPLICATION_IS_INITIALIZING, null)
        );
        dispatch(setUiConfig(DefaultUIConfigs.BLOCK_UI_INPUT, false));
      });
    } else {
      if (onSuccess) {
        onSuccess();
      }

      dispatch(setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, false));
      dispatch(setUiConfig(DefaultUIConfigs.APPLICATION_IS_INITIALIZING, null));
      dispatch(setUiConfig(DefaultUIConfigs.BLOCK_UI_INPUT, false));
    }
  };
};
