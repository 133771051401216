import React from "react";
import { connect } from "react-redux";
import { Drawer, Modal } from "rsuite";
import { setUiConfig } from "../../../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import "./ActionSheetDrawer.scss";

type Props = {
  title: string;
  viewportSizeSelector: "xs" | "sm" | "md" | "lg";
  active: boolean;
  onHide: () => void;
  className?: string;
};

type States = {};

class ActionSheetDrawer extends React.Component<Props, States> {
  oldState = {};

  static defaultProps = {};

  readonly state: States = {};

  render() {
    const {
      viewportSizeSelector,
      title,
      children,
      active,
      onHide,
      className
    } = this.props;

    if (viewportSizeSelector === "xs") {
      return (
        <Drawer
          show={active}
          className={`action-sheet-drawer ${className}`}
          size={"sm"}
          placement={"bottom"}
          backdrop={true}
          onHide={() => (onHide ? onHide() : null)}
        >
          <Drawer.Header>
            <Drawer.Title>{title}</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>{children}</Drawer.Body>
        </Drawer>
      );
    } else {
      return (
        <Modal
          show={active}
          className={`action-sheet-modal ${className}`}
          onHide={() => (onHide ? onHide() : null)}
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{children}</Modal.Body>
        </Modal>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  viewportSizeSelector:
    state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR]
});

export default connect(mapStateToProps, { setUiConfig })(ActionSheetDrawer);
