import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../utils/ComponentsMapper";

type Props = {
  orderIndex?: number;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class Fill extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const { orderIndex } = this.props;

    const config = {
      _component: "div",
      orderIndex: orderIndex,
      style: {
        flexGrow: 1
      },
      components: {}
    };

    return ComponentsMapper.createElement(config);
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Fill))
);
