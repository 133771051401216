import Log from "../debug/Log";
import { SET_UI_CONFIG } from "../redux/actions/ui-config/ui-config-actions-types";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { store } from "../redux/store";

export const Properties = {
  dev: {
    context: "iberio.de"
  },
  fallbackLanguage: "de",
  logLevels: {
    error:
      process.env.REACT_APP_LOG_ERROR !== undefined
        ? process.env.REACT_APP_LOG_ERROR === "true"
        : false,
    info:
      process.env.REACT_APP_LOG_INFO !== undefined
        ? process.env.REACT_APP_LOG_INFO === "true"
        : false,
    debug:
      process.env.REACT_APP_LOG_DEBUG !== undefined
        ? process.env.REACT_APP_LOG_DEBUG === "true"
        : false,
    warning:
      process.env.REACT_APP_LOG_WARNING !== undefined
        ? process.env.REACT_APP_LOG_WARNING === "true"
        : false,
    state:
      process.env.REACT_APP_LOG_STATES !== undefined
        ? process.env.REACT_APP_LOG_STATES === "true"
        : false
  },

  // serverUrl: "https://api.iberio.de", //"localhost",
  debugMode: false, //process.env.REACT_APP_DEBUG_MODE !== undefined ? process.env.REACT_APP_DEBUG_MODE === "true" : false,
  globalConfigVersion: 1
};
setTimeout(() => {
  Log.debug("PROCESS ENVIRONMENTS", process.env);
});

export const isDevelopment = () => {
  return window.location.hostname === "localhost";
};

export const devProp = (propNormal: any, propDev: any) => {
  if (isDevelopment()) {
    return propDev;
  }

  return propNormal;
};

(window as any).toggleDebugMode = () => {
  store.dispatch({
    type: SET_UI_CONFIG,
    key: DefaultUIConfigs.DEBUG,
    value: !store.getState().uiConfig.general[DefaultUIConfigs.DEBUG]
  });
};
