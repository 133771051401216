import React, { Component } from "react";
import { connect } from "react-redux";

import "./DemoComponentsView.scss";

import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../../redux/store";
import { Route, Switch, withRouter } from "react-router";
import {
  ComponentsButtonsPage,
  ComponentsFormsPage,
  ComponentsOverviewPage
} from "./DemoSubComponentPages";

type Props = {
  match: any;
  history: any;
};
type States = {};

class DemoComponentsView extends Component<Props, States> {
  render() {
    const { match } = this.props;

    return (
      <div className="demo-components-view page">
        <Switch>
          <Route path={`${match.path}/forms`} component={ComponentsFormsPage} />
          <Route
            path={`${match.path}/buttons`}
            component={ComponentsButtonsPage}
          />
          <Route path={`${match.path}/`} component={ComponentsOverviewPage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(setUserData(user, apps, permissions)) }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DemoComponentsView)
);
