import React, { Component } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";

type Props = {
  blockUiInput: boolean;
};
type States = {};

class BlockUiOverlay extends Component<Props, States> {
  render() {
    return (
      <div
        style={{
          zIndex: 99999,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: this.props.blockUiInput ? "all" : "none"
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  blockUiInput: state.uiConfig.general[DefaultUIConfigs.BLOCK_UI_INPUT]
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  // loginUser: (username:string, password:string, onSuccess:(data:LoginResponse) => void, onError:(err:any) => void) => { dispatch(loginUser(username, password, onSuccess, onError) )}
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(GlobalActions.setUserData(user, apps, permissions)) }
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockUiOverlay);

// withRouter()withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ApplicationContainer);
