import axios from "axios";
import { ThunkDispatch } from "redux-thunk";
import { setTableData } from "../redux/actions/application/application-actions";
import { HTTP } from "../utils/Http";

export type Filter = {
	name: string;
	value: any;
	op:
		| "LIKE"
		| "NOT_LIKE"
		| "EQUALS"
		| "NOT_EQUALS"
		| "LESS_THAN"
		| "LESS_THAN_EQUALS"
		| "GREATER_THAN"
		| "GREATER_THAN_EQUALS"
		| "IN"
		| "NOT_IN"
		| "BETWEEN";
};

export type MatchQueryOP = {
	type: "op";
	name: string;
	op: "eq" | "ne" | "lt" | "lte" | "gt" | "gte" | "in" | "nin";
	value: any;
};
export type MatchQueryAndOr = {
	type: "and" | "or";
	query: MatchQuery[];
};
export type MatchQuery = MatchQueryAndOr | MatchQueryOP;

export type RequestListOpts = {
	tableIdentifier: string;
	url: string;
	limit?: number;
	skip?: number;
	filters?: { [dataKey: string]: Filter }; // matchQuery?: MatchQuery,
	textQuery?: string;
	sort?: { dataKey: string; sortType: "asc" | "desc" };
	onSuccess?: (data: any) => void;
	onError?: (err: any) => void;
	additionalParams?: MatchQuery;
	append?: boolean;
};

const requestIdGen: { [key: string]: number } = {};

const convertFiltersToMatchQuery = (filters: { [dataKey: string]: Filter }): MatchQuery => {
	const keys = Object.keys(filters);

	const andMatchQueries: MatchQuery[] = [];

	keys.forEach(key => {
		const filter = filters[key];
		if (filter && filter.value !== "") {
			switch (filter.op) {
				case "EQUALS":
					andMatchQueries.push({
						type: "op",
						name: filter.name,
						value: filter.value,
						op: "eq"
					});
					break;
				case "NOT_EQUALS":
					andMatchQueries.push({
						type: "op",
						name: filter.name,
						value: filter.value,
						op: "ne"
					});
					break;
				case "IN":
					if (filter.value.length !== 0) {
						andMatchQueries.push({
							type: "op",
							name: filter.name,
							value: filter.value,
							op: "in"
						});
					}
					break;
				case "NOT_IN":
					if (filter.value.length !== 0) {
						andMatchQueries.push({
							type: "op",
							name: filter.name,
							value: filter.value,
							op: "nin"
						});
					}
					break;
				case "GREATER_THAN":
					andMatchQueries.push({
						type: "op",
						name: filter.name,
						value: filter.value,
						op: "gt"
					});
					break;
				case "GREATER_THAN_EQUALS":
					andMatchQueries.push({
						type: "op",
						name: filter.name,
						value: filter.value,
						op: "gte"
					});
					break;
				case "LESS_THAN":
					andMatchQueries.push({
						type: "op",
						name: filter.name,
						value: filter.value,
						op: "lt"
					});
					break;
				case "LESS_THAN_EQUALS":
					andMatchQueries.push({
						type: "op",
						name: filter.name,
						value: filter.value,
						op: "lte"
					});
					break;
			}
		}
	});

	if (andMatchQueries.length === 0) {
		return null;
	}

	return {
		type: "and",
		query: andMatchQueries
	};
};

export const emptyListData = (tableIdentifier: string) => {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch(
			setTableData(tableIdentifier, {
				total: 0,
				data: [],
				limit: 0,
				skip: 0,
				filters: {},
				sort: null,
				fulltextSearch: ""
			})
		);
	};
};
export const requestListData = (opts: RequestListOpts, cancelObj: { cancel?: () => void }) => {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		requestListDataHelper(opts, cancelObj, dispatch);
	};
};

const requestListDataHelper = (
	opts: RequestListOpts,
	cancelObj: { cancel?: () => void },
	dispatch: ThunkDispatch<{}, {}, any>
) => {
	const queryParams: any = {};
	if (opts.limit) {
		queryParams.limit = opts.limit;
	}
	if (opts.skip) {
		queryParams.skip = opts.skip;
	}
	if (opts.filters) {
		queryParams.matchQuery = convertFiltersToMatchQuery(opts.filters) as MatchQuery;
	}
	if (opts.additionalParams) {
		if (queryParams.matchQuery) {
			queryParams.matchQuery = {
				type: "and",
				query: [queryParams.matchQuery, opts.additionalParams]
			};
		} else {
			queryParams.matchQuery = opts.additionalParams;
		}
	}
	if (!queryParams.matchQuery) {
		queryParams.matchQuery = undefined;
	}

	if (opts.textQuery) {
		queryParams.textQuery = opts.textQuery;
	}
	if (opts.sort) {
		queryParams.sort = {
			fieldName: opts.sort.dataKey,
			sortKey: opts.sort.sortType === "asc" ? 1 : -1
		};
	}

	const requestId = requestIdGen[opts.tableIdentifier] !== undefined ? (requestIdGen[opts.tableIdentifier] + 1) % 10000 : 0;
	requestIdGen[opts.tableIdentifier] = requestId;

	const doRequest = requestId => {
		HTTP.get({
			url: opts.url,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json"
			},
			queryParams: {
				param: queryParams
			},
			cancelToken: cancelObj ? new axios.CancelToken(cancel => (cancelObj.cancel = cancel)) : undefined
		})
			.then(data => {
				if (cancelObj) {
					cancelObj.cancel = undefined;
				}

				if (requestIdGen[opts.tableIdentifier] !== requestId) {
					return;
				}
				if (data.count < data.skip) {
					opts.skip = data.count - (data.count % data.limit);
					requestListDataHelper(opts, cancelObj, dispatch);
					return;
				}

				dispatch(
					setTableData(opts.tableIdentifier, {
						append: opts.append,
						total: data.count,
						data: data.data,
						limit: data.limit,
						skip: data.skip,
						filters: opts.filters,
						sort: opts.sort
							? {
									dataKey: opts.sort.dataKey,
									sortType: opts.sort.sortType
							  }
							: null,
						fulltextSearch: opts.textQuery
					})
				);

				if (opts.onSuccess) {
					opts.onSuccess(data);
				}
			})
			.catch(err => {
				if (cancelObj) {
					cancelObj.cancel = undefined;
				}

				if (err instanceof axios.Cancel) {
					return;
				}

				if (opts.onError) {
					opts.onError(err);
				}
			});
	};
	doRequest(requestId);
};
