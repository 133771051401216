import React, { Component } from "react";

import "./BFDate.scss";
import { DatePicker } from "rsuite";
import { Placement } from "../../common/Placements";
import { WithTranslation, withTranslation } from "react-i18next";
import LabeledInput from "../LabeledInput";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";

type Props = {
  label?: string;
  labelPosition?: "top" | "left";
  tabIndex?: number;

  format?: string; // ('YYYY-MM-DD')	Format date

  // block:	boolean	Blocking an entire row
  calendarDefaultDate?: Date; //	Calendar panel default presentation date and time
  cleanable?: boolean; // (true)	Whether the selected value can be cleared
  container?: HTMLElement | (() => HTMLElement); //	Sets the rendering container
  // defaultOpen	boolean	Default value of open property
  defaultValue?: Date; //	Default value
  disabled?: boolean; //Whether disabled the component
  disabledDate?: (date: Date) => boolean; //	Disabled date
  disabledHours?: (hour: number, date: Date) => boolean; //	Disabled hours
  disabledMinutes?: (minute: number, date: Date) => boolean; //	Disabled minutes
  disabledSeconds?: (second: number, date: Date) => boolean; //	Disabled seconds
  hideHours?: (hour: number, date: Date) => boolean; //	Hidden hours
  hideMinutes?: (minute: number, date: Date) => boolean; //	Hidden minutes
  hideSeconds?: (second: number, date: Date) => boolean; //	Hidden seconds
  inline?: boolean; //Display date panel when component initial
  isoWeek?: boolean; //	ISO 8601 standard, each calendar week begins on Monday and Sunday on the seventh day
  limitEndYear?: number; // (1000)	Set the lower limit of the available year relative to the current selection date
  // locale?: Object, // (Locale)	i18n config
  onChange?: (date: Date) => void; //	Callback fired when value changed
  onChangeCalendarDate?: (date: Date, event?: React.SyntheticEvent) => void; //	Callback function that changes the calendar date.
  onClose?: () => void; //	Callback fired when close component
  onClean?: (event: React.SyntheticEvent) => void; //	Callback fired when value clean
  onNextMonth?: (date: Date) => void; //	Switch to the callback function for the next Month
  onOk?: (date: Date, event: React.SyntheticEvent) => void; //	Click the OK callback function
  onOpen?: () => void; //	Callback fired when open component
  onPrevMonth?: (date: Date) => void; //	Switch to the callback function for the previous Month
  onSelect?: (date: Date) => void; //	Callback fired when date or time is selected
  onToggleMonthDropdown?: (open: boolean) => void; //	Callback function that switches to the month view
  onToggleTimeDropdown?: (open: boolean) => void; //	Callback function that switches to the time view
  open?: boolean; //	Whether open the component
  oneTap?: boolean; //	One click to complete the selection date
  placeholder?: string; //	Placeholder
  placement?: Placement; //('bottomLeft')	The placement of component
  // ranges?: RangeType[], // (Ranges)	Shortcut config
  // toggleComponentClass	:React.ElementType ('a')	You can use a custom element for this component
  value?: Date; //Value (Controlled)

  onBlur?: () => void;
  onFocus?: () => void;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
};

type States = {};

class BFDate extends Component<Props & WithTranslation, States> {
  // genId: string;
  container;

  static defaultProps = {
    isoWeek: true
  };
  //
  // componentWillMount(): void {
  //     this.genId = uuid();
  // }

  render() {
    const {
      label,
      labelPosition,
      i18n,
      tReady,
      validation,

      ...rest
    } = this.props;
    (rest as any).t = undefined;
    let translations = i18n.getResourceBundle(i18n.language, null);

    if (!translations) {
      translations = i18n.getResourceBundle("en", null);
    }

    const propsToGo: any = {
      ...rest,
      block: true,
      container: () => {
        return this.container;
      }
    };

    return (
      <LabeledInput label={label} labelPosition={labelPosition}>
        <ValidationPopover
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
        >
          <div
            className={"bf-date"}
            style={{ position: "relative" }}
            ref={ref => {
              this.container = ref;
            }}
          >
            <DatePicker locale={translations["dateLocales"]} {...propsToGo} />
          </div>
        </ValidationPopover>
      </LabeledInput>
    );
  }
}

export default withTranslation()(BFDate);
