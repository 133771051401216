/** @format */

import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Animation } from "rsuite";
import ProgressLoader from "../../components/Loaders/ProgressLoader/ProgressLoader";
import { Notification } from "../../model/notifications/Notification";
import { removeNotification, toggleExpandState } from "../../redux/actions/notifications/notifications-actions";
import { AppState } from "../../redux/store";
import BFButton from "../abstract-ui/general/Button/BFButton";
import "./NotificationEntry.scss";

const { Collapse, Slide } = Animation;

type Props = {
	removeNotification: (id: string) => void;
	toggleExpandState: (id: string, toggled: boolean) => void;
	notification: Notification;
} & RouteComponentProps &
	WithTranslation;

type States = {};
export const NOTIFICATION_DELETE_TRANSITION = 300;

class NotificationEntry extends Component<Props, States> {
	static defaultProps = {};

	readonly state: States = {};

	renderProgressBar() {
		const { notification } = this.props;
		if (notification.type === "progress" && notification.state === "inprogress") {
			let progressType = null;
			if (notification.progressType === "indeterminated") {
				progressType = "intermediate";
			} else if (notification.progressType === "query") {
				progressType = "query";
			}
			const value = notification.progress ? notification.progress * 100 : null;

			return (
				<div className={`progress`}>
					<ProgressLoader value={value} intermediate={progressType} />
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		const { notification } = this.props;

		const canExpaned = notification.expanded !== undefined;
		let canDelete = notification.deletable !== false;
		const headerActions = canExpaned || canDelete;
		return (
			<Slide transitionAppear={true} key={notification.id} in={!notification.isDeleting}>
				<div className={`notification-entry`}>
					<div className={`icon`}></div>
					<div className={`content`}>
						<div className={`head`}>
							<div className={`title`}>{notification.title}</div>

							{headerActions ? (
								<div className={`header-actions`}>
									{canDelete ? (
										<div className={`delete`}>
											<BFButton
												className={`action delete-action`}
												onClick={() => this.props.removeNotification(notification.id)}
												appearance={"clear-on-white"}
												icon={{ type: "bf", data: "close" }}
											/>
										</div>
									) : null}

									{canExpaned ? (
										<div className={`expand`}>
											<BFButton
												className={`action expand-action ${!notification.expanded ? "rotated" : ""}`}
												onClick={() => this.props.toggleExpandState(notification.id, !notification.expanded)}
												appearance={"clear-on-white"}
												icon={{
													type: "bf",
													data: "arrow-up-1"
												}}
											/>
										</div>
									) : null}
								</div>
							) : null}
						</div>
						{canExpaned ? (
							<Collapse in={notification.expanded}>
								{notification.message ? <div className={`message`}>{notification.message}</div> : null}
							</Collapse>
						) : null}
						{this.renderProgressBar()}
					</div>
					<div className={`actions`}></div>
				</div>
			</Slide>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({});

export default connect(mapStateToProps, {
	toggleExpandState,
	removeNotification
})(withRouter(withTranslation()(NotificationEntry)));
