import React from "react";
import ActionComponent from "../../configurable/components/ActionComponent/ActionComponent";
import { GFButton } from "./common/CommonComponents";
import {
  GFFieldLabel,
  GFFieldValue,
  GFImage,
  GFLabel
} from "./display/DisplayComponents";
import { GFCol, GFRow } from "./layout/BootsrapLayout";
import { GFDiv } from "./layout/DivLayout";
import FormPurposeChooser from "./layout/FormPurposeChooser";
import FormWizard from "./layout/FormWizard";

class GenericFormsComponentsClass {
  private components: {
    [ident: string]: React.Component | React.FunctionComponent;
  } = {
    fieldValue: GFFieldValue,
    fieldLabel: GFFieldLabel,

    row: GFRow,
    col: GFCol,
    wizard: FormWizard as any,
    purposeChooser: FormPurposeChooser as any,

    image: GFImage,
    label: GFLabel,
    button: GFButton,
    div: GFDiv,
    action: ActionComponent as any
  };

  registerComponent(
    ident: string,
    component: React.Component | React.FunctionComponent,
    overwrite = true
  ) {
    if (ident === "property") {
      return false;
    }

    if (!this.components[ident] || overwrite) {
      this.components[ident] = component;
      return true;
    } else {
      return false;
    }
  }

  getComponent(ident: string) {
    return this.components[ident];
  }
}

const GenericFormsComponents = new GenericFormsComponentsClass();
(window as any).gfcomponents = GenericFormsComponents;
export default GenericFormsComponents;
