import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import application from "./reducers/application/Application";
import { ApplicationReducer } from "./reducers/application/ApplicationInterface";
import global from "./reducers/global/Global";
import { GlobalReducer } from "./reducers/global/GlobalInterface";
import notifications from "./reducers/notifications/Notifications";
import { NotificationsReducer } from "./reducers/notifications/NotificationsInterface";
import uiConfig from "./reducers/ui-config/UiConfig";
import { UiConfigReducer } from "./reducers/ui-config/UiConfigInterface";
export interface StoreInterface {
  application: ApplicationReducer;
  uiConfig: UiConfigReducer;
  global: GlobalReducer;
  notifications: NotificationsReducer;
}

const rootReducer = (state: AppState, action) => {
  const newState = {} as AppState;

  newState["global"] = global(state ? state.global : undefined, action, state);
  newState["uiConfig"] = uiConfig(
    state ? state.uiConfig : undefined,
    action,
    state
  );
  newState["application"] = application(
    state ? state.application : undefined,
    action,
    state
  );

  newState["notifications"] = notifications(
    state ? state.notifications : undefined,
    action,
    state
  );

  return newState;
};

// combineReducers({
//     global: global,
//     uiConfig: uiConfig,
//     application: application
// })

export type AppState = StoreInterface;

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(thunk))
);
