import React, { Component, CSSProperties } from "react";

import "./DashboardCard.scss";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppState } from "../../redux/store";

import { RouteComponentProps, withRouter, WithRouterProps } from "react-router";
import {
  DashboardEntry,
  DashboardLayoutConfig
} from "../../model/common/DashboardConfig";

type Props = {
  dashboardEntryConfig: DashboardEntry;
};

type States = {};

class DashboardCard extends Component<Props, States> {
  render() {
    const { dashboardEntryConfig } = this.props;

    const styles: CSSProperties = {
      gridColumn: `${dashboardEntryConfig.col} / ${dashboardEntryConfig.col +
        dashboardEntryConfig.colSpan}`,
      gridRow: `${dashboardEntryConfig.row} / ${dashboardEntryConfig.row +
        dashboardEntryConfig.rowSpan}`,
      minHeight: dashboardEntryConfig.minHeight
    };
    return (
      <div className="dashboard-card" style={styles}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

export default DashboardCard;
