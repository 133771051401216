import React, { Component } from "react";

import "./BFCheckbox.scss";
import { Checkbox } from "rsuite";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";

type Props = {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  inline?: boolean;
  name?: string;
  onChange?: (value: any, checked: boolean, event: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  title?: string;
  value?: any;
  defaultChecked?: boolean;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
};

type States = {};

class BFCheckbox extends Component<Props, States> {
  render() {
    const { validation, className, ...rest } = this.props;

    return (
      <ValidationPopover
        level={validation ? validation.level : "error"}
        message={validation ? validation.message : null}
      >
        <Checkbox className={className ? className : ""} {...rest}>
          {" "}
          {this.props.children}
        </Checkbox>
      </ValidationPopover>
    );
  }
}

// dirty fix: changing the display name in order to get the allProperties set by the checkbox group as intended ( https://github.com/rsuite/rsuite/blob/6095d1f61c8d014349d7e0e3d3e639f202e5fed5/src/CheckboxGroup.js#L67 )
(BFCheckbox as any).displayName = "Checkbox";

export default BFCheckbox;
