import React, { Component, CSSProperties } from "react";
import "./AvatarComponent.scss";
type Props = {
  avatar: string;
  displayName: string;
  renderEmpty: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
  className?: string;
  style?: CSSProperties;
};

type States = {};

const defaultBackgroundColors = [
  "994d00",
  "669900",
  "99004d",
  "990000",
  "999900",
  "003d99",
  "003366",
  "5c5c3d",
  "4d0099"
];

class AvatarComponent extends Component<Props, States> {
  static defaultProps = {
    size: "md",
    renderEmpty: false
  };
  readonly state: States = {};

  generateAvatarString() {
    const { displayName, renderEmpty } = this.props;
    if (!displayName) {
      if (renderEmpty) {
        return " #AAAAAA";
      } else {
        return null;
      }
    }
    let character = displayName.trim()[0];

    let number = 0;

    displayName.split("").forEach(char => (number += char.charCodeAt(0)));

    const color =
      defaultBackgroundColors[number % defaultBackgroundColors.length];

    return `#${color}`;
  }

  getDisplayNameInitials() {
    const { displayName } = this.props;

    const matches = displayName.match(/\b\w/g) || [];
    const initials = (
      (matches.shift() || "") + (matches.pop() || "")
    ).toUpperCase();

    return initials;
  }

  render() {
    const { className, avatar, size, style } = this.props;
    let useAvatar = avatar;
    if (!useAvatar) {
      useAvatar = this.generateAvatarString();
    }
    if (!useAvatar) {
      return null;
    }

    const isGenerated = /#[0-9A-Fa-f]{6}/.test(useAvatar);

    return (
      <div
        className={`avatar size-${size} ${className ? className : ""}`}
        style={style}
      >
        {isGenerated ? (
          <div className={`generated`} style={{ backgroundColor: useAvatar }}>
            {/* {useAvatar.split("#")[0]} */}
            <svg width="100%" height="100%" viewBox="0 0 200 200">
              {/* couldnt use props: dominantBaseline="central" alignmentBaseline="central", had to use dy instead*/}
              <text
                x="100"
                y="100"
                dy=".4em"
                textAnchor="middle"
                fontSize="110"
                fill="white"
              >
                {this.getDisplayNameInitials()}
              </text>
            </svg>
          </div>
        ) : (
          <div
            className={`image`}
            style={{ backgroundImage: `url(${useAvatar})` }}
          />
        )}
      </div>
    );
  }
}

export default AvatarComponent;
