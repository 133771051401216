import validator from "validator";
import ExpressionHelper from "./ExpressionHelper";
import {
  JsonPropertyCheckboxGroup,
  JsonPropertyComponent,
  JsonPropertyRadioGroup,
  JsonPropertySelect,
  OptionConditionGroup,
  OptionEntry,
  Validator
} from "./JsonValidation";
import JsonValidationException from "./JsonValidationException";
import ValidatorsUtils from "./ValidatorsUtils";

export type ValidatorTypes = "required" | "condition";

class ValidatorsClass {
  typesValidatioFuncs: {
    [key: string]: (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => boolean;
  } = {
    string: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    password: (value, jsonPropertyComp, jsonValue) => typeof value === "string",

    website: (value, jsonPropertyComp, jsonValue) => {
      return (
        typeof value === "string" &&
        (ValidatorsUtils.isWebsiteString(value) || value === "")
      );
    },
    mail: (value, jsonPropertyComp, jsonValue) => {
      return (
        typeof value === "string" && (validator.isEmail(value) || value === "")
      );
    },
    text: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    textarea: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    number: (value, jsonPropertyComp, jsonValue) =>
      typeof value === "number" &&
      ((jsonPropertyComp as any).decimalFixed === undefined ||
        Number(value.toFixed((jsonPropertyComp as any).decimalFixed)) ===
          value),
    toggle: (value, jsonPropertyComp, jsonValue) => typeof value === "boolean",
    checkbox: (value, jsonPropertyComp, jsonValue) =>
      typeof value === "boolean",
    select: (value, jsonPropertyComp, jsonValue) => {
      if ((jsonPropertyComp as JsonPropertySelect).multiple) {
        if (Array.isArray(value)) {
          const allowedOptions = this.getValidOptions(
            (jsonPropertyComp as JsonPropertySelect).options,
            jsonValue
          );

          let success = true;
          for (const val of value) {
            success = success && this.checkOptionValidtiy(val, allowedOptions);
          }
          return success;
        } else {
          return false;
        }
      } else {
        const allowedOptions = this.getValidOptions(
          (jsonPropertyComp as JsonPropertySelect).options,
          jsonValue
        );

        return this.checkOptionValidtiy(value, allowedOptions);
      }
    },
    radio: (value, jsonPropertyComp, jsonValue) => {
      const allowedOptions = this.getValidOptions(
        (jsonPropertyComp as JsonPropertyRadioGroup).options,
        jsonValue
      );
      return this.checkOptionValidtiy(value, allowedOptions);
    },
    "checkbox-group": (value, jsonPropertyComp, jsonValue) => {
      if (Array.isArray(value)) {
        const allowedOptions = this.getValidOptions(
          (jsonPropertyComp as JsonPropertyCheckboxGroup).options,
          jsonValue
        );
        let success = true;
        for (const val of value) {
          success = success && this.checkOptionValidtiy(val, allowedOptions);
        }
        return success;
      } else {
        return false;
      }
    },
    date: (value, jsonPropertyComp, jsonValue) =>
      new Date(value).toString() !== "Invalid Date"
  };

  registerType(
    typeIdentifier: string,
    validateTypeFunc: (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => boolean
  ) {
    this.typesValidatioFuncs[typeIdentifier] = validateTypeFunc;
  }

  validateValue(
    fieldName: string,
    value: any,
    validatorObj: Validator,
    allValues: any
  ) {
    if (!this[validatorObj.type]) {
      throw new JsonValidationException(
        fieldName,
        `validator type ${validatorObj.type} doesn't exists`
      );
    }

    if (
      validatorObj.condition &&
      !ExpressionHelper.evaluateExpression(validatorObj.condition, allValues)
    ) {
      // this validator should not be evaluated -> return true
      return true;
    }

    const success = this[validatorObj.type](
      value,
      fieldName,
      allValues,
      validatorObj.param
    );

    return success;
  }

  getValidOptions(
    options: (OptionEntry | OptionConditionGroup)[],
    jsonValue: { [key: string]: any }
  ) {
    let allowedOptions = [];
    options.forEach(option => {
      if ((option as OptionConditionGroup).condition) {
        if (
          ExpressionHelper.evaluateExpression(
            (option as OptionConditionGroup).condition,
            jsonValue
          )
        ) {
          allowedOptions = allowedOptions.concat(
            this.getValidOptions(
              (option as OptionConditionGroup).options,
              jsonValue
            )
          );
        }
      } else {
        allowedOptions.push(option);
      }
    });
    return allowedOptions;
  }

  checkOptionValidtiy(value: any, options: OptionEntry[]) {
    return options.map(option => option.value).indexOf(value) !== -1;
  }

  /**
   * validates a value against its type, checks in selects if the values are in the options
   * @param value
   * @param jsonPropertyComp
   */
  validateType(
    value: any,
    jsonPropertyComp: JsonPropertyComponent,
    jsonValue: { [key: string]: any }
  ) {
    if (value === null || value === undefined) {
      return true;
    }
    const typeValidationFunc = this.typesValidatioFuncs[jsonPropertyComp.type];
    if (typeValidationFunc && typeof typeValidationFunc === "function") {
      return typeValidationFunc(value, jsonPropertyComp, jsonValue);
    } else {
      return false;
    }
  }

  required(value: any, fieldName: string, allValues: any, param?: any) {
    if (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "") ||
      value.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  email(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isEmail(value);
  }

  currency(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isCurrency(value, param);
  }

  creditCard(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isCreditCard(value);
  }

  alphanumeric(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isAlphanumeric(value);
  }

  contains(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.contains(value, param);
  }

  hexolor(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isHexColor(value);
  }

  decimal(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isDecimal(value, param);
  }

  mobilephone(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isMobilePhone(value, param);
  }

  postalcode(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isPostalCode(value, param);
  }

  condition(value: any, fieldName: string, allValues: any, param?: any) {
    return ExpressionHelper.evaluateExpression(param, allValues);
  }

  maxLength(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return value.length <= param;
  }
  minLength(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return value.length > param;
  }
}

const Validators = new ValidatorsClass();
export default Validators;
