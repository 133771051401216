import { css } from "emotion";
import React, { Component, CSSProperties } from "react";
import { Icon } from "rsuite";
import "./font.scss";

export type BfIconProps = {
  type?: string;
  data: any;
  size?: "xs" | "sm" | "lg" | "xl" | "2x" | "3x" | "4x" | "5x";
  color?: "white" | "black" | "grey" | "primary" | "accent";
  className?: string;
  style?: CSSProperties;
};

type States = {};

class BfIcon extends Component<BfIconProps, States> {
  render() {
    const { type, data, size, color, style, className } = this.props;

    if (type === "bf") {
      return (
        <i
          className={`rs-icon iber-icon icon-${data}  ${
            className ? className : ""
          } ${color ? "color-" + color : ""} bf-icon size-${
            size ? size : "norm"
          } ${style ? css(style as any) : ""}`}
        />
      );
    } else {
      return (
        <Icon
          className={`${color ? "color-" + color : ""} ${
            className ? className : ""
          } ${style ? css(style as any) : ""}`}
          icon={data}
          size={size !== "xl" && size !== "xs" && size !== "sm" ? size : "lg"}
        />
      );
    }
  }
}

export default BfIcon;
