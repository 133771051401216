import React, { Component } from "react";
import BfIcon from "../../../icon/BfIcon";
import "./NoDataComponent.scss";

type Props = {
  errorMessage?: string;
};

type States = {};

class NoDataComponent extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const { children, errorMessage } = this.props;

    return (
      <div className={`no-data-component `}>
        {errorMessage ? (
          <div className={`error`}>
            <div className={`icon`}>
              <BfIcon type="bf" data="alert-triangle" size="2x" />
            </div>
            <div className={`message`}>{errorMessage}</div>
          </div>
        ) : (
          children
        )}
      </div>
    );
  }
}

export default NoDataComponent;
