import { LogInterface } from "../../../configurable/components/LogComponent/LogModel";
import DataBus from "../../../services/DataBus";
import { Filter } from "../../../services/DataService";

export const SET_LOG_DATA = "SET_LOG_DATA";
export const SET_LOG_LOADING = "SET_LOG_LOADING";
export const CLEAR_APPLICATION_DATA = "CLEAR_APPLICATION_DATA";
export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_TABLE_FILTER = "SET_TABLE_FILTER";
export const SET_TABLE_FULLTEXT_SEARCH = "SET_TABLE_FULLTEXT_SEARCH";
export const SET_TABLE_EVENT = "SET_TABLE_EVENT";
export const SET_TABLE_SORT = "SET_TABLE_SORT";
export const SET_APPICATION_CACHE_DATA = "SET_APPICATION_CACHE_DATA";
export const SET_APPICATION_CACHE_DEPRECATED = "SET_APPICATION_CACHE_DEPRECATED";
export const SET_APPICATION_CACHE_LOADING = "SET_APPICATION_CACHE_LOADING";
export const SET_APPICATION_CACHE_ERROR = "SET_APPICATION_CACHE_ERROR";
export const RESET_LOG_DATA = "RESET_LOG_DATA";
export const PATCH_TABLE_ROW_DATA = "PATCH_TABLE_ROW_DATA";
export const RESET_CACHE_COMPLETE = "RESET_CACHE_COMPLETE";

export type TABLE_EVENT_TYPES = "SELECTION" | "DOUBLE_CLICK";

interface ResetCacheComplete {
	type: typeof RESET_CACHE_COMPLETE;
}

interface ResetLogData {
	type: typeof RESET_LOG_DATA;
	identifiers: string[];
}
interface SetLogData {
	type: typeof SET_LOG_DATA;
	logIdentifier: string;
	mode: "append" | "prepend" | "replace";
	data: LogInterface[];
	params: any;
}
interface SetLogLoading {
	type: typeof SET_LOG_LOADING;
	logIdentifier: string;
	mode: "append" | "prepend" | "replace";
}
interface SetTableData {
	type: typeof SET_TABLE_DATA;
	tableIdentifier: string;
	data: {
		append?: boolean;
		total: number;
		skip: number;
		limit: number;
		filters: { [dataKey: string]: Filter };
		data: Object[];
		sort: {
			dataKey: string;
			sortType: "asc" | "desc";
		};
		fulltextSearch: string;
	};
}

interface PatchTableRowData {
	type: typeof PATCH_TABLE_ROW_DATA;
	tableIdentifier: string;
	rowId: string;
	data: any;
}

interface ClearApplicationData {
	type: typeof CLEAR_APPLICATION_DATA;
	paths: string[];
}

interface SetTableFilter {
	type: typeof SET_TABLE_FILTER;
	tableIdentifier: string;
	dataKey: string;
	filter: Filter;
}
interface SetTableFulltextSearch {
	type: typeof SET_TABLE_FULLTEXT_SEARCH;
	tableIdentifier: string;
	fulltextSearch: string;
}

interface SetTableEvent {
	type: typeof SET_TABLE_EVENT;
	tableIdentifier: string;
	event: TABLE_EVENT_TYPES;
	data: Object;
}

interface SetTableSort {
	type: typeof SET_TABLE_SORT;
	tableIdentifier: string;
	dataKey: string;
	sortType: "asc" | "desc";
}

interface SetApplicationCacheLoading {
	type: typeof SET_APPICATION_CACHE_LOADING;
	oType: "group" | "user" | "asset";
	id: string;
	assetType?: string;
}
interface SetApplicationCacheError {
	type: typeof SET_APPICATION_CACHE_ERROR;
	oType: "group" | "user" | "asset";
	id: string;
	assetType?: string;
	error: any;
}
interface SetApplicationCacheDeprecated {
	type: typeof SET_APPICATION_CACHE_DEPRECATED;
	oType: "group" | "user" | "asset";
	id: string;
	assetType?: string;
}
interface SetApplicationCacheData {
	type: typeof SET_APPICATION_CACHE_DATA;
	oType: "group" | "user" | "asset";
	id: string;
	data: any;
	assetType?: string;
	ttl?: number;
}

export type ApplicationAction =
	| SetApplicationCacheLoading
	| SetApplicationCacheError
	| SetApplicationCacheDeprecated
	| ClearApplicationData
	| SetTableData
	| SetTableEvent
	| SetTableFilter
	| SetTableFulltextSearch
	| SetTableSort
	| SetApplicationCacheData
	| PatchTableRowData
	| SetLogData
	| SetLogLoading
	| ResetLogData
	| ResetCacheComplete;

setTimeout(() => {
	DataBus.subscribeReduxActions([SET_TABLE_EVENT, RESET_LOG_DATA]);
});
