import React, { Component } from "react";

type Props = {};

type States = {};

class SwiperPage extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    return <div className="swiper-slide">{this.props.children}</div>;
  }
}

export default SwiperPage;
