import React, { Component } from "react";
import {
  Field,
  FieldInputProps,
  FieldMetaState,
  FormSpy
} from "react-final-form";
import { GenericFormsLayoutProps } from "./BaseElement";
import ExpressionHelper from "./util/ExpressionHelper";
import { JsonPropertyComponent } from "./util/JsonValidation";

type Props = {
  name: string;
  jsonProperty: JsonPropertyComponent;
  render: (
    input: FieldInputProps<any, any>,
    meta: FieldMetaState<any>,
    name: string,
    jsonProperty: JsonPropertyComponent,
    currentValues: any
  ) => React.ReactNode;
  forceFormSpy?: boolean;
} & GenericFormsLayoutProps;

type States = {};

class GenericFormField extends Component<Props, States> {
  render() {
    const { name, jsonProperty, forceFormSpy } = this.props;

    if (jsonProperty.condition || forceFormSpy) {
      return (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            if (jsonProperty.condition) {
              if (
                ExpressionHelper.evaluateExpression(
                  jsonProperty.condition,
                  values
                )
              ) {
                return this.renderField(values);
              } else {
                return (
                  <Field
                    name={name}
                    render={({ input, meta }) => {
                      input.onChange("");
                      return null;
                    }}
                  />
                );
              }
            } else {
              return this.renderField(values);
            }
          }}
        </FormSpy>
      );
    } else {
      return this.renderField();
    }
  }

  renderField(values?: any) {
    const { name, jsonProperty, render } = this.props;

    return (
      <Field
        name={name}
        defaultValue={
          !this.props.formProps.values ||
          this.props.formProps.values[name] === undefined
            ? jsonProperty.defaultValue
            : undefined
        }
        render={({ input, meta }) =>
          render(input, meta, name, jsonProperty, values)
        }
      />
    );
  }
}

export default GenericFormField;
