import React, { Component } from "react";
import "./ApplicationAdmin.scss";
import { withTranslation, WithTranslation } from "react-i18next";
import ApplicationSidenav from "../../components/ApplicationSidenav/ApplicationSidenav";
import { Icon, Nav } from "rsuite";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router";
import Dashboard, {
  mockedDashboard
} from "../../components/Dashboard/Dashboard";
import { AppState } from "../../redux/store";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

type Props = {} & WithTranslation & RouteComponentProps;

type States = {};

class ApplicationAdmin extends Component<Props, States> {
  onNavigationItemClick(route) {
    this.props.history.push(route);
  }

  render() {
    const { i18n, match, location } = this.props;

    return (
      <div className="view-application-admin app-layout">
        <div className={"sidenav"}>
          <ApplicationSidenav
            onKeySelect={key => this.onNavigationItemClick(key)}
            noTransition={true}
            activeKey={location.pathname}
          >
            <Nav>
              <Nav.Item
                eventKey={`${match.path}/dashboard`}
                icon={<Icon icon="squares" />}
              >
                {i18n.t("apps.ApplicationAdmin.Dashboard.title")}
              </Nav.Item>
              <Nav.Item
                eventKey={`${match.path}/group`}
                icon={<Icon icon="group" />}
              >
                {i18n.t("apps.ApplicationAdmin.Groups.title")}
              </Nav.Item>
              <Nav.Item
                eventKey={`${match.path}/user`}
                icon={<Icon icon="user" />}
              >
                {i18n.t("apps.ApplicationAdmin.Users.title")}
              </Nav.Item>
              <Nav.Item
                eventKey={`${match.path}/app`}
                icon={<Icon icon="th2" />}
              >
                {i18n.t("apps.ApplicationAdmin.Apps.title")}
              </Nav.Item>
            </Nav>
          </ApplicationSidenav>
        </div>
        <div className={"app-layout-content"}>
          <Switch>
            <Route path={`${match.path}/dashboard`}>
              <Dashboard dashboardLayout={mockedDashboard} />
            </Route>
            {/*<Route path={`${match.path}/components`} component={DemoComponentsView}/>*/}

            <Route render={() => <Redirect to={`${match.path}/dashboard`} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(setUserData(user, apps, permissions)) }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ApplicationAdmin))
);
