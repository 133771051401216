import _ from "lodash";
import React, { CSSProperties } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import Ratings from "react-star-ratings";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import Santizer from "../../../configurable/components/Santizer/Santizer";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import BFCheckGroup from "../../abstract-ui/forms/checkbox-group/BFCheckGroup";
import BFCheckbox from "../../abstract-ui/forms/checkbox/BFCheckbox";
import BfDate from "../../abstract-ui/forms/date/BFDate";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFRadioGroup from "../../abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../abstract-ui/forms/radio/BfRadio";
import BfSelect from "../../abstract-ui/forms/select/BFSelect";
import BFToggle from "../../abstract-ui/forms/toggle/BFToggle";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../abstract-ui/icon/BfIcon";
import { GenericFormsLayoutProps } from "../../generic-forms/BaseElement";
import GenericFormField from "../../generic-forms/GenericFormField";
import {
  JsonPropertyCheckbox,
  JsonPropertyCheckboxGroup,
  JsonPropertyCommon,
  JsonPropertyDate,
  JsonPropertyNumber,
  JsonPropertyRadioGroup,
  JsonPropertySelect,
  JsonPropertyText,
  JsonPropertyToggle,
  OptionConditionGroup,
  OptionEntry
} from "../../generic-forms/util/JsonValidation";
import Validators from "../../generic-forms/util/Validatos";
import "./GenericFormFieldsImpl.scss";

type WrapperProps = CSSProperties; //{ padding: 10};
export const FormWrapper: React.FC<WrapperProps> = props => {
  return <div style={{ ...props }}>{props.children}</div>;
};

/***
 * ############ Rating ####################
 */
export interface JsonRatingProps extends JsonPropertyCommon {
  type: "rating";
  numberOfStars?: number;
  colorRated?: string;
  colorEmpty?: string;
  colorHover?: string;
  starDimension?: string;
  starSpacing?: string;
  svgIconPath?: string;
  svgIconViewBox?: string;
  align: "center" | "left" | "right";
  minIcon?: BfIconProps;
  maxIcon?: BfIconProps;
}
type GFRatingProps = {
  name: string;
  properties: JsonRatingProps;
} & GenericFormsLayoutProps;
export const GFRating: React.FC<GFRatingProps> = props => {
  const { name, properties } = props;

  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties as any}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <div
            className={"gf-rating"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: properties.align
                ? properties.align === "center"
                  ? "center"
                  : properties.align === "right"
                  ? "flex-end"
                  : "flex-end"
                : "center"
            }}
          >
            {properties.minIcon ? <BfIcon {...properties.minIcon} /> : null}
            <Ratings
              rating={input.value ? input.value : 0}
              changeRating={(newRating, name) => {
                console.log(newRating);
                input.onChange(newRating);
              }}
              starRatedColor={properties.colorRated}
              starEmptyColor={properties.colorEmpty}
              starHoverColor={properties.colorHover}
              starDimension={properties.starDimension}
              starSpacing={properties.starSpacing}
              svgIconPath={properties.svgIconPath}
              svgIconViewBox={properties.svgIconViewBox}
              numberOfStars={
                properties.numberOfStars ? properties.numberOfStars : undefined
              }
            />
            {properties.maxIcon ? <BfIcon {...properties.maxIcon} /> : null}
          </div>
        );
      }}
    />
  );
};

/***
 * ############ GridCheck ####################
 */
interface GridCheckOptionEntry extends OptionEntry {
  icon: BfIconProps;
}

export interface JsonGridCheckProps extends JsonPropertyCommon {
  type: "gridcheck";
  maxCheckedCount?: number;
  options: GridCheckOptionEntry[];
  columns?:
    | number
    | {
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
      };
}
type GFGridCheckProps = {
  name: string;
  properties: JsonGridCheckProps;
} & GenericFormsLayoutProps;
export const GFGridCheck: React.FC<GFGridCheckProps> = props => {
  const viewportWidthSelector = useSelector(
    (state: AppState) =>
      state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR]
  );

  const { name, properties } = props;
  let columnCount = 3;
  if (properties.columns) {
    if (typeof properties.columns === "number") {
      columnCount = properties.columns;
    } else {
      columnCount = properties.columns[viewportWidthSelector];
    }
  }
  const rows = [];
  let current = -1;
  properties.options.forEach((option, index) => {
    if (index % columnCount === 0) {
      current++;
      rows.push([]);
    }

    rows[current].push(option);
  });

  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties as any}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <div className={"gf-grid-check"}>
            {rows.map(row => {
              return (
                <div className={`grid-row`}>
                  {row.map(option => {
                    const checked = input.value.indexOf(option.value) !== -1;
                    return (
                      <div className={`grid-option`}>
                        <div
                          className={`checked-over ${checked ? "active" : ""}`}
                        >
                          <BfIcon type={"bf"} data={"check-1"} size={"2x"} />
                        </div>
                        <BFButton
                          icon={option.icon}
                          iconPosition={"top"}
                          text={option.label}
                          onClick={() => {
                            if (checked) {
                              input.onChange(
                                input.value.filter(
                                  entry => entry !== option.value
                                )
                              );
                            } else {
                              if (
                                properties.maxCheckedCount === undefined ||
                                input.value.length < properties.maxCheckedCount
                              ) {
                                input.onChange([...input.value, option.value]);
                              }
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};
/***
 * ############ AVATAR ####################
 */
export interface JsonAvatarProps extends JsonPropertyCommon {
  type: "avatar";
  displaynameField: string;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
}
type GFAvatarProps = {
  name: string;
  properties: JsonAvatarProps;
} & GenericFormsLayoutProps;
export const GFAvatar: React.FC<GFAvatarProps> = props => {
  const { name, properties } = props;

  return (
    <Field
      name={properties.displaynameField}
      render={displayNameProps => {
        return (
          <GenericFormField
            allProperties={props.allProperties}
            formProps={props.formProps}
            name={name}
            jsonProperty={properties as any}
            render={(input, meta, name1, jsonProperty) => {
              return (
                <div className={"gf-avatar"}>
                  <AvatarComponent
                    avatar={input.value}
                    displayName={displayNameProps.input.value}
                    size={properties.size}
                  />
                  <div className={"options"}>
                    <BfIcon data="upload-bottom" type="bf" size="3x" />
                  </div>
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};

/***
 * ############ CHECKBOX ####################
 */
type GFCheckboxProps = {
  name: string;
  properties: JsonPropertyCheckbox;
} & GenericFormsLayoutProps;
export const GFCheckbox: React.FC<GFCheckboxProps> = props => {
  const { name, properties } = props;

  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <FormWrapper {...properties.containerStyleProps}>
            <BFCheckbox
              checked={input.value}
              onChange={(value, checked, event) => input.onChange(checked)}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined
              }}
            >
              {properties.label}
            </BFCheckbox>
          </FormWrapper>
        );
      }}
    />
  );
};

/***
 * ############ TOGGLE ####################
 */
type GFToggleProps = {
  name: string;
  properties: JsonPropertyToggle;
} & GenericFormsLayoutProps;
export const GFToggle: React.FC<GFToggleProps> = props => {
  const { name, properties } = props;
  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties}
      render={(input, meta, name1, jsonProperty) => {
        if (input.value === "") {
          input.onChange(
            properties.defaultValue !== undefined
              ? properties.defaultValue
              : false
          );
        }

        return (
          <FormWrapper {...properties.containerStyleProps}>
            <BFToggle
              {...input}
              checked={input.value}
              onChange={(checked, event) => input.onChange(checked)}
              label={properties.label}
              labelPosition={properties.labelPosition}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined
              }}
            />
          </FormWrapper>
        );
      }}
    />
  );
};

/***
 * ############ RADIO ####################
 */
type GFRadioGroupProps = {
  name: string;
  properties: JsonPropertyRadioGroup;
} & GenericFormsLayoutProps;
export const GFRadioGroup: React.FC<GFRadioGroupProps> = props => {
  const { name, properties } = props;

  let forceFormSpy =
    properties.options.filter(
      option => (option as OptionConditionGroup).condition !== undefined
    ).length !== 0;
  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const options = Validators.getValidOptions(
          properties.options,
          currentValues
        );
        const value =
          options.find(option => option.value === input.value) === -1
            ? null
            : input.value;
        if (value !== input.value) {
          input.onChange(value);
        }
        return (
          <FormWrapper {...properties.containerStyleProps}>
            <BFRadioGroup
              onChange={(checked, event) => input.onChange(checked)}
              label={properties.label}
              value={value}
              inline={properties.inline}
              appearance={properties.appearance}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined
              }}
            >
              {options.map(option => (
                <BfRadio key={option.value} value={option.value}>
                  {option.label}
                </BfRadio>
              ))}
            </BFRadioGroup>
          </FormWrapper>
        );
      }}
    />
  );
};

/***
 * ############ CHECKBOX-GROUP ####################
 */
type GFCheckboxGroupProps = {
  name: string;
  properties: JsonPropertyCheckboxGroup;
} & GenericFormsLayoutProps;
export const GFCheckboxGroup: React.FC<GFCheckboxGroupProps> = props => {
  const { name, properties, formProps } = props;

  let forceFormSpy =
    properties.options.filter(
      option => (option as OptionConditionGroup).condition !== undefined
    ).length !== 0;

  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const options = Validators.getValidOptions(
          properties.options,
          currentValues
        );

        let value = input.value
          ? input.value.filter(
              selVal =>
                options.find(option => option.value === selVal) !== undefined
            )
          : "";

        if (!_.isEqual(value, input.value)) {
          input.onChange(value);
        }
        return (
          <FormWrapper {...properties.containerStyleProps}>
            <BFCheckGroup
              onChange={(checked, event) => input.onChange(checked)}
              value={value}
              inline={properties.inline}
              validation={{
                level: "error",
                message: meta.dirty ? meta.error : undefined
              }}
            >
              {options.map(option => (
                <BFCheckbox key={option.value} value={option.value}>
                  <Santizer html={option.label} />
                </BFCheckbox>
              ))}
            </BFCheckGroup>
          </FormWrapper>
        );
      }}
    />
  );
};

/***
 * ############ INPUT ####################
 */
type GFInputProps = { name: string } & {
  properties: JsonPropertyText | JsonPropertyNumber;
} & GenericFormsLayoutProps;
const GFInput: React.FC<GFInputProps> = props => {
  const { properties } = props;
  const { type } = properties;
  const additionalProps: any = {};

  if (type === "number") {
    additionalProps.decimalFixed = (properties as JsonPropertyNumber).decimalFixed;
    additionalProps.min = (properties as JsonPropertyNumber).min;
    additionalProps.max = (properties as JsonPropertyNumber).max;
  }
  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={props.name}
      jsonProperty={properties}
      render={(input, meta, name1, jsonProperty) => (
        <>
          <FormWrapper {...properties.containerStyleProps}>
            <BFInput
              {...input}
              type={type}
              autoComplete={(properties as JsonPropertyText).autoComplete}
              autoCapitalize={(properties as JsonPropertyText).autoCapitalize}
              autoCorrect={(properties as JsonPropertyText).autoCorrect}
              label={properties.label}
              labelPosition={properties.labelPosition}
              value={input.value}
              disabled={properties.disabled}
              onChange={(value, ev) => input.onChange(value)}
              defaultValue={properties.defaultValue}
              prefix={
                properties.prefix &&
                properties.prefix.indexOf("icon:") === 0 ? (
                  <BfIcon data={properties.prefix.split(":")[1] as any} />
                ) : (
                  properties.prefix
                )
              }
              suffix={
                properties.suffix &&
                properties.suffix.indexOf("icon:") === 0 ? (
                  <BfIcon data={properties.suffix.split(":")[1] as any} />
                ) : (
                  properties.suffix
                )
              }
              placeholder={properties.placeholder}
              validation={{
                level: meta.error ? "error" : "warning",
                message:
                  meta.error &&
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : meta.data.warning
              }}
              {...additionalProps}
            />
          </FormWrapper>
        </>
      )}
    />
  );
};

type GFTextProps = {
  name: string;
  properties: JsonPropertyText;
} & GenericFormsLayoutProps;
export const GFText: React.FC<GFTextProps> = props => {
  return GFInput(props);
};
type GFNumberProps = {
  name: string;
  properties: JsonPropertyNumber;
} & GenericFormsLayoutProps;
export const GFNumber: React.FC<GFNumberProps> = props => {
  return GFInput(props);
};
type GFMailProps = {
  name: string;
  properties: JsonPropertyNumber;
} & GenericFormsLayoutProps;
export const GFMail: React.FC<GFNumberProps> = props => {
  return GFInput(props);
};

/***
 * ############ SELECT ####################
 */
type GFSelectProps = {
  name: string;
  properties: JsonPropertySelect;
} & GenericFormsLayoutProps;
export const GFSelect: React.FC<GFSelectProps> = props => {
  const { name, properties } = props;

  let forceFormSpy =
    properties.options.filter(
      option => (option as OptionConditionGroup).condition !== undefined
    ).length !== 0;

  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const options = Validators.getValidOptions(
          properties.options,
          currentValues
        );

        let value;
        if (properties.multiple) {
          value = (input.value || []).filter(
            selVal =>
              options.find(option => option.value === selVal) !== undefined
          );
        } else {
          value = options.find(option => option.value === input.value)
            ? input.value
            : "";
        }
        if (!_.isEqual(value, input.value)) {
          input.onChange(value);
        }

        return (
          <FormWrapper {...properties.containerStyleProps}>
            <BfSelect
              {...input}
              type={properties.multiple ? "multiple" : "normal"}
              label={properties.label}
              labelPosition={properties.labelPosition}
              value={value}
              // onBlur={() => {input.onBlur(value)}}
              onChange={(value, ev) => input.onChange(value)}
              data={Validators.getValidOptions(
                properties.options,
                currentValues
              )}
              defaultValue={properties.defaultValue}
              placeholder={properties.placeholder}
              cleanable={properties.cleanable}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined
              }}
            />
          </FormWrapper>
        );
      }}
    />
  );
};

/***
 * ############ DATE ####################
 */
type GFDateProps = {
  name: string;
  properties: JsonPropertyDate;
} & GenericFormsLayoutProps;
export const GFDate: React.FC<GFDateProps> = props => {
  const { name, properties } = props;

  const min = properties.min ? new Date(properties.min) : undefined;
  const max = properties.max ? new Date(properties.max) : undefined;

  return (
    <GenericFormField
      allProperties={props.allProperties}
      formProps={props.formProps}
      name={name}
      jsonProperty={properties}
      render={(input, meta, name1, jsonProperty) => (
        <FormWrapper {...properties.containerStyleProps}>
          <BfDate
            {...input}
            label={properties.label}
            labelPosition={properties.labelPosition}
            value={input.value}
            onChange={value => input.onChange(value)}
            defaultValue={
              properties.defaultValue
                ? new Date(properties.defaultValue)
                : undefined
            }
            cleanable={properties.cleanable}
            placeholder={properties.placeholder}
            format={properties.format}
            validation={{
              level: "error",
              message:
                !meta.active &&
                (meta.touched || meta.submitError || meta.submitFailed)
                  ? meta.error
                  : undefined
            }}
            disabledDate={
              min || max
                ? date => {
                    if (min) {
                      if (Number(min) - Number(date) < 0) {
                        return false;
                      }
                    }
                    if (max) {
                      if (Number(date) - Number(max) < 0) {
                        return false;
                      }
                    }
                    return true;
                  }
                : undefined
            }
          />
        </FormWrapper>
      )}
    />
  );
};
