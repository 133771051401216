import { css } from "emotion";
import React from "react";
import { connect } from "react-redux";
import {
  BaseElement,
  GenericFormsLayoutProps
} from "../../../modules/generic-forms/BaseElement";
import GenericLayoutComponent from "../../../modules/generic-forms/GenericLayoutComponent";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../IComponent";

export type DivLayoutConf = {
  className?: string;
  components?: { [key: string]: IComponent };

  items: { [key: string]: any };
  condition?: string;
} & AbstractStylableProps &
  GenericFormsLayoutProps;

type States = {} & AbstractStylableStates;

class DivLayout extends AbstractStylableComponent<DivLayoutConf, States> {
  render() {
    const { className, components, items, condition } = this.props;

    if (components) {
      if (!this.shoudBeRendered()) {
        return null;
      }
      return (
        <div
          className={`div-layout ${className ? className : ""} ${
            this.state.usedStyle ? css(this.state.usedStyle as any) : ""
          }`}
        >
          {Object.entries(components)
            .sort((a, b) =>
              a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
            )
            .map(([key, item]) => {
              return ComponentsMapper.createElement(item, key);
            })}
        </div>
      );
    } else if (items) {
      return (
        <GenericLayoutComponent
          stateSubscriptions={this.props.stateSubscriptions}
          style={this.state.usedStyle}
          condition={condition}
          render={styleProps => (
            <div
              style={{ ...styleProps }}
              className={`gf-div ${className ? className : ""}`}
            >
              {items
                ? Object.values(items).map((item, index) => (
                    <BaseElement
                      key={index}
                      {...item}
                      allProperties={this.props.allProperties}
                      formProps={this.props.formProps}
                    />
                  ))
                : null}
            </div>
          )}
        />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: AppState, props: DivLayoutConf) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

const exportComp = connect(mapStateToProps, {})(DivLayout);

export default exportComp;
ComponentsMapper["DivLayout"] = exportComp;
ComponentsMapper["div"] = exportComp;
ComponentsMapper["Div"] = exportComp;
