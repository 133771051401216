import {
	ApplicationAction,
	CLEAR_APPLICATION_DATA,
	PATCH_TABLE_ROW_DATA,
	RESET_CACHE_COMPLETE,
	RESET_LOG_DATA,
	SET_APPICATION_CACHE_DATA,
	SET_APPICATION_CACHE_DEPRECATED,
	SET_APPICATION_CACHE_ERROR,
	SET_APPICATION_CACHE_LOADING,
	SET_LOG_DATA,
	SET_LOG_LOADING,
	SET_TABLE_DATA,
	SET_TABLE_EVENT,
	SET_TABLE_FILTER,
	SET_TABLE_FULLTEXT_SEARCH,
	SET_TABLE_SORT
} from "../../actions/application/application-action-types";
import { AppState } from "../../store";
import { ApplicationReducer } from "./ApplicationInterface";

export const initialState: ApplicationReducer = {
	tables: {},
	cache: {
		user: {},
		group: {},
		asset: {}
	},
	logs: {}
};

export default function(state = initialState, action: ApplicationAction, root: AppState): ApplicationReducer {
	switch (action.type) {
		case RESET_CACHE_COMPLETE:
			const stateNew = {
				...initialState
			};
			return stateNew;
		case RESET_LOG_DATA:
			const newLogState = {
				...state,
				logs: {
					...state.logs
				}
			};
			action.identifiers.forEach(identifier => (newLogState.logs[identifier] = undefined));
			return newLogState;
		case SET_LOG_LOADING:
			const oldLog = state.logs[action.logIdentifier] ? state.logs[action.logIdentifier] : { data: [] };
			return {
				...state,
				logs: {
					...state.logs,
					[action.logIdentifier]: {
						...oldLog,
						loading: action.mode
					}
				}
			};
		case SET_LOG_DATA:
			const oldData = state.logs[action.logIdentifier] ? state.logs[action.logIdentifier].data : [];
			let newData = [];
			switch (action.mode) {
				case "replace":
					newData = action.data;
					break;
				case "append":
					// newData = [...(oldData.slice(oldData.length - allowedSize, oldData.length)), ...action.data]
					newData = [...oldData, ...action.data];
					break;
				case "prepend":
					// newData = [...action.data, ...(oldData.slice(0, allowedSize))]
					newData = [...action.data, ...oldData];
					break;
			}

			return {
				...state,
				logs: {
					...state.logs,
					[action.logIdentifier]: {
						loading: null,
						timestamp: Number(new Date()),
						params: action.params
							? action.params
							: state.logs[action.logIdentifier]
							? state.logs[action.logIdentifier].params
							: {},
						data: newData
					}
				}
			};

		case CLEAR_APPLICATION_DATA:
			const paths = action.paths;
			const stateCopy = { ...state };
			// removes some application data
			for (const path of paths) {
				// steps are "."-seprated
				const steps = path.split(".");

				let tmpState = stateCopy;
				for (let i = 0; i < steps.length; i++) {
					const nextPathStep = tmpState[steps[i]];

					if (tmpState === undefined) {
						break;
					} else if (i < steps.length - 1) {
						tmpState[steps[i]] = {
							...nextPathStep
						};
						tmpState = tmpState[steps[i]];
					} else {
						tmpState[steps[i]] = undefined;
					}
				}
			}
			return stateCopy;

		case SET_APPICATION_CACHE_DATA:
			return {
				...state,
				cache: {
					...state.cache,
					[action.oType !== "asset" ? action.oType : action.assetType]: {
						...state.cache[action.oType !== "asset" ? action.oType : action.assetType],
						[action.id]: {
							state: "cached",
							ttl: action.ttl,
							timestamp: Number(new Date()),
							data: action.data,
							deprecated: false
						}
					}
				}
			};
		case SET_APPICATION_CACHE_DEPRECATED:
			return {
				...state,
				cache: {
					...state.cache,
					[action.oType !== "asset" ? action.oType : action.assetType]: {
						...state.cache[action.oType !== "asset" ? action.oType : action.assetType],
						[action.id]: {
							...state.cache[action.oType][action.id],
							deprecated: true
						}
					}
				}
			};
		case SET_APPICATION_CACHE_LOADING:
			return {
				...state,
				cache: {
					...state.cache,
					[action.oType !== "asset" ? action.oType : action.assetType]: {
						...state.cache[action.oType !== "asset" ? action.oType : action.assetType],
						[action.id]: {
							state: "loading"
						}
					}
				}
			};
		case SET_APPICATION_CACHE_ERROR:
			return {
				...state,
				cache: {
					...state.cache,
					[action.oType !== "asset" ? action.oType : action.assetType]: {
						...state.cache[action.oType !== "asset" ? action.oType : action.assetType],
						[action.id]: {
							state: "error",
							error: action.error
						}
					}
				}
			};

		case SET_TABLE_FULLTEXT_SEARCH:
			return {
				...state,
				tables: {
					...state.tables,
					[action.tableIdentifier]: {
						...state.tables[action.tableIdentifier],
						fulltextSearch: action.fulltextSearch
					}
				}
			};
		case SET_TABLE_FILTER:
			return {
				...state,
				tables: {
					...state.tables,
					[action.tableIdentifier]: {
						...state.tables[action.tableIdentifier],
						filters: {
							...state.tables[action.tableIdentifier].filters,
							[action.dataKey]: action.filter
						}
					}
				}
			};
		case SET_TABLE_SORT:
			return {
				...state,
				tables: {
					...state.tables,
					[action.tableIdentifier]: {
						...state.tables[action.tableIdentifier],
						sort: {
							dataKey: action.dataKey,
							sortType: action.sortType
						}
					}
				}
			};

		case SET_TABLE_DATA:
			let data = action.data.data;
			if (action.data.append && state.tables[action.tableIdentifier] && state.tables[action.tableIdentifier].data) {
				data = [...state.tables[action.tableIdentifier].data, ...data];
			}

			return {
				...state,
				tables: {
					...state.tables,
					[action.tableIdentifier]: {
						events: {
							...state.tables[action.tableIdentifier].events
						}, //new data resets all events on the table
						data: data,
						total: action.data.total,
						skip: action.data.skip,
						limit: action.data.limit,
						filters: action.data.filters,
						sort: action.data.sort,
						timestamp: new Date(),
						fulltextSearch: action.data.fulltextSearch
					}
				}
			};
		case PATCH_TABLE_ROW_DATA:
			return {
				...state,
				tables: {
					...state.tables,
					[action.tableIdentifier]: {
						...state.tables[action.tableIdentifier],
						data: state.tables[action.tableIdentifier].data
							? state.tables[action.tableIdentifier].data.map(entry => {
									if (entry["_id"] === action.rowId) {
										return {
											...action.data
										};
									} else {
										return entry;
									}
							  })
							: []
					}
				}
			};
		case SET_TABLE_EVENT:
			return {
				...state,
				tables: {
					...state.tables,
					[action.tableIdentifier]: {
						...state.tables[action.tableIdentifier],
						events: {
							...state.tables[action.tableIdentifier].events,
							[action.event]: action.data
						}
					}
				}
			};

		default:
			return state;
	}
}
