import React, { Component } from "react";
import { Modal } from "rsuite";
import "wicg-inert";
import BfButton from "../../modules/abstract-ui/general/Button/BFButton";
import "./ModalComponent.scss";
import ModalManager from "./ModalManager";

type Props = {};

type States = {
  current: ModalParams;
  show: boolean;
  queue: ModalParams[];
  currentParams: any;
};

export interface ModalParams {
  title?: string | React.ReactNode;
  content:
    | string
    | React.ReactNode
    | ((states: any, setStates: (states: any) => void) => React.ReactNode);
  buttons: {
    className?: string;
    text: string | React.ReactNode;
    onClick?: (
      states,
      setStates?: (states: any) => void,
      closeFc?: () => void
    ) => void;
    closeOnClick?: boolean;
    appearance?:
      | "default"
      | "primary"
      | "link"
      | "clear"
      | "outline"
      | "clear-on-white";
    isEnabled?: (states) => boolean;
    isLoading?: (states) => boolean;
  }[];
  backdrop?: boolean | "static";
  size?: "lg" | "md" | "sm" | "xs";
  headerCloseButton?: boolean;
  onHide?: () => void;
}

class ModalComponent extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    queue: [],
    current: null,
    show: false,
    currentParams: {}
  };

  componentDidMount(): void {
    ModalManager.init(this);
  }

  show(conf: ModalParams) {
    const { current, queue } = this.state;
    const el = document.querySelector("#root");
    el.setAttribute("inert", "");
    if (!current) {
      this.setState({
        current: conf,
        show: true,
        currentParams: {}
      });
    } else {
      this.setState({
        queue: [...queue, conf]
      });
    }
  }

  setCurrentStates(newStates: any) {
    this.setState({
      currentParams: { ...this.state.currentParams, ...newStates }
    });
  }

  render() {
    const { queue, show, current } = this.state;

    if (current) {
      return (
        <Modal
          className={`modal-component`}
          backdrop={current.backdrop ? current.backdrop : true}
          enforceFocus={true}
          show={show}
          onHide={() => {
            this.setState({
              show: false
            });
            if (current.onHide) {
              current.onHide();
            }
          }}
          onExited={() => {
            if (queue.length !== 0) {
              this.setState({
                show: true,
                current: queue[0],
                queue: queue.slice(1),
                currentParams: {}
              });
            } else {
              const el = document.querySelector("#root");
              el.removeAttribute("inert");
              this.setState({
                show: false,
                current: null,
                currentParams: {}
              });
            }
          }}
          size={current.size ? current.size : "xs"}
        >
          {current.title ? (
            <Modal.Header
              closeButton={
                current.headerCloseButton ? current.headerCloseButton : false
              }
            >
              {current.title}
            </Modal.Header>
          ) : null}
          <Modal.Body>
            {typeof current.content == "function"
              ? current.content(this.state.currentParams, states =>
                  this.setCurrentStates(states)
                )
              : current.content}
          </Modal.Body>
          <Modal.Footer>
            <div className={`footer`}>
              {current.buttons.map((buttonConf, index) => (
                <BfButton
                  type="button"
                  disabled={
                    buttonConf.isEnabled
                      ? !buttonConf.isEnabled(this.state.currentParams)
                      : false
                  }
                  loading={
                    buttonConf.isLoading
                      ? buttonConf.isLoading(this.state.currentParams)
                      : false
                  }
                  key={index}
                  appearance={
                    buttonConf.appearance
                      ? buttonConf.appearance
                      : "clear-on-white"
                  }
                  className={buttonConf.className ? buttonConf.className : ""}
                  onClick={() => {
                    if (buttonConf.onClick) {
                      buttonConf.onClick(
                        this.state.currentParams,
                        states => this.setCurrentStates(states),
                        () => this.setState({ show: false })
                      );
                    }
                    if (buttonConf.closeOnClick !== false) {
                      this.setState({ show: false });
                    }
                  }}
                >
                  {buttonConf.text}
                </BfButton>
              ))}
            </div>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

export default ModalComponent;
