import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IComponent } from "../../../../../configurable/layouts/IComponent";
import { ComponentsMapper } from "../../../../../utils/ComponentsMapper";

type Props = {
  title: string;
  headerComponents: { [key: string]: IComponent };
} & WithTranslation;

type States = {};

class TableComponentTitlebar extends Component<Props, States> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      title,
      headerComponents
      // i18n
    } = this.props;
    return (
      <div className={"table-component-header"}>
        <div className={"title"}>{title}</div>
        <div className={"fill"} />
        {headerComponents
          ? Object.entries(headerComponents)
              .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
              .map(([key, headerComponent]) =>
                ComponentsMapper.createElement({ ...headerComponent }, key)
              )
          : null}
      </div>
    );
  }
}

// const mapStateToProps = (state: AppState) => ({})

export default withTranslation()(TableComponentTitlebar);
