import React from "react";
import { FormSpy } from "react-final-form";
import { SubmitMessage, SubmitResponse } from "../../../services/SubmitService";
import { AbstractComponent } from "../../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../../utils/Constants";
import { BaseElement, GenericFormsLayoutProps } from "../BaseElement";
import GenericFormsComponents from "../GenericFormsComponents";

type Props = {
	id?: string;
	handleSubmitId: string;
	layoutReadonly: { [key: string]: any };
	layoutEdit: { [key: string]: any };
} & GenericFormsLayoutProps;

type States = {
	editMode: boolean;
};

class FormPurposeChooser extends AbstractComponent<Props, States> {
	oldState = {
		hasValidationErrors: null
	};

	static defaultProps = {};

	readonly state: States = {
		editMode: false
	};

	componentDidMount(): void {
		this.subscribe(DataBusSubKeys.SUBMIT, (data: SubmitMessage) => {
			this.populateButtonState("submit", {
				loading: true
			});
		});

		this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, (data: SubmitResponse) => {
			this.populateButtonState("submit", {
				loading: false
			});
			if (data.success) {
				this.setState({ editMode: false }, () => {
					this.updateEditmodeState();

					this.updateActionState(formProps.hasValidationErrors);
				});
			}
		});

		this.subscribeActionEvent("edit", actionData => {
			this.onEdit();
		});
		this.subscribeActionEvent("abort", actionData => {
			this.onAbort();
		});
		this.subscribeActionEvent("submit", actionData => {
			this.onSubmit();
		});

		this.subscribe("RESET", data => {
			if (data.identifiers) {
				if (data.identifiers.indexOf(this.props.id) !== -1 || data.identifiers.indexOf(this.props.identifier) !== -1) {
					this.onAbort();
				}
			}
		});

		const { formProps } = this.props;
		this.updateEditmodeState();

		this.updateActionState(formProps.hasValidationErrors);
	}

	updateEditmodeState() {
		const { id, formProps } = this.props;
		this.emit(
			id,
			{
				editMode: this.state.editMode
			},
			true
		);
	}

	getPropertiesOf(items) {
		let fields = [];

		for (const item of items) {
			if (item.type === "property") {
				fields.push(item.name);
			} else if (item.type === "field-table-view" && item.editable) {
				item.editable.forEach(field => fields.push(field));
			} else {
				if (item.items) {
					fields = fields.concat(Object.values(this.getPropertiesOf(Object.values(item.items))));
				}
			}
		}
		return fields;
	}

	onAbort() {
		const {
			id,
			// registerStatusChange,
			formProps,
			layoutEdit
		} = this.props;
		//todo reset changes

		const currentPageFields = this.getPropertiesOf(Object.values(layoutEdit));

		currentPageFields.forEach(field => {
			(formProps as any).form.mutators.setValue(
				field,
				formProps.initialValues && formProps.initialValues[field] ? formProps.initialValues[field] : undefined
			);
		});

		this.setState({ editMode: false }, () => {
			this.updateEditmodeState();

			this.updateActionState(formProps.hasValidationErrors);
		});
	}

	onEdit() {
		const {
			id,
			// registerStatusChange,
			formProps
		} = this.props;
		this.setState({ editMode: true }, () => {
			this.updateActionState(formProps.hasValidationErrors);
			this.updateEditmodeState();
		});
	}

	onSubmit() {
		(this.props.formProps as any).form.submit();
	}

	updateActionState(hasValidationErrors: boolean) {
		const { editMode } = this.state;
		const {
			id,
			// registerActionChange,
			formProps
		} = this.props;
		this.oldState.hasValidationErrors = hasValidationErrors;

		this.populateButtonState("edit", { hidden: editMode });
		this.populateButtonState("abort", { hidden: !editMode });
		this.populateButtonState("submit", { hidden: !editMode });
	}

	render() {
		const { layoutReadonly, layoutEdit, allProperties, formProps } = this.props;
		const { editMode } = this.state;

		const translateFunc = (formProps as any).form.mutators.translateFunc;

		const buttonComp = GenericFormsComponents.getComponent("button");

		return (
			<div className={"form-wizard"}>
				<div className={"page-content"}>
					{Object.values(editMode ? layoutEdit : layoutReadonly).map((item, index) => (
						<BaseElement key={index} {...item} formProps={formProps} allProperties={allProperties} />
					))}
				</div>

				<FormSpy
					subscription={{
						hasValidationErrors: true,
						values: true,
						errors: true
					}}
					onChange={({ values, errors, hasValidationErrors }) => {
						this.updateActionState(hasValidationErrors);
						if (this.oldState.hasValidationErrors !== hasValidationErrors) {
							this.oldState.hasValidationErrors = hasValidationErrors;
							this.updateActionState(hasValidationErrors);
						}
					}}
				/>
			</div>
		);
	}
}

export default FormPurposeChooser;
