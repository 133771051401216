import React from "react";
import "./LabeledInput.scss";

type Props = {
  label: string;
  children: React.ReactNode;
  labelPosition?: "top" | "left" | "right" | "bottom";
  labelGrow?: boolean;
};

const LabeledInput: React.FC<Props> = props => {
  let { label, children, labelPosition, labelGrow } = props;

  labelPosition = labelPosition || "top";
  labelGrow = labelGrow === undefined || false;

  if (!label) {
    return <>{children}</>;
  } else {
    return (
      <div className={`labeled-input label-position-${labelPosition}`}>
        <div className={`label ${labelGrow ? "grow" : ""}`}>{label}</div>
        {children}
      </div>
    );
  }
};
export default LabeledInput;
