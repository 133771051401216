import { css } from "emotion";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../../utils/abstracts/AbstractStylableComponent";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFTag.scss";

type Props = {
  textKey: string;
  icon?: BfIconProps;
} & AbstractStylableProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class BFTag extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    const { i18n, textKey, icon } = this.props;
    if (!this.shoudBeRendered()) {
      return;
    }
    return (
      <div
        className={`BFTag ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        {icon ? (
          <div className={`icon`}>
            <BfIcon {...icon} />
          </div>
        ) : null}
        <div className={`text`}>{i18n.t(textKey)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default connect(mapStateToProps, {})(withTranslation()(BFTag));
