import * as H from "history";
import React from "react";
import { useTranslation } from "react-i18next";
import NavigationPrompt from "react-router-navigation-prompt";
import { Modal } from "rsuite";
import BfButton from "../../modules/abstract-ui/general/Button/BFButton";

type BFPromptProps = {
  when:
    | boolean
    | ((currentLocation: H.Location, nextLocation?: H.Location) => boolean);
  textKey?: string;
};
export const BFPrompt: React.FC<BFPromptProps> = props => {
  const { i18n } = useTranslation();
  const { when, textKey } = props;

  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel, isActive }) => (
        <Modal
          className={`modal-component`}
          backdrop="static"
          show={isActive}
          onHide={() => onCancel()}
          size="xs"
        >
          <Modal.Body>
            {textKey
              ? i18n.t(textKey)
              : i18n.t("Global.Confirm.formDataChanged")}
          </Modal.Body>
          <Modal.Footer>
            <div className={`footer`}>
              <BfButton onClick={() => onCancel()} appearance="clear-on-white">
                {i18n.t("Global.Buttons.cancel")}
              </BfButton>
              <BfButton onClick={() => onConfirm()} appearance="clear-on-white">
                {i18n.t("Global.Buttons.ok")}
              </BfButton>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </NavigationPrompt>
  );
};
