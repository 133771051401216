import Color from "color";
import React, { Component } from "react";
import { SketchPicker } from "react-color";
import Helmet from "react-helmet";
import { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import Tools from "../../utils/Tools";
import CSSField from "./CSSField";
import "./ThemeConfigurator.scss";

type Props = {
  themeConfiguratorActive: boolean;
} & RouteComponentProps &
  WithTranslation;

type States = {
  baseColor: string;
  active: boolean;
  variables: {
    [cssVariable: string]: string;
  };
};

const VARS = {
  "base-color": (color: Color) => {
    return color.hex();
  },
  "base-color-hover": (color: Color) => {
    return color.darken(0.2).hex();
  },
  "base-color-foreground": (color: Color) => {
    return color.isLight() ? "#000" : "#fff";
  },

  "header-background": (color: Color) => {
    return color.darken(0.4).hex();
  },
  "sidenav-background": (color: Color) => {
    return `linear-gradient(to bottom, ${color
      .darken(0.4)
      .hex()} 0%, ${color.hex()} 100%)`;
  },
  "sidenav-color": (color: Color) => {
    return color.isLight() ? "#000" : "#fff";
  },

  "table-titlebar-search-background": (color: Color) => {
    return color.hex();
  },
  "table-titlebar-background": (color: Color) => {
    return color.darken(0.4).hex();
  },
  "table-row-color-hover": (color: Color) => {
    return color
      .mix(Color("#fff"))
      .mix(Color("#fff"))
      .hex();
  },
  "table-row-color-selected": (color: Color) => {
    return color.hex();
  },
  "table-row-color-selected-hover": (color: Color) => {
    return color.darken(0.2).hex();
  },
  "help-drawer-header-background": (color: Color) => {
    return color.hex();
  },
  "login-background": (color: Color) => {
    return `linear-gradient(to bottom, ${color.hex()} 0%, ${color
      .darken(0.9)
      .hex()} 100%)`;
  }

  // "login-background",

  // "highligt-color",

  // // "error-color",
  // "app-background",
  // "app-background-loadmask",
  // /* BASE VARIABLES */

  // "divider-color-dark",
  // "divider-color",
  // /* Application Layouts */
  // "header-height",
  // "header-color",
  // "sidenav-border",

  // /* LoginView */
  // "login-cookies-notification-background",
  // "login-cookies-notification-color",
  // "login-center-background",
  // "login-footer-button-color",

  // /* ApplicationDrawer */
  // "app-drawer-background",

  // "dropdown-background-dark",
  // /* Table variables */
  // "table-titlebar-height",
  // "table-titlebar-search-foreground",
  // "table-titlebar-color",
  // "table-footer-background",
  // "table-footer-color",
  // "table-header-background",
  // "table-header-height",
  // "table-header-background-hover",
  // "table-header-column-resize-spanner-color",
  // "table-row-color",
  // "table-row-color-striped",
  // "table-row-color-selected-color",

  // /* help drawer variables */
  // "help-drawer-header-color",

  // /* Form-Element variables */
  // "form-field-view-table-row-background",
  // "form-field-view-table-subtitle-background",
  // "form-field-view-table-subtitle-color",
};

class ThemeConfiguratior extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    baseColor: "#3B2369",
    variables: {},
    active: false
  };

  render() {
    if (!this.props.themeConfiguratorActive) {
      return null;
    }
    return (
      <div
        className={`theme-configurator ${this.state.active ? "active" : ""}`}
      >
        <div className={`wrapper`}>
          <div
            className={`handle`}
            onClick={() => this.setState({ active: !this.state.active })}
          >
            <BfIcon
              type="bf"
              data={this.state.active ? "close" : "color-bucket-brush"}
              size="2x"
              color="white"
            />
          </div>
          <div className="content">
            <h6>Theme Configuratior</h6>
            <div>
              <div>
                Generate Theme depended on a Base-Color, you can adapt your
                other colors after that with no problem.
              </div>
              <div>
                <SketchPicker
                  color={this.state.baseColor}
                  onChange={color => {
                    this.setState({ baseColor: color.hex });
                    const colorInstance = Color(color.hex);

                    const vars = {};

                    Object.entries(VARS).forEach(([varKey, func]) => {
                      vars[varKey] = func(colorInstance);
                    });

                    this.setState({ variables: vars });
                  }}
                />
              </div>
            </div>
            <div>
              {Object.keys(VARS).map(key => {
                return (
                  <CSSField
                    key={key}
                    name={key}
                    value={
                      this.state.variables[key] ? this.state.variables[key] : ""
                    }
                    onChange={val => {
                      this.setState({
                        variables: { ...this.state.variables, [key]: val }
                      });
                    }}
                  />
                );
              })}
            </div>
            <div>
              <BFButton
                onClick={() => {
                  Tools.copy2Clipboard(
                    JSON.stringify(
                      Object.fromEntries(
                        Object.entries(this.state.variables).filter(
                          ([key, val]) => val && val.trim() !== ""
                        )
                      ),
                      undefined,
                      2
                    ),
                    "Configuration saved in clipboard"
                  );
                }}
              >
                Export Theme-Config
              </BFButton>
            </div>
            <Helmet>
              <style id="context-styles" type="text/css">
                {`
                                    ${
                                      this.state.variables
                                        ? `
                                    body {
                                        ${Object.entries(this.state.variables)
                                          .map(
                                            ([varName, value]) =>
                                              "--" +
                                              varName +
                                              ": " +
                                              value +
                                              ";"
                                          )
                                          .join("\n")}
                                    } `
                                        : null
                                    }
                                
                                `}
              </style>
            </Helmet>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  themeConfiguratorActive:
    state.uiConfig.general[DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE]
});

export default connect(mapStateToProps, {})(ThemeConfiguratior);
