class ObjectToolsClass {
  subtractObject(
    obj1: Object,
    obj2: Object,
    arrayObjEqFc: (key, obj1, obj2) => boolean,
    ...ignoreKeys: string[]
  ): Object {
    const resultObj = {};

    Object.keys(obj1).forEach(key => {
      if (ignoreKeys.indexOf(key) === -1) {
        if (obj2[key]) {
          //check if both are objects
          if (this.isObject(obj1[key]) && this.isObject(obj2[key])) {
            resultObj[key] = this.subtractObject(
              obj1[key],
              obj2[key],
              arrayObjEqFc,
              ...ignoreKeys
            );
          } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            resultObj[key] = obj1[key]
              .map(entry => {
                const other = obj2[key].find(entry2 =>
                  arrayObjEqFc(key, entry, entry2)
                );
                if (other) {
                  return this.subtractObject(
                    entry,
                    other,
                    arrayObjEqFc,
                    ...ignoreKeys
                  );
                } else {
                  return entry;
                }
              })
              .filter(entry => Object.keys(entry).length !== 0);
          }
          //else do not add
        } else {
          resultObj[key] = obj1[key];
        }
      } else {
        resultObj[key] = obj1[key];
      }
    });

    return resultObj;
  }

  mergeObjects(obj1: Object, obj2: Object, ignoreUndefined?: boolean) {
    const resultObj = {};
    if (obj1) {
      Object.keys(obj1).forEach(key => {
        resultObj[key] = obj1[key];
      });
    }
    if (obj2) {
      Object.keys(obj2).forEach(key => {
        if (resultObj[key] === undefined) {
          resultObj[key] = obj2[key];
        } else if (resultObj[key] !== obj2[key]) {
          if (ignoreUndefined && obj2[key] === undefined) {
          } else {
            if (this.isObject(resultObj[key]) && this.isObject(obj2[key])) {
              resultObj[key] = this.mergeObjects(resultObj[key], obj2[key]);
            } else {
              resultObj[key] = obj2[key];
            }
          }
        }
      });
    }

    return resultObj;
  }

  isObject(obj) {
    return !Array.isArray(obj) && obj === Object(obj);
  }
}

const ObjectTools = new ObjectToolsClass();
export default ObjectTools;
