import { css } from "emotion";
import React from "react";
import { connect } from "react-redux";
import { Loader } from "rsuite";
import Log from "../../debug/Log";
import i18n from "../../i18n";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import GenericForms, {
  FormDefinition
} from "../../modules/generic-forms/GenericForms";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../utils/abstracts/AbstractStylableComponent";
import { HTTP } from "../../utils/Http";
import { surveys } from "./surveyconf";
import "./SurveyView.scss";

export interface SurveyConf {
  title: string;
  subtitle: string;
  formDefinition: FormDefinition;
}

type Props = {
  surveyId: string;
  entryId: string;
} & AbstractStylableProps;

type States = {
  config: SurveyConf;
  stateId: "LOADING" | "OK" | "ERROR" | "ALLREADY_EVALUATED";
  submitState: null | "submitting" | "error" | "success";
} & AbstractStylableStates;

class SurveyView extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    config: null,
    stateId: "LOADING",
    submitState: null
  };

  componentDidMount() {
    if (surveys[this.props.surveyId]) {
      this.setState({
        config: surveys[this.props.surveyId]
      });

      HTTP.post({
        responseType: "text",
        url: `prm/ec/${this.props.entryId}`,
        withCredentials: false
      })
        .then(data => {
          this.setState({ stateId: data });
        })
        .catch(e => this.setState({ stateId: "ERROR" }));
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  renderSurvey() {
    const { stateId, submitState } = this.state;

    if (stateId === "LOADING") {
      return (
        <div className={`loader`}>
          <Loader />
        </div>
      );
    }
    if (stateId === "ALLREADY_EVALUATED") {
      return (
        <div className={`info`}>
          Sie haben bereits an der Umfrage teilgenommen. Vielen Dank dafür.
          Sollten Sie noch Fragen haben, melden Sie sich gerne per E-Mail bei
          uns unter{" "}
          <a href="mailto:fabian.kuhn@immox.io">fabian.kuhn@immox.io</a>.
        </div>
      );
    }
    if (stateId === "ERROR") {
      return (
        <div className={`error-message`}>
          <BfIcon data="alert-triangle" type="bf" size="xl" />
          <div>
            Es tut uns Leid, leider ist ein Fehler aufgetreten. Versuchen Sie es
            bitte später erneut.
          </div>
        </div>
      );
    }
    if (submitState === "success") {
      return (
        <div className={`info`}>
          Vielen Dank für Ihre Teilnahme. Sollten Sie noch weitere Fragen und
          Anmerkungen haben, können Sie sich gerne unter{" "}
          <a href="mailto:fabian.kuhn@immox.io">fabian.kuhn@immox.io</a> melden.
        </div>
      );
    }

    return (
      <div className={`form`}>
        <GenericForms
          identifier={"survey"}
          id="survey"
          style={this.state.usedStyle}
          translateFunc={i18n.t}
          formValue={{}}
          actionIdMapping={{
            submit: "survey-submit"
          }}
          additionalData={this.state.params}
          formDefinition={this.state.config.formDefinition}
          onFormSubmit={data => {
            this.setState({
              submitState: "submitting"
            });

            HTTP.put({
              responseType: "text",
              url: `prm/ee/${this.props.entryId}`,
              withCredentials: false,
              bodyParams: {
                data
              }
            })
              .then(result => {
                this.setState({
                  submitState: "success"
                });
              })
              .catch(err => {
                this.setState({
                  submitState: "error"
                });
              });
          }}
          onFormCancel={() => Log.debug("canceled")}
        />
      </div>
    );
  }
  render() {
    const { config } = this.state;
    return (
      <div
        className={`SurveyView ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        <div className={`background`} />
        <div className={`content`}>
          <div className={`header`}>
            {config ? (
              <>
                <div className={`title`}>{config.title}</div>
                <div className={`sub-title`}>{config.subtitle}</div>
              </>
            ) : (
              <>
                <div className={`title`}>Fehler</div>
                <div className={`sub-title`}>Umfrage wurde nicht gefunden</div>
              </>
            )}
          </div>
          <div className={`survey`}>{this.renderSurvey()}</div>
          <div className={`footer`}>
            <div>&copy; immox 2019</div>
            <div className="fill" />
            <BFButton
              appearance="clear"
              onClick={() =>
                DataBus.emit("ROUTE", { route: "__about", append: true })
              }
            >
              {i18n.t("views.login.labels.about")}
            </BFButton>
            <BFButton
              appearance="clear"
              onClick={() =>
                DataBus.emit("ROUTE", { route: "__privacy", append: true })
              }
            >
              {i18n.t("views.login.labels.dataPrivacy")}
            </BFButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default connect(mapStateToProps, {})(SurveyView);
