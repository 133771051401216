import flatten from "flat";
import Log from "../debug/Log";
import { SET_APPICATION_CACHE_DATA } from "../redux/actions/application/application-action-types";
import { store } from "../redux/store";
import { DataBusSubKeys } from "../utils/Constants";
import { HTTP } from "../utils/Http";
import { JsonProperties } from "./../modules/generic-forms/util/JsonValidation";
import { PATCH_TABLE_ROW_DATA } from "./../redux/actions/application/application-action-types";
import { CACHE_TTL } from "./CacheService";
import DataBus from "./DataBus";

export interface SubmitMessage {
	id: string;
	type: "asset" | "user" | "group";
	assetType?: string;
	pushToCache?: boolean;
	pushToTableCache?: string;
	initialData: { [key: string]: any };
	data: { [key: string]: any };
	onSuccess?: (data) => void;
	onError?: (err) => void;
	properties: JsonProperties;
	overwriteUrl?: string;
	overwriteMethod?: "patch" | "put" | "post";
}

export interface SubmitResponse {
	id: string;
	success: boolean;
	data: any;
}

class SubmitServiceClass {
	constructor() {}

	init() {
		DataBus.subscribe<SubmitMessage>(DataBusSubKeys.SUBMIT, data => this.submitData(data));
	}

	submitData(submitMessage: SubmitMessage) {
		let method = null;
		let url = null;

		if (submitMessage.type === "asset") {
			url = `/${submitMessage.type}/${submitMessage.assetType}`;
			method = HTTP.post;
		} else {
			url = `/${submitMessage.type}`;
			if (submitMessage.data["_id"]) {
				method = HTTP.patch;
				url += `/${submitMessage.data["_id"]}`;
			} else {
				method = HTTP.put;
			}
			if (submitMessage.overwriteUrl) {
				url = submitMessage.overwriteUrl;
			}
			if (submitMessage.overwriteMethod) {
				method = HTTP[submitMessage.overwriteMethod];
			}
		}

		// const submitData = { ...submitMessage.data };
		// delete submitData["_id"];
		// delete submitData["id"];
		// delete submitData["permissions"]["ADMIN"];
		// properties
		const initialFlattened = submitMessage.initialData
			? flatten(submitMessage.initialData, {
					safe: true,
					delimiter: "|"
			  })
			: null;

		const submitData: object = flatten.unflatten(
			Object.fromEntries(
				Object.entries(submitMessage.data)
					.map(([key, value]) => {
						let val = value;
						if (val === undefined) {
							val = null;
						}
						return [key, val];
					})
					.filter(([key, value]) => {
						Log.debug("##SUBMITSERVICE EQ KEY, ", key, value, initialFlattened);
						if (!submitMessage.properties[key]) {
							return false;
						}

						switch (submitMessage.properties[key].submitType) {
							case "add":
								return true;
							case "ignore":
								return false;
							default:
								Log.debug("##eq", initialFlattened, key, value);
								if (initialFlattened) {
									let valCheck = value !== null ? value : undefined;

									return initialFlattened[key] !== valCheck;
								} else {
									return true;
								}
						}
					})
			),
			{
				delimiter: "|"
			}
		);

		if (Object.keys(submitData).length !== 0) {
			DataBus.emit(DataBusSubKeys.SUBMIT_START, {
				id: submitMessage.id
			} as SubmitResponse);

			method({
				url: url,
				withCredentials: true,
				headers: {
					"Content-Type": "application/json"
				},
				bodyParams: {
					...submitData,
					...(submitMessage.type === "asset" ? { _id: submitMessage.data["_id"] } : {})
				}
			})
				.then(response => {
					if (submitMessage.pushToCache) {
						store.dispatch({
							type: SET_APPICATION_CACHE_DATA,
							oType: submitMessage.type,
							assetType: submitMessage.assetType,
							id: submitMessage.data["_id"],
							data: response,
							ttl: CACHE_TTL
						});
					}
					if (submitMessage.pushToTableCache) {
						store.dispatch({
							type: PATCH_TABLE_ROW_DATA,
							tableIdentifier: submitMessage.pushToTableCache,
							rowId: submitMessage.data["_id"],
							data: response
						});
					}

					if (submitMessage.onSuccess) {
						submitMessage.onSuccess(response);
					}

					DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
						id: submitMessage.id,
						success: true,
						data: response
					} as SubmitResponse);
				})
				.catch(err => {
					if (submitMessage.onError) {
						submitMessage.onError(err);
					}
					DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
						id: submitMessage.id,
						success: false,
						data: err
					});
				});
		} else {
			//TODO better fix for this issue
			setTimeout(() => {
				DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
					id: submitMessage.id,
					success: true
				} as SubmitResponse);
			});
		}
	}
}

const SubmitService = new SubmitServiceClass();

export default SubmitService;
