import React, { useState } from "react";
import Helmet from "react-helmet";
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Loader } from "rsuite";
import useReactRouter from "use-react-router";
import "./App.css";
import ApplicationBody from "./components/ApplicationBody/ApplicationBody";
import ApplicationContainer from "./components/ApplicationContainer/ApplicationContainer";
import BlockUiOverlay from "./components/BlockUiOverlay/BlockUiOverlay";
import { AboutComponent, PrivacyComponent } from "./components/DataPrivacy";
import HelpDrawer from "./components/HelpDrawer/HelpDrawer";
import LoadMask from "./components/LoadMask/LoadMask";
import ModalComponent from "./components/ModalComponent/ModalComponent";
import DebugController from "./debug/DebugController/DebugController";
import Log from "./debug/Log";
import ThemeConfiguratior from "./debug/ThemeConfigurator/ThemeConfiguratior";
import { User } from "./model/db/User";
import Toast from "./modules/abstract-ui/notification/Toast";
import Automate from "./modules/automate/Automate";
import GFInitializer from "./modules/generic-forms-impl/GFInitializer";
import { setUiConfig } from "./redux/actions/ui-config/ui-config-actions";
import { LayoutUserprofile } from "./redux/reducers/global/StandardLayouts";
import { DefaultUIConfigs } from "./redux/reducers/ui-config/UiConfig";
import { AppState } from "./redux/store";
import { getUserData } from "./services/AuthenticationService";
import DataBus from "./services/DataBus";
import { loadGlobalConfig } from "./services/InitActionService";
import "./styles/global.scss";
import { ComponentsMapper } from "./utils/ComponentsMapper";
import { getErrorLocalized } from "./utils/ErrorCodes";
import AppSelectionView from "./views/app-selection/AppSelectionView";
import CompleteRegistrationForm from "./views/completeRegistration/CompleteRegistrationForm";
import LoginForm from "./views/login/components/LoginForm/LoginForm";
import LoginView from "./views/login/LoginView";
import ModalRouteView from "./views/modalRoute/ModalRouteView";
import ResetPasswordForm from "./views/reset-password/ResetPasswordForm";
import SurveyView from "./views/survey-totrash/SurveyView";

Automate.registerEvent("");

//initialize generic forms components
GFInitializer();

const RedirectToApps = () => {
  return <Redirect to={{ pathname: `/apps` }} />;
};
const RedirectToAppsToasted = () => {
  const { t } = useTranslation();
  Toast.info(t("routing.routeNotValid"), 3000);

  return <RedirectToApps />;
};
const RedirectToLogin = (redirectTo?: string, toastMessage?: string) => {
  if (typeof redirectTo === "string") {
    Toast.info(toastMessage);
  }

  return (
    <Redirect
      to={{
        pathname: `/login${
          typeof redirectTo === "string"
            ? "?redirectTo=" + encodeURIComponent(redirectTo)
            : ""
        }`
      }}
    />
  );
};

const RedirectRoute = () => {
  const { history, location, match } = useReactRouter();
  let redirectTo = match.params["redirectTo"];
  if (redirectTo) {
    redirectTo = decodeURIComponent(redirectTo);

    return <Redirect to={{ pathname: redirectTo }} />;
  } else {
    return <Redirect to={{ pathname: "/apps" }} />;
  }
};

const PrivateRoutes = ({ user }) => {
  return (
    <ApplicationBody>
      <Switch>
        <Route path="/apps" component={AppSelectionView} />
        {user.mandator_info.apps.map(app => {
          if (ComponentsMapper[app.component]) {
            return (
              <Route
                key={app._id}
                path={`/${app.name}`}
                render={() => (
                  <ApplicationContainer applicationConfig={app}>
                    {React.createElement(
                      ComponentsMapper[app.component],
                      app.config
                    )}
                  </ApplicationContainer>
                )}
              />
            );
          }
        })}

        <Route component={RedirectToAppsToasted} />
      </Switch>
    </ApplicationBody>
  );
};

const FetchUserDataView = () => {
  const { location } = useReactRouter();
  const [errorOccurred, setErrorOccurred] = useState(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  dispatch(
    getUserData(
      (data: User) => {
        Log.debug("user data fetched successfully", data);
      },
      (err: any) => {
        Log.error("auth failed on user data fetching", err);
        setErrorOccurred(true);
      }
    )
  );

  if (errorOccurred === null) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff"
        }}
      >
        <Loader size={"lg"} />
      </div>
    );
  } else {
    if (errorOccurred) {
      const toastMessage = t("routing.routeIsPrivate");
      return RedirectToLogin(location.pathname, toastMessage);
    } else {
      return null;
    }
  }
};

const XL = 1200;
const LG = 992;
const MD = 768;
const SM = 576;

class App extends React.Component<any, any> {
  sizeSelector = null;
  sizeCalcTimeout = null;
  constructor(props) {
    super(props);

    this.state = {
      initError: null
    };
  }

  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    (window as any).router = nextProps.history;
    if (
      this.props.debug !== nextProps.debug ||
      this.state.initError !== nextState.initError ||
      (this.props.user && !nextProps.user) ||
      (nextProps.user && !this.props.user) ||
      (nextProps.user &&
        this.props.user &&
        nextProps.user._id !== this.props.user._id) ||
      this.props.configInitialized !== nextProps.configInitialized
    ) {
      return true;
    }
    return false;
  }

  componentDidMount(): void {
    DataBus.subscribe("ROUTE", val => {
      if (val["route"]) {
        if (val["append"]) {
          this.props.history.push(
            this.props.location.pathname + "/" + val["route"]
          );
        } else {
          this.props.history.push(val["route"]);
        }
      }
    });
    this.props.loadGlobalConfig(undefined, err => {
      this.setState({
        initError: getErrorLocalized(err)
      });
    });

    this.calculateSizes();
    window.onresize = ev => {
      if (this.sizeCalcTimeout !== null) {
        clearTimeout(this.sizeCalcTimeout);
        this.sizeCalcTimeout = null;
      }

      this.sizeCalcTimeout = setTimeout(() => {
        this.calculateSizes();
        this.sizeCalcTimeout = null;
      }, 100);
    };
  }

  calculateSizes() {
    const currentWidth = window.innerWidth;
    this.props.setUiConfig(DefaultUIConfigs.VIEWPORT_WIDTH, currentWidth);
    this.props.setUiConfig(
      DefaultUIConfigs.VIEWPORT_HEIGHT,
      window.innerHeight
    );

    let sizeSelector = "";
    if (currentWidth > XL) {
      sizeSelector = "xl";
    } else if (currentWidth > LG) {
      sizeSelector = "lg";
    } else if (currentWidth > MD) {
      sizeSelector = "md";
    } else if (currentWidth > SM) {
      sizeSelector = "sm";
    } else {
      sizeSelector = "xs";
    }
    if (this.sizeSelector !== sizeSelector) {
      this.sizeSelector = sizeSelector;

      this.props.setUiConfig(
        DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR,
        sizeSelector
      );
    }
  }

  buildHelmet() {
    const { context } = this.props;

    return (
      <Helmet>
        <title>{context.name ? context.name : "iberio"}</title>
        {context.favIcon ? (
          <link rel="icon" type="image/png" href={context.favIcon} />
        ) : (
          <link rel="icon" type="image/png" href="/img/favIcon.png" />
        )}
        <style id="context-styles" type="text/css">
          {`
                            ${
                              context.styleVariables
                                ? `
                            body {
                                ${Object.entries(context.styleVariables)
                                  .map(
                                    ([varName, value]) =>
                                      "--" + varName + ": " + value + ";"
                                  )
                                  .join("\n")}
                            } `
                                : null
                            }
                        
                            ${
                              context.customCSS
                                ? Object.values(context.customCSS).join("\n")
                                : ""
                            }
                        `}
        </style>
      </Helmet>
    );
  }

  render() {
    const { context } = this.props;

    (window as any).i18n = this.props.i18n;
    (window as any).translate = this.props.t;
    (window as any).translateFallback = (key, fallbackKey) => {
      const translation = (window as any).translate(key);
      if (translation === key) {
        return (window as any).translate(fallbackKey);
      }
      return translation;
    };

    if (this.state.initError) {
      return (
        <div
          style={{
            padding: 30,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {this.state.initError}
        </div>
      );
    }
    if (!this.props.configInitialized) {
      return <LoadMask />;
    }

    const user = this.props.user;

    return (
      <>
        {this.buildHelmet()}
        <ModalComponent />
        {user ? (
          <>
            <Switch>
              <Route
                path="/completeRegistration"
                children={
                  <Redirect to={{ pathname: "/apps/__userprofile/profile" }} />
                }
              />
              <Route
                path="/resetPassword"
                children={<Redirect to={{ pathname: "/apps" }} />}
              />
              <Route
                path="/login?redirectTo=:redirectTo"
                component={RedirectRoute}
              />
              <Route exact path="/login" component={RedirectRoute} />
              <Route exact path="/" component={RedirectToApps} />
              <Route path="/">
                <Switch>
                  <PrivateRoutes user={user} />
                  <Route component={RedirectToAppsToasted} />
                </Switch>
              </Route>
              <Route component={RedirectToAppsToasted} />
            </Switch>
            <ModalRouteView
              full={true}
              backdrop={"static"}
              routeName={"__userprofile"}
              titleKey={"UserModal.Title"}
              titleCondition={
                "#{profileModal} && #{profileModal}.textHidden ? #{profileModal}.translated : ${default} "
              }
              stateSubscriptions={["profileModal"]}
            >
              {ComponentsMapper.createElement(LayoutUserprofile)}
            </ModalRouteView>
          </>
        ) : (
          <>
            <Switch>
              <Route exact path="/" component={RedirectToLogin} />

              <Route path="/confirmNewEmail/:mandator/:token">
                {
                  //todo do confirm request and redirect to login
                }
              </Route>

              <Route path="/resetPassword/:mandator/:token">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <CompleteRegistrationForm />
                  )}
                />
              </Route>

              <Route path="/resetPassword">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <ResetPasswordForm />
                  )}
                />
              </Route>
              <Route path="/completeRegistration/:mandator/:token">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <CompleteRegistrationForm />
                  )}
                />
              </Route>

              <Route path="/login">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <LoginForm
                      cookiesAccepted={cookiesAccepted}
                      mandatorName={mandatorName}
                    />
                  )}
                />
              </Route>
              <Route path="/login?redirectTo=:redirectTo">
                <LoginView
                  renderContent={(cookiesAccepted, mandatorName) => (
                    <LoginForm
                      cookiesAccepted={cookiesAccepted}
                      mandatorName={mandatorName}
                    />
                  )}
                />
              </Route>

              <Route
                path="/survey/:surveyid/:entryid"
                render={routeprops => {
                  return (
                    <SurveyView
                      surveyId={routeprops.match.params.surveyid}
                      entryId={routeprops.match.params.entryid}
                    />
                  );
                }}
              />

              <Route component={FetchUserDataView} />
            </Switch>
          </>
        )}
        <BlockUiOverlay />

        <ModalRouteView routeName={"__about"} titleKey={"Modals.About.title"}>
          <AboutComponent />
        </ModalRouteView>
        <ModalRouteView
          overflow={false}
          full={true}
          routeName={"__privacy"}
          titleKey={"Modals.Privacy.title"}
        >
          <PrivacyComponent />
        </ModalRouteView>

        <HelpDrawer />

        {this.props.debug ? <DebugController /> : null}
        {this.props.debug ? <ThemeConfiguratior /> : null}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    debug: state.uiConfig.general[DefaultUIConfigs.DEBUG],
    user: state.global.user,
    configInitialized: state.global.configInitialized,
    context: state.global.context
  };
};

export default withRouter(
  connect(mapStateToProps, { setUiConfig, loadGlobalConfig })(
    withTranslation()(App)
  )
);
