import { Locale } from "./locale";
export const localeDE: Locale = {
    "translation": {
        "applicationTitle": "BenfaSoft - Plattform",
        "Formats": {
            "dateFormat": "DD.MM.YYYY",
            "timeFormat": "hh:mm:ss",
            "dateTimeFormat": "DD.MM.YYYY hh:mm:ss",
            "currencyFormat": "##.# €"
        },
        "Global": {
            "Confirm": {
                "formDataChanged": "Sie haben im Formular bereits Daten erfasst, wollen Sie wirklich die Seite verlassen?"
            },
            "Labels": {
                "yes": "Ja",
                "no": "Nein",
                "true": "Ja",
                "false": "Nein",
                "submitSuccess": "Daten erfolgreich übertragen"
            },
            "Buttons": {
                "ok": "Ok",
                "abort": "Abbrechen",
                "cancel": "Abbrechen",
                "next": "Weiter",
                "back": "Zurück",
                "submit": "Speichern",
                "edit": "Bearbeiten",
                "save": "Speichern",
                "add": "Hinzufügen",
                "remove": "Entfernen",
                "delete": "Löschen",
                "revert": "Rückgängig"
            },
            "Table": {
                "LoadingText": "Lädt Daten...",
                "NoDataFound": "Keine Daten gefunden"
            },
            "Date": {
                "today": "Heute",
                "yesterday": "Gestern",
                "tomorrow": "Morgen"
            }
        },
        "HelpDrawer": {
            "Default": {
                "Title": "Hilfe",
                "Questions": {
                    "Example1": {
                        "question": "Question 1",
                        "answer": "Answer 1"
                    },
                    "Example2": {
                        "question": "Question 2",
                        "answer": "Answer 2"
                    },
                    "Example3": {
                        "question": "Question 3",
                        "answer": "Answer 3"
                    }
                }
            },
            "AppDrawer": {
                "Title": "Hilfe Applikationsauswahl",
                "Questions": {
                    "ApplicationSelection": {
                        "question": "Wie wähle ich eine Applikation aus?",
                        "answer": "Drücken Sie das entsprechende Applikationsymbol und die Applikation startet sich automatisch."
                    },
                    "ApplicationMissing": {
                        "question": "Es fehlt eine Applikation?",
                        "answer": "Die Sichtbarkeit der Applikationen steuert ein Administrator Ihres Unternehmens, bitte wenden Sie sich an diesen."
                    }
                }
            },
            "Login": {
                "Title": "Hilfe Login",
                "Questions": {
                    "Definition": {
                        "question": "Was ist iberio?",
                        "answer": "Iberio ist eine Plattform für Unternehmen, auf welcher Sie ihre Daten, Betriebsmittel und Prozesse einfach und sicher digitalisieren können."
                    },
                    "WhyMandator": {
                        "question": "Was ist eine Organisation?",
                        "answer": "Jede Organisation hat ihre eigenen Benutzer und Daten, deswegen muss angegeben werden, bei welcher Organisation man sich anmelden möchte."
                    },
                    "HowToRegister": {
                        "question": "Wie kann man sich registrieren?",
                        "answer": "Sollte Ihr Unternehmen nicht bei uns angemeldet sein, besteht leider keine Möglichkeit für Sie als Benutzer sich hier anzumelden, die Verteilung der Benutzeraccounts liegt bei dem Unternehmen selbst, wir haben darauf keinen Einfluss. Sollte Ihr Unternehmen Interesse an unserem Angebot haben, zögern Sie nicht uns zu kontaktieren, gerne stellen wir Ihnen einen Testzugang bereit."
                    },
                    "HowToLogin": {
                        "question": "Wie kann man sich einloggen?",
                        "answer": "Geben Sie ihren Organisation-Identifizierungs-Token, sowie ihre Zugangsdaten ein. Sollten Sie noch keinen Account besitzen, fragen Sie bei ihrem Unternehmen nach."
                    },
                    "ForgotUsername": {
                        "question": "Ich habe mein Benutzernamen vergessen.",
                        "answer": "Nutzen Sie die Passwort zurücksetzen Funktionaliät, Ihr Zugangsname wird ihnen per Mail versendet und Sie können sich ein neuen Passwort setzten."
                    },
                    "ForgotPassword": {
                        "question": "Ich habe mein Passwort vergessen.",
                        "answer": "Nutzen Sie die Passwort zurücksetzen Funktionaliät, Ihr Zugangsname wird ihnen per Mail versendet und Sie können sich ein neuen Passwort setzten."
                    },
                    "FurtherQuestions": {
                        "question": "Weitere Fragen?",
                        "answer": "Sie haben weitere Fragen oder Anmerkungen? Zögern Sie nicht uns zu kontaktieren!"
                    }
                }
            }
        },
        "BFComponents": {
            "PermissionsFormField": {
                "ApplicationPermission": {
                    "Header": "Zugewiesene Apps",
                    "ModalAddApplication": "Wählen Sie die Applikation aus, die Sie hinzufügen wollen",
                    "NoEntries": "Keine Applikation zugewiesen"
                },
                "AssetTypePermission": {
                    "Header": "Rechte Betriebsmittel"
                },
                "AdministrativePermission": {
                    "Header": "Administrative Rechte"
                }
            },
            "Table": {
                "Global": {
                    "total": "Gesamt"
                },
                "Dropdown": {
                    "ToggleColumnFilters": "Erweiterte Filteroption",
                    "ColumnSettings": "Spalten",
                    "BackToDefault": "Zurücksetzen"
                },
                "Tooltips": {
                    "ReloadAction": "Neu laden",
                    "FilterAction": "Spalten-Details-Filter ein/ausblenden",
                    "ConfigurationAction": "Tabellenkonfiguration"
                },
                "ColumnContextMenu": {
                    "HideColumn": "Ausblenden",
                    "Fixate": {
                        "Title": "Fixieren",
                        "Left": "Links",
                        "Right": "Rechts"
                    }
                },
                "Filter": {
                    "Description": {
                        "LIKE": "Beginnt mit",
                        "NOT_LIKE": "Beginnt nicht mit",
                        "IN": "Ist teil von",
                        "NOT_IN": "Ist nicht teil von",
                        "EQUALS": "Ist gleich",
                        "NOT_EQUALS": "Ist nicht gleich",
                        "LESS_THAN": "Kleiner als",
                        "LESS_THAN_EQUALS": "Kleiner oder gleich als",
                        "GREATER_THAN": "Größer als",
                        "GREATER_THAN_EQUALS": "Größer oder gleich als",
                        "BETWEEN": "Zwischen"
                    },
                    "LIKE": "≈",
                    "NOT_LIKE": "!≈",
                    "IN": "[,]",
                    "NOT_IN": "![,]",
                    "EQUALS": "=",
                    "NOT_EQUALS": "≠",
                    "LESS_THAN": "<",
                    "LESS_THAN_EQUALS": "≤",
                    "GREATER_THAN": ">",
                    "GREATER_THAN_EQUALS": "≥",
                    "BETWEEN": "↔",
                    "Boolean": {
                        "True": "Wahr",
                        "False": "Falsch",
                        "Null": "Nicht gesetzt"
                    }
                }
            },
            "MetaRow": {
                "CreatedBy": "Erstellt von",
                "ModifiedBy": "Bearbeitet von"
            }
        },
        "common": {
            "labels": {
                "yes": "Ja",
                "no": "Nein",
                "ok": "Ok",
                "cancel": "Abbrechen"
            },
            "actions": {
                "insertObject": "Datensatz einfügen",
                "updateObject": "Datensatz ändern",
                "deleteObject": "Datensatz löschen",
                "viewObject": "Datensatz betrachten",
                "getObject": "Fehler"
            },
            "errors": {
                "required": "Pflichtfeld",
                "emailInvalid": "Keine gültige E-Mail Adresse"
            }
        },
        "routing": {
            "routeNotValid": "Sie haben keinen Zugriff auf diese Seite. Sie wurden umgeleitet auf ihre Applikationsauswahl.",
            "routeIsPrivate": "Diese Seite ist geschützt, bitte loggen Sie sich zunächst ein."
        },
        "views": {
            "login": {
                "labels": {
                    "username": "Benutzername",
                    "password": "Passwort",
                    "login": "Einloggen",
                    "help": "Hilfe",
                    "passwordReset": "Passwort zurücksetzen",
                    "register": "Registrieren",
                    "mandator": "Organisation",
                    "dataPrivacy": "Datenschutz",
                    "about": "Impressum",
                    "email": "E-Mail"
                },
                "cookies": {
                    "msg": "Diese Website nutzt Cookies, um Ihnen das bestmögliche Erlebnis bieten zu können. Bitte akzeptieren Sie diese, um die Webseite nutzen zu können.",
                    "confirm": "Cookies akzeptieren"
                },
                "errors": {
                    "noValidCredentialsTitle": "Zugangsdaten unvollständig",
                    "noValidCredentialsMsg": "Bitte geben Sie Ihre vollständigen Zugangsdaten ein",
                    "noCookiesAccepted": "Sie können diese Webseite nur nutzen, wenn Sie die Cookies akzeptieren.",
                    "emailNotValid": "Die eingegebene E-Mail-Adresse ist nicht gültig."
                }
            },
            "completeRegistration": {
                "Title": {
                    "added_displayname": "Registrierung abschließen",
                    "reset_password": "Passwort zurücksetzen"
                },
                "Description": {
                    "reset_password": "Bitte geben Sie ihr neues Passwort an.",
                    "added_displayname": "Es wurde ein Account für Sie angelegt. Bitte geben Sie ein Passwort für diesen an."
                },
                "backToLogin": "Zum Login",
                "Success": {
                    "reset_password": "Passwort wurde erfolgreich gesetzt.",
                    "user_creation": "Benutzer wurde erfolgreich angelegt."
                }
            },
            "forgotPassword": {
                "Title": "Passwort zurücksetzten",
                "Description": "Bitte geben Sie ihre Daten ein, Sie bekommen anschließend eine E-Mail zugesendet. Die E-Mail enthält ein Link, welchen Sie zum Neusetzen des Passworts verwenden können.",
                "Success": "Sie erhalten eine E-Mail, in welcher ein Link enthalten ist, über welches Sie Ihr Passwort zurücksetzen könnnen. Sollten Sie die E-Mail nicht erhalten haben, schauen Sie bitte in Ihrem Spam-Ordner nach oder fragen Sie bei Ihrem Administrator nach."
            }
        },
        "GroupService": {
            "ActionDeleteGroup": "Gruppe löschen",
            "GroupDeletionDialogText": "Wollen Sie die Gruppe endgültig löschen? Die Benutzer der Gruppe verlieren die Rechte, die Ihnen durch die Gruppe erteilt wurden. Dieser Vorgang ist nicht umkehrbar.",
            "GroupDeletionDialogConfirmButton": "Löschen",
            "GroupCreationDialogText": "Bitte geben Sie den Namen der Gruppe ein, die Sie hinzufügen wollen",
            "GroupSuccessfullyCreated": "Gruppe wurde erfolgreich angelegt",
            "GroupSuccessfullyDeleted": "Gruppe wurde erfolgreich gelöscht"
        },
        "UserService": {
            "UserSuccessfullyCreated": "Benutzer wurde erfolgreich angelegt",
            "UserSuccessfullyDeleted": "Benutzer wurde erfolgreich gelöscht",
            "ActionDeleteUser": "Benutzer löschen",
            "UserDeletionDialogText": "Wollen Sie den Benutzer entgültig löschen?",
            "UserDeletionDialogConfirmButton": "Benutzer löschen",
            "ActionLockUser": "Sperren",
            "ActionUnlockUser": "Freischalten",
            "ActionSetPassword": "Passwort setzen",
            "ActionPasswordReset": "Password zurücksetzen",
            "ActionSendAccountActivationMail": "Aktivierungsmail erneut senden",
            "ActionChangeUserEmail": "Email ändern",
            "UserLockDialogText": "Wollen Sie den Benutzer sperren? Dem Benutzer ist es hierauf nicht mehr möglich sich einzuloggen. Sie können die Sperre jederzeit wieder aufheben.",
            "UserLockDialogConfirmButton": "Benutzer sperren",
            "UserPasswordResetDialogText": "Wollen Sie dem Benutzer eine E-Mail schicken, mit welcher er sein Passwort zurücksetzen kann?",
            "UserPasswordResetDialogConfirmButton": "E-Mail schicken",
            "UserResendRegisterTokenDialogText": "Wollen Sie die Registrierungsmail erneut verschicken? Die Gültigkeit der Registrierung wird erneut gesetzt und ist somit erneut X Stunden gültig.",
            "UserResendRegisterTokenDialogConfirmButton": "Registrierungsmail senden",
            "UserUnlockDialogText": "Wollen Sie den Benutzer entsperren? Der Nutzer kann sich dann wieder einloggen und kann entsprechend seiner ihm zugewiesenen Rechte auf Applikationen zugreifen.",
            "UserUnlockDialogConfirmButton": "Benutzer entsperren",
            "StatusSet_0": "Benutzer erfolgreich entsperrt",
            "StatusSet_1": "Benutzer erfolgreich gesperrt",
            "requestNewPasswordSuccess": "E-Mail wurde erfolgreich verschickt",
            "SetPassword": {
                "title": "Passwort setzen",
                "description": "Sie können das Passwort des Benutzers direkt setzen, dies wird nur unter bestimmten Voraussetzungen empfohlen. Bitte nutzen Sie falls möglich eher die 'Passwort zurücksetzen' Option.",
                "submit": "Passwort setzen"
            },
            "SetUserEmail": {
                "title": "Benutzermail ändern",
                "description": "Der Benutzer bekommt nach dem Setzen der neuen E-Mail Adresse eine E-Mail zu der neuen Adresse geschickt, wo er die neue Adresse bestätigen muss. Bis dahin wird die E-Mail Adresse nicht gewechselt.",
                "submit": "E-Mail ändern"
            }
        },
        "Application": {
            "Administration": {
                "Dashboard": {
                    "Title": "Dashboard"
                },
                "User": {
                    "Title": "Benutzer",
                    "SearchPlaceholder": "Name oder ID",
                    "ID": "ID",
                    "Displayname": "Name",
                    "AssignedUsersTableTitle": "Zugeordnete Benutzer",
                    "TableTitle": "Benutzer",
                    "Navigation": {
                        "Form": "Daten",
                        "Groups": "Gruppen",
                        "history": "Historie"
                    },
                    "EmptyText": "Bitte wählen Sie einen Benutzer aus",
                    "AssignedGroupsTableTitle": "Zugewiesene Gruppen",
                    "UserFormTitle": "Benutzerprofil",
                    "Forms": {
                        "userProfile": "Benutzerprofil",
                        "publicData": "Öffentliche Daten"
                    },
                    "createUser": "Benutzer erstellen",
                    "Creation": {
                        "describeSetPassword": "Sie setzten das Passwort des Benutzers direkt, ohne dass dieser eine Registrierungs-Email erhält, in welcher er die Möglichkeit hat, ein eigenes Passwort zu setzten."
                    },
                    "addUser": "Benutzer hinzufügen"
                },
                "App": {
                    "Title": "Applikationen"
                },
                "Group": {
                    "Title": "Gruppen",
                    "TableTitle": "Gruppen",
                    "SearchPlaceholder": "Name oder ID",
                    "Name": "Name",
                    "GroupFormTitle": "Gruppendaten",
                    "AppPermissions": "Applikationsrechte",
                    "Navigation": {
                        "Form": "Daten",
                        "Users": "Zuordnung",
                        "history": "Historie"
                    },
                    "Form": {
                        "Title": "Gruppendetails",
                        "warningUserGroup": "Sie sind Mitglied dieser Gruppe, sollten Sie Änderungen an den Rechten vornehmen, kann dies Auswirkung auf Ihre aktuellen Rechte haben."
                    },
                    "EditForm": {
                        "Title": "Gruppendetails bearbeiten"
                    },
                    "AssignUserToGroup": "Benutzer hinzufügen",
                    "EmptyText": "Bitte wählen Sie eine Gruppe aus",
                    "noAppPermissions": "Keine Apps zugewiesen",
                    "noAssetPermissions": "Keine Betriebsmittel zugewiesen",
                    "name": "Name",
                    "id": "ID",
                    "ID": "ID",
                    "createGroup": "Gruppe erstellen",
                    "addGroup": "Gruppe hinzufügen",
                    "removeSelfWarningText": "Wenn Sie sich selber der Gruppe entfernen, verlieren Sie die zugehörigen Rechte. Wollen Sie sich wirklich der Gruppe entfernen?",
                    "removeSelfWarningTitle": "Eigene Gruppenzugehörigkeit entfernen"
                }
            },
            "Demo": {
                "Wizard": {
                    "Title": "Form-Wizard"
                },
                "PurposeChooser": {
                    "Title": "Form-Switch",
                    "UserDataSubtitle": "Profildaten",
                    "UserApplicationsSubtitle": "Applikationsrechte",
                    "UserAccountSubtitle": "Accountdaten"
                },
                "WizardComplex": {
                    "Title": "Umfragebeispiel"
                }
            },
            "ToDo": "Aufgaben",
            "Todo": {
                "Title": "Aufgaben",
                "List": {
                    "Title": "Liste"
                },
                "Create": {
                    "Title": "Aufgabe erstellen",
                    "Success": "Aufgabe erfolgreich erstellt"
                },
                "TableTitle": "Aufgaben",
                "SearchPlaceholder": "Suche..."
            },
            "Asset": {
                "Todo": {
                    "Name": "Aufgabenberschreibung"
                }
            }
        },
        "errorCodes": {
            "login": {
                "mandatorDeactivated": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Ihre Organisation wurde deaktiviert, bitte melden SIe sich bei den Ansprechpartnern ihrer Organisation"
                },
                "accountDeactivated": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Account wurde deaktiviert"
                },
                "accountInactive": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Account wurde von ihrer Organisation deaktiviert"
                },
                "accountLocked": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Account wurde gesperrt aufgrund zu vielen Fehlversuchen beim Login, bitte melden SIe sich bei Ihrem Ansprechpartner der Organisation"
                },
                "accountWaitingForActivation": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Account muss erst aktiviert werden"
                },
                "unknownUserStatus": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Unbekannter Acocuntstatus"
                },
                "wrongCreds": {
                    "title": "Login nicht erfolgreich",
                    "msg": "Zugangsdaten nicht bekannt"
                }
            },
            "unknownError": {
                "title": "Fehler",
                "msg": "Ein unerwarteter Fehler ist aufgetreten. Bei wiederholtem Auftreten kontakieren Sie bitte den Support."
            },
            "loginCredentialsInvalid": {
                "title": "Login",
                "msg": "Ungültige Logindaten"
            },
            "permissionMissing": {
                "update": {
                    "title": "Fehler",
                    "msg": "Ihnen fehlt die Berechtigung den Datensatz zu ändern."
                },
                "insert": {
                    "title": "Fehler",
                    "msg": "Ihnen fehlt die Berechtigung den Datensatz einzufügen."
                },
                "delete": {
                    "title": "Fehler",
                    "msg": "Ihnen fehlt die Berechtigung den Datensatz zu löschen."
                },
                "view": "Sie haben keine Berechtigung, die Daten zu sehen."
            },
            "idNotFound": {
                "msg": "Das angefragte Objekt konnte nicht gefunden werden",
                "title": "Fehler",
                "completeRegistration": {
                    "msg": "Der Link ist entweder abgelaufen oder ungültig. Bitte wenden Sie sich an Ihren Administrator, um einen neuen Link erhalten zu können."
                },
                "completeRegistrationUserSubmit": {
                    "msg": "Der Benutzer wurde in der Zwischenzeit gelöscht. Bitte wenden Sie sich an den Administrator, falls Sie einen Account benötigen."
                }
            },
            "common": {
                "invalidParam": {
                    "msg": "Anfrage fehlerhaft aufgebaut",
                    "title": "Error"
                }
            },
            "createUser": {
                "userAlreadyExists": {
                    "msg": "Benutzer mit der E-Mail Adresse existiert bereits.",
                    "title": "Fehler"
                }
            },
            "unauthorized": {
                "msg": "Ihre Session scheint abgelaufen zu sein, bitte loggen Sie sich erneut ein.",
                "title": "Session abgelaufen"
            }
        },
        "dateLocales": {
            "sunday": "So",
            "monday": "Mo",
            "tuesday": "Di",
            "wednesday": "Mi",
            "thursday": "Do",
            "friday": "Fr",
            "saturday": "Sa",
            "ok": "OK",
            "today": "Heute",
            "yesterday": "Gestern",
            "hours": "Stunden",
            "minutes": "Minuten",
            "seconds": "Sekunden"
        },
        "UserModal": {
            "Profile": {
                "Title": "Profil",
                "SuccessfullyUpdated": "Profil erfolgreich geändert",
                "Public": {
                    "Title": "Öffentliche Daten"
                }
            },
            "Organization": {
                "Title": "Organisation",
                "SuccessfullyUpdated": "Organization erfolgreich geändert"
            },
            "Settings": {
                "Title": "Einstellungen"
            },
            "History": {
                "Title": "Historie"
            },
            "Title": "Benutzerprofil",
            "Devices": {
                "Title": "Geräte"
            }
        },
        "AssetTypes": {
            "task": "Aufgaben"
        },
        "Log": {
            "Entries": {
                "default": "<1>{{user}}</1> hat eine Aktion mit <3>{{target}}</3> ausgeführt.",
                "Asset": {
                    "update": "<1>{{user}}</1> hat das Betriebsmittel <3>{{target}}</3> aktualisiert.",
                    "create": "<1>{{user}}</1> hat das Betriebsmittel <3>{{target}}</3> erstellt.",
                    "delete": "<1>{{user}}</1> hat das Betriebsmittel <3>{{target}}</3> gelöscht.",
                    "default": "<1>{{user}}</1> hat etwas am Betriebsmittel <3>{{target}}</3> gemacht."
                },
                "User": {
                    "create": "<1>{{user}}</1> hat den Nutzer <3>{{target}}</3> angelegt.",
                    "update": "<1>{{user}}</1> hat den Nutzer <3>{{target}}</3> bearbeitet.",
                    "delete": "<1>{{user}}</1> hat den Nutzer <3>{{target}}</3> gelöscht.",
                    "addGroup": "<1>{{user}}</1> hat den Nutzer <3>{{target}}</3> der Gruppe <5>{{data}}</5> hinzugefügt.",
                    "removeGroup": "<1>{{user}}</1> hat den Nutzer <3>{{target}}</3> aus der Gruppe <5>{{data}}</5> entfernt.",
                    "default": "<1>{{user}}</1> hat etwas mit dem Nutzer <3>{{target}}</3> gemacht.",
                    "setPassword": "<1>{{user}}</1> hat sein Passwort geändert.",
                    "registerUser": "<1>{{user}}</1> hat den Benutzer <3>{{target}}</3> registriert.",
                    "setStatus": "<1>{{user}}</1> hat den Status des Benutzers <3>{{target}}</3> auf <5>{{data}}</5> gesetzt."
                },
                "Group": {
                    "create": "<1>{{user}}</1> hat die Gruppe <3>{{target}}</3> erstellt..",
                    "update": "<1>{{user}}</1> hat die Gruppe <3>{{target}}</3> bearbeitet.",
                    "delete": "<1>{{user}}</1> hat die Gruppe <3>{{target}}</3> gelöscht.",
                    "addUser": "<1>{{user}}</1> hat der Gruppe <3>{{target}}</3> den Benutzer <5>{{data}}</5> hinzugefügt.",
                    "removeUser": "<1>{{user}}</1> hat der Gruppe <3>{{target}}</3> den Benutzer <5>{{data}}</5> entfernt.",
                    "default": "<1>{{user}}</1> hat etwas mit der Gruppe <3>{{target}}</3> gemacht."
                },
                "Labels": {
                    "deletedUser": "Gelöschter Benutzer"
                },
                "noMoreEntries": "Keine weiteren Log-Einträge vorhanden",
                "noEntries": "Keine Logeinträge vorhanden"
            },
            "ContextMenu": {
                "routeToUser": "Anzeigen",
                "routeToGroup": "Anzeigen",
                "routeToAsset": "Anzeigen"
            }
        },
        "General": {
            "Labels": {
                "history": "Historie"
            }
        },
        "Modals": {
            "About": {
                "title": "Impressum"
            },
            "Privacy": {
                "title": "Datenschutzerklärung"
            }
        },
        "User": {
            "Status": {
                "0": "Aktiv",
                "1": "Inaktiv",
                "2": "Gesperrt",
                "3": "Warte auf Aktivierung",
                "4": "Deaktiviert"
            }
        },
        "Header": {
            "profile": "Profil",
            "logout": "Ausloggen",
            "help": "Hilfe",
            "about": "Impressum",
            "privacy": "Datenschutz",
            "openFullscreen": "Vollbild aktivieren",
            "closeFullscreen": "Vollbild schließen",
            "appChange": "Applikation wechseln",
            "language": "Sprache"
        },
        "Permissions": {
            "administrationSuperAdmin": "Super-Administrator",
            "administrationNormalAdmin": "Administrator",
            "administrationMinorAdmin": "Benutzer-Administrator",
            "administrationdDataView": "Dateneinsicht",
            "none": "Keine Sonderrechte",
            "test": "Interner Benutzer"
        },
        "UserProfile": {
            "SuccessfullyUpdated": "Benutzer erfolgreich geändert"
        },
        "OrganizationForm": {
            "SuccessfullyUpdated": "Organization erfolgreich geändert"
        },
        "Notifications": {
            "EmptyText": "Keine Benachrichtigungen vorhanden",
            "Remove": "Löschen",
            "RemoveAll": "Alle löschen",
            "Title": "Benachrichtigungen"
        }
    }
}