import { Alert } from "rsuite";

interface BfToastOptions {
  content: string | React.ReactElement;
  type: "info" | "success" | "warning" | "error";
  duration?: number;

  onClose?: () => void;
}

export const BFToast = {
  open: (options: BfToastOptions) => {
    const { type } = options;

    Alert[type](options.content, options.duration, options.onClose);
  }
};
