import React, { Component, CSSProperties } from "react";
import Swiper, { SwiperOptions } from "swiper";
import "swiper/css/swiper.css";
import "./SwiperComponent.scss";

type Props = {
  id: string;
  style?: CSSProperties;
  className?: string;
  usePagination: boolean;
  useNavigationButtons: boolean;
  useScrollbar: boolean;
  direction: "horizontal" | "vertical";
  loop: boolean;
};

type States = {};

class SwiperComponent extends Component<Props, States> {
  static defaultProps = {
    usePagination: true,
    useNavigationButtons: false,
    useScrollbar: false,
    direction: "horizontal",
    loop: false
  };
  readonly state: States = {};

  swiperComp: Swiper;

  componentDidMount() {
    const {
      id,
      usePagination,
      direction,
      loop,
      useNavigationButtons,
      useScrollbar
    } = this.props;

    const options: SwiperOptions = {
      direction: direction,
      loop: loop,
      centeredSlides: true,
      observeParents: true,
      observer: true
    };
    if (usePagination) {
      options.pagination = {
        el: `#${id}-pagination`
      };
    }

    if (useNavigationButtons) {
      options.navigation = {
        nextEl: `#${id}-navigation-next`,
        prevEl: `#${id}-navigation-previous`
      };
    }

    if (useScrollbar) {
      options.scrollbar = {
        el: `#${id}-scrollbar`
      };
    }

    this.swiperComp = new Swiper(`#${id}-swiper`, options);
  }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    const {
      id,
      style,
      className,
      usePagination,
      useNavigationButtons,
      useScrollbar
    } = this.props;

    return (
      <div
        id={`${id}-swiper`}
        className={`swiper-container bf-swiper-component ${
          className ? className : ""
        }`}
        style={style}
      >
        <div className="swiper-wrapper">{this.props.children}</div>

        {usePagination ? (
          <div id={`${id}-pagination`} className="swiper-pagination"></div>
        ) : null}

        {useNavigationButtons ? (
          <>
            <div
              id={`${id}-navigation-previous`}
              className="swiper-button-prev"
            ></div>
            <div
              id={`${id}-navigation-next`}
              className="swiper-button-next"
            ></div>
          </>
        ) : null}

        {useScrollbar ? (
          <div id={`${id}-scrollbar`} className="swiper-scrollbar"></div>
        ) : null}
      </div>
    );
  }
}

export default SwiperComponent;
