import React, { Component } from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { Modal } from "rsuite";
import BFButton from "../modules/abstract-ui/general/Button/BFButton";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { AppState } from "../redux/store";
import "./DebugDataComponent.scss";

type Props = {
  data: any;
  text?: string;
  debugActive: boolean;
};

type States = {
  active: boolean;
};

class DebugDataComponent extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    active: false
  };

  render() {
    if (!this.props.debugActive) {
      return null;
    }

    return (
      <>
        <BFButton
          text={this.props.text}
          style={{ padding: 0, overflow: "visible" }}
          appearance="debug"
          onClick={() => this.setState({ active: true })}
          icon={{ className: "debug-icon", data: "bug", fontSize: 8 }}
        />

        <Modal
          show={this.state.active}
          onHide={() => this.setState({ active: false })}
        >
          <Modal.Header>
            <Modal.Title>Debug</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactJson
              src={this.props.data}
              displayDataTypes={false}
              enableClipboard={false}
            />

            {/* <code>
                            <pre>
                                {JSON.stringify(this.props.data, null, 5)}
                            </pre>
                        </code> */}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  debugActive: state.uiConfig.general[DefaultUIConfigs.DEBUG_COMPONENTS]
});

export default connect(mapStateToProps, {})(DebugDataComponent);
