import { ThunkDispatch } from "redux-thunk";
import { Filter } from "../../../services/DataService";
import {
	CLEAR_APPLICATION_DATA,
	RESET_LOG_DATA,
	SET_APPICATION_CACHE_DATA,
	SET_LOG_DATA,
	SET_LOG_LOADING,
	SET_TABLE_DATA,
	SET_TABLE_EVENT,
	SET_TABLE_FILTER,
	SET_TABLE_FULLTEXT_SEARCH,
	SET_TABLE_SORT,
	TABLE_EVENT_TYPES
} from "./application-action-types";

export function resetLogData(identifiers: string[]) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: RESET_LOG_DATA,
			identifiers
		});
	};
}

export function setLogLoading(logIdentifier: string, mode: "append" | "prepend" | "replace") {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_LOG_LOADING,
			logIdentifier,
			mode
		});
	};
}

export function setLogData(logIdentifier: string, mode: "append" | "prepend" | "replace", data: Object[], params: any) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_LOG_DATA,
			logIdentifier,
			mode,
			data,
			params
		});
	};
}

export function setTableData(
	tableIdentifier: string,
	data: {
		append?: boolean;
		total: number;
		data: Object[];
		limit: number;
		skip: number;
		fulltextSearch: string;
		filters: { [dataKey: string]: Filter };
		sort: { dataKey: string; sortType: "asc" | "desc" };
	}
) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_TABLE_DATA,
			tableIdentifier,
			data
		});
	};
}

export function setTableFilter(tableIdentifier: string, dataKey: string, filter: Filter) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_TABLE_FILTER,
			tableIdentifier,
			dataKey,
			filter
		});
	};
}

export function setTableFulltext(tableIdentifier: string, fulltextSearch: string) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_TABLE_FULLTEXT_SEARCH,
			tableIdentifier,
			fulltextSearch
		});
	};
}

export function setTableEvent(tableIdentifier: string, event: TABLE_EVENT_TYPES, data: Object) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_TABLE_EVENT,
			tableIdentifier,
			event,
			data
		});
	};
}

export function setTableSort(tableIdentifier: string, dataKey: string, sortType: "asc" | "desc") {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_TABLE_SORT,
			tableIdentifier,
			dataKey,
			sortType
		});
	};
}

export function setApplicationCacheData(
	oType: string,
	id: string,
	data: any,
	timestamp: number,
	ttl?: number,
	assetType?: string
) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_APPICATION_CACHE_DATA,
			oType,
			id,
			data,
			timestamp,
			ttl,
			assetType
		});
	};
}

export function clearApplicationData(paths: string[]) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: CLEAR_APPLICATION_DATA,
			paths
		});
	};
}
