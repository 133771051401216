import React, { Component } from "react";
import { AbstractStylableProps } from "../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../IComponent";

export type FlexLayoutConf = {
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "stretch";

  height?: number | string;
  width?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;

  padding?: string;
  margin?: string;
  className?: string;

  components: { [key: string]: FlexLayoutChildConf };
} & AbstractStylableProps;

export interface FlexLayoutChildConf {
  orderIndex: number;
  order?: number;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number | "auto";
  alignSelf:
    | "auto"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "stretch";

  component: IComponent;
  className?: string;
}

type States = {};

class GridLayout extends Component<FlexLayoutConf, States> {
  render() {
    const { className, components, ...styles } = this.props;

    return (
      <div
        className={`flex-layout ${className ? className : ""}`}
        style={styles}
      >
        {Object.entries(components)
          .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
          .map(([key, item]) => {
            return this.renderFlexLayoutEntry(item, key);
          })}
      </div>
    );
  }

  renderFlexLayoutEntry(entryConf: FlexLayoutChildConf, key) {
    if (
      entryConf.component &&
      ComponentsMapper[entryConf.component._component]
    ) {
      const { component, className, ...styles } = entryConf;

      return (
        <div
          className={`flex-layout-item ${
            entryConf.className ? entryConf.className : ""
          }`}
          data-key={key}
          key={key}
          style={styles}
        >
          {ComponentsMapper.createElement(entryConf.component)}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default GridLayout;
