import React, { Component } from "react";
import sanitizeHtml from "sanitize-html";
type Props = {
  html: string;
};

type States = {};

class Santizer extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  omponentDidMount() {}

  render() {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.props.html) }}
      />
    );
  }
}

export default Santizer;
