import { CSSProperties } from "react";
import Tools, { StyleConfiguration } from "../Tools";
import {
  AbstractComponent,
  AbstractProps,
  AbstractStates
} from "./AbstractComponent";

export type AbstractStylableProps = {
  style?: StyleConfiguration;
  viewportWidth?: number;
} & AbstractProps;

export type AbstractStylableStates = {
  usedStyle?: CSSProperties;
} & AbstractStates;

export abstract class AbstractStylableComponent<T, E> extends AbstractComponent<
  T & AbstractStylableProps,
  E & AbstractStylableStates
> {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps: AbstractProps, nextState: AbstractStates) {
    //do not update on every viewport change
    if (nextState.usedStyle !== this.state.usedStyle) {
      return true;
    }

    if (nextProps.viewportWidth !== this.props.viewportWidth) {
      return false;
    }
    return true;
  }

  componentWillMount(): void {
    super.componentWillMount();
    // let usedStyle = Tools.transformSantizedToCSS(Tools.getActiveBreakpointProperties(this.props.viewportWidth, this.props.style));
    let usedStyle = Tools.getActiveBreakpointProperties(
      this.props.viewportWidth,
      this.props.style
    );
    this.setState({
      usedStyle: usedStyle as any
    });
  }

  componentWillReceiveProps(nextProps: AbstractProps) {
    if (Array.isArray(this.props.style)) {
      if (nextProps.viewportWidth !== this.props.viewportWidth) {
        this.setState({
          usedStyle: Tools.getActiveBreakpointProperties(
            nextProps.viewportWidth,
            nextProps.style
          ) as any
          // usedStyle: Tools.transformSantizedToCSS(Tools.getActiveBreakpointProperties(nextProps.viewportWidth, nextProps.style)) as any
        });
      }
    }
  }

  shoudBeRendered() {
    if (this.state.usedStyle && this.state.usedStyle.display === "none") {
      return false;
    }
    return super.shoudBeRendered();
  }
}
