import React, { SyntheticEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ActionDataEvent } from "../../../model/common/DataBus/ActionData";
import BfButton from "../../../modules/abstract-ui/general/Button/BFButton";
import { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../../layouts/IComponent";

type Props = {
  actionId: string;

  viewportWidth: number;

  appearance?:
    | "default"
    | "primary"
    | "link"
    | "clear"
    | "outline"
    | "clear-on-white"
    | "clear-highlight";
  size?: "lg" | "md" | "sm" | "xs";
  circle?: boolean;
  textKey?: string;
  icon?: BfIconProps;
  iconPosition?: "left" | "right";
  text?: string;
  tabIndex?: string;

  initialState?: {
    hidden?: boolean;
    focus?: boolean;
    loading?: boolean;
    disabled?: boolean;
    toggled?: boolean;
  };
  ignoreStateUpdates?: boolean;
  actionParams: { [key: string]: string };
  component?: IComponent;
} & WithTranslation &
  AbstractStylableProps;

type States = {
  hidden?: boolean;
  focus?: boolean;
  loading?: boolean;
  disabled?: boolean;
  toggled?: boolean;
} & AbstractStylableStates;

class ActionComponent extends AbstractStylableComponent<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = props.initialState
      ? props.initialState
      : {
          hidden: true,
          focus: false,
          loading: false,
          disabled: false,
          toggled: false
        };
  }

  componentDidMount(): void {
    this.subscribe(this.props.actionId, data => {
      const { type, ...params } = data;
      if (!this.props.ignoreStateUpdates) {
        if (type === "state") {
          this.setState(params);
        }
      }
    });
  }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    const shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);

    return shouldUpdate;
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }

    const {
      actionId,
      textKey,
      text,
      i18n,
      appearance,
      size,
      circle,
      icon,
      iconPosition,
      tabIndex,
      component
    } = this.props;
    const { usedStyle } = this.state;
    let usedText = null;

    if (text) {
      usedText = text;
    } else if (textKey) {
      usedText = i18n.t(textKey);
    }

    if (this.state.hidden) {
      return null;
    }
    return (
      <BfButton
        disabled={this.state.disabled}
        loading={this.state.loading}
        appearance={appearance}
        style={usedStyle}
        size={size}
        circle={circle}
        textKey={textKey}
        icon={icon}
        iconPosition={iconPosition}
        tabIndex={tabIndex}
        text={text}
        onClick={(e: SyntheticEvent) =>
          this.emit(actionId, {
            type: "click",
            event: e,
            data: this.props.actionParams
              ? {
                  ...Object.fromEntries(
                    Object.entries(this.props.actionParams).map(
                      ([key, actionParam]) => {
                        if (typeof actionParam === "string") {
                          return [
                            key,
                            this.evaluateExpression(actionParam as string)
                          ];
                        } else {
                          return [key, actionParam];
                        }
                      }
                    )
                  )
                }
              : undefined
          } as ActionDataEvent)
        }
      >
        {component ? ComponentsMapper.createElement(component) : null}
      </BfButton>
    );
  }
}
const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

const exportAction = connect(
  mapStateToProps,
  {}
)(withTranslation()(ActionComponent));

export default exportAction;

ComponentsMapper["action"] = exportAction;
ComponentsMapper["Action"] = exportAction;
