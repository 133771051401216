import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import { localeDE } from "./locales/de";
import { localeEN } from "./locales/en";
import { setUiConfig } from "./redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "./redux/reducers/ui-config/UiConfig";
import { store } from "./redux/store";

const resources = {
  de: localeDE,
  en: localeEN
};

moment.locale("de");
export const i18nOptions = {
  resources,
  lng: "de",
  fallbackLng: "en",
  debug: process.env.NODE_ENV !== "production",
  saveMissing: false,

  interpolation: {
    escapeValue: false // react already safes from xss
  },
  // special options for react-i18next
  // learn more: https://react.i18next.com/components/i18next-instance
  react: {
    wait: true,
    useSuspense: false
  }
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector);
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options

i18n.on("languageChanged", lng => {
  moment.locale(lng);

  store.dispatch(setUiConfig(DefaultUIConfigs.SELECTED_LANGUAGE, lng));
});
export default i18n;
