import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Message } from "rsuite";
import { IComponent } from "../../../../configurable/layouts/IComponent";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../../utils/ComponentsMapper";
import BfIcon from "../../icon/BfIcon";
import "./BFMessage.scss";

type Props = {
  type: "info" | "success" | "warning" | "error";
  titleKey?: string;
  textKey: string;
  component?: IComponent;
  closable?: boolean;
  showIcon?: boolean;
} & AbstractStylableProps &
  RouteComponentProps<any> &
  WithTranslation;

type States = {} & AbstractStylableStates;

class BFMessage extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  renderIcon(type) {
    let icon = null;
    switch (type) {
      case "info":
        icon = "information-circle";
        break;
      case "success":
        icon = "check-1";
        break;
      case "warning":
        icon = "alert-circle";
        break;
      case "error":
        icon = "alert-triangle";
        break;
    }
    if (icon) {
      return <BfIcon type="bf" data={icon} size={"xl"} />;
    }
    return null;
  }

  render() {
    const {
      i18n,
      type,

      titleKey,
      textKey,
      component,
      closable,
      showIcon
    } = this.props;

    return (
      <Message
        style={this.state.usedStyle}
        type={type}
        showIcon={false}
        closable={closable}
        description={
          <div className={`bf-message-content`}>
            {showIcon ? this.renderIcon(type) : null}
            {component ? (
              ComponentsMapper.createElement(component)
            ) : (
              <div className={`text-content`}>
                {titleKey ? (
                  <div className={`title`}>{i18n.t(titleKey)}</div>
                ) : null}
                {textKey ? (
                  <div className={`description`}>{i18n.t(textKey)}</div>
                ) : null}
              </div>
            )}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(BFMessage))
) as React.ComponentType<any>;
