import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Badge, Dropdown } from "rsuite";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader";
import { Notification } from "../../model/notifications/Notification";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { seenAllNotifications } from "./../../redux/actions/notifications/notifications-actions";
import "./NotificationsButton.scss";
import NotificationsMenu from "./NotificationsMenu";

type Props = {
	seenAllNotifications: () => void;
	notifications: Notification[];
	notificationsOverlayActive: boolean;
	setUiConfig: (key: string, value: any) => void;
} & WithTranslation;

type States = {};

class NotificationsButton extends Component<Props, States> {
	static defaultProps = {};
	readonly state: States = {};

	// omponentDidMount() {}

	// componentWillUnmount() {}

	// componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

	// getSnapshotBeforeUpdate(prevProps: Props, prevState: States) {}

	shouldComponentUpdate(nextProps: Props, nextState: States) {
		return true;
	}
	toggleNotification() {
		if (!this.props.notificationsOverlayActive) {
			this.props.seenAllNotifications();
		}
		this.props.setUiConfig(DefaultUIConfigs.NOTIFICATION_OVERLAY_ACTIVE, !this.props.notificationsOverlayActive);
	}

	evaluateNotifications() {
		const { notifications } = this.props;
		let badgeCounter = 0;
		let asyncTask = false;

		notifications.forEach(notification => {
			if (!notification.seen) {
				badgeCounter++;
			}
			if (notification.type === "progress" && notification.state === "inprogress") {
				asyncTask = true;
			}
		});

		return {
			badgeCounter,
			asyncTask
		};
	}

	render() {
		const { notificationsOverlayActive } = this.props;
		const { badgeCounter, asyncTask } = this.evaluateNotifications();

		const alarmIcon = (
			<div className="font-color-white">
				<BfIcon data="alarm-bell" type="bf" size="lg" />
			</div>
		);

		return (
			<Dropdown
				className="header-dropdown notifications-dropdown"
				open={notificationsOverlayActive}
				onToggle={() => this.toggleNotification()}
				title={
					<>
						{badgeCounter !== 0 ? <Badge content={badgeCounter}>{alarmIcon}</Badge> : alarmIcon}
						{asyncTask ? (
							<div className={`async-task-loader`}>
								<HorizontalLoader size="xs" />
							</div>
						) : null}
					</>
				}
				noCaret
				placement="bottomEnd"
			>
				<NotificationsMenu />
			</Dropdown>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({
	notifications: state.notifications.entries,
	notificationsOverlayActive: state.uiConfig.general[DefaultUIConfigs.NOTIFICATION_OVERLAY_ACTIVE]
});

export default connect(mapStateToProps, { setUiConfig, seenAllNotifications })(withTranslation()(NotificationsButton));
