import React from "react";
import ApplicationDemo from "../apps/application-demo/ApplicationDemo";
import ApplicationAdmin from "../apps/ApplicationAdmin/ApplicationAdmin";
import PermissionChecker from "../components/PermissionChecker/PermissionChecker";
import ActionComponent from "../configurable/components/ActionComponent/ActionComponent";
import CardComponent from "../configurable/components/CardComponent/CardComponent";
import ChartComponent from "../configurable/components/ChartComponent/ChartComponent";
import ConfAvatarComponent from "../configurable/components/ConfAvatarComponent/ConfAvatarComponent";
import DropdownComponent from "../configurable/components/DropdownComponent/DropdownComponent";
import IconComponent from "../configurable/components/IconComponent/IconComponent";
import LogComponent from "../configurable/components/LogComponent/LogComponent";
import SearchField from "../configurable/components/SearchField/SearchField";
import TableSearchField from "../configurable/components/SearchField/TableSearchField";
import Separator from "../configurable/components/Separator/Separator";
import TableComponent from "../configurable/components/TableComponent/TableComponent";
import TextComponent from "../configurable/components/TextComponent/TextComponent";
import ApplicationSidenavLayout from "../configurable/layouts/ApplicationLayouts/ApplicationSidenavLayout";
import DetailsRouteComponent from "../configurable/layouts/DetailsRouteComponent/DetailsRouteComponent";
import DivLayout from "../configurable/layouts/DivLayout/DivLayout";
import FlexLayout from "../configurable/layouts/FlexLayout/FlexLayout";
import GridLayout from "../configurable/layouts/GridLayout/GridLayout";
import { IComponent } from "../configurable/layouts/IComponent";
import ModalContainer from "../configurable/layouts/ModalContainer/ModalContainer";
import NavLayout from "../configurable/layouts/NavLayout/NavLayout";
import ScrollPanel from "../configurable/layouts/ScrollPanel/ScrollPanel";
import SplitPageLayout from "../configurable/layouts/SplitPageLayout/SplitPageLayout";
import TabLayout from "../configurable/layouts/TabLayout/TabLayout";
import CardSection from "../configurable/wrapped/CardSection";
import Fill from "../configurable/wrapped/Fill";
import MetaRow from "../configurable/wrapped/MetaRow";
import ScrollCard from "../configurable/wrapped/ScrollCard";
import SidenavAction from "../configurable/wrapped/SidenavAction";
import TabButtonDefault from "../configurable/wrapped/TabButtonDefault";
import {
  GenericFormsWrapper,
  StandardFormsWrapper
} from "../modules/generic-forms-impl/FormsWrapper";
import FormWrapper from "../modules/generic-forms-impl/FormWrapper";

export const ComponentsMapper = {
  createElement(comp: IComponent, key?: any) {
    if (!comp) {
      return null;
    }

    if (comp._component && ComponentsMapper[comp._component]) {
      return (
        <PermissionChecker key={key} permission={comp["permission"]}>
          {React.createElement(ComponentsMapper[comp._component], {
            key: key,
            ...comp
          })}
        </PermissionChecker>
      );
    }
    console.error("#### Couldnt find component: " + comp._component);
    return null;
  },
  //custom applications
  ApplicationAdmin: ApplicationAdmin,
  ApplicationDemo: ApplicationDemo,

  //app layouts
  SidenavApp: ApplicationSidenavLayout,

  //layout components
  TabLayout: TabLayout,
  tabs: TabLayout,
  FlexLayout: FlexLayout,
  Flex: FlexLayout,
  flex: FlexLayout,
  GridLayout: GridLayout,
  Grid: GridLayout,
  grid: GridLayout,
  DivLayout: DivLayout,
  Div: DivLayout,
  div: DivLayout,
  SplitPageLayout: SplitPageLayout,
  scroll: ScrollPanel,
  scrollPanel: ScrollPanel,
  modal: ModalContainer,
  NavLayout: NavLayout,

  //standand components
  action: ActionComponent,
  Action: ActionComponent,
  Card: CardComponent,
  Table: TableComponent,
  separator: Separator,
  Separator: Separator,
  searchfield: SearchField,
  tableSearchfield: TableSearchField,
  log: LogComponent,
  chart: ChartComponent,
  dropdown: DropdownComponent,
  avatar: ConfAvatarComponent,
  //specific compoenents
  detailsRoute: DetailsRouteComponent,
  //dashboard components

  //forms
  "standard-forms": StandardFormsWrapper,
  "generic-forms": GenericFormsWrapper,
  form: FormWrapper,

  //display components
  icon: IconComponent,
  text: TextComponent,

  //wrapper
  tabButtonDefault: TabButtonDefault,
  ScrollCard: ScrollCard,
  fill: Fill,
  CardSection: CardSection,
  sidenavAction: SidenavAction,
  "meta-row": MetaRow
};
export const registerComponent = (name: string, component: React.Component) => {
  ComponentsMapper[name] = component;
};
