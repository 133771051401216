import Axios, {
  AxiosBasicCredentials,
  CancelToken,
  Method,
  ResponseType
} from "axios";
import Log from "../debug/Log";
import { devProp, Properties } from "./Properties";

export const METHOD = {
  GET: "GET",
  POST: "POST",
  INSERT: "INSERT",
  UPDATE: "UPDATE"
};

export interface HttpInterface {
  url: string;
  queryParams?: { [key: string]: any };
  bodyParams?: { [key: string]: any };
  headers?: any;
  auth?: AxiosBasicCredentials;
  cancelToken?: CancelToken;
  withCredentials?: boolean;
  timeout?: number;
  responseType?: ResponseType;
}

export interface ApiResponse {
  data?: any;
  errorIdentifier?: string;
  errorParams?: any;
  debug?: any;
}

// (window as any).axios.defaults.headers.common = {
//     'X-CSRF-TOKEN': (window as any).Laravel.csrfToken,
//     'X-Requested-With': 'XMLHttpRequest'
//   };

// const API_URL = Properties.serverUrl + "/api";

const contextTokens = devProp(
  window.location.hostname,
  Properties.dev.context
).split(".");
const serverURL =
  "https://api." +
  contextTokens[contextTokens.length - 2] +
  "." +
  contextTokens[contextTokens.length - 1];
export const HTTP = {
  API_URL: serverURL + "/api/",

  //
  // convertParams: (paramsObj) => {
  //
  //
  //     let queryParams = "";
  //     if (paramsObj) {
  //         Object.keys(paramsObj).forEach(paramKey => {
  //             const paramVal = paramsObj[paramKey];
  //             let paramValue = undefined;
  //             switch (typeof paramVal) {
  //                 case 'string':
  //                     paramValue = encodeURIComponent(paramVal);
  //                     break;
  //                 case 'object':
  //                     paramValue = encodeURIComponent(JSON.stringify(paramVal));
  //                     break;
  //                 case 'number':
  //                 case 'boolean':
  //                     paramValue = paramVal;
  //                     break;
  //             }
  //             queryParams += `&${paramKey}=${paramValue}`;
  //         });
  //     }
  //     if (queryParams !== "") {
  //         queryParams = queryParams.substr(1);
  //     }
  //
  //     return queryParams;
  // },

  http: (method: Method, httpObj: HttpInterface) => {
    return new Promise<any>((resolve, reject) => {
      const isApiCall = httpObj.url.indexOf(":") === -1;

      // let bodyParams = HTTP.convertParams(httpObj.bodyParams);
      // if (bodyParams === "") {
      //     bodyParams = undefined;
      // }

      const url = httpObj.url;
      Axios.request({
        baseURL: HTTP.API_URL,
        method: method,
        params: httpObj.queryParams,
        url: url,
        responseType: httpObj.responseType ? httpObj.responseType : "json",
        data: httpObj.bodyParams,
        headers: {
          "Content-Type": "application/json"
        },
        ...httpObj,
        withCredentials: true
      })
        .then(data => {
          if (data.data) {
            resolve(data.data);
          } else {
            resolve(data);
          }
        })
        .catch(err => {
          //wrap error handling here
          Log.error(err);

          reject(err);
        });
    });
  },

  get: (httpObj: HttpInterface) => {
    return HTTP.http("GET", httpObj);
  },
  post: (httpObj: HttpInterface) => {
    return HTTP.http("POST", httpObj);
  },
  patch: (httpObj: HttpInterface) => {
    return HTTP.http("PATCH", httpObj);
  },
  put: (httpObj: HttpInterface) => {
    return HTTP.http("PUT", httpObj);
  },
  delete: (httpObj: HttpInterface) => {
    return HTTP.http("DELETE", httpObj);
  }
};
