import { ThunkDispatch } from "redux-thunk";
import { Notification } from "../../../model/notifications/Notification";
import { NOTIFICATION_DELETE_TRANSITION } from "../../../modules/notifications/NotificationEntry";
import { store } from "../../store";
import {
	REGISTER_NOTIFICATION,
	REMOVE_NOTIFICATION,
	SEEN_ALL_NOTIFICATIONS,
	TOGGLE_EXPAND_STATE
} from "./notifications-actions-types";

// TypeScript infers that this function is returning SendMessageAction

export function seenAllNotifications() {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SEEN_ALL_NOTIFICATIONS
		});
	};
}
export function clearNotifications() {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		const entries = store.getState().notifications.entries;
		entries.forEach(entry => {
			console.log(entry);
			if (entry.deletable !== false) {
				dispatch(removeNotification(entry.id));
			}
		});
	};
}
export function removeNotification(id: string) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: REGISTER_NOTIFICATION,
			notification: {
				id: id,
				isDeleting: true
			}
		});
		setTimeout(() => {
			dispatch({
				type: REMOVE_NOTIFICATION,
				id
			});
		}, NOTIFICATION_DELETE_TRANSITION);
	};
}
export function registerNotification(notification: Notification) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: REGISTER_NOTIFICATION,
			notification
		});
	};
}

export function toggleExpandState(id: string, toggled: boolean) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: TOGGLE_EXPAND_STATE,
			id,
			toggled
		});
	};
}
