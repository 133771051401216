import { Notification } from "rsuite";
import { NotificationPlacement } from "../modules/abstract-ui/common/Placements";

interface BfNotificationOptions {
	title?: string | React.ReactNode;
	description: string | React.ReactNode;
	type?: "info" | "success" | "warning" | "error";
	duration?: number;
	placement?: NotificationPlacement;
	top?: number;
	bottom?: number;
	key?: string;

	onClose?: () => void;
}

export const BFNotification = {
	open: (options: BfNotificationOptions) => {
		Notification.open(options);
	},
	remove: (key: string) => {
		// Notification.remove(key);
	}
};
