import { ThunkDispatch } from "redux-thunk";
import { ContextData } from "../../../model/db/ContextData";
import { User, UserConfig } from "../../../model/db/User";
import { JsonProperties } from "../../../modules/generic-forms/util/JsonValidation";
import DataBus from "../../../services/DataBus";
import { MandatorData } from "../../reducers/global/GlobalInterface";
import { RESET_CACHE_COMPLETE } from "../application/application-action-types";
import {
	MERGE_GLOBAL_CONFIG,
	SET_ASSET_TYPE_CONF,
	SET_CONTEXT_DATA,
	SET_GLOBAL_CACHE_DATA,
	SET_MANDATOR_DATA,
	SET_USER_DATA,
	UPDATE_USER_CONFIG
} from "./global-actions-types";

// TypeScript infers that this function is returning SendMessageAction

export function setAssetTypeConfig(
	assetTypeConf: {
		name: string;
		formDefinition: { [key: string]: { [key: string]: any } };
		properties: JsonProperties;
	}[]
) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_ASSET_TYPE_CONF,
			assetTypeConfigs: assetTypeConf
		});
	};
}

export function setContextData(context: ContextData) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_CONTEXT_DATA,
			context
		});
	};
}

export function setMandatorData(mandator: MandatorData) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		DataBus.emit("mandator", mandator, true);
		dispatch({
			type: SET_MANDATOR_DATA,
			mandator
		});
	};
}

export function setUserData(user: User) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_USER_DATA,
			user
		});
		if (!user) {
			dispatch({
				type: RESET_CACHE_COMPLETE
			});
		}
	};
}

export function updateUserConfig(config: UserConfig) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: UPDATE_USER_CONFIG,
			config: config
		});
	};
}

export function setGlobalCacheData(key: string, data: any, timestamp: number, ttl?: number) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: SET_GLOBAL_CACHE_DATA,
			key,
			data,
			timestamp,
			ttl
		});
	};
}

export function mergeGlobalConfig(config: any) {
	return (dispatch: ThunkDispatch<{}, {}, any>) => {
		dispatch({
			type: MERGE_GLOBAL_CONFIG,
			config
		});
	};
}
