import moment from "moment";
import React, { CSSProperties } from "react";
import { Field, FieldInputProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { GenericFormsLayoutProps } from "../BaseElement";
import GenericLayoutComponent from "../GenericLayoutComponent";
import GenericReplaceVariablesWrapper from "../GenericReplaceVariablesWrapper";
import { JsonPropertyComponent } from "../util/JsonValidation";
import Validators from "../util/Validatos";
import "./DisplayComponents.scss";

type GFFieldDisplayProps = {
  name: string;
  condition?: string;
  stateSubscriptions?: string[];
  style?: CSSProperties;
  render: (
    fieldLabel: string,
    fieldValue: any,
    properties: JsonPropertyComponent,
    input: FieldInputProps<any, HTMLElement>,
    style?: CSSProperties
  ) => React.ReactNode;
} & GenericFormsLayoutProps;

export const GFFieldDisplay: React.FC<GFFieldDisplayProps> = props => {
  const {
    name,
    render,
    allProperties,
    condition,
    stateSubscriptions,
    style
  } = props;
  const jsonProps = allProperties[name];

  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      condition={condition}
      style={style}
      render={styleProps => (
        <Field
          name={name}
          render={({ input, meta }) => {
            return render(
              jsonProps.label,
              input.checked !== undefined ? input.checked : input.value,
              jsonProps as JsonPropertyComponent,
              input,
              styleProps
            );
          }}
        />
      )}
    />
  );
};

type GFFieldValueProps = {
  name: string;
  condition?: string;
  style?: CSSProperties;
  stateSubscriptions?: string[];
} & GenericFormsLayoutProps;
export const GFFieldValue: React.FC<GFFieldValueProps> = props => {
  const { i18n } = useTranslation();
  const {
    name,
    formProps,
    allProperties,
    condition,
    stateSubscriptions,
    style
  } = props;

  const renderValue = (
    fieldLabel: string,
    fieldValue: any,
    properties: JsonPropertyComponent,
    input: FieldInputProps<any, HTMLElement>,
    style?: CSSProperties
  ) => {
    //todo localise booleans & use option labels
    switch (properties.type) {
      case "mail":
        return fieldValue !== undefined ? (
          <a href={`mailto:${fieldValue}`} target="_blank">
            {fieldValue}
          </a>
        ) : (
          ""
        );
      case "website":
        return fieldValue !== undefined ? (
          <a href={`${fieldValue}`} target="_blank">
            {fieldValue}
          </a>
        ) : (
          ""
        );
      case "text":
      case "textarea":
      case "number":
        return fieldValue === undefined ? "" : fieldValue;
      case "checkbox":
      case "toggle":
        if (fieldValue === true) {
          return i18n.t("Global.Labels.true");
        }
        if (fieldValue === false) {
          return i18n.t("Global.Labels.false");
        }
        return "";
      case "date":
        if (fieldValue !== undefined) {
          return moment(new Date(fieldValue)).format(
            i18n.t("Formats.dateFormat")
          );
        } else {
          return "";
        }
      case "checkbox-group":
      case "radio":
      case "select":
        const isMultiple =
          properties.type === "checkbox-group" ||
          (properties.type === "select" && properties.multiple);

        const valueOptions = Validators.getValidOptions(
          (properties as any).options,
          formProps.values
        ).filter(option =>
          isMultiple
            ? (fieldValue || []).find(val => val === option.value) !== undefined
            : fieldValue === option.value
        );

        return valueOptions.map(valueOption => valueOption.label).join("; ");
    }

    return fieldValue === undefined ? "" : fieldValue;
  };

  return (
    <GFFieldDisplay
      style={style}
      condition={condition}
      stateSubscriptions={stateSubscriptions}
      name={name}
      render={(fieldLabel, fieldValue, properties, input, styles) => {
        return (
          <div style={styles}>
            {renderValue(fieldLabel, fieldValue, properties, input, styles)}
          </div>
        );
      }}
      allProperties={allProperties}
      formProps={formProps}
    />
  );
};

type GFFieldLabelProps = {
  name: string;
  condition?: string;
  stateSubscriptions?: string[];
  style?: CSSProperties;
} & GenericFormsLayoutProps;
export const GFFieldLabel: React.FC<GFFieldLabelProps> = props => {
  const {
    name,
    formProps,
    allProperties,
    condition,
    stateSubscriptions,
    style
  } = props;
  return (
    <GFFieldDisplay
      style={style}
      condition={condition}
      stateSubscriptions={stateSubscriptions}
      name={name}
      render={(fieldLabel, fieldValue, properties) => {
        return fieldLabel === undefined ? null : fieldLabel;
      }}
      allProperties={allProperties}
      formProps={formProps}
    />
  );
};

type GFImageProps = {
  imageUrl: string;
  condition?: string;
  stateSubscriptions?: string[];
} & GenericFormsLayoutProps;
export const GFImage: React.FC<GFImageProps> = props => {
  const { imageUrl, condition, stateSubscriptions } = props;
  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      condition={condition}
      render={styleProps => (
        <div style={{ ...styleProps }} className={"gf-image"}>
          <img src={imageUrl} />
        </div>
      )}
    />
  );
};

type GFLabelProps = {
  textKey?: string;
  text?: string;
  condition?: string;
  className?: string;
  textStyle: "label" | "h1" | "h2" | "h3" | "h4" | "h5" | "p";
  style?: CSSProperties;
  stateSubscriptions?: string[];
} & GenericFormsLayoutProps;
export const GFLabel: React.FC<GFLabelProps> = props => {
  const { i18n } = useTranslation();
  let {
    textStyle,
    text,
    condition,
    textKey,
    style,
    stateSubscriptions,
    className
  } = props;
  if (!textStyle) {
    textStyle = "label";
  }
  if (text === undefined && textKey) {
    text = i18n.t(textKey);
  }
  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      condition={condition}
      render={styleProps => (
        <GenericReplaceVariablesWrapper
          conditions={[text]}
          render={([replacedText]) => (
            <div
              style={{ ...style, ...styleProps }}
              className={`gf-label text-style-${textStyle} ${
                className ? className : ""
              }`}
            >
              {replacedText}
            </div>
          )}
        />
      )}
    />
  );
};
