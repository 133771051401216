import React, { Component } from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import { Animation, Dropdown, Nav } from "rsuite";
import ApplicationSidenav from "../../../components/ApplicationSidenav/ApplicationSidenav";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../IComponent";
import { setUiConfig } from "./../../../redux/actions/ui-config/ui-config-actions";
import "./ApplicationSidenavLayout.scss";

const { Collapse } = Animation;

type ApplicationSidenavLayoutConf = {
  subPages: { [key: string]: SubpageConf };
  redirectNotFound: {
    path: string;
    appExtern?: boolean;
  };
  idleComponents?: IComponent;
};

interface SubpageConf {
  orderIndex: number;
  icon?: BfIconProps;
  routeName: string;
  displayNameSelector: string;
  actions?: {
    [key: string]: IComponent;
  };
  component?: IComponent;

  subPages?: { [key: string]: SubpageConf };
}

type Props = {
  setUiConfig: (key: string, value: any) => void;
} & ApplicationSidenavLayoutConf &
  RouteComponentProps &
  WithTranslation;

type States = {};

class ApplicationSidenavLayout extends Component<Props, States> {
  componentDidMount() {
    this.setMobileHeader();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      this.setMobileHeader();
    }
  }
  componentWillUnmount() {
    this.props.setUiConfig(DefaultUIConfigs.MOBILE_HEADER, null);
  }
  setMobileHeader() {
    const { setUiConfig, location, subPages } = this.props;
    const activeSubpage = Object.values(subPages).find(
      subPage => location.pathname.indexOf(subPage.routeName) !== -1
    );
    if (activeSubpage) {
      setUiConfig(DefaultUIConfigs.MOBILE_HEADER, {
        icon: activeSubpage.icon,
        textKey: activeSubpage.displayNameSelector
      });
    } else {
      setUiConfig(DefaultUIConfigs.MOBILE_HEADER, null);
    }
  }

  onNavigationItemClick(route) {
    this.props.history.push(route);
  }

  render() {
    const {
      match,
      location,
      subPages,
      redirectNotFound,
      i18n,
      idleComponents
    } = this.props;

    const activeSubpage = Object.values(subPages).find(
      subPage =>
        location.pathname.indexOf(`${match.url}/${subPage.routeName}`) === 0
    );
    const activeKey = activeSubpage
      ? `${match.url}/${activeSubpage.routeName}`
      : undefined;

    return (
      <>
        <div className="application-sidenav-layout app-layout">
          <div className={"sidenav"}>
            <ApplicationSidenav
              onKeySelect={key => this.onNavigationItemClick(key)}
              noTransition={true}
              activeKey={activeKey}
              renderChildren={expanded => {
                return (
                  <Nav>
                    {Object.entries(subPages)
                      .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
                      .map(([key, subPage]) => {
                        return this.renderNavObj(
                          subPage,
                          key,
                          subPage === activeSubpage,
                          expanded
                        );
                      })}
                  </Nav>
                );
              }}
            />
          </div>

          <div className={"app-layout-content"}>
            <Switch>
              {Object.entries(subPages)
                .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
                .map(([key, subPage]) => {
                  return this.renderRoute(subPage, key);
                })}

              <Route
                render={() => (
                  <Redirect
                    to={`${redirectNotFound.appExtern ? "" : match.path}/${
                      redirectNotFound.path
                    }`}
                  />
                )}
              />
            </Switch>
          </div>
        </div>

        {idleComponents
          ? Object.entries(idleComponents)
              .sort((a, b) =>
                a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
              )
              .map(([key, item]) => {
                return ComponentsMapper.createElement({ ...item }, key);
              })
          : null}
      </>
    );
  }

  renderRoute(subPageConf: SubpageConf, key: string) {
    const { match } = this.props;

    if (
      !subPageConf.component ||
      !ComponentsMapper[subPageConf.component._component]
    ) {
      return null;
    }

    return (
      <Route key={key} path={`${match.url}/${subPageConf.routeName}`}>
        {ComponentsMapper.createElement(subPageConf.component)}
      </Route>
    );
  }

  renderNavObj(
    subPageConf: SubpageConf,
    key: string,
    isActive: boolean,
    expanded: boolean
  ) {
    const { match, i18n } = this.props;

    if (
      subPageConf.component &&
      !ComponentsMapper[subPageConf.component._component]
    ) {
      return null;
    }

    if (subPageConf.subPages) {
      return (
        <Dropdown
          key={key}
          data-key={key}
          placement="rightStart"
          eventKey={`${match.url}/${subPageConf.routeName}`}
          title={
            <span>
              {subPageConf.icon ? (
                <BfIcon
                  type={subPageConf.icon.type}
                  data={subPageConf.icon.data}
                />
              ) : null}{" "}
              {i18n.t(subPageConf.displayNameSelector)}
            </span>
          }
        >
          {Object.entries(subPageConf.subPages)
            .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
            .map(([key, subPage]) => {
              if (
                subPage.component &&
                !ComponentsMapper[subPage.component._component]
              ) {
                return null;
              }

              return [
                <Dropdown.Item
                  key={key}
                  eventKey={`${match.url}/${subPage.routeName}`}
                >
                  <Trans>{subPage.displayNameSelector}</Trans>
                </Dropdown.Item>,
                subPageConf.actions ? (
                  <Collapse in={isActive}>
                    <div className={`sidenav-quick-actions`}>
                      {Object.entries(subPageConf.actions)
                        .sort((a, b) =>
                          a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
                        )
                        .map(([key, item]) => {
                          return ComponentsMapper.createElement(
                            { ...item, expanded },
                            key
                          );
                        })}
                    </div>
                  </Collapse>
                ) : null
              ];
            })}
        </Dropdown>
      );
    } else {
      return [
        <Nav.Item
          key={key}
          data-key={key}
          eventKey={`${match.url}/${subPageConf.routeName}`}
          icon={
            subPageConf.icon ? (
              <BfIcon
                type={subPageConf.icon.type}
                data={subPageConf.icon.data}
              />
            ) : null
          }
        >
          <Trans>{subPageConf.displayNameSelector}</Trans>
        </Nav.Item>,
        subPageConf.actions ? (
          <Collapse in={isActive}>
            <div className={`sidenav-quick-actions`}>
              {Object.entries(subPageConf.actions)
                .sort((a, b) =>
                  a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
                )
                .map(([key, item]) => {
                  return ComponentsMapper.createElement(
                    { ...item, expanded },
                    key
                  );
                })}
            </div>
          </Collapse>
        ) : null
      ];
    }
  }
}

const mapStateToProps = (state: AppState) => ({});

export default withRouter(
  connect(mapStateToProps, { setUiConfig })(
    withTranslation()(ApplicationSidenavLayout)
  )
);
