import React, { Component, CSSProperties } from "react";

import "./BfCard.scss";

type Props = {
  style?: CSSProperties;
  className?: string;
};

type States = {};

class BfCard extends Component<Props, States> {
  render() {
    return (
      <div
        className={`bf-card-component ${this.props.className}`}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default BfCard;
