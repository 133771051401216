import ModalComponent, { ModalParams } from "./ModalComponent";

class ModalManagerClass {
  modalCmp: ModalComponent;

  init(modalCmp: ModalComponent) {
    this.modalCmp = modalCmp;
  }

  show(conf: ModalParams) {
    this.modalCmp.show(conf);
  }

  alert(conf: { title: string; message: string; onHide?: () => void }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: conf.message,
      onHide: conf.onHide,
      buttons: [
        {
          text: t("Global.Buttons.ok"),
          closeOnClick: true
        }
      ]
    });
  }

  confirm(conf: {
    title?: string;
    message: string;
    onConfirm: () => void;
    onAbort?: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
  }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: conf.message,
      onHide: conf.onAbort,
      buttons: [
        {
          text: t(
            conf.cancelButtonText
              ? conf.cancelButtonText
              : "Global.Buttons.cancel"
          ),
          closeOnClick: true,
          onClick: conf.onAbort
        },
        {
          text: t(
            conf.confirmButtonText
              ? conf.confirmButtonText
              : "Global.Buttons.ok"
          ),
          closeOnClick: true,
          onClick: conf.onConfirm
        }
      ]
    });
  }
}

const ModalManager = new ModalManagerClass();

export default ModalManager;
