import { css } from "emotion";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import { Nav } from "rsuite";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../IComponent";
import "./TabLayout.scss";

type TabLayoutConf = {
  appearance?: "tabs" | "subtle";
  subPages: { [key: string]: SubpageConf };
  redirectNotFound: {
    path: string;
    appExtern?: boolean;
  };
  preTabComponent?: IComponent;
  postTabComponent?: IComponent;
};

interface SubpageConf extends IComponent {
  orderIndex: number;
  className?: string;
  icon?: BfIconProps;
  routeName: string;
  displayNameSelector: string;
  tabButtonComponent: IComponent;
  component: IComponent;
  condition?: string;
}

type Props = TabLayoutConf &
  RouteComponentProps &
  WithTranslation &
  AbstractStylableProps;

type States = {} & AbstractStylableStates;

class TabLayout extends AbstractStylableComponent<Props, States> {
  onNavigationItemClick(route) {
    this.props.history.push(route);
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const {
      match,
      location,
      subPages,
      redirectNotFound,
      i18n,
      preTabComponent,
      postTabComponent
    } = this.props;
    const t = i18n.t;

    const activeSubpage = Object.values(subPages).find(
      subPage => location.pathname.indexOf(subPage.routeName) !== -1
    );

    const activeKey = activeSubpage
      ? `${match.url}/${activeSubpage.routeName}`
      : undefined;
    return (
      <div
        className={`tab-layout ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
        style={this.state.usedStyle}
      >
        <div className={"tab-items"}>
          {preTabComponent
            ? ComponentsMapper.createElement(preTabComponent)
            : null}
          <Nav
            appearance={this.props.appearance}
            onSelect={key => this.onNavigationItemClick(key)}
            activeKey={activeKey}
          >
            {Object.entries(subPages)
              .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
              .map(([key, subPage]) => {
                return this.renderNavObj(subPage, key);
              })}
          </Nav>
          {postTabComponent
            ? ComponentsMapper.createElement(postTabComponent)
            : null}
        </div>

        <div className={"tab-content"}>
          <Switch>
            {Object.entries(subPages)
              .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
              .map(([key, subPage]) => {
                return this.renderRoute(subPage, key);
              })}

            <Route
              render={() => (
                <Redirect
                  to={`${redirectNotFound.appExtern ? "" : match.url}/${
                    redirectNotFound.path
                  }`}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }

  renderRoute(subPageConf: SubpageConf, key: string) {
    const { match } = this.props;

    if (
      !subPageConf.component ||
      !ComponentsMapper[subPageConf.component._component]
    ) {
      return null;
    }

    if (subPageConf.condition) {
      if (
        !this.evaluateExpression(subPageConf.condition, {
          viewportWidth: this.props.viewportWidth
        })
      ) {
        return null;
      }
    }

    return (
      <Route key={key} path={`${match.url}/${subPageConf.routeName}`}>
        {ComponentsMapper.createElement(subPageConf.component)}
      </Route>
    );
  }

  renderNavObj(subPageConf: SubpageConf, key: string) {
    const { match, i18n } = this.props;

    if (
      subPageConf.component &&
      !ComponentsMapper[subPageConf.component._component]
    ) {
      return null;
    }
    if (subPageConf.condition) {
      if (
        !this.evaluateExpression(subPageConf.condition, {
          viewportWidth: this.props.viewportWidth
        })
      ) {
        return null;
      }
    }

    return (
      <Nav.Item
        className={subPageConf.className}
        key={key}
        data-key={key}
        eventKey={`${match.url}/${subPageConf.routeName}`}
        icon={
          subPageConf.icon ? (
            <BfIcon
              type={subPageConf.icon.type}
              size={subPageConf.icon.size}
              style={subPageConf.icon.style}
              data={subPageConf.icon.data}
            />
          ) : null
        }
      >
        {subPageConf.tabButtonComponent ? (
          ComponentsMapper.createElement(subPageConf.tabButtonComponent)
        ) : (
          <Trans>{subPageConf.displayNameSelector}</Trans>
        )}
      </Nav.Item>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(TabLayout))
);
