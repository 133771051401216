import React, { CSSProperties } from "react";
import { Field, FormSpy } from "react-final-form";
import { useTranslation } from "react-i18next";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { BFPrompt } from "../../../components/NavigationPrompt/NavigationPrompt";
import BFMessage from "../../abstract-ui/general/Message/BFMessage";
import { BaseElement, BaseElementProps, GenericFormsLayoutProps } from "../../generic-forms/BaseElement";
import { GFFieldLabel, GFFieldValue } from "../../generic-forms/display/DisplayComponents";
import GenericLayoutComponent from "../../generic-forms/GenericLayoutComponent";
import "./LayoutComponentsImpl.scss";

type GFAvatarProps = {
	avatarField: string;
	displaynameField: string;

	className?: string;
	condition?: string;
	renderEmpty: boolean;
	stateSubscriptions: string[];
	style?: CSSProperties;
	size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
} & GenericFormsLayoutProps;
export const GFShowAvatar: React.FC<GFAvatarProps> = props => {
	const {
		className,
		condition,
		formProps,
		allProperties,
		stateSubscriptions,
		style,
		size,
		displaynameField,
		avatarField
	} = props;

	return (
		<Field
			name={displaynameField}
			render={displaynameProps => {
				return (
					<Field
						name={avatarField}
						render={avatarProps => {
							return (
								<GenericLayoutComponent
									stateSubscriptions={stateSubscriptions}
									condition={condition}
									style={style}
									render={styleProps => {
										return (
											<AvatarComponent
												avatar={avatarProps.input.value}
												displayName={displaynameProps.input.value}
												size={size}
											/>
										);
									}}
								/>
							);
						}}
					/>
				);
			}}
		/>
	);
};

type GFCardLayout = {
	className?: string;
	items: { [key: string]: any };
	condition?: string;
	stateSubscriptions: string[];
	style?: CSSProperties;
} & GenericFormsLayoutProps;
export const GFCard: React.FC<GFCardLayout> = props => {
	const { className, condition, items, formProps, allProperties, style, stateSubscriptions } = props;

	return (
		<GenericLayoutComponent
			stateSubscriptions={stateSubscriptions}
			condition={condition}
			style={style}
			render={styleProps => {
				return (
					<div className={`card-component ${className ? className : ""}`} style={styleProps}>
						{Object.values(items).map(item => (
							<BaseElement {...item} formProps={formProps} allProperties={allProperties} />
						))}
					</div>
				);
			}}
		/>
	);
};

type GFMessageProps = {
	messageType: "info" | "success" | "warning" | "error";
	titleKey?: string;
	textKey: string;
	closable?: boolean;
	showIcon?: boolean;

	className?: string;
	condition?: string;
	stateSubscriptions: string[];
	style?: CSSProperties;
} & GenericFormsLayoutProps;
export const GFMessage: React.FC<GFMessageProps> = props => {
	const {
		className,
		condition,
		formProps,
		allProperties,
		style,
		stateSubscriptions,
		messageType,
		titleKey,
		textKey,
		closable,
		showIcon
	} = props;

	return (
		<GenericLayoutComponent
			stateSubscriptions={stateSubscriptions}
			condition={condition}
			style={style}
			render={styleProps => {
				return (
					<BFMessage
						type={messageType}
						titleKey={titleKey}
						textKey={textKey}
						closable={closable}
						showIcon={showIcon}
						style={styleProps}
					/>
				);
			}}
		/>
	);
};

type GFFieldViewTableProps = {
	className?: string;
	items: (BaseElementProps | string)[];
	condition?: string;
	stateSubscriptions: string[];
	style?: CSSProperties;
	editable?: string[];
} & GenericFormsLayoutProps;
export const BFFieldViewTable: React.FC<GFFieldViewTableProps> = props => {
	const { className, condition, stateSubscriptions, items, formProps, allProperties, style, editable } = props;

	return (
		<GenericLayoutComponent
			stateSubscriptions={stateSubscriptions}
			condition={condition}
			style={style}
			render={styleProps => {
				return (
					<div style={styleProps}>
						<div className={`field-view-table ${className ? className : ""}`}>
							{Object.values(items).map((item, index) => {
								if (typeof item === "string" || item.fieldName) {
									const keyName = typeof item === "string" ? item : item.fieldName;
									const itemProps = allProperties[keyName];

									return (
										<GenericLayoutComponent
											key={item + "_" + index}
											condition={itemProps.condition}
											// style={style}
											render={styleProps => (
												<div style={styleProps} className={"row-entry"}>
													<div className={"field-label"}>
														<GFFieldLabel name={keyName} allProperties={allProperties} formProps={formProps} />
													</div>
													{editable && editable.indexOf(keyName) !== -1 ? (
														<div className={"field-value"}>
															<BaseElement
																type={"property"}
																name={keyName}
																formProps={formProps}
																label={""}
																allProperties={allProperties}
															/>
														</div>
													) : (
														<div className={"field-value"}>
															<GFFieldValue name={keyName} allProperties={allProperties} formProps={formProps} />
														</div>
													)}
												</div>
											)}
										/>
									);
								} else {
									return (
										<GenericLayoutComponent
											key={index}
											condition={item.condition}
											style={item.style}
											// style={style}
											render={styleProps => (
												<div className={"row-entry subtitle"} style={styleProps}>
													<BaseElement {...item} formProps={formProps} allProperties={allProperties} />
												</div>
											)}
										/>
									);
								}
							})}
						</div>
					</div>
				);
			}}
		/>
	);
};

type GFPromptBlockProps = {
	textKey?: string;
	items?: { [key: string]: any };
} & GenericFormsLayoutProps;
export const GFPromptBlock: React.FC<GFPromptBlockProps> = props => {
	const { i18n } = useTranslation();
	const { formProps, allProperties, items, textKey } = props;

	return (
		<>
			<FormSpy subscription={{ pristine: true, submitSucceeded: true }}>
				{({ pristine, submitSucceeded }) => {
					return (
						<BFPrompt
							when={(currentLocation, nextLocation) =>
								currentLocation.pathname !== nextLocation.pathname && !submitSucceeded && !pristine
							}
							textKey={textKey}
						/>
					);
				}}
			</FormSpy>

			{items
				? Object.values(items).map(item => <BaseElement {...item} formProps={formProps} allProperties={allProperties} />)
				: null}
		</>
	);
};
