import { Alert } from "rsuite";
import DataBus from "../../../services/DataBus";
import "./Toast.scss";

class ToastClass {
  // config
  constructor() {
    DataBus.subscribe(
      "TOAST",
      (data: {
        type: "info" | "success" | "warning" | "error";
        textKey: string;
        duration?: number;
      }) => {
        this[data.type]((window as any).translate(data.textKey), data.duration);
      }
    );
  }

  info(text: string, duration?: number, onClose?: () => void) {
    Alert.info(text, duration, onClose);
  }
  success(text: string, duration?: number, onClose?: () => void) {
    Alert.success(text, duration, onClose);
  }
  warning(text: string, duration?: number, onClose?: () => void) {
    Alert.warning(text, duration, onClose);
  }
  error(text: string, duration?: number, onClose?: () => void) {
    Alert.error(text, duration, onClose);
  }
}
const Toast = new ToastClass();

Alert.config({
  duration: 2000
});
export default Toast;
