import React from "react";
import { BFNotification } from "../../utils/BFNotification";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";
import "./Automate.scss";

class AutomateClass {
  constructor() {
    // window.document.addEventListener("keydown", (e: KeyboardEvent) => {
    //     if (e.key === "e") {
    //         this.showPopup(0, <span>Ich habe erkannt, dass du die <strong>E-Mail Funktionalität</strong> nicht nutzt.<br/> Willst du etwas mehr darüber erfahren, oder möchtest du die Funktionalität ausblenden?</span>, ["Tutorial", "Ausblenden"]);
    //     }
    //     if (e.key === "w") {
    //         this.showPopup(0,
    //         <div>
    //             Sie haben nun mehrfach folgenden Prozess ausgeführt:
    //             <ul>
    //                 <li>Objektanfrage ist per E-Mail eingetroffen</li>
    //                 <li>Neuen Kontakt erstellt</li>
    //                 <li>Expose an Kontakt verschickt</li>
    //                 <li>Erinnerung für Kontakt erstellt: in 3 Tagen erinnern</li>
    //             </ul>
    //             <strong>Wollen Sie diesen Prozess automatisieren?</strong><br/><span style={{fontSize: "0.8em", paddingTop: 10, fontStyle:"italic"}}>Schauen Sie sich gerne unsere <a href=''>Informationen</a> bezüglich der Prozessautomatisierungen an.</span>
    //         </div>
    //         , ["Abbrechen", "Prozess automatisieren"]);
    //     }
    // })
  }

  registerEvent(event) {}

  showPopup(duration, text, buttons?) {
    BFNotification.open({
      duration: duration,

      description: (
        <div className={`automate-container`}>
          <div className={`content`}>
            <div>
              <div className={`icon`}>
                <BfIcon type="bf" data="robot" size="2x" />
              </div>
            </div>
            <div className={`text`}>{text}</div>
          </div>
          {buttons ? (
            <div className={`buttons`}>
              {buttons.map(button => (
                <BFButton appearance="primary">{button}</BFButton>
              ))}
            </div>
          ) : null}
        </div>
      )
    });
  }
}
const Automate = new AutomateClass();
export default Automate;
