import { ContextData } from "../../../model/db/ContextData";
import { User, UserConfig } from "../../../model/db/User";
import { JsonProperties } from "../../../modules/generic-forms/util/JsonValidation";
import DataBus from "../../../services/DataBus";
import { MandatorData } from "../../reducers/global/GlobalInterface";

export const SET_ASSET_TYPE_CONF = "SET_ASSET_TYPE_CONF";
export const SET_CONTEXT_DATA = "SET_CONTEXT_DATA";
export const SET_MANDATOR_DATA = "SET_MANDATOR_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_CONFIG = "UPDATE_USER_CONFIG";
export const SET_GLOBAL_CACHE_DATA = "SET_GLOBAL_CACHE_DATA";
export const MERGE_GLOBAL_CONFIG = "MERGE_GLOBAL_CONFIG";

interface MergeGlobalConfigAction {
	type: typeof MERGE_GLOBAL_CONFIG;
	config: any;
}

interface SetAssetTypeConfig {
	type: typeof SET_ASSET_TYPE_CONF;
	assetTypeConfigs: {
		name: string;
		formDefinition: { [key: string]: { [key: string]: any } };
		properties: JsonProperties;
	}[];
}

interface SetUserAction {
	type: typeof SET_USER_DATA;
	user: User;
}
interface SetContextData {
	type: typeof SET_CONTEXT_DATA;
	context: ContextData;
}

interface SetMandatorData {
	type: typeof SET_MANDATOR_DATA;
	mandator: MandatorData;
}

interface UpdateUserConfig {
	type: typeof UPDATE_USER_CONFIG;
	config: UserConfig;
}

interface SetGlobalCacheData {
	type: typeof SET_GLOBAL_CACHE_DATA;
	key: string;
	data: any;
	ttl?: number;
	timestamp: number;
}

export type GlobalAction =
	| SetContextData
	| SetMandatorData
	| SetUserAction
	| UpdateUserConfig
	| SetGlobalCacheData
	| SetAssetTypeConfig
	| MergeGlobalConfigAction;

setTimeout(() => {
	DataBus.subscribeReduxActions([SET_USER_DATA]);
});
