import { css } from "emotion";
import React, { CSSProperties, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import "react-table/react-table.css";
import { Table } from "rsuite";
import AvatarComponent from "../../../../components/AvatarComponent/AvatarComponent";
import PermissionChecker from "../../../../components/PermissionChecker/PermissionChecker";
import { IComponent } from "../../../../configurable/layouts/IComponent";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import { ActionDataEvent } from "../../../../model/common/DataBus/ActionData";
import { AppState } from "../../../../redux/store";
import DataBus from "../../../../services/DataBus";
import { Filter } from "../../../../services/DataService";
import { AbstractComponent, AbstractProps } from "../../../../utils/abstracts/AbstractComponent";
import { ComponentsMapper } from "../../../../utils/ComponentsMapper";
import ExpressionHelper from "../../../generic-forms/util/ExpressionHelper";
import BFCheckbox from "../../forms/checkbox/BFCheckbox";
import BfRadio from "../../forms/radio/BfRadio";
import BFButton from "../../general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFTable.scss";
import NoDataComponent from "./components/NoDataComponent";
import TableComponentTitlebar from "./components/TableComponentTitlebar";
import TableLoader from "./components/TableLoader";

const { Pagination } = Table;

export type ColumnFilterType = "TEXT" | "ENUM" | "BOOLEAN" | "NUMBER" | "DATE";

export type ColumnState = "fixated_left" | "fixated_right" | "visible" | "hidden";

const SelectTable = selectTableHOC(ReactTable);

export interface TableAddons {
	idRefLink?: {
		condition?: string;
		url: string;
		showAlways?: boolean;
		column?: string;
	};
	avatar?: {
		condition?: string;
		avatarField: string;
		column: string;
		displayNameField: string;
		renderAfterField?: boolean;
		size?: "xs" | "sm" | "md" | "lg" | "xl" | "flex";
	}[];
}
export interface TableProperties {
	errorMessage?: string;
	headerComponents: { [key: string]: IComponent };
	keyField: string;
	title?: string;
	data: Object[];
	striped?: boolean;
	hover?: boolean;
	onSortColumn?: (dataKey: string, sortType: "asc" | "desc") => void;

	hideTitlebar?: boolean;

	selectedRows?: any[];
	selection?: "multiple" | "single" | "none";
	hideSelectionControls?: boolean;

	onRowDoubleClick: (rowData: Object) => void;
	onRowClick?: (rowDatas: Object[]) => void;

	onReload?: () => void;

	hideConfigMenu?: boolean;
	hideColumnHeaders?: boolean;
	tableIdentifier: string;
	onColumnStateChanged: (dataKey: string, state: ColumnState, orderIndex?: number) => void;
	onFilterChange?: (dataKey: string, filter: Filter) => void;
	filtersObject?: { [dataKey: string]: Filter };
	loading?: boolean;
	height?: number;
	width?: number;
	wordWrap?: boolean;
	minHeight?: number;
	sortColumn?: string;
	sortType?: "desc" | "asc";
	autoHeight?: boolean;
	bordered?: boolean;
	cellBordered?: boolean;
	showHeader?: boolean;
	columnsSortable?: boolean;
	addons?: TableAddons;

	// bodyRef:	React.ElementRef,
}

export interface ColumnProperties {
	className?: string;

	orderIndex?: number;
	sortable?: boolean;
	resizable?: boolean;

	columnHeaderTextKey?: string;
	columnHeaderText?: string;
	columnHeaderCustom?: ReactNode;

	cellRenderer?: (row: any, key: string, columnConf: ColumnProperties) => React.ReactNode;
	minWidth?: number;
	width?: number;
	hidden?: boolean;

	actions?: ColumnAction[];
	verticalAlign?: "top" | "middle" | "bottom";
	align?: "left" | "center" | "right";
	// className?: string,
	//
	// flexGrow?: number,
	// columnFilterType?: ColumnFilterType,
	// onResize?: (columnWidth: number, dataKey: string) => void
}

export interface ColumnAction {
	permission: any;
	stateSubscriptions?: string[];
	hidden?: string;
	loading?: string;
	disabled?: string;
	toggled?: string;
	icon?: BfIconProps;
	iconPosition?: "left" | "right";
	text?: string;
	textKey?: string;
	circle?: boolean;
	actionParams?: { [key: string]: string };
	component?: IComponent;
	appearance?: "default" | "primary" | "link" | "clear" | "outline" | "clear-on-white" | "clear-highlight";
	size?: "lg" | "md" | "sm" | "xs";
	actionId: string;
	showWhen?: "hover" | "selected" | "always";
}

export interface PagingProperties {
	activePage: number;
	total: number;
	pageSize: number;
	disabled?: boolean;
	useEndlessScrolling?: boolean;
	onChangePage?: (eventKey: number) => void;
}

type Props = {
	rowClassNameConditions?: { [className: string]: string };
	appearance?: "clear" | "default" | "card";
	style?: CSSProperties;
	tableProps: TableProperties;
	columnProps: { [dataKey: string]: ColumnProperties };
	pagingProps?: PagingProperties;

	footer?: IComponent;
	subHeader?: IComponent;
} & WithTranslation &
	AbstractProps;

type States = {};

const TITLE_BAR_HEIGHT = 35;

class BFTable extends AbstractComponent<Props, States> {
	checkboxTable;
	lastSelectedIndex: number = null;
	lastSelectedEndIndex: number = null;
	containerRef = React.createRef<HTMLDivElement>();

	readonly state: States = {};

	componentDidMount(): void {
		this.subscribeActionEvent("reload", data => {
			if (data.type === "click") {
				this.props.tableProps.onReload();
			}
		});
		this.populateButtonState("reload", {
			hidden: false, //this.props.tableProps.onReload === undefined,
			loading: false
		});

		const scrollFc: (ev: Event) => void = (ev: Event) => {
			const { pagingProps } = this.props;

			const maxPage = Math.floor(pagingProps.total / pagingProps.pageSize) + 1;

			if (pagingProps.activePage === maxPage) {
				return;
			}
			const scrollContainer = ev.target as any;
			const containerBounds = scrollContainer.getBoundingClientRect();

			if (scrollContainer.scrollHeight - containerBounds.height - scrollContainer.scrollTop < 20) {
				pagingProps.onChangePage(pagingProps.activePage + 1);
			}
		};
		setTimeout(() => {
			(this.containerRef.current.getElementsByClassName("rt-tbody")[0] as HTMLBaseElement).addEventListener(
				"scroll",
				scrollFc as any
			);
		});
	}

	componentWillReceiveProps(nextProps: Props): void {
		super.componentWillReceiveProps(nextProps);

		if (this.props.tableProps.loading !== nextProps.tableProps.loading) {
			this.populateButtonState("reload", {
				hidden: false, // this.props.tableProps.onReload === undefined,
				loading: nextProps.tableProps.loading
			});
		}
	}

	/**
	 * Toggle a single checkbox for select table
	 */
	toggleSelection = (key, shift, row) => {
		this.rowSelectionModifiers(row, false, shift, false);
	};
	/**
	 * Toggle all checkboxes for select table
	 */
	toggleAll = () => {
		if (this.isSelectedAll()) {
			this.props.tableProps.onRowClick([]);
		} else {
			this.props.tableProps.onRowClick(this.props.tableProps.data);
		}
	};

	/**
	 * Whether or not a row is selected for select table
	 */
	isSelected = key => {
		return (
			this.props.tableProps.selectedRows && this.props.tableProps.selectedRows.find(row => row["_id"] === key) !== undefined
		);
	};

	rowFn = (state, rowData, column, instance) => {
		const { rowClassNameConditions } = this.props;
		let classNames = "";
		if (rowClassNameConditions) {
			Object.entries(rowClassNameConditions).forEach(([className, condition]) => {
				if (this.evaluateExpression(condition, rowData.original)) {
					classNames += className + " ";
				}
			});
		}

		return {
			onClick: (e, handleOriginal) => {
				this.rowSelection(rowData.original, e);

				// IMPORTANT! React-Table uses onClick internally to trigger
				// events like expanding SubComponents and pivots.
				// By default a custom 'onClick' handler will override this functionality.
				// If you want to fire the original onClick handler, call the
				// 'handleOriginal' function.
				if (handleOriginal) {
					handleOriginal();
				}
			},
			className: `${this.isSelected(rowData.original[this.props.tableProps.keyField]) ? "selected" : ""} ${classNames}`
			// className: `${this.isSelected(rowData.original[this.props.tableProps.keyField]) ? "selected" : ""} ${rowData.original["__deleted"] ? "-flagged-deleted" : ""} ${rowData.original["__added"] ? "-flagged-added" : ""}`
		};
	};

	renderHeader = (dataIndex, content) => {
		return (
			<div className={"column-header"}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					{this.props.tableProps.sortColumn === dataIndex ? (
						<BfIcon
							type="bf"
							style={{
								color: "#000",
								marginRight: 3,
								transform: `scale(0.7) rotate(${this.props.tableProps.sortType === "asc" ? "0" : "180"}deg)`
							}}
							data={`arrow-up-1`}
						/>
					) : null}
				</div>
				{typeof content === "function" ? content() : content}
			</div>
		);
	};

	renderDataColumn(content: any, key: string, conf: ColumnProperties) {
		return (
			<div className={"data-column"}>
				{key === "id" ? <DebugDataComponent data={content.original} /> : null}
				{this.renderAvatar(content, key, false)}
				{content.original[key]}
				{this.renderAvatar(content, key, true)}
				{this.renderIfRefLink(content, key)}
			</div>
		);
	}
	renderAvatar(content: any, key: string, isAfter: boolean) {
		if (this.props.tableProps.addons && this.props.tableProps.addons.avatar) {
			const options = this.props.tableProps.addons.avatar.find(option => option.column === key);
			if (options) {
				if (!!isAfter === !!options.renderAfterField) {
					return (
						<AvatarComponent
							style={{
								marginLeft: isAfter ? 5 : 0,
								marginRight: isAfter ? 0 : 5
							}}
							avatar={content.original[options.avatarField]}
							displayName={content.original[options.displayNameField]}
							size={options.size}
						/>
					);
				}
			}
		}
		return null;
	}

	renderIfRefLink(content: any, key: string) {
		if (this.props.tableProps.addons && this.props.tableProps.addons.idRefLink) {
			const options = this.props.tableProps.addons.idRefLink;
			const field = options.column || "id";

			if (key === field) {
				return (
					<BFButton
						className={`id-ref-link ${options.showAlways ? "show-always" : ""}`}
						style={{ padding: 0, overflow: "visible" }}
						appearance="debug"
						onClick={() =>
							DataBus.emit("ROUTE", {
								route: this.evaluateExpression(options.url, content.original)
							})
						}
						icon={{ type: "bf", data: "expand-6", fontSize: 8 }}
					/>
				);
			}
		}
		return null;
	}

	renderActionColumn(rowData, actions) {
		return (
			<div className={"action-column"}>
				{actions.map(action => {
					const hidden = action.hidden ? ExpressionHelper.evaluateExpression(action.hidden, rowData.original) : false;
					if (hidden) {
						return null;
					}

					const disabled = action.disabled ? ExpressionHelper.evaluateExpression(action.disabled, rowData.original) : false;
					const loading = action.loading ? ExpressionHelper.evaluateExpression(action.loading, rowData.original) : false;
					// const toggled = action.disabled ? ExpressionHelper.evaluateExpression(action.toggled, rowData.original): false;
					const showWhen = action.showWhen ? action.showWhen : "always";

					return (
						<PermissionChecker key={action.actionId} permission={action.permission}>
							<BFButton
								stateSubscriptions={action.stateSubscriptions}
								tabIndex={action.tabIndex ? action.tabIndex : "-1"}
								className={`table-action show-when-${showWhen}`}
								key={action.actionId}
								disabled={disabled}
								loading={loading}
								appearance={action.appearance ? action.appearance : "clear-on-white"}
								style={action.style}
								size={action.size}
								textKey={action.textKey}
								icon={action.icon}
								iconPosition={action.iconPosition}
								onClick={(e, params) => {
									this.emit(action.actionId, {
										type: "click",
										event: e,
										data: action.actionParams
											? {
													...Object.fromEntries(
														Object.entries(action.actionParams).map(([key, actionParam]) => [
															key,
															ExpressionHelper.evaluateExpression(actionParam as string, rowData.original, params)
														])
													),
													rowData: rowData.original
											  }
											: { rowData: rowData.original }
									} as ActionDataEvent);
								}}
							>
								{action.component ? ComponentsMapper.createElement(action.component) : action.text ? action.text : null}
							</BFButton>
						</PermissionChecker>
					);
				})}
			</div>
		);
	}

	render() {
		const { i18n, tableProps, columnProps, pagingProps, style, appearance } = this.props;
		const {} = this.state;

		const {
			onReload,
			onColumnStateChanged,
			hideTitlebar,
			hideConfigMenu,
			title,
			onFilterChange,
			filtersObject,
			selectedRows,
			onRowClick,
			onRowDoubleClick,
			onSortColumn,
			selection,
			data,
			striped,
			tableIdentifier,
			columnsSortable,
			hideSelectionControls,
			headerComponents,
			hideColumnHeaders,
			...tablePropsToSet
		} = tableProps;

		const columns = Object.entries(columnProps)
			.map(([key, value]) => {
				const className = `${value.className ? value.className : ""} ${
					value.align === "center" ? "align-center" : value.align === "right" ? "align-right" : ""
				} `;

				let Cell = undefined;
				if (value.cellRenderer) {
					Cell = row => value.cellRenderer(row, key, value);
				} else if (value.actions) {
					Cell = row => this.renderActionColumn(row, value.actions);
				} else {
					Cell = row => this.renderDataColumn(row, key, value);
				}

				let Header = null;
				if (value.columnHeaderTextKey) {
					Header = i18n.t(value.columnHeaderTextKey);
				} else if (value.columnHeaderCustom) {
					Header = value.columnHeaderCustom;
				} else {
					Header = value.columnHeaderText;
				}
				return {
					Header: this.renderHeader(key, Header),
					Cell,
					accessor: key,
					show: !value.hidden,
					...value,
					className
				};
			})
			.sort((a, b) => a.orderIndex - b.orderIndex);

		return (
			<div ref={this.containerRef} className={`bf-table ${appearance ? appearance : ""} ${style ? css(style as any) : ""}`}>
				{!hideTitlebar ? <TableComponentTitlebar headerComponents={headerComponents} title={title} /> : null}
				{this.props.subHeader ? ComponentsMapper.createElement(this.props.subHeader) : null}
				{selection === "none" || hideSelectionControls ? (
					<ReactTable
						getNoDataProps={() => ({
							errorMessage: this.props.tableProps.errorMessage
						})}
						NoDataComponent={NoDataComponent}
						LoadingComponent={TableLoader}
						loadingText={i18n.t("Global.Table.LoadingText")}
						noDataText={i18n.t("Global.Table.NoDataFound")}
						className={`table ${tableProps.striped ? "-striped" : ""} ${hideColumnHeaders ? "hide-column-headers" : ""} ${
							tableProps.hover === true || tableProps.hover === undefined ? "-highlight" : ""
						} `}
						showPagination={false}
						manual
						ref={r => (this.checkboxTable = r)}
						loading={tableProps.loading}
						minRows={0}
						data={data}
						style={{
							flexGrow: 1,
							maxWidth: "100%",
							height: 0
						}}
						onSortedChange={sort => {
							tableProps.onSortColumn(sort[0]["id"], sort[0]["desc"] ? "desc" : "asc");
						}}
						columns={columns}
						getTrProps={this.rowFn}
					/>
				) : (
					<SelectTable
						noDataText={i18n.t("Global.Table.NoDataFound")}
						className={`table ${tableProps.striped ? "-striped" : ""} ${hideColumnHeaders ? "hide-column-headers" : ""} ${
							tableProps.hover ? "-highlight" : ""
						} `}
						showPagination={false}
						manual
						ref={r => (this.checkboxTable = r)}
						loading={tableProps.loading}
						minRows={0}
						data={data}
						style={{
							flexGrow: 1,
							maxWidth: "100%",
							height: 0
						}}
						onSortedChange={sort => {
							tableProps.onSortColumn(sort[0]["id"], sort[0]["desc"] ? "desc" : "asc");
						}}
						sorted={[
							{
								id: tableProps.sortColumn,
								desc: tableProps.sortType === "desc" ? true : false
							}
						]}
						columns={columns}
						toggleSelection={this.toggleSelection}
						selectAll={this.isSelectedAll()}
						selectType={selection === "multiple" ? "checkbox" : "radio"}
						toggleAll={this.toggleAll}
						isSelected={this.isSelected}
						getTrProps={this.rowFn}
						selectWidth={36}
						SelectInputComponent={compData => {
							if (compData.selectType === "checkbox") {
								return (
									<BFCheckbox
										className={"select-input-checkbox"}
										checked={compData.checked}
										onChange={(val, checked, e) => {}}
									/>
								);
							} else {
								return (
									<BfRadio
										className={"select-input-radio"}
										checked={compData.checked}
										onChange={(val, checked, e) => {}}
									/>
								);
							}
						}}
					/>
				)}
				{pagingProps && !pagingProps.useEndlessScrolling ? (
					<Pagination
						maxButtons={4}
						ellipsis={true}
						last={false}
						activePage={pagingProps.activePage}
						displayLength={pagingProps.pageSize}
						showLengthMenu={false}
						total={pagingProps.total}
						onChangePage={pagingProps.onChangePage}
						renderTotal={(total: number, activePage: number) => {
							return (
								<div>
									{i18n.t("BFComponents.Table.Global.total")}: {total}
								</div>
							);
						}}
						// onChangeLength={this.handleChangeLength}
					/>
				) : null}
				{this.props.footer ? ComponentsMapper.createElement(this.props.footer) : null}
			</div>
		);
	}

	isSelectedAll() {
		return (
			this.props.tableProps.selectedRows && this.props.tableProps.selectedRows.length === this.props.tableProps.data.length
		);
	}

	rowSelectionModifiers(rowData: any, dblClick: boolean, shift: boolean, ctrl: boolean) {
		const { selectedRows, onRowClick, onRowDoubleClick, selection, data } = this.props.tableProps;
		if (selection === "none" || !onRowClick) {
			if (dblClick) {
				onRowDoubleClick(rowData);
			}
			return;
		}
		if (selection === "single") {
			if (dblClick) {
				onRowDoubleClick(rowData);
			} else {
				onRowClick([rowData]);
			}
		} else if (selection === "multiple") {
			if (!ctrl && !shift) {
				// no modifiers -> single row select
				if (dblClick) {
					onRowDoubleClick(rowData);
				} else {
					onRowClick([rowData]);
					this.lastSelectedIndex = data.indexOf(rowData);
					this.lastSelectedEndIndex = null;
				}
			} else {
				// multiple selection via rowClick with modifiers

				if (ctrl) {
					this.lastSelectedIndex = data.indexOf(rowData);
					this.lastSelectedEndIndex = null;
					if (selectedRows.indexOf(rowData) === -1) {
						onRowClick([...selectedRows, rowData]);
					} else {
						onRowClick(selectedRows.filter(a => a !== rowData));
					}
				}

				if (shift) {
					if (selectedRows.length === 0) {
						onRowClick([rowData]);
						this.lastSelectedIndex = data.indexOf(rowData);
						this.lastSelectedEndIndex = null;
					} else {
						const dataSet = new Set<Object>(selectedRows);
						const startIndex = this.lastSelectedIndex;

						if (this.lastSelectedEndIndex !== null) {
							// revert the last selection
							const step = startIndex < this.lastSelectedEndIndex ? 1 : -1;
							let i = startIndex;
							do {
								dataSet.delete(data[i]);

								i += step;
							} while (i !== this.lastSelectedEndIndex + step);
						}

						//select the range
						const endIndex = data.indexOf(rowData);
						let i = startIndex;
						const step = startIndex < endIndex ? 1 : -1;
						do {
							dataSet.add(data[i]);

							i += step;
						} while (i !== endIndex + step);

						this.lastSelectedEndIndex = endIndex;
						onRowClick(Array.from(dataSet));
					}
				}
			}
		}
	}

	rowSelection(rowData: any, e?: Event) {
		const { selectedRows, onRowClick, onRowDoubleClick, selection, data } = this.props.tableProps;

		const event = e as any;

		const clickOnCheckbox = (e.target as HTMLElement).classList.contains("rs-checkbox-wrapper");

		this.rowSelectionModifiers(rowData, event.nativeEvent.detail > 1, event.shiftKey, event.ctrlKey || clickOnCheckbox);
	}
}

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey }) => (
	<div>
		<BFCheckbox
			value={rowData[dataKey]}
			inline
			onChange={onChange}
			checked={checkedKeys.some(item => item === rowData[dataKey])}
		/>
	</div>
);

const mapStateToProps = (state: AppState) => ({});

export default withTranslation()(BFTable);
