import * as React from "react";

type AboutProps = {};
export const AboutComponent: React.FC<AboutProps> = props => {
  return (
    <div>
      <span style={{ fontWeight: "bold" }}>Angaben gemäß § 5 TMG</span>
      <br />
      Dennis Renz
      <br />
      Heinrichauer Str. 8<br />
      33100 Paderborn
      <br />
      <br />
      Kontakt
      <br />
      E-Mail: <a href="mailto:info@immox.io">info@immox.io</a>
    </div>
  );
};

type PrivacyProps = {};

export const PrivacyComponent: React.FC<PrivacyProps> = props => {
  return (
    <div style={{ height: "100%", overflow: "auto", padding: 20 }}>
      <div>
        <p style={{ fontWeight: "bold" }}>
          1) Information über die Erhebung personenbezogener Daten und
          Kontaktdaten des Verantwortlichen
        </p>
        <p>
          1.1 Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns
          für Ihr Interesse. Im Folgenden informieren wir Sie über den Umgang
          mit Ihren personenbezogenen Daten bei der Nutzung unserer Website.
          Personenbezogene Daten sind hierbei alle Daten, mit denen Sie
          persönlich identifiziert werden können.
        </p>
        <p>
          1.2 Verantwortlicher für die Datenverarbeitung auf dieser Website im
          Sinne der Datenschutz-Grundverordnung (DSGVO) ist Dennis Renz,
          Heinrichauer Str 8, 33100 Paderborn, Deutschland, Tel.: 015150770028,
          E-Mail: info@immox.io. Der für die Verarbeitung von personenbezogenen
          Daten Verantwortliche ist diejenige natürliche oder juristische
          Person, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
        </p>
        <p>
          1.3 Diese Website nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung personenbezogener Daten und anderer vertraulicher Inhalte
          (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine
          SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte
          Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in
          Ihrer Browserzeile erkennen.
        </p>

        <p style={{ fontWeight: "bold" }}>
          2) Datenerfassung beim Besuch unserer Website
        </p>
        <p>
          Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie
          sich nicht registrieren oder uns anderweitig Informationen
          übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren
          Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere Website
          aufrufen, erheben wir die folgenden Daten, die für uns technisch
          erforderlich sind, um Ihnen die Website anzuzeigen:
        </p>
        <p>- Unsere besuchte Website</p>
        <p>- Datum und Uhrzeit zum Zeitpunkt des Zugriffes</p>
        <p>- Menge der gesendeten Daten in Byte</p>
        <p>- Quelle/Verweis, von welchem Sie auf die Seite gelangten</p>
        <p>- Verwendeter Browser</p>
        <p>- Verwendetes Betriebssystem </p>
        <p>- Verwendete IP-Adresse (ggf.: in anonymisierter Form)</p>
        <p>
          Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
          unseres berechtigten Interesses an der Verbesserung der Stabilität und
          Funktionalität unserer Website. Eine Weitergabe oder anderweitige
          Verwendung der Daten findet nicht statt. Wir behalten uns allerdings
          vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete
          Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
        </p>

        <p style={{ fontWeight: "bold" }}>3) Cookies</p>
        <p>
          Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
          bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen
          Seiten sogenannte Cookies. Hierbei handelt es sich um kleine
          Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von
          uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also
          nach Schließen Ihres Browsers, wieder gelöscht (sog.
          Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und
          ermöglichen, Ihren Browser beim nächsten Besuch wiederzuerkennen (sog.
          persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten
          diese im individuellen Umfang bestimmte Nutzerinformationen wie
          Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies
          werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich
          je nach Cookie unterscheiden kann. Die Dauer der jeweiligen
          Cookie-Speicherung können Sie der Übersicht zu den
          Cookie-Einstellungen Ihres Webbrowsers entnehmen.
        </p>
        <p>
          Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen
          den Bestellprozess zu vereinfachen (z.B. Merken des Inhalts eines
          virtuellen Warenkorbs für einen späteren Besuch auf der Website).
          Sofern durch einzelne von uns eingesetzte Cookies auch
          personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
          gemäß Art. 6 Abs. 1 lit. b DSGVO zur Durchführung des Vertrages, gemäß
          Art. 6 Abs. 1 lit. a DSGVO im Falle einer erteilten Einwilligung oder
          gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten
          Interessen an der bestmöglichen Funktionalität der Website sowie einer
          kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
        </p>
        <p>
          Bitte beachten Sie, dass Sie Ihren Browser so einstellen können, dass
          Sie über das Setzen von Cookies informiert werden und einzeln über
          deren Annahme entscheiden oder die Annahme von Cookies für bestimmte
          Fälle oder generell ausschließen können. Jeder Browser unterscheidet
          sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist
          in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert,
          wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für
          die jeweiligen Browser unter den folgenden Links:
        </p>
        <p>
          Internet Explorer:
          https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
          <p>
            Firefox:
            https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
          </p>
          <p>
            Chrome: https://support.google.com/chrome/answer/95647?hl=de&hlrm=en
          </p>
        </p>
        <p>
          Safari:
          https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14
        </p>
        <p>Opera: https://help.opera.com/de/latest/web-preferences/#cookies</p>
        <p>
          Bitte beachten Sie, dass bei Nichtannahme von Cookies die
          Funktionalität unserer Website eingeschränkt sein kann.
        </p>

        <p style={{ fontWeight: "bold" }}>4) Kontaktaufnahme</p>
        <p>
          Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
          E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle
          eines Kontaktformulars erhoben werden, ist aus dem jeweiligen
          Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum
          Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme
          und die damit verbundene technische Administration gespeichert und
          verwendet. Rechtsgrundlage für die Verarbeitung dieser Daten ist unser
          berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art.
          6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss
          eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
          Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach
          abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der Fall,
          wenn sich aus den Umständen entnehmen lässt, dass der betroffene
          Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen
          Aufbewahrungspflichten entgegenstehen.
        </p>

        <p style={{ fontWeight: "bold" }}>
          5) Nutzung Ihrer Daten zur Direktwerbung
        </p>
        <p>Anmeldung zu unserem E-Mail-Newsletter</p>
        <p>
          Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden wir
          Ihnen regelmäßig Informationen zu unseren Angeboten. Pflichtangabe für
          die Übersendung des Newsletters ist allein Ihre E-Mailadresse. Die
          Angabe weiterer Daten ist freiwillig und wird verwendet, um Sie
          persönlich ansprechen zu können. Für den Versand des Newsletters
          verwenden wir das sog. Double Opt-in Verfahren. Dies bedeutet, dass
          wir Ihnen erst dann einen E-Mail Newsletter übermitteln werden, wenn
          Sie uns ausdrücklich bestätigt haben, dass Sie in den Empfang von
          Newsletter einwilligen. Wir schicken Ihnen dann eine
          Bestätigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines
          entsprechenden Links zu bestätigen, dass Sie künftig den Newsletter
          erhalten wollen.
        </p>
        <p>
          Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre
          Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß Art.
          6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter speichern wir
          Ihre vom Internet Service-Provider (ISP) eingetragene IP-Adresse sowie
          das Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch
          Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen zu
          können. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten
          werden ausschließlich für Zwecke der werblichen Ansprache im Wege des
          Newsletters benutzt. Sie können den Newsletter jederzeit über den
          dafür vorgesehenen Link im Newsletter oder durch entsprechende
          Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach
          erfolgter Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem
          Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in eine
          weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine
          darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich
          erlaubt ist und über die wir Sie in dieser Erklärung informieren.
        </p>

        <p style={{ fontWeight: "bold" }}>6) Online-Marketing</p>
        <p>Einsatz von Google Ads Conversion-Tracking</p>
        <p>
          Diese Website nutzt das Online-Werbeprogramm "Google Ads" und im
          Rahmen von Google Ads das Conversion-Tracking der Google Ireland
          Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
          („Google“). Wir nutzen das Angebot von Google Ads, um mit Hilfe von
          Werbemitteln (sogenannten Google Adwords) auf externen Webseiten auf
          unsere attraktiven Angebote aufmerksam zu machen. Wir können in Bezug
          zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die
          einzelnen Werbemaßnahmen sind. Wir verfolgen damit das Anliegen, Ihnen
          Werbung anzuzeigen, die für Sie von Interesse ist, unsere Website für
          Sie interessanter zu gestalten und eine faire Berechnung der
          anfallenden Werbekosten zu erreichen.
        </p>
        <p>
          Das Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf
          eine von Google geschaltete Ads-Anzeige klickt. Bei Cookies handelt es
          sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden.
          Diese Cookies verlieren in der Regel nach 30 Tagen ihre Gültigkeit und
          dienen nicht der persönlichen Identifizierung. Besucht der Nutzer
          bestimmte Seiten dieser Website und ist das Cookie noch nicht
          abgelaufen, können Google und wir erkennen, dass der Nutzer auf die
          Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder
          Google Ads-Kunde erhält ein anderes Cookie. Cookies können somit nicht
          über die Websites von Google Ads-Kunden nachverfolgt werden. Die
          mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
          Conversion-Statistiken für Google Ads-Kunden zu erstellen, die sich
          für Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am
          Tracking teilnehmen möchten, können Sie diese Nutzung blockieren,
          indem Sie das Cookie des Google Conversion-Trackings über ihren
          Internet-Browser unter dem Stichwort „Nutzereinstellungen“
          deaktivieren. Sie werden sodann nicht in die Conversion-Tracking
          Statistiken aufgenommen. Wir setzen Google Ads auf Grund unseres
          berechtigten Interesses an einer zielgerichteten Werbung gem. Art. 6
          Abs. 1 lit. f DSGVO ein. Im Rahmen der Nutzung von Google Ads kann es
          auch zu einer Übermittlung von personenbezogenen Daten an die Server
          der Google LLC. in den USA kommen.
        </p>
        <p>
          Für den Fall der Übermittlung von personenbezogenen Daten an die
          Google LLC. mit Sitz in den USA, hat sich Google LLC. für das
          us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert,
          welches die Einhaltung des in der EU geltenden Datenschutzniveaus
          gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden:
          https://www.privacyshield.gov/list
        </p>
        <p>
          Unter der nachstehenden Internetadresse erhalten Sie weitere
          Informationen über die Datenschutzbestimmungen von Google:
          https://www.google.de/policies/privacy/
        </p>
        <p>
          Sie können Cookies für Anzeigenvorgaben dauerhaft deaktivieren, indem
          Sie diese durch eine entsprechende Einstellung Ihrer Browser-Software
          verhindern oder das unter folgendem Link verfügbare Browser-Plug-in
          herunterladen und installieren:
        </p>
        <p>https://www.google.com/settings/ads/plugin?hl=de</p>
        <p>
          Bitte beachten Sie, dass bestimmte Funktionen dieser Website
          möglicherweise nicht oder nur eingeschränkt genutzt werden können,
          wenn Sie die Verwendung von Cookies deaktiviert haben.
        </p>
        <p>
          Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten
          Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a
          DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit
          Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben,
          befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur
          Vornahme eines Widerspruchs.
        </p>

        <p style={{ fontWeight: "bold" }}>7) Webanalysedienste</p>
        <p>Google (Universal) Analytics</p>
        <p>
          Diese Website benutzt Google (Universal) Analytics, einen
          Webanalysedienst der Google Ireland Limited, Gordon House, 4 Barrow
          St, Dublin, D04 E5W5, Irland ("Google"). Google (Universal) Analytics
          verwendet sog. "Cookies", das sind Textdateien, die auf Ihrem Computer
          gespeichert werden und die eine Analyse Ihrer Nutzung der Website
          ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre
          Benutzung dieser Website (einschließlich der gekürzten IP-Adresse)
          werden in der Regel an einen Server von Google übertragen und dort
          gespeichert, hierbei kann es auch zu einer Übermittlung an die Server
          der Google LLC. in den USA kommen.
        </p>
        <p>
          Diese Website verwendet Google (Universal) Analytics ausschließlich
          mit der Erweiterung "_anonymizeIp()", die eine Anonymisierung der
          IP-Adresse durch Kürzung sicherstellt und eine direkte
          Personenbeziehbarkeit ausschließt. Durch die Erweiterung wird Ihre
          IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen
          Union oder in anderen Vertragsstaaten des Abkommens über den
          Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
          die volle IP-Adresse an einen Server von Google LLC.in den USA
          übertragen und dort gekürzt. In diesen Ausnahmefällen erfolgt diese
          Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO auf Grundlage unseres
          berechtigten Interesses an der statistischen Analyse des
          Nutzerverhaltens zu Optimierungs- und Marketingzwecken.
        </p>
        <p>
          In unserem Auftrag wird Google diese Informationen benutzen, um Ihre
          Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns
          gegenüber zu erbringen. Die im Rahmen von Google (Universal) Analytics
          von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
          von Google zusammengeführt.
        </p>
        <p>
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich nutzen können. Sie können
          darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
          Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
          Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:
        </p>
        <p>https://tools.google.com/dlpage/gaoptout?hl=de</p>
        <p>
          Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen
          Geräten klicken Sie bitte auf den folgenden Link, um ein
          Opt-Out-Cookie zu setzen, das die Erfassung durch Google Analytics
          innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie
          funktioniert nur in diesem Browser und nur für diese Domain. Wenn Sie
          Ihre Cookies in diesem Browser löschen, müssen Sie diesen Link erneut
          anklicken):{" "}
          <a
            onClick={() => alert("Google Analytics wurde deaktiviert")}
            href="javascript:gaOptout()"
          >
            Google Analytics deaktivieren
          </a>
        </p>
        <p>
          Weitere Hinweise zu Google (Universal) Analytics finden Sie hier:
          https://policies.google.com/privacy?hl=de&gl=de
        </p>
        <p>
          Für den Fall der Übermittlung von personenbezogenen Daten an die
          Google LLC. mit Sitz in den USA, hat sich Google LLC. für das
          us-europäische Datenschutzübereinkommen „Privacy Shield“ zertifiziert,
          welches die Einhaltung des in der EU geltenden Datenschutzniveaus
          gewährleistet. Ein aktuelles Zertifikat kann hier eingesehen werden:
          https://www.privacyshield.gov/list
        </p>
        <p>
          Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten
          Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a
          DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit
          Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben,
          befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur
          Vornahme eines Widerspruchs.
        </p>

        <p style={{ fontWeight: "bold" }}>8) Rechte des Betroffenen</p>
        <p>
          8.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem
          Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen
          Daten umfassende Betroffenenrechte (Auskunfts- und
          Interventionsrechte), über die wir Sie nachstehend informieren:
        </p>
        <p>
          - Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht
          auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten,
          die Verarbeitungszwecke, die Kategorien der verarbeiteten
          personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern,
          gegenüber denen Ihre Daten offengelegt wurden oder werden, die
          geplante Speicherdauer bzw. die Kriterien für die Festlegung der
          Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
          Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung,
          Beschwerde bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn
          diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer
          automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftige Informationen über die involvierte Logik und die Sie
          betreffende Tragweite und die angestrebten Auswirkungen einer solchen
          Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien
          gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer
          bestehen;
        </p>
        <p>
          - Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf
          unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder
          Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;
        </p>
        <p>
          - Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die
          Löschung Ihrer personenbezogenen Daten bei Vorliegen der
          Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht
          besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur
          Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
          Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </p>
        <p>
          - Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie
          haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, solange die von Ihnen
          bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine
          Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen und
          stattdessen die Einschränkung der Verarbeitung Ihrer Daten verlangen,
          wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen, nachdem wir diese Daten nach
          Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus
          Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht
          feststeht, ob unsere berechtigten Gründe überwiegen;
        </p>
        <p>
          - Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf
          Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber
          dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
          Empfängern, denen die Sie betreffenden personenbezogenen Daten
          offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
          Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist
          sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
          verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet
          zu werden.
        </p>
        <p>
          - Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das
          Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
          in einem strukturierten, gängigen und maschinenlesebaren Format zu
          erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
          verlangen, soweit dies technisch machbar ist;
        </p>
        <p>
          - Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3
          DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die
          Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
          widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten
          unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine
          Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden
          kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          nicht berührt;
        </p>
        <p>
          - Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind,
          dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
          gegen die DSGVO verstößt, haben Sie - unbeschadet eines anderweitigen
          verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht
          auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des
          Ortes des mutmaßlichen Verstoßes.
        </p>
        <p>8.2 WIDERSPRUCHSRECHT</p>
        <p>
          WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN
          DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES
          VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG
          WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
        </p>
        <p>
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
          VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
          ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND
          GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER
          GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.
        </p>
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
          DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
          GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
          ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE
          OBEN BESCHRIEBEN AUSÜBEN.
        </p>
        <p>
          MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
          VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
        </p>

        <p style={{ fontWeight: "bold" }}>
          9) Dauer der Speicherung personenbezogener Daten
        </p>
        <p>
          Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
          anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und –
          sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen
          Aufbewahrungsfrist (z.B. handels- und steuerrechtliche
          Aufbewahrungsfristen).
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
          ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO werden
          diese Daten so lange gespeichert, bis der Betroffene seine
          Einwilligung widerruft.
        </p>
        <p>
          Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen
          rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher Verpflichtungen auf
          der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden,
          werden diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig
          gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder
          Vertragsanbahnung erforderlich sind und/oder unsererseits kein
          berechtigtes Interesse an der Weiterspeicherung fortbesteht.
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
          bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO
          ausübt, es sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der
          betroffenen Person überwiegen, oder die Verarbeitung dient der
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
          Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
          diese Daten so lange gespeichert, bis der Betroffene sein
          Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt.
        </p>
        <p>
          Sofern sich aus den sonstigen Informationen dieser Erklärung über
          spezifische Verarbeitungssituationen nichts anderes ergibt, werden
          gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie
          für die Zwecke, für die sie erhoben oder auf sonstige Weise
          verarbeitet wurden, nicht mehr notwendig sind.
        </p>
      </div>
    </div>
  );
};
