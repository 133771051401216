import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../utils/ComponentsMapper";
import { IComponent } from "../layouts/IComponent";

type Props = {
  orderIndex?: number;
  titleKey?: string;
  titleComponents?: { [key: string]: IComponent };
  postHeaderComponents?: { [key: string]: IComponent };
  preHeaderComponents?: { [key: string]: IComponent };
  contentComponents: { [key: string]: IComponent };

  footerComponents?: { [key: string]: IComponent };
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class CardSection extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const {
      orderIndex,
      style,
      titleKey,
      postHeaderComponents,
      preHeaderComponents,
      footerComponents,
      contentComponents,
      titleComponents
    } = this.props;

    const config = {
      _component: "div",
      orderIndex: orderIndex,
      style: [
        {
          padding: 0,
          marginBottom: 20,
          ...(!Array.isArray(style) ? style : {})
        },
        ...(Array.isArray(style) ? style : [])
      ],
      components: {
        header: {
          _component: "div",
          style: {
            display: "flex",
            marginLeft: 5,
            marginRight: 5,
            borderBottom: "solid 1px #888"
          },
          components: {
            ...(preHeaderComponents ? preHeaderComponents : {}),
            title: titleKey
              ? {
                  _component: "text",
                  textKey: titleKey,
                  type: "span",
                  style: {
                    padding: "10px 10px 5px 10px",
                    fontSize: "1.4em",
                    flexGrow: 1
                  }
                }
              : {
                  _component: "div",
                  style: {
                    flexGrow: 1
                  },
                  components: titleComponents ? titleComponents : {}
                },
            ...(postHeaderComponents ? postHeaderComponents : {})
          }
        },
        content: {
          _component: "div",
          style: [
            {
              padding: "20px"
            },
            {
              breakpoint: 500,
              padding: "10px"
            },
            {
              breakpoint: 340,
              padding: "5px"
            }
          ],
          components: contentComponents
        },
        footer: footerComponents
          ? {
              _component: "div",
              style: {
                display: "flex",
                flexDirection: "row",
                width: "100%"
              },
              components: footerComponents
            }
          : undefined
      }
    };

    return ComponentsMapper.createElement(config);
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(CardSection))
);
