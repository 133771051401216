import { FormState } from "final-form";
import React from "react";
import GenericFormsComponents from "./GenericFormsComponents";
import { JsonProperties } from "./util/JsonValidation";

export type GenericFormsLayoutProps = {
  allProperties: JsonProperties;
  formProps: FormState<any>;
};

export type BaseElementProps = {
  type: string;
  name?: string;
  [key: string]: any;
} & GenericFormsLayoutProps;
export const BaseElement: React.FC<BaseElementProps> = props => {
  let { type, allProperties, formProps, name, ...rest } = props;
  if (type === "property") {
    // link to property
    const property = { ...allProperties[name], ...rest };

    if (property) {
      return render({
        name,
        type: property.type,
        properties: property,
        formProps: formProps,
        allProperties: allProperties
      });
    } else {
      console.warn(
        `couldn't link property ${name}', no configuration for this property available`
      );
      return null;
    }
  }
  return render(props);
};

const render = props => {
  let { type, allProperties, formProps, ...rest } = props;

  const comp = GenericFormsComponents.getComponent(type);
  if (comp != null) {
    rest.allProperties = allProperties;
    rest.formProps = formProps;
    // if (typeof comp === "function") {
    //     return comp(rest);
    // } else {
    return React.createElement(comp as any, rest);
    // }
  } else {
    console.warn(
      `couldn't render type '${type}', is not registered to the engine`
    );
    return null;
  }
};
