import { css } from "emotion";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import Tools from "../../../utils/Tools";
import "./SearchField.scss";

type Props = {
  appearance: "on-white" | "default" | "on-white-as-button";
  placeholderKey?: string;
  placeholder?: string;
  sizes?: {
    breakpoint?: string;
    focusWidth: string | number;
    blurWidth: string | number;
  }[];
  value: string;
  onChanged: (value: string) => void;
  animate: "left" | "right" | "expand";
} & AbstractStylableProps &
  WithTranslation;

type States = {
  focused: boolean;
} & AbstractStylableStates;

class SearchField extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    appearance: "default",
    animate: "left"
  };
  readonly state: States = {
    focused: false
  };

  componentDidMount() {}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  // getSnapshotBeforeUpdate(prevProps: Props, prevState: States) { }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const {
      appearance,
      viewportWidth,
      value,
      onChanged,
      placeholderKey,
      placeholder,
      i18n,
      sizes,
      animate
    } = this.props;

    const searchActive = value && value.trim() !== "";

    const usedSize = Tools.getActiveBreakpointProperties(viewportWidth, sizes);
    const focusWidth = usedSize ? usedSize.focusWidth : 200;
    const blurWidth = usedSize ? usedSize.blurWidth : 80;

    const usedPlaceholder = placeholderKey
      ? i18n.t(placeholderKey)
      : placeholder;
    return (
      <div
        className={`searchfield ${appearance} ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        } animate-${animate}`}
        style={{ width: blurWidth }}
      >
        <div
          className={`input-wrapper ${
            searchActive || this.state.focused ? "active" : ""
          }`}
          style={{
            width: this.state.focused || searchActive ? focusWidth : blurWidth,
            left:
              animate === "expand" && (this.state.focused || searchActive)
                ? ((focusWidth - blurWidth) / 2) * -1
                : undefined,
            right:
              animate === "expand" && (this.state.focused || searchActive)
                ? (focusWidth - blurWidth) / 2
                : undefined
          }}
        >
          <div className={`search-icon`}>
            <BfIcon type="bf" data="search" />
          </div>
          <input
            placeholder={usedPlaceholder}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
            onChange={value => {
              onChanged(value.target.value);
            }}
            value={value}
          />
          <div className={`clear-action ${searchActive ? "active" : ""}`}>
            <BFButton
              appearance={appearance === "default" ? "clear" : "clear-on-white"}
              style={{
                paddingLeft: 4,
                paddingRight: 4,
                height: "100%",
                display: "flex"
              }}
              onClick={() => onChanged("")}
            >
              <BfIcon type="bf" data="close" size="xs" />
            </BFButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
});

export default connect(mapStateToProps, {})(withTranslation()(SearchField));
