import DivLayout from "../../configurable/layouts/DivLayout/DivLayout";
import BfButton from "../abstract-ui/general/Button/BFButton";
import GenericFormsComponents from "../generic-forms/GenericFormsComponents";
import { JsonPropertyComponent } from "../generic-forms/util/JsonValidation";
import Validators from "../generic-forms/util/Validatos";
import {
	GFAvatar,
	GFCheckbox,
	GFCheckboxGroup,
	GFDate,
	GFGridCheck,
	GFMail,
	GFNumber,
	GFRadioGroup,
	GFRating,
	GFSelect,
	GFText,
	GFToggle
} from "./form-fields/GenericFormFieldsImpl";
import PermissionsFormField from "./form-fields/PermissionFormField";
import PermissionFormFieldSimple from "./form-fields/PermissionFormFieldSimple";
import GFAutosave from "./layout-components/GFAutosave";
import { BFFieldViewTable, GFCard, GFMessage, GFPromptBlock, GFShowAvatar } from "./layout-components/LayoutComponentsImpl";

const GFInitializer = () => {
	// layout elements
	GenericFormsComponents.registerComponent("div", DivLayout as any, true);

	GenericFormsComponents.registerComponent("autosave", GFAutosave as any, true);
	GenericFormsComponents.registerComponent("prompt", GFPromptBlock as any, true);
	GenericFormsComponents.registerComponent("field-table-view", BFFieldViewTable as any, true);
	GenericFormsComponents.registerComponent("card", GFCard, true);

	GenericFormsComponents.registerComponent("showAvatar", GFShowAvatar, true);
	GenericFormsComponents.registerComponent("message", GFMessage, true);
	// form elementspassword
	GenericFormsComponents.registerComponent("password", GFText, true);
	GenericFormsComponents.registerComponent("mail", GFMail, true);
	GenericFormsComponents.registerComponent("text", GFText, true);
	GenericFormsComponents.registerComponent("toggle", GFToggle, true);
	GenericFormsComponents.registerComponent("select", GFSelect, true);
	GenericFormsComponents.registerComponent("radio", GFRadioGroup, true);
	GenericFormsComponents.registerComponent("number", GFNumber, true);
	GenericFormsComponents.registerComponent("date", GFDate, true);
	GenericFormsComponents.registerComponent("checkbox", GFCheckbox, true);
	GenericFormsComponents.registerComponent("checkbox-group", GFCheckboxGroup, true);
	GenericFormsComponents.registerComponent("avatar", GFAvatar, true);
	GenericFormsComponents.registerComponent("rating", GFRating, true);
	GenericFormsComponents.registerComponent("gridcheck", GFGridCheck, true);

	GenericFormsComponents.registerComponent("button", BfButton as any, true);

	GenericFormsComponents.registerComponent("permissions-advanced", PermissionsFormField, true);
	GenericFormsComponents.registerComponent("permissions", PermissionFormFieldSimple, true);

	// init validaton functions
	Validators.registerType(
		"permissions",
		(value: any, jsonPropertyComp: JsonPropertyComponent, jsonValue: { [key: string]: any }) => {
			//todo check type if valid
			return true;
		}
	);
	Validators.registerType(
		"permissions-advanced",
		(value: any, jsonPropertyComp: JsonPropertyComponent, jsonValue: { [key: string]: any }) => {
			//todo check type if valid
			return true;
		}
	);
	Validators.registerType("avatar", (value: any, jsonPropertyComp: JsonPropertyComponent, jsonValue: { [key: string]: any }) => {
		//todo check type if valid
		return true;
	});
	Validators.registerType("gridcheck", (value, jsonPropertyComp, jsonValue) => {
		if (Array.isArray(value)) {
			const allowedOptions = Validators.getValidOptions((jsonPropertyComp as any).options, jsonValue);
			let success = true;
			for (const val of value) {
				success = success && Validators.checkOptionValidtiy(val, allowedOptions);
			}
			return success;
		} else {
			return false;
		}
	});

	Validators.registerType("rating", (value: any, jsonPropertyComp: JsonPropertyComponent, jsonValue: { [key: string]: any }) => {
		//todo check type if valid
		return typeof value === "number";
	});
};

export default GFInitializer;
