import React from "react";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "rsuite/lib/styles/themes/default/index.less";
import App from "./App";
import i18n, { i18nOptions } from "./i18n";
import "./index.scss";
import { store } from "./redux/store";
import CacheService from "./services/CacheService";
import GroupService from "./services/GroupService";
import MandatorService from "./services/MandatorService";
import SubmitService from "./services/SubmitService";
import UserService from "./services/UserService";
import * as serviceWorker from "./serviceWorker";
import "./styles/custom-rsuite.scss";
require("polyfill-object.fromentries");

const URL_LOCAL = "https://benfa-backend2.herokuapp.com"; // "localhost";
const URL_PROD = "https://benfa-backend2.herokuapp.com";

SubmitService.init();
GroupService.init();
UserService.init();
CacheService.init();
MandatorService.init();
// Properties.mocked = process.env.NODE_ENV !== "production" ;

i18n
  .init(i18nOptions)
  .then(data => {
    ReactDOM.render(
      <CookiesProvider>
        <DndProvider
          backend={HTML5Backend}
          options={{ enableMouseEvents: true }}
        >
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              <Router>
                <App />
              </Router>
            </I18nextProvider>
          </Provider>
        </DndProvider>
      </CookiesProvider>,
      document.getElementById("root")
    );
  })
  .catch(err => {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
