import React, { Component, SyntheticEvent } from "react";

import "./BFToggle.scss";
import { Toggle } from "rsuite";
import LabeledInput from "../LabeledInput";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";

type Props = {
  label?: string;
  labelPosition?: "left" | "right";

  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean, event: React.SyntheticEvent) => void;
  size?: "lg" | "md" | "sm";
  onBlur?: () => void;
  onFocus?: () => void;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
};

type States = {};

class BFToggle extends Component<Props, States> {
  render() {
    let { label, labelPosition, validation, ...toggleProps } = this.props;

    labelPosition = labelPosition || "left";
    return (
      <LabeledInput label={label} labelPosition={labelPosition}>
        <ValidationPopover
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
        >
          <Toggle {...toggleProps} />
        </ValidationPopover>
      </LabeledInput>
    );
  }
}

export default BFToggle;
