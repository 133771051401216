import { CSSProperties } from "@emotion/serialize";
import { css } from "emotion";
import React from "react";
import { connect } from "react-redux";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../IComponent";

export type DivLayoutConf = {
  key?: string;
  className?: string;
  components: { [key: string]: IComponent };
} & AbstractStylableProps;

type States = {} & AbstractStylableStates;

class ScrollPanel extends AbstractStylableComponent<DivLayoutConf, States> {
  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const { className, components, key, ...styles } = this.props;

    let usedStyle: CSSProperties = { overflow: "auto" };
    if (this.state.usedStyle) {
      usedStyle = { ...this.state.usedStyle, ...usedStyle };
    }

    return (
      <div
        key={key}
        className={`scroll-panel ${className ? className : ""} ${css(
          usedStyle as any
        )}`}
      >
        {Object.entries(components)
          .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
          .map(([key, item], index) => {
            return ComponentsMapper.createElement(
              {
                ...item
              },
              key ? key : index
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: DivLayoutConf) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default connect(mapStateToProps, {})(ScrollPanel);
