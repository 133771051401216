import { Application } from "../../../model/db/Application";

export const SET_UI_CONFIG = "SET_UI_CONFIG";
export const SET_ACTIVE_APPLICATION = "SET_ACTIVE_APPLICATION";

interface SetUiConfigAction {
  type: typeof SET_UI_CONFIG;
  key: string;
  value: any;
}

interface SetActiveApplication {
  type: typeof SET_ACTIVE_APPLICATION;
  application: Application;
}

export type UIConfigAction = SetUiConfigAction | SetActiveApplication;
