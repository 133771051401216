import { Properties } from "../utils/Properties";

class LogClass {
  error(message?: any, ...optionalParams: any[]) {
    if (Properties.logLevels.error) console.error(message, ...optionalParams);
  }
  state(message?: any, ...optionalParams: any[]) {
    if (Properties.logLevels.state) console.log(message, ...optionalParams);
  }
  info(message?: any, ...optionalParams: any[]) {
    if (Properties.logLevels.info) console.info(message, ...optionalParams);
  }
  debug(message?: any, ...optionalParams: any[]) {
    if (Properties.logLevels.debug) console.debug(message, ...optionalParams);
  }
  warning(message?: any, ...optionalParams: any[]) {
    if (Properties.logLevels.warning) console.warn(message, ...optionalParams);
  }
}
const Log = new LogClass();
export default Log;
