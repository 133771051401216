import React, { Component } from "react";

import { CheckboxGroup } from "rsuite";

import "./BFCheckGroup.scss";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";

type Props = {
  defaultValue?: any;
  inline?: boolean;
  name?: string;
  onChange?: (value: any, event: any) => void;
  value?: any;
  children: React.ReactNode[];

  validation?: {
    message: string;
    level: "error" | "warning";
  };
};

type States = {};

class BFCheckGroup extends Component<Props, States> {
  render() {
    const { validation, ...rest } = this.props;

    return (
      <ValidationPopover
        level={validation ? validation.level : "error"}
        message={validation ? validation.message : null}
      >
        <CheckboxGroup {...rest}>{this.props.children}</CheckboxGroup>
      </ValidationPopover>
    );
  }
}

export default BFCheckGroup;
