import { UserConfig } from "../../../model/db/User";
import ObjectTools from "../../../utils/ObjectTools";
import {
	GlobalAction,
	MERGE_GLOBAL_CONFIG,
	SET_ASSET_TYPE_CONF,
	SET_CONTEXT_DATA,
	SET_GLOBAL_CACHE_DATA,
	SET_MANDATOR_DATA,
	SET_USER_DATA,
	UPDATE_USER_CONFIG
} from "../../actions/global/global-actions-types";
import { AppState } from "../../store";
import { GlobalConfig, GlobalReducer, MandatorData } from "./GlobalInterface";
import { StandardForms } from "./StandardForms";

const initialState: GlobalReducer = {
	user: null,
	mandator: null,
	context: null,
	configInitialized: false,
	config: {
		standardForms: StandardForms,
		assetTypeForms: {}
	},
	cache: {}
};

export default function(state = initialState, action: GlobalAction, root: AppState): GlobalReducer {
	switch (action.type) {
		case SET_ASSET_TYPE_CONF:
			const assetTypeForms = {};
			action.assetTypeConfigs.forEach(entry => {
				assetTypeForms[entry.name] = {
					properties: entry.properties,
					formDefinitions: entry.formDefinition
				};
			});

			return {
				...state,
				config: {
					...state.config,
					assetTypeForms: assetTypeForms
				}
			};
		case SET_CONTEXT_DATA:
			return {
				...state,
				context: action.context
			};

		case SET_MANDATOR_DATA:
			if (action.mandator === null) {
				return {
					...state,
					mandator: null
				};
			} else {
				return {
					...state,
					mandator: state.mandator
						? (ObjectTools.mergeObjects(state.mandator, action.mandator) as MandatorData)
						: action.mandator
				};
			}
		case MERGE_GLOBAL_CONFIG:
			return {
				...state,
				configInitialized: true,
				config: ObjectTools.mergeObjects(state.config, action.config) as GlobalConfig
			};
		case SET_GLOBAL_CACHE_DATA:
			return {
				...state,
				cache: {
					...state.cache,
					[action.key]: {
						ttl: action.ttl,
						timestamp: action.timestamp,
						data: action.data
					}
				}
			};
		case UPDATE_USER_CONFIG:
			return {
				...state,
				user: {
					...state.user,
					config: ObjectTools.mergeObjects(state.user.config, action.config) as UserConfig
				}
			};
		case SET_USER_DATA:
			if (action.user == null) {
				return {
					...state,
					user: null
				};
			} else {
				const currentUser = state.user || {};

				// if (action.user.groups && action.user.groups.length > 0 && typeof action.user.groups[0] === "string") {
				//     action.user["groups"] = undefined;
				// }

				return {
					...state,
					user: {
						...currentUser,
						...action.user
					}
				};
			}
		default:
			return state;
	}
}
