import React from "react";
import Log from "../debug/Log";
import { SET_USER_DATA } from "../redux/actions/global/global-actions-types";
import { store } from "../redux/store";
import DataBus from "../services/DataBus";
import { BFNotification } from "./BFNotification";
import { BFToast } from "./BFToast";

interface ErrorEntry {
  localizeTitleKey?: string;
  localizeMsg: string;
  showNotification: boolean;
  showAlert: boolean;
  duration?: number;
}

export function handleError(errorObj, context = "default") {
  const t = (window as any).translate;
  Log.error(errorObj);
  if (errorObj && errorObj.response) {
    const data = errorObj.response.data;
    if (data && data.code) {
      const error =
        ErrorCodes[data.code] && ErrorCodes[data.code][context]
          ? ErrorCodes[data.code][context]
          : ErrorCodes["UNKNOWN_ERROR"]["default"];
      if (error) {
        if (error.showAlert) {
          BFToast.open({
            type: "error", //duration: 4000000,
            duration: error.duration,
            content: (
              <>
                <h5>{t(error.localizeTitleKey)}</h5>
                <p>{t(error.localizeMsg)}</p>
              </>
            )
          });
        }

        if (error.showNotification) {
          BFNotification.open({
            type: "error",
            duration: error.duration,
            title: t(error.localizeTitleKey),
            description: t(error.localizeMsg)
          });
        }
      }
    } else {
      //TODO not a api error, handle status codes
      if (errorObj.response.status === 401) {
        const error = ErrorCodes["UNAUTHORIZED"][context];
        BFToast.open({
          type: "error", //duration: 4000000,
          duration: error.duration,
          content: (
            <>
              <h5>{t(error.localizeTitleKey)}</h5>
              <p>{t(error.localizeMsg)}</p>
            </>
          )
        });
        store.dispatch({
          type: SET_USER_DATA,
          user: null
        });
        DataBus.emit("ROUTE", {
          route: "/login"
        });
      } else {
        const error = ErrorCodes["UNKNOWN_ERROR"][context];
        BFToast.open({
          type: "error", //duration: 4000000,
          duration: error.duration,
          content: (
            <>
              <h5>{t(error.localizeTitleKey)}</h5>
              <p>{t(error.localizeMsg)}</p>
            </>
          )
        });
      }
    }
  }
}
export function getErrorLocalized(errorObj, context = "default") {
  const t = (window as any).translate;
  if (errorObj && errorObj.response) {
    const data = errorObj.response.data;
    if (data && data.code) {
      const error =
        ErrorCodes[data.code] && ErrorCodes[data.code][context]
          ? ErrorCodes[data.code][context]
          : ErrorCodes["UNKNOWN_ERROR"]["default"];
      return t(error.localizeMsg);
    } else {
      const error = ErrorCodes["UNKNOWN_ERROR"]["default"];
      return t(error.localizeMsg);
    }
  }
  return null;
}

export const ErrorCodes: {
  [key: string]: { [context: string]: ErrorEntry };
} = {
  USER_ALLREADY_EXISTS: {
    default: {
      localizeMsg: "errorCodes.createUser.userAlreadyExists.msg",
      localizeTitleKey: "errorCodes.createUser.userAlreadyExists.title",
      showNotification: false,
      showAlert: true
    }
  },
  WRONG_CRED: {
    default: {
      localizeMsg: "errorCodes.login.wrongCreds.msg",
      localizeTitleKey: "errorCodes.login.wrongCreds.title",
      showNotification: false,
      showAlert: true
    }
  },
  REQUEST_INVALID_PARAM: {
    default: {
      localizeMsg: "errorCodes.common.invalidParam.msg",
      localizeTitleKey: "errorCodes.common.invalidParam.title",
      showNotification: false,
      showAlert: true
    }
  },
  MANDATOR_DEACTIVATED: {
    default: {
      localizeMsg: "errorCodes.login.mandatorDeactivated.msg",
      localizeTitleKey: "errorCodes.login.mandatorDeactivated.title",
      showNotification: false,
      showAlert: true
    }
  },
  ACCOUNT_DEACTIVATED: {
    default: {
      localizeMsg: "errorCodes.login.accountDeactivated.msg",
      localizeTitleKey: "errorCodes.login.accountDeactivated.title",
      showNotification: false,
      showAlert: true
    }
  },
  ACCOUNT_INACTIVE: {
    default: {
      localizeMsg: "errorCodes.login.accountInactive.msg",
      localizeTitleKey: "errorCodes.login.accountInactive.title",
      showNotification: false,
      showAlert: true
    }
  },
  ACCOUNT_LOCKED: {
    default: {
      localizeMsg: "errorCodes.login.accountLocked.msg",
      localizeTitleKey: "errorCodes.login.accountLocked.title",
      showNotification: false,
      showAlert: true
    }
  },
  ACCOUNT_WAITINGFORACTIVATION: {
    default: {
      localizeMsg: "errorCodes.login.accountWaitingForActivation.msg",
      localizeTitleKey: "errorCodes.login.accountWaitingForActivation.title",
      showNotification: false,
      showAlert: true
    }
  },
  UNKNOWN_USER_STATUS: {
    default: {
      localizeMsg: "errorCodes.login.unknownUserStatus.msg",
      localizeTitleKey: "errorCodes.login.unknownUserStatus.title",
      showNotification: false,
      showAlert: true
    }
  },

  UNAUTHORIZED: {
    default: {
      localizeMsg: "errorCodes.unauthorized.msg",
      localizeTitleKey: "errorCodes.unauthorized.title",
      showNotification: false,
      showAlert: true
    }
  },

  UNKNOWN_ERROR: {
    default: {
      localizeMsg: "errorCodes.unknownError.msg",
      localizeTitleKey: "errorCodes.unknownError.title",
      showNotification: false,
      showAlert: true
    }
  },
  SILENT_UNKNOWN_ERROR: {
    default: {
      localizeMsg: "errorCodes.unknownError.msg",
      localizeTitleKey: "errorCodes.unknownError.title",
      showNotification: false,
      showAlert: false
    }
  },

  NO_PERMISSION_TO_DELETE: {
    default: {
      localizeMsg: "errorCodes.permissionMissing.delete",
      localizeTitleKey: "common.actions.deleteObject",
      showNotification: true,
      showAlert: false
    }
  },
  NO_PERMISSION_TO_UPDATE: {
    default: {
      localizeMsg: "errorCodes.permissionMissing.update",
      localizeTitleKey: "common.actions.updateObject",
      showNotification: true,
      showAlert: false
    }
  },
  NO_PERMISSION_TO_INSERT: {
    default: {
      localizeMsg: "errorCodes.permissionMissing.insert",
      localizeTitleKey: "common.actions.insertObject",
      showNotification: true,
      showAlert: false
    }
  },
  NO_PERM_VIEW: {
    default: {
      localizeMsg: "errorCodes.permissionMissing.view",
      localizeTitleKey: "common.actions.getObject",
      showNotification: true,
      showAlert: false
    }
  },
  ID_NOT_FOUND: {
    default: {
      localizeMsg: "errorCodes.idNotFound.msg",
      showNotification: false,
      showAlert: true,
      duration: 4000
    },
    completeRegistration: {
      localizeMsg: "errorCodes.idNotFound.completeRegistration.msg",
      showNotification: false,
      showAlert: true,
      duration: 4000
    }
  }
};
