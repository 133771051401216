import flatten from "flat";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DebugDataComponent from "../../debug/DebugDataComponent";
import Log from "../../debug/Log";
import { CacheData } from "../../redux/reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import CacheService from "../../services/CacheService";
import DataBus from "../../services/DataBus";
import { SubmitMessage } from "../../services/SubmitService";
import { SendEvent } from "../../utils/abstracts/AbstractComponent";
import {
	AbstractStylableComponent,
	AbstractStylableProps,
	AbstractStylableStates
} from "../../utils/abstracts/AbstractStylableComponent";
import { DataBusSubKeys } from "../../utils/Constants";
import { handleError } from "../../utils/ErrorCodes";
import GenericForms, { FormDefinition } from "../generic-forms/GenericForms";
import { JsonProperties } from "../generic-forms/util/JsonValidation";

type Props = {
	oType: "asset" | "user" | "group";
	assetType?: string;
	initialValuesObjectId: string;
	formDefinition: FormDefinition | { properties: JsonProperties; formDefinitions: { [key: string]: any } };
	cachedContainer: { [objId: string]: CacheData };
	formSelector?: string;
	formData?: any;
	handleErrorMessaging?: boolean;
	onSubmitSuccess: SendEvent[];
	onSubmitError: SendEvent[];
	submitUrl?: string;
	submitMethod?: string;
	resetOnSubmit?: boolean;
	pushToTableCache?: string;
} & AbstractStylableProps &
	RouteComponentProps &
	WithTranslation;

type States = {
	submitCount: number;
	formValue: any;
	error: any;
	loading: boolean;
	currentId: string;
} & AbstractStylableStates;

class FormWrapper extends AbstractStylableComponent<Props, States> {
	static defaultProps = {
		handleErrorMessaging: true
	};
	readonly state: States = {
		submitCount: 0,
		formValue: null,
		error: null,
		loading: false,
		currentId: null
	};

	componentDidMount() {
		const { initialValuesObjectId, formData } = this.props;

		if (initialValuesObjectId) {
			this.loadCachedData();
		} else if (formData) {
			let formValue = null;
			formValue = this.props.formData ? this.evaluateExpression(this.props.formData) : null;
			this.setState({
				formValue
			});
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
	}

	loadCachedData() {
		const { oType, initialValuesObjectId, assetType } = this.props;
		let id = initialValuesObjectId ? this.evaluateExpression(initialValuesObjectId) : null;
		if (id) {
			this.setState({
				loading: true,
				error: null,
				currentId: id
			});
			CacheService.getData({
				oType,
				id,
				assetType
			})
				.then(data => {
					this.setState({
						formValue: data,
						loading: false,
						error: null
					});
				})
				.catch(err => {
					this.setState({
						formValue: null,
						loading: false,
						error: err
					});
				});
		}
	}

	componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

	//getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

	shouldComponentUpdate(nextProps: Props, nextState: States) {
		const { initialValuesObjectId, formData } = this.props;
		let update = super.shouldComponentUpdate(nextProps, nextState);

		//todo check on change
		let formValue = null;
		if (nextProps.initialValuesObjectId) {
			const nextId = this.evaluateExpression(nextProps.initialValuesObjectId);

			if (nextId !== this.state.currentId) {
				this.loadCachedData();
			}
		} else if (formData && nextProps.formData !== formData) {
			formValue = this.props.formData ? this.evaluateExpression(this.props.formData) : null;
			this.setState({
				formValue
			});
			return true;
		}

		if (nextState.submitCount !== this.state.submitCount) {
			this.loadCachedData();
			return true;
		}
		if (nextProps.initialValuesObjectId) {
			let id = this.evaluateExpression(nextProps.initialValuesObjectId);
			const cachedDataOj = nextProps.cachedContainer ? nextProps.cachedContainer[id] : null;
			const beforeCachedDataOj = this.props.cachedContainer ? nextProps.cachedContainer[id] : null;

			if (cachedDataOj !== beforeCachedDataOj) {
				this.setState({ submitCount: this.state.submitCount + 1 });
				return true;
			}
		}

		return update;
	}

	render() {
		const { submitCount, formValue } = this.state;
		const {
			i18n,
			formDefinition,
			identifier,
			oType,
			initialValuesObjectId,
			handleErrorMessaging,
			assetType,
			formSelector
		} = this.props;

		// if (submitted) {
		//     return <Trans i18nKey="Global.Labels.submitSuccess" />
		// }
		// const formValue = this.evaluateExpression(initialValues, { store: store.getState() });
		if (!formDefinition) {
			return <div>error: no form definition loaded</div>;
		}
		let formDefinitionToLoad: FormDefinition = null;
		if (oType === "asset") {
			formDefinitionToLoad = {
				properties: formDefinition.properties,
				layout: (formDefinition as any).formDefinitions[formSelector ? formSelector : "default"]
			};
		} else {
			formDefinitionToLoad = formDefinition as FormDefinition;
		}

		return (
			<>
				{formValue ? <DebugDataComponent text={"form-input"} data={formValue} /> : null}
				<GenericForms
					identifier={this.props.identifier}
					id={"" + submitCount}
					style={this.state.usedStyle}
					translateFunc={i18n.t}
					formValue={formValue}
					actionIdMapping={this.props.actionIdMapping}
					additionalData={this.state.params}
					formDefinition={formDefinitionToLoad}
					onFormSubmit={data => {
						const dataObj = flatten.unflatten(data, {
							delimiter: "|"
						});
						DataBus.emit(DataBusSubKeys.SUBMIT, {
							id: identifier,
							type: oType,
							assetType: assetType,
							initialData: formValue,
							data: data,
							pushToCache: true,
							pushToTableCache: this.props.pushToTableCache,
							properties: formDefinition.properties,
							overwriteUrl: this.props.submitUrl
								? this.evaluateExpression(this.props.submitUrl, { value: data })
								: undefined,
							overwriteMethod: this.props.submitMethod
								? this.evaluateExpression(this.props.submitMethod, {
										value: data
								  })
								: undefined,
							onSuccess: data => {
								this.setState({
									submitCount: submitCount + 1,
									formValue: this.props.resetOnSubmit ? {} : dataObj
								});
								DataBus.emit("FORM_SUBMITTED", {
									identifiers: [this.props.identifier]
								});
								this.handleEvents(this.props.onSubmitSuccess, { value: data });
							},
							onError: err => {
								if (handleErrorMessaging) {
									handleError(err);
								}
								this.handleEvents(this.props.onSubmitError, { error: err });
							}
						} as SubmitMessage);
					}}
					onFormCancel={() => Log.debug("canceled")}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({
	viewportWidth: Array.isArray(props.style) ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH] : null,
	formDefinition:
		props.oType !== "asset"
			? state.global.config.standardForms[props.formSelector ? props.formSelector : props.oType]
			: state.global.config.assetTypeForms[props.assetType],
	cachedContainer: state.application.cache[props.oType !== "asset" ? props.oType : props.assetType]
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(FormWrapper)));
