import { get, set } from "local-storage";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import { Field, Form } from "react-final-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Animation, Icon } from "rsuite";
import validator from "validator";
import { TranslationProps } from "../../../../model/common/TranslationProps";
import { ContextData } from "../../../../model/db/ContextData";
import { User } from "../../../../model/db/User";
import BFInput from "../../../../modules/abstract-ui/forms/input/BFInput";
import BfButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { AppState } from "../../../../redux/store";
import { loginUser } from "../../../../services/AuthenticationService";
import { BFToast } from "../../../../utils/BFToast";
import "./LoginForm.scss";

const { Collapse } = Animation;

interface Props extends TranslationProps {
  loginUser: (
    email: string,
    password: string,
    mandator: string,
    onSuccess: (data: User) => void,
    onError: (err: any) => void
  ) => void;
  cookies: Cookies;
  mandatorName: string;
  cookiesAccepted: boolean;
  context: ContextData;
}

interface States {
  loading: boolean;
  showFurtherLogin: boolean;
  showErrors: boolean;
}

class LoginForm extends Component<Props, States> {
  constructor(props) {
    super(props);

    let showFurtherLogin = props.mandatorName ? true : false;

    if (
      props.context &&
      props.context.login &&
      props.context.login.ignoreMandatorFieldAnimation
    ) {
      showFurtherLogin = true;
    }

    this.state = {
      loading: false,
      showFurtherLogin: showFurtherLogin,
      showErrors: false
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (this.props.mandatorName !== nextProps.mandatorName) {
      this.setState({ showFurtherLogin: true });
    }
  }

  componentDidMount() {
    if (!this.props.mandatorName) {
      (document.querySelector(
        `.form-row.mandator input`
      ) as HTMLElement).onkeydown = ev => {
        if (ev.key === "Tab" && !this.state.showFurtherLogin) {
          ev.preventDefault();
          this.collapseFurtherInfo();
        }
      };
      this.focusInputfield("mandator");
    } else {
      this.focusInputfield("email");
    }
  }

  focusInputfield(fieldName: string, ignoreDelay = false) {
    if (ignoreDelay) {
      (document.querySelector(
        `.form-row.${fieldName} input`
      ) as HTMLElement).focus();
    } else {
      setTimeout(() => {
        (document.querySelector(
          `.form-row.${fieldName} input`
        ) as HTMLElement).focus();
      }, 100);
    }
  }

  onSubmit = form => {
    const { t } = this.props;

    if (!this.state.showFurtherLogin) {
      this.collapseFurtherInfo();
    } else {
      if (
        !form.email ||
        !form.password ||
        (!this.props.mandatorName && !form.mandator)
      ) {
        this.setState({
          showErrors: true
        });
        // BFToast.open({
        //     type: "error",
        //     content: t("views.login.errors.noValidCredentialsMsg")
        // })

        if (!form.mandator) {
          this.focusInputfield("mandator", true);
        } else if (!form.email && !validator.isEmail(form.email)) {
          this.focusInputfield("email", true);
        } else if (!form.password) {
          this.focusInputfield("password", true);
        }
      } else {
        if (!this.props.cookiesAccepted) {
          BFToast.open({
            type: "error",
            content: t("views.login.errors.noCookiesAccepted")
          });
        } else if (!validator.isEmail(form.email)) {
          this.setState({
            showErrors: true
          });
        } else {
          this.setState({ loading: true });
          this.props.loginUser(
            form.email,
            form.password,
            this.props.mandatorName ? this.props.mandatorName : form.mandator,
            data => {
              if (form.mandator) {
                set("mandator", form.mandator);
              }
            },
            err => {
              this.setState({ loading: false });
            }
          );
        }
      }
    }
  };
  validate = form => {
    const errors = {};

    return errors;
  };

  collapseFurtherInfo() {
    this.setState({ showFurtherLogin: true }, () => {
      setTimeout(() => {
        this.focusInputfield("email");
      }, 100);
    });
  }

  render() {
    const { t, mandatorName } = this.props;

    return (
      <div className="login-form">
        <Form
          initialValues={{
            mandator: mandatorName
          }}
          onSubmit={this.onSubmit}
          validate={this.validate}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              {!mandatorName ? (
                <Field name="mandator" defaultValue={get("mandator") as string}>
                  {({ input, meta }) => (
                    <div className="form-row mandator">
                      <BFInput
                        {...input}
                        size="lg"
                        autoComplete="off"
                        prefix={<Icon icon={"building"} size={"2x"} />}
                        placeholder={t("views.login.labels.mandator")}
                        addonsInside={true}
                        autoCorrect={"off"}
                        autoCapitalize={"off"}
                        spellCheck={"false"}
                        validation={
                          this.state.showErrors &&
                          (!input.value || input.value.trim() === "")
                            ? {
                                message: this.props.i18n.t(
                                  "common.errors.required"
                                ),
                                level: "error"
                              }
                            : undefined
                        }
                        suffix={
                          !this.state.showFurtherLogin ? (
                            <BfIcon
                              style={{ fontSize: "1.5em", lineHeight: "1em" }}
                              data="arrow-circle-right"
                            />
                          ) : (
                            undefined
                          )
                        }
                        suffixOnClick={() => this.collapseFurtherInfo()}
                      />
                    </div>
                  )}
                </Field>
              ) : null}

              <Collapse in={this.state.showFurtherLogin}>
                <div>
                  <Field name="email" defaultValue={""}>
                    {({ input, meta }) => (
                      <div className="form-row email">
                        <BFInput
                          {...input}
                          size="lg"
                          autoComplete="username"
                          autoCorrect={"off"}
                          autoCapitalize={"off"}
                          spellCheck={"false"}
                          prefix={<Icon icon={"user-circle"} size={"2x"} />}
                          placeholder={t("views.login.labels.email")}
                          validation={
                            this.state.showErrors &&
                            (!input.value ||
                              input.value.trim() === "" ||
                              !validator.isEmail(input.value))
                              ? {
                                  message:
                                    !input.value || input.value.trim() === ""
                                      ? this.props.i18n.t(
                                          "common.errors.required"
                                        )
                                      : this.props.i18n.t(
                                          "common.errors.emailInvalid"
                                        ),
                                  level: "error"
                                }
                              : undefined
                          }
                          addonsInside={true}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="password" defaultValue={""}>
                    {({ input, meta }) => (
                      <div className="form-row password">
                        <BFInput
                          {...input}
                          size="lg"
                          prefix={<Icon icon={"lock"} size={"2x"} />}
                          type="password"
                          placeholder={t("views.login.labels.password")}
                          validation={
                            this.state.showErrors &&
                            (!input.value || input.value.trim() === "")
                              ? {
                                  message: this.props.i18n.t(
                                    "common.errors.required"
                                  ),
                                  level: "error"
                                }
                              : undefined
                          }
                          addonsInside={true}
                        />
                      </div>
                    )}
                  </Field>

                  <div className="button-row">
                    <BfButton
                      className={"reset-password"}
                      type="button"
                      appearance="clear-on-white"
                      size="lg"
                      href="/resetPassword"
                    >
                      {t("views.login.labels.passwordReset")}
                    </BfButton>

                    <div className="fill" />
                    <BfButton
                      className={"login"}
                      type="submit"
                      loading={this.state.loading}
                      appearance="primary"
                      size="lg"
                      onClick={handleSubmit}
                    >
                      {t("views.login.labels.login")}
                    </BfButton>
                  </div>
                </div>
              </Collapse>
            </form>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  context: state.global.context
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  loginUser: (
    email: string,
    password: string,
    mandator: string,
    onSuccess: (data: User) => void,
    onError: (err: any) => void
  ) => {
    dispatch(loginUser(email, password, mandator, onSuccess, onError));
  }
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(GlobalActions.setUserData(user, apps, permissions)) }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(withTranslation()(LoginForm)));
