import React, { Component } from "react";
import { SketchPicker } from "react-color";
import BFInput from "../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";

const HEX_PATTERN = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)|\b(black|silver|gray|whitesmoke|maroon|red|purple|fuchsia|green|lime|olivedrab|yellow|navy|blue|teal|aquamarine|orange|aliceblue|antiquewhite|aqua|azure|beige|bisque|blanchedalmond|blueviolet|brown|burlywood|cadetblue|chartreuse|chocolate|coral|cornflowerblue|cornsilk|crimson|darkblue|darkcyan|darkgoldenrod|darkgray|darkgreen|darkgrey|darkkhaki|darkmagenta|darkolivegreen|darkorange|darkorchid|darkred|darksalmon|darkseagreen|darkslateblue|darkslategray|darkslategrey|darkturquoise|darkviolet|deeppink|deepskyblue|dimgray|dimgrey|dodgerblue|firebrick|floralwhite|forestgreen|gainsboro|ghostwhite|goldenrod|gold|greenyellow|grey|honeydew|hotpink|indianred|indigo|ivory|khaki|lavenderblush|lavender|lawngreen|lemonchiffon|lightblue|lightcoral|lightcyan|lightgoldenrodyellow|lightgray|lightgreen|lightgrey|lightpink|lightsalmon|lightseagreen|lightskyblue|lightslategray|lightslategrey|lightsteelblue|lightyellow|limegreen|linen|mediumaquamarine|mediumblue|mediumorchid|mediumpurple|mediumseagreen|mediumslateblue|mediumspringgreen|mediumturquoise|mediumvioletred|midnightblue|mintcream|mistyrose|moccasin|navajowhite|oldlace|olive|orangered|orchid|palegoldenrod|palegreen|paleturquoise|palevioletred|papayawhip|peachpuff|peru|pink|plum|powderblue|rosybrown|royalblue|saddlebrown|salmon|sandybrown|seagreen|seashell|sienna|skyblue|slateblue|slategray|slategrey|snow|springgreen|steelblue|tan|thistle|tomato|turquoise|violet|wheat|white|yellowgreen|rebeccapurple)\b/gi;

type Props = {
  name: string;
  value: string;
  onChange: (val) => void;
};

type States = {
  colorPicker: number | null;
  edit: boolean;
};

class CSSField extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    colorPicker: null,
    edit: false
  };

  tokenize() {
    const { value } = this.props;

    const matches = value.match(HEX_PATTERN);

    if (!matches) {
      return [{ value: value, isColor: false }];
    } else {
    }
    const tokens = [];
    let current = value;
    let matchIndex = 0;
    while (matchIndex < matches.length) {
      let index = current.indexOf(matches[matchIndex]);

      if (index !== 0) {
        tokens.push({
          isColor: false,
          value: current.substring(0, index)
        });
      }

      tokens.push({
        isColor: true,
        value: matches[matchIndex]
      });
      current = current.substr(index + matches[matchIndex].length);
      matchIndex++;
    }
    if (current !== "") {
      tokens.push({
        isColor: false,
        value: current
      });
    }

    return tokens;
  }
  render() {
    const tokens = this.tokenize();

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: 50,
            padding: 5
          }}
        >
          <div style={{ fontWeight: "bold" }}>{this.props.name}</div>
          <div style={{ flexGrow: 1, paddingLeft: 10, paddingRight: 10 }}>
            {this.state.edit ? (
              <BFInput
                value={this.props.value}
                onChange={val => this.props.onChange(val)}
              />
            ) : (
              <div style={{ display: "inline-block" }}>
                {tokens.map((token, index) => {
                  if (token.isColor) {
                    return (
                      <span style={{ fontWeight: "bold" }} key={index}>
                        <div
                          onClick={() =>
                            this.setState({
                              colorPicker:
                                this.state.colorPicker === index ? null : index
                            })
                          }
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                            height: 16,
                            width: 16,
                            border:
                              this.state.colorPicker === index
                                ? "solid 1px #fff"
                                : "solid 1px #555",
                            background: token.value
                          }}
                        ></div>
                        {token.value}
                      </span>
                    );
                  } else {
                    return token.value;
                  }
                })}
              </div>
            )}
          </div>
          <div>
            <BFButton
              appearance="clear"
              onClick={() => this.setState({ edit: !this.state.edit })}
            >
              {this.state.edit ? (
                <BfIcon type="bf" data="check-1" />
              ) : (
                <BfIcon type="bf" data="pencil-1" />
              )}
            </BFButton>
          </div>
        </div>
        {this.state.colorPicker !== null ? (
          <SketchPicker
            color={tokens[this.state.colorPicker]}
            onChange={color => {
              const newValue = tokens
                .map((token, index) => {
                  if (index === this.state.colorPicker) {
                    return color.hex;
                  } else {
                    return token.value;
                  }
                })
                .join("");
              this.props.onChange(newValue);
              console.log(color);
            }}
          />
        ) : null}
      </>
    );
  }
}

export default CSSField;
