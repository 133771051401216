import Log from "../../../debug/Log";
import DataBus from "../../../services/DataBus";
import { SendEvent } from "./../../../utils/abstracts/AbstractComponent";

const VARIABLE_REGEX = /\$\{[_a-zA-Z0-9\.]*\}/g;

const DATA_REGEX = /\#\{[_a-zA-Z0-9\.]*\}/g;

class ExpressionHelperClass {
  getVariableMatches(text: string) {
    return text.match(VARIABLE_REGEX);
  }

  public handleEvents(additionalEvents: SendEvent[], values: any) {
    if (additionalEvents) {
      additionalEvents.forEach(event => {
        let sendEvent = true;

        if (event.condition) {
          sendEvent = ExpressionHelper.evaluateExpression(
            event.condition,
            values
          );
        }
        if (sendEvent) {
          DataBus.emit(
            ExpressionHelper.evaluateExpression(event.eventKey, values),
            typeof event.data === "string"
              ? ExpressionHelper.evaluateExpression(event.data, values)
              : event.data,
            event.isState
          );
        }
      });
    }
  }

  private replace(
    regex: RegExp,
    text: string,
    values: { [key: string]: any },
    stringify: boolean
  ) {
    const regExpMatches = text.match(regex);

    if (regExpMatches) {
      regExpMatches.forEach(value => {
        const variableKey = value.substr(2, value.length - 3);
        if (values[variableKey] !== undefined) {
          text = text.replace(
            value,
            stringify
              ? JSON.stringify(values[variableKey])
              : values[variableKey]
          );
        } else {
          text = text.replace(value, stringify ? "undefined" : "");
        }
      });
    }
    return text;
  }

  replaceVariables(
    text: string,
    values: { [key: string]: any },
    data?: { [key: string]: any }
  ) {
    let output = text;
    text = this.replace(VARIABLE_REGEX, text, values, false);
    if (data) {
      text = this.replace(DATA_REGEX, text, data, false);
    }
    return text;
  }

  evaluateExpression(
    expression: string,
    values: { [key: string]: any },
    data?: { [key: string]: any }
  ) {
    let expressionString = expression;
    expressionString = this.replace(
      VARIABLE_REGEX,
      expressionString,
      values,
      true
    );
    if (data) {
      expressionString = this.replace(DATA_REGEX, expressionString, data, true);
    }
    Log.state(expression, expressionString, values, data);
    return eval("(" + expressionString + ")");
  }
}

const ExpressionHelper = new ExpressionHelperClass();
export default ExpressionHelper;
