import React, { SyntheticEvent } from "react";
import { connect } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import { RouteComponentProps, withRouter } from "react-router";
import Bounce from "rsuite-utils/lib/Animation/Bounce";
import SearchField from "../../configurable/components/SearchField/SearchField";
import { Application } from "../../model/db/Application";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import { initializeApplication } from "../../services/ApplicationService";
import AppDrawerIcon from "../AppDrawerIcon/AppDrawerIcon";
import SwiperComponent from "../Swiper/SwiperComponent";
import SwiperPage from "../Swiper/SwiperPage";
import "./AppDrawer.scss";

type Props = {
  userAsOverlay: boolean;
  apps: Application[];
  isActive: boolean;
  setUiConfig: (key: string, value: any) => void;
  initializingApp: Application;
  activeApplication: Application;
} & JSX.IntrinsicAttributes &
  RouteComponentProps;
type States = {
  visibility: "hidden" | "visible";
  searchText: string;
  pages: Application[][];
  appsPerPage: number;
  buttonWidth: number;
};

class AppDrawer extends React.Component<Props, States> {
  _isMounted: boolean;
  applicationDrawerRef: any;

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      visibility: props.location.pathname === "/apps" ? "visible" : "hidden",
      pages: [],
      appsPerPage: 0,
      buttonWidth: 150
    };
    this.applicationDrawerRef = React.createRef();
  }

  componentDidMount(): void {
    if (this.props.location.pathname !== "/apps") {
      setTimeout(() => {
        this.setState({ visibility: "visible" });
      }, 400);
    }
    this._isMounted = true;
    window.onpopstate = (e: PopStateEvent) => {
      if (this._isMounted) {
        if (this.props.isActive && this.props.activeApplication) {
          this.props.setUiConfig(
            DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE,
            false
          );
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  hideAppDrawer(e: SyntheticEvent) {
    if (
      this.props.activeApplication &&
      (e.target === this.applicationDrawerRef.current ||
        (e.target as HTMLElement).classList.contains("hide-appdrawer"))
    ) {
      this.props.setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, false);
    }
  }
  onResize(width: number, height: number) {
    const buttonWidth = width == 500 ? 150 : 100;

    const columns = Math.max(1, Math.floor(width / buttonWidth));
    const rows = Math.max(1, Math.floor((height - 80) / buttonWidth));
    const appsPerPage = columns * rows;

    this.assignFilter(this.state.searchText, appsPerPage, buttonWidth);
  }
  assignFilter(searchText: string, appsPerPage: number, buttonWidth: number) {
    const pages = [];
    let currentPage;

    const searchBlocks = searchText.toLowerCase().split(" ");

    this.props.apps
      .filter(app => {
        let find = true;
        searchBlocks.forEach(searchBlock => {
          find =
            find && app.displayName.toLowerCase().indexOf(searchBlock) !== -1;
        });
        return find;
      })
      .forEach((app, index) => {
        if (index % appsPerPage === 0) {
          currentPage = [];
          pages.push(currentPage);
        }
        currentPage.push(app);
      });

    this.setState({
      searchText,
      appsPerPage,
      buttonWidth,
      pages
    });
  }
  render() {
    return (
      <div
        ref={this.applicationDrawerRef}
        style={{ display: "flex", visibility: this.state.visibility }}
        className={`application-drawer ${
          this.props.isActive === true ? "active" : "inactive"
        } ${this.props.initializingApp ? "initializing" : ""}`}
        onTouchStart={e => this.hideAppDrawer(e)}
        onMouseDown={e => this.hideAppDrawer(e)}
      >
        <Bounce in={this.props.isActive}>
          <>
            <div className="application-drawer-app-outer-container">
              <ReactResizeDetector
                handleWidth
                handleHeight
                onResize={(width, height) => this.onResize(width, height)}
              />
              <div className={"application-drawer-app-container"}>
                <div className="header">
                  <div className={`title`}>APPS</div>
                  <div className={`actions`}>
                    <SearchField
                      sizes={[
                        {
                          focusWidth: 200,
                          blurWidth: 30
                        }
                      ]}
                      value={this.state.searchText}
                      onChanged={value =>
                        this.assignFilter(
                          value,
                          this.state.appsPerPage,
                          this.state.buttonWidth
                        )
                      }
                      appearance="on-white-as-button"
                    />
                    {this.props.activeApplication ? (
                      <BFButton
                        onClick={(e: SyntheticEvent) => this.hideAppDrawer(e)}
                        appearance="clear-on-white"
                        className="hide-appdrawer"
                      >
                        <BfIcon
                          type="bf"
                          data="close"
                          className="hide-appdrawer"
                        />
                      </BFButton>
                    ) : null}
                  </div>
                </div>
                <div className={"apps"}>
                  <SwiperComponent
                    style={{ height: "100%" }}
                    id={"appdrawer-swiper"}
                    usePagination={true}
                    useNavigationButtons={false}
                  >
                    {this.state.pages.map((page, index) => (
                      <SwiperPage key={index}>
                        <div style={{ height: "100%", width: "100%" }}>
                          <div className="app-page">
                            {this.renderPage(page)}
                          </div>
                        </div>
                      </SwiperPage>
                    ))}
                  </SwiperComponent>
                </div>
              </div>
            </div>
          </>
        </Bounce>
      </div>
    );
  }

  renderPage(page) {
    const { appsPerPage, buttonWidth } = this.state;

    const renderEmptyDivs = appsPerPage - page.length;
    const divs = [];
    for (let i = 0; i < renderEmptyDivs; i++) {
      divs.push(
        <div key={i} style={{ width: buttonWidth, height: buttonWidth }} />
      );
    }

    return (
      <>
        {page.map((app, index) => {
          return <AppDrawerIcon key={index + app._id} applicationObj={app} />;
        })}
        {divs}
      </>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  apps:
    state.global.user && state.global.user.mandator_info
      ? state.global.user.mandator_info.apps
      : null,
  // apps: state.global.user && state.global.user.mandator_info ? state.global.user.mandator_info.apps : null,
  isActive: ownProps.userAsOverlay
    ? state.uiConfig.general[DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE]
    : ownProps.isActive,
  initializingApp:
    state.uiConfig.general[DefaultUIConfigs.APPLICATION_IS_INITIALIZING],
  activeApplication: state.uiConfig.activeApplication
});

export default connect(mapStateToProps, { setUiConfig, initializeApplication })(
  withRouter(AppDrawer)
);
