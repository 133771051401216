import React, { CSSProperties } from "react";
import { BaseElement, GenericFormsLayoutProps } from "../BaseElement";
import GenericLayoutComponent from "../GenericLayoutComponent";

type GFDivProps = {
  items: { [key: string]: any };
  condition?: string;
  style: CSSProperties;
  stateSubscriptions: string[];
  className?: string;
} & GenericFormsLayoutProps;
export const GFDiv: React.FC<GFDivProps> = props => {
  const {
    items,
    condition,
    allProperties,
    formProps,
    style,
    stateSubscriptions,
    className
  } = props;

  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      style={style}
      condition={condition}
      render={styleProps => (
        <div
          style={{ ...styleProps }}
          className={`gf-div ${className ? className : ""}`}
        >
          {items
            ? Object.values(items).map((item, index) => (
                <BaseElement
                  key={index}
                  {...item}
                  allProperties={allProperties}
                  formProps={formProps}
                />
              ))
            : null}
        </div>
      )}
    />
  );
};
