export const DataBusSubKeys = {
	RELOAD: "RELOAD",
	SUBMIT: "SUBMIT",
	SUBMIT_START: "SUBMIT_START",
	SUBMIT_RESPONSE: "SUBMIT_RESPONSE",

	DETAIL_DATA_FETCH: "DETAIL_DATA_FETCH",

	TABLE_ROW_SELECTION: "TABLE_ROW_SELECTION",
	TABLE_ROW_DBL_CLICK: "TABLE_ROW_DBL_CLICK",
	TABLE_ROW_RELOAD: "TABLE_ROW_RELOAD",

	ACTION_REMOVE_GROUP_USER_ASSIGNMENT: "ACTION_REMOVE_GROUP_USER_ASSIGNMENT",
	ACTION_ADD_GROUP_USER_ASSIGNMENT: "ACTION_ADD_GROUP_USER_ASSIGNMENT",
	ACTION_CREATE_GROUP: "ACTION_CREATE_GROUP",
	ACTION_CREATE_GROUP_RESPONSE: "ACTION_CREATE_GROUP_RESPONSE",
	ACTION_DELETE_GROUP: "ACTION_DELETE_GROUP",
	ACTION_DELETE_GROUP_RESPONSE: "ACTION_DELETE_GROUP_RESPONSE",

	ACTION_CREATE_USER: "ACTION_CREATE_USER",
	ACTION_CREATE_USER_RESPONSE: "ACTION_CREATE_USER_RESPONSE",
	ACTION_DELETE_USER: "ACTION_DELETE_USER",
	ACTION_DELETE_USER_RESPONSE: "ACTION_DELETE_USER_RESPONSE",

	ACTION_LOCK_USER: "ACTION_LOCK_USER",
	ACTION_UNLOCK_USER: "ACTION_UNLOCK_USER",

	ACTION_SEND_PASSWORD_TOKEN_MAIL: "ACTION_SEND_PASSWORD_TOKEN_MAIL",
	ACTION_SEND_ACCOUNT_ACTIVATION_MAIL: "ACTION_SEND_ACCOUNT_ACTIVATION_MAIL"
};

export const UserStatus = {
	ACTIVE: 0,
	INACTIVE: 1, //set by mandator admin
	LOCKED: 2, //locked by condition - too many login tries
	WAITING_FOR_ACTIVATION: 3, //
	DEACTIVATED: 4 //deactivated by vendor
};
