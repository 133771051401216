import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { TranslationProps } from "../../model/common/TranslationProps";
import { Application } from "../../model/db/Application";
import BfButton from "../../modules/abstract-ui/general/Button/BFButton";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import { initializeApplication } from "../../services/ApplicationService";
import "./AppDrawerIcon.scss";

interface Props extends TranslationProps {
  applicationObj: Application;
  setUiConfig: (key: string, value: any) => void;
  initializeApplication: (
    application: Application,
    onSuccess?: () => void,
    onError?: (err) => void
  ) => void;
  initializingApp: Application;
  activeApplication: Application;
}
type States = {};

class AppDrawerIcon extends Component<Props & RouteComponentProps, States> {
  openApplication() {
    const { applicationObj, activeApplication } = this.props;
    if (activeApplication === applicationObj) {
      this.props.setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, false);
    } else {
      // this.props.initializeApplication(applicationObj);
      // setTimeout(() => {
      this.props.history.push(`/${applicationObj.name}`);
      // }, 200);
    }
  }

  render() {
    const { initializingApp, applicationObj, activeApplication } = this.props;

    return (
      <BfButton
        tabIndex="-1"
        className={`application-drawer-button ${
          applicationObj === activeApplication ? "active" : ""
        }`}
        loading={this.props.applicationObj === this.props.initializingApp}
        appearance="clear"
        onClick={() => this.openApplication()}
      >
        <div
          className="application-logo"
          style={{ backgroundImage: `url(${applicationObj.logo.drawer})` }}
        />
        <div className="application-title">{applicationObj.displayName}</div>
      </BfButton>
    );
  }
}

// const mapStateToProps = (state: AppState) => ({
// })
// const mapDispatchToProps = (dispatch: ThunkDispatch<{},{},any>, ownProps:any) => ({

// })

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  initializingApp:
    state.uiConfig.general[DefaultUIConfigs.APPLICATION_IS_INITIALIZING],
  activeApplication: state.uiConfig.activeApplication
});
export default connect(mapStateToProps, { initializeApplication, setUiConfig })(
  withRouter(AppDrawerIcon)
);
