import React, { CSSProperties } from "react";
import "./BootstrapLayout.scss";
import GenericLayoutComponent from "../GenericLayoutComponent";
import { BaseElement, GenericFormsLayoutProps } from "../BaseElement";

type GFColProps = {
  className?: string;
  items: { [key: string]: any };
  condition?: string;
  style: CSSProperties;
  stateSubscriptions: string[];
} & GenericFormsLayoutProps;
export const GFCol: React.FC<GFColProps> = props => {
  const {
    className,
    items,
    condition,
    allProperties,
    style,
    stateSubscriptions,
    formProps
  } = props;
  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      style={style}
      condition={condition}
      render={styleProps => (
        <div style={styleProps} className={`col ${className ? className : ""}`}>
          {Object.values(items).map((item, index) => (
            <BaseElement
              key={index}
              {...item}
              allProperties={allProperties}
              formProps={formProps}
            />
          ))}
        </div>
      )}
    />
  );
};

type GFRowProps = {
  items: { [key: string]: any };
  condition?: string;
  style: CSSProperties;
  stateSubscriptions: string[];
} & GenericFormsLayoutProps;
export const GFRow: React.FC<GFRowProps> = props => {
  const {
    items,
    condition,
    allProperties,
    formProps,
    style,
    stateSubscriptions
  } = props;
  return (
    <GenericLayoutComponent
      stateSubscriptions={stateSubscriptions}
      style={style}
      condition={condition}
      render={styleProps => (
        <div style={styleProps} className={"row"}>
          {Object.values(items).map((item, index) => (
            <BaseElement
              key={index}
              {...item}
              allProperties={allProperties}
              formProps={formProps}
            />
          ))}
        </div>
      )}
    />
  );
};
