import ModalManager from "../components/ModalComponent/ModalManager";
import { ReloadMessage } from "../configurable/components/TableComponent/TableComponent";
import {
  ActionData,
  ActionDataStateChange
} from "../model/common/DataBus/ActionData";
import Toast from "../modules/abstract-ui/notification/Toast";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import { PATCH_TABLE_ROW_DATA } from "../redux/actions/application/application-action-types";
import { SET_USER_DATA } from "../redux/actions/global/global-actions-types";
import { store } from "../redux/store";
import { SendEvent } from "../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../utils/Constants";
import { handleError } from "../utils/ErrorCodes";
import { HTTP } from "../utils/Http";
import DataBus from "./DataBus";

class GroupServiceClass {
  constructor() {}

  init() {
    DataBus.subscribe<ActionData>(
      DataBusSubKeys.ACTION_REMOVE_GROUP_USER_ASSIGNMENT,
      data => this.removeGroupUserAssignment(data)
    );
    DataBus.subscribe<ActionData>(
      DataBusSubKeys.ACTION_ADD_GROUP_USER_ASSIGNMENT,
      data => this.addGroupUserAssignemnt(data)
    );
    DataBus.subscribe<ActionData>(DataBusSubKeys.ACTION_DELETE_GROUP, data =>
      this.actionDeleteGroupDialog(data)
    );

    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_CREATE_GROUP,
      {
        type: "state",
        hidden: false,
        disabled: false
      },
      true
    );
    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_DELETE_GROUP,
      {
        type: "state",
        hidden: false,
        disabled: false
      },
      true
    );
  }

  actionDeleteGroupDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_DELETE_GROUP,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true
        },
        true
      );
      this.deleteGroup(
        actionData.data["groupId"],
        actionData.data["tablesToReload"],
        actionData.data["goToUrl"],
        actionData.data["additionalEvents"]
      ).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_DELETE_GROUP,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false
          },
          true
        );
      });
    }
  }

  private deleteGroup(
    groupId,
    tablesToReload: string[],
    goToUrl?: string,
    additionalEvents?: SendEvent[]
  ) {
    return new Promise((resolve, reject) => {
      HTTP.delete({
        url: `group/${groupId}`,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          ExpressionHelper.handleEvents(additionalEvents, response);
          DataBus.emit(DataBusSubKeys.ACTION_DELETE_GROUP_RESPONSE, {
            success: true,
            data: response
          });
          DataBus.emit(DataBusSubKeys.RELOAD, {
            identifiers: tablesToReload
          } as ReloadMessage);
          Toast.success(
            (window as any).translate("GroupService.GroupSuccessfullyDeleted")
          );
          if (goToUrl) {
            (window as any).router.push(goToUrl);
          }
          resolve(response);
        })
        .catch(err => {
          DataBus.emit(DataBusSubKeys.ACTION_DELETE_GROUP_RESPONSE, {
            success: false,
            data: err
          });
          handleError(err);
          reject(err);
        });
    });
  }

  addGroupUserAssignemnt(actionData: ActionData) {
    if (actionData.type === "click") {
      const {
        rowData,
        groupID,
        userID,
        tableIdentifier,
        splitPage
      } = actionData.data;

      let url = `user/${userID}/addToGroup`;

      const bodyParams = {
        groupID: groupID
      };

      store.dispatch({
        type: PATCH_TABLE_ROW_DATA,
        tableIdentifier,
        rowId: rowData["_id"],
        data: {
          ...rowData,
          __loading: true
        }
      });
      HTTP.post({
        url: url,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        bodyParams
      })
        .then(response => {
          if (store.getState().global.user._id === response._id) {
            store.dispatch({
              type: SET_USER_DATA,
              user: response
            });
          }

          store.dispatch({
            type: PATCH_TABLE_ROW_DATA,
            tableIdentifier,
            rowId: rowData["_id"],
            data: {
              ...rowData,
              __added: true,
              __deleted: false,
              __loading: false
            }
          });
          if (splitPage) {
            DataBus.emit("SPLITPAGE_UPDATE_OBJECT", {
              identifier: splitPage,
              data: response
            });
          }

          //
          // DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
          //     id: submitMessage.id,
          //     success: true,
          //     data: response
          // } as SubmitResponse);
        })
        .catch(err => {
          handleError(err);
          // DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
          //     id: submitMessage.id,
          //     success: false,
          //     data: err
          // });
        });
    }
  }

  removeGroupUserAssignment(actionData: ActionData) {
    if (actionData.type === "click") {
      const {
        rowData,
        userID,
        groupID,
        tableIdentifier,
        splitPage
      } = actionData.data;

      if (userID === store.getState().global.user._id) {
        ModalManager.confirm({
          message: (window as any).translate(
            "Application.Administration.Group.removeSelfWarningText"
          ),
          confirmButtonText: (window as any).translate("Global.Buttons.remove"),
          onConfirm: () => {
            this.removeGroupUserAssignmentDo(actionData);
          }
        });
      } else {
        this.removeGroupUserAssignmentDo(actionData);
      }
    }
  }

  removeGroupUserAssignmentDo(actionData: ActionData) {
    if (actionData.type === "click") {
      const {
        rowData,
        userID,
        groupID,
        tableIdentifier,
        splitPage
      } = actionData.data;

      let url = `user/${userID}/removeFromGroup`;

      const bodyParams = {
        groupID: groupID
      };

      store.dispatch({
        type: PATCH_TABLE_ROW_DATA,
        tableIdentifier,
        rowId: rowData["_id"],
        data: {
          ...rowData,
          __loading: true
        }
      });
      HTTP.post({
        url: url,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        bodyParams
      })
        .then(response => {
          if (store.getState().global.user._id === response._id) {
            store.dispatch({
              type: SET_USER_DATA,
              user: response
            });
          }

          store.dispatch({
            type: PATCH_TABLE_ROW_DATA,
            tableIdentifier,
            rowId: rowData["_id"],
            data: {
              ...rowData,
              __deleted: true,
              __added: false,
              __loading: false
            }
          });
          if (splitPage) {
            DataBus.emit("SPLITPAGE_UPDATE_OBJECT", {
              identifier: splitPage,
              data: response
            });
          }

          // if (submitMessage.pushToCache) {
          //     store.dispatch({
          //         type: SET_APPICATION_CACHE_DATA,
          //         key: submitMessage.pushToCache,
          //         data: response,
          //         timestamp: Number(new Date()),
          //         ttl: -1
          //     })
          // }
          //
          // DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
          //     id: submitMessage.id,
          //     success: true,
          //     data: response
          // } as SubmitResponse);
        })
        .catch(err => {
          handleError(err);
          // DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
          //     id: submitMessage.id,
          //     success: false,
          //     data: err
          // });
        });
    }
  }
}

const GroupService = new GroupServiceClass();

export default GroupService;
