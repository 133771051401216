import React, { Component } from "react";
import Flag from "react-flags";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dropdown, Icon } from "rsuite";
import screenfull from "screenfull";
import Separator from "../../configurable/components/Separator/Separator";
import i18n from "../../i18n";
import { Application } from "../../model/db/Application";
import { User } from "../../model/db/User";
import BfRadio from "../../modules/abstract-ui/forms/radio/BfRadio";
import BfButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../modules/abstract-ui/icon/BfIcon";
import NotificationsButton from "../../modules/notifications/NotificationsButton";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import { logoutUser } from "../../services/AuthenticationService";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import "./ApplicationHeader.scss";

type Props = {
  availableLanguages: { selector: string; flag: string; name: string }[];
  selectedLanguage: string;
  user: User;
  logoutUser: (onSuccess: () => void, onError: (err) => void) => void;
  headerTransparent: boolean;
  headerShowAppDrawer: boolean;
  appDrawerOverlayActive: boolean;
  setUiConfig: (key: string, value: any) => void;
  activeApplication: Application;
  viewportSizeSelector: string;
  initializingApp: Application;
  showHeaderLeftNode: React.ReactNode;
  mobileHeader?: { icon?: BfIconProps; textKey: string };
} & RouteComponentProps;
type States = {
  fullscreenActive: boolean;
  fullscreenEnabled: boolean;
};

class ApplicationHeader extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    fullscreenActive: false,
    fullscreenEnabled: false
  };

  componentDidMount() {
    if (screenfull.isEnabled) {
      this.setState({
        fullscreenActive: screenfull.isFullscreen,
        fullscreenEnabled: true
      });
      screenfull.on("change", () => {
        if (screenfull.isEnabled) {
          this.setState({ fullscreenActive: screenfull.isFullscreen });
        }
      });
    }
  }

  openFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.request();
    }
  }

  closeFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  }

  logoutUser() {
    this.props.logoutUser(
      () => {
        this.props.history.push("/login");
      },
      err => {}
    );
  }

  renderDropdown() {
    const { mobileHeader, user } = this.props;
    return (
      <Dropdown
        className="header-dropdown"
        title={
          <div className="font-color-white">
            <Icon
              size="lg"
              style={{ transform: "rotate(90deg)" }}
              icon="more"
            />
          </div>
        }
        noCaret
        placement="bottomEnd"
      >
        {this.props.viewportSizeSelector === "xs" ? (
          <>
            <Dropdown.Item
              icon={
                <AvatarComponent
                  style={{ marginLeft: -3, marginRight: 10 }}
                  size="sm"
                  avatar={user.avatar}
                  displayName={user.displayname}
                />
              }
              onClick={() => {
                this.props.history.push(
                  this.props.location.pathname + "/__userprofile/profile"
                );
              }}
            >
              <Trans i18nKey="Header.profile" />
            </Dropdown.Item>

            <Separator horizontal={true} />
          </>
        ) : null}
        {/* {
                        this.props.viewportSizeSelector !== "xs" && this.props.headerShowAppDrawer ? (
                            <Dropdown.Item
                                icon={<BfIcon type="bf" data="layout-module" />}
                                onClick={() => this.props.setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, !this.props.appDrawerOverlayActive)} >
                                <Trans i18nKey="Header.appChange" />
                            </Dropdown.Item>
                        ) : null
                    } */}
        {this.state.fullscreenActive ? (
          <Dropdown.Item
            icon={<BfIcon type="bf" data="shrink-4" />}
            onClick={() => this.closeFullscreen()}
          >
            <Trans i18nKey="Header.closeFullscreen" />
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            icon={<BfIcon type="bf" data="expand-5" />}
            onClick={() => this.openFullscreen()}
          >
            <Trans i18nKey="Header.openFullscreen" />
          </Dropdown.Item>
        )}
        <Separator horizontal={true} />
        {this.props.availableLanguages.length > 1 ? (
          <Dropdown.Menu
            icon={<BfIcon type="bf" data="earth-3" />}
            title={<Trans i18nKey="Header.language" />}
            pullLeft
          >
            {this.props.availableLanguages.map(lang => (
              <Dropdown.Item
                key={lang.flag}
                onSelect={() => i18n.changeLanguage(lang.selector)}
                icon={
                  <Flag
                    basePath={`/img/flags`}
                    name={lang.flag}
                    format="png"
                    pngSize={32}
                  />
                }
              >
                <div className={`language-option`}>
                  <div className={`name`}>{lang.name}</div>{" "}
                  <BfRadio
                    checked={lang.selector === this.props.selectedLanguage}
                  />
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        ) : null}
        {this.props.availableLanguages.length > 1 ? (
          <Separator horizontal={true} />
        ) : null}
        <Dropdown.Item
          icon={<BfIcon type="bf" data="information-circle" />}
          onClick={() =>
            this.props.history.push(this.props.location.pathname + "/__about")
          }
        >
          <Trans i18nKey="Header.about" />
        </Dropdown.Item>
        <Dropdown.Item
          icon={<BfIcon type="bf" data="shield-lock" />}
          onClick={() =>
            this.props.history.push(this.props.location.pathname + "/__privacy")
          }
        >
          <Trans i18nKey="Header.privacy" />
        </Dropdown.Item>
        <Dropdown.Item
          icon={<BfIcon type="bf" data="question-help-circle" />}
          onClick={() =>
            this.props.setUiConfig(DefaultUIConfigs.HELP_DRAWER_ACTIVE, true)
          }
        >
          <Trans i18nKey="Header.help" />
        </Dropdown.Item>

        <Separator horizontal={true} />
        <Dropdown.Item
          icon={<BfIcon type="bf" data="logout-1" />}
          onClick={() => this.logoutUser()}
        >
          <Trans i18nKey="Header.logout" />
        </Dropdown.Item>
      </Dropdown>
    );
  }

  render() {
    const { mobileHeader, user } = this.props;

    return (
      <div
        className={`application-header ${
          this.props.headerTransparent ? "transparent" : ""
        } ${this.props.viewportSizeSelector === "xs" ? "mobile" : ""} `}
      >
        {this.renderDropdown()}
        <NotificationsButton />
        {this.props.activeApplication &&
        this.props.user.mandator_info.apps &&
        this.props.user.mandator_info.apps.length > 1 ? (
          <BfButton
            appearance={"clear"}
            onClick={() => {
              this.props.setUiConfig(
                DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE,
                !this.props.appDrawerOverlayActive
              );
            }}
          >
            <BfIcon size="xl" type="bf" data={"layout-module-3"} />
            {/* <BfIcon size="lg" data={"th2"} /> */}
            {/* <img className={"logo"} src={this.props.activeApplication.logo.navbar} /> */}
          </BfButton>
        ) : null}
        {this.props.viewportSizeSelector !== "xs" ? (
          <BfButton
            className={"profile-button"}
            appearance="clear"
            onClick={() =>
              this.props.history.push(
                this.props.location.pathname + "/__userprofile/profile"
              )
            }
          >
            <AvatarComponent
              size={"sm"}
              avatar={user.avatar}
              displayName={user.displayname}
            />
          </BfButton>
        ) : null}
        {this.props.headerShowAppDrawer ? (
          this.props.viewportSizeSelector === "xs" ? (
            <>
              <div className="fill" />
              {mobileHeader ? (
                <div className="mobile-header">
                  {mobileHeader.icon ? (
                    <BfIcon className={"icon"} {...mobileHeader.icon} />
                  ) : null}
                  {<Trans>{mobileHeader.textKey}</Trans>}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="fill" />
              <div
                className={`appDrawerButton ${
                  this.props.initializingApp ? "initializing" : ""
                }`}
              >
                {/*<Icon size="lg" icon={"th2"}/>*/}
                <img
                  className={"logo"}
                  src={this.props.activeApplication.logo.navbar}
                />
                {this.props.activeApplication &&
                this.props.activeApplication.logo.navbarDisplayname ? (
                  <div className="applicationName">
                    {this.props.activeApplication.displayName}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )
        ) : null}
        {this.props.showHeaderLeftNode ? this.props.showHeaderLeftNode : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
  headerTransparent:
    state.uiConfig.general[DefaultUIConfigs.HEADER_TRANSPARENT],
  headerShowAppDrawer:
    state.uiConfig.general[DefaultUIConfigs.HEADER_APP_DRAWER],
  appDrawerOverlayActive:
    state.uiConfig.general[DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE],
  activeApplication: state.uiConfig.activeApplication,
  viewportSizeSelector:
    state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR],
  showSidenavDrawer:
    state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR],
  showHeaderLeftNode:
    state.uiConfig.general[DefaultUIConfigs.SHOW_HEADER_LEFT_NODE],
  initializingApp:
    state.uiConfig.general[DefaultUIConfigs.APPLICATION_IS_INITIALIZING],
  mobileHeader: state.uiConfig.general[DefaultUIConfigs.MOBILE_HEADER],
  availableLanguages:
    state.uiConfig.general[DefaultUIConfigs.AVAILABLE_LANGUAGES],
  selectedLanguage: state.uiConfig.general[DefaultUIConfigs.SELECTED_LANGUAGE]
});

export default connect(mapStateToProps, { logoutUser, setUiConfig })(
  withRouter(ApplicationHeader)
);
// withRouter()withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ApplicationHeader);
