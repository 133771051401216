import React, { Component, CSSProperties } from "react";

import "./Dashboard.scss";

import { AppState } from "../../redux/store";
import { DashboardLayoutConfig } from "../../model/common/DashboardConfig";
import DashboardCard from "./DashboardCard";
import { DashboardComponentMapper } from "../../utils/DashboardComponentsMapper";

export const mockedDashboard: DashboardLayoutConfig = {
  columns: 4,
  rows: 4,
  height: 400,
  width: "100%",
  minHeight: "100%",
  entries: [
    {
      col: 1,
      row: 1,
      colSpan: 2,
      rowSpan: 3,
      minHeight: "300px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 3,
      row: 1,
      colSpan: 1,
      rowSpan: 1,
      minHeight: "140px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 3,
      row: 2,
      colSpan: 1,
      rowSpan: 1,
      minHeight: "140px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 3,
      row: 3,
      colSpan: 1,
      rowSpan: 2,
      minHeight: "220px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 4,
      row: 1,
      colSpan: 1,
      rowSpan: 2,
      minHeight: "150px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 4,
      row: 3,
      colSpan: 1,
      rowSpan: 2,
      minHeight: "50px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 1,
      row: 4,
      colSpan: 1,
      rowSpan: 1,
      minHeight: "140px",
      component: "TestDashboardComponent",
      config: {}
    },
    {
      col: 2,
      row: 4,
      colSpan: 1,
      rowSpan: 1,
      minHeight: "140px",
      component: "TestDashboardComponent",
      config: {}
    }
  ]
};

type Props = {
  dashboardLayout: DashboardLayoutConfig;
};

type States = {};

class Dashboard extends Component<Props, States> {
  render() {
    const { dashboardLayout } = this.props;

    let columnsDef = "";
    for (let i = 0; i < dashboardLayout.columns; i++) {
      columnsDef += "1fr ";
    }
    let rowsDef = "";
    for (let i = 0; i < dashboardLayout.rows; i++) {
      rowsDef += "1fr ";
    }

    const stylesObj: CSSProperties = {
      display: "grid",
      gridTemplateColumns: columnsDef,
      gridTemplateRows: rowsDef,
      height: dashboardLayout.height,
      width: dashboardLayout.width,
      minHeight: dashboardLayout.minHeight
    };

    return (
      <div className="application-dashboard" style={stylesObj}>
        {dashboardLayout.entries.map((entry, index) => {
          if (DashboardComponentMapper[entry.component]) {
            return (
              <DashboardCard key={index} dashboardEntryConfig={entry}>
                {React.createElement(
                  DashboardComponentMapper[entry.component],
                  entry.config
                )}
              </DashboardCard>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user
});

export default Dashboard;
