import React, { Component } from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { Modal } from "rsuite";
import BFCheckbox from "../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { SET_UI_CONFIG } from "../../redux/actions/ui-config/ui-config-actions-types";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState, store } from "../../redux/store";
import DataBus from "../../services/DataBus";
import { setUiConfig } from "./../../redux/actions/ui-config/ui-config-actions";
import "./DebugController.scss";

type Props = {
  debugActive: boolean;
  themeConfiguratorActive: boolean;
};

type States = {
  databusActive: boolean;
  reduxActive: boolean;
};

class DebugController extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    databusActive: false,
    reduxActive: false
  };

  render() {
    return (
      <div className={`debug-controller`}>
        <div className={`controls`}>
          <BFButton
            onClick={() => this.setState({ databusActive: true })}
            appearance="primary"
          >
            Databus
          </BFButton>
          <BFButton
            onClick={() => this.setState({ reduxActive: true })}
            appearance="primary"
          >
            Redux
          </BFButton>

          <div className={`check-entry`}>
            <BFCheckbox
              onChange={(value, checked) => {
                store.dispatch({
                  type: SET_UI_CONFIG,
                  key: DefaultUIConfigs.DEBUG_COMPONENTS,
                  value: checked
                });
              }}
              checked={this.props.debugActive}
            />{" "}
            Debug
          </div>
          <div className={`check-entry`}>
            <BFCheckbox
              onChange={(value, checked) => {
                store.dispatch({
                  type: SET_UI_CONFIG,
                  key: DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE,
                  value: checked
                });
              }}
              checked={this.props.themeConfiguratorActive}
            />
            Theme
          </div>
        </div>
        <div className={`hover-indicator`}>
          <BfIcon data="bug" />
        </div>

        <Modal
          show={this.state.databusActive}
          onHide={() => this.setState({ databusActive: false })}
        >
          <Modal.Header>
            <Modal.Title>Databus States</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactJson
              collapsed={2}
              src={DataBus.getDatabusDebug()}
              displayDataTypes={false}
              enableClipboard={false}
            />

            {/* <code>
                            <pre>
                                {JSON.stringify(this.props.data, null, 5)}
                            </pre>
                        </code> */}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.reduxActive}
          onHide={() => this.setState({ reduxActive: false })}
        >
          <Modal.Header>
            <Modal.Title>Redux States</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactJson
              collapsed={1}
              src={store.getState()}
              displayDataTypes={false}
              enableClipboard={false}
            />

            {/* <code>
                            <pre>
                                {JSON.stringify(this.props.data, null, 5)}
                            </pre>
                        </code> */}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  debugActive: state.uiConfig.general[DefaultUIConfigs.DEBUG_COMPONENTS],
  themeConfiguratorActive:
    state.uiConfig.general[DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE]
});

export default connect(mapStateToProps, {
  setUiConfig
})(DebugController);
