import {
  SET_ACTIVE_APPLICATION,
  SET_UI_CONFIG
} from "./ui-config-actions-types";
import { ThunkDispatch } from "redux-thunk";
import { Application } from "../../../model/db/Application";
import { initializeApplication } from "../../../services/ApplicationService";

export function setUiConfig(key: string, value: any) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_UI_CONFIG,
      key,
      value
    });
  };
}

export function setActiveApplication(application: Application) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_ACTIVE_APPLICATION,
      application
    });

    dispatch(initializeApplication(application));
  };
}
