import React, { CSSProperties } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Application } from "../../../model/db/Application";
import { AssetType } from "../../../model/db/AssetType";
import { setUiConfig } from "../../../redux/actions/ui-config/ui-config-actions";
import { AppState } from "../../../redux/store";
import { GenericFormsLayoutProps } from "../../generic-forms/BaseElement";
import "./PermissionFormField.scss";

type Props = {
  name: string;
  properties: any;
  mandatorApps: Application[];
  assetTypes: AssetType[];
  style?: CSSProperties;
} & GenericFormsLayoutProps &
  WithTranslation;

type States = {
  appSelectionActive;
};

class PermissionFormField extends React.Component<Props, States> {
  oldState = {
    hasValidationErrors: null
  };

  static defaultProps = {};

  readonly state: States = {
    appSelectionActive: false
  };

  // renderModal(value: Permissions, input: FieldInputProps<any, any>) {
  //     const {
  //         mandatorApps,
  //         i18n
  //     } = this.props;

  //     const data: ActionSheetEntry[] = [];
  //     mandatorApps.filter(mandatorApp => !value.APP || value.APP.indexOf(mandatorApp._id) === -1).forEach(app => {

  //         const appConfig = this.props.mandatorApps.find(appConfig => appConfig._id === app._id);

  //         data.push({

  //             key: app._id,
  //             label: app.displayName,
  //             value: app,
  //             disabled: false,
  //             preRender: () => (<div className={"logo"} style={{ backgroundImage: `url(${appConfig.logoThumb})` }} />)
  //             // postRender?: () => React.ReactNode,
  //         })
  //     });

  //     return (
  //         <ActionSheetDrawer

  //             onHide={() => this.setState({ appSelectionActive: false })}
  //             active={this.state.appSelectionActive}
  //             title={i18n.t("BFComponents.PermissionsFormField.ApplicationPermission.ModalAddApplication")}>

  //             <ActionSheetEntries className={"PermissionFormField-Modal-Appselection"} data={data}
  //                 onClick={(value) => {
  //                     const obj1 = {
  //                         ...input.value,
  //                         APP: [
  //                             ...(input.value.APP ? input.value.APP : []),
  //                             value._id
  //                         ]
  //                     };
  //                     const newVal = ObjectTools.subtractObject(obj1, value.permissions, (key, obj1, obj2) => key === "ASSET" ? obj1.AssetID === obj2.AssetID : false, "ADMIN", "AssetID");

  //                     input.onChange(newVal);

  //                     this.setState({ appSelectionActive: false });
  //                 }}
  //             />
  //         </ActionSheetDrawer>
  //     )
  // }

  // renderAppPermissions(input: FieldInputProps<any, any>, value: Permissions) {
  //     if (!value.APP || value.APP.length === 0) {
  //         return (<div className={`no-entries-label`}>{this.props.i18n.t("Application.Administration.Group.noAppPermissions")}</div>);
  //     } else {

  //         const data: ActionSheetEntry[] = [];

  //         value.filter(appID => {

  //             const appConfig = this.props.mandatorApps.find(appConfig => appConfig._id === appID);
  //             if (appConfig) {
  //                 data.push({

  //                     key: appConfig._id,
  //                     label: appConfig.displayName,
  //                     value: appConfig,
  //                     disabled: false,
  //                     preRender: () => (
  //                         <div className={"logo"} style={{ backgroundImage: `url(${appConfig.logoThumb})` }} />),
  //                     postRender: () => (
  //                         <div className={"actions"}>
  //                             {
  //                                 !this.props.properties.readonly ? (
  //                                     <BfButton
  //                                         onClick={() => {
  //                                             ModalManager.show(
  //                                                 {
  //                                                     size: "sm",
  //                                                     content: "Sie entfernen gerade den Zugriff der Gruppe auf eine Applikation. Wollen Sie die Rechte, die durch die Applikation gesetzt waren auch entfernen?",
  //                                                     buttons: [
  //                                                         {
  //                                                             text: "Abbrechen"
  //                                                         },
  //                                                         {
  //                                                             text: "Rechte beibehalten",
  //                                                             onClick: () => {
  //                                                                 const newValue = ObjectTools.mergeObjects({
  //                                                                     ...input.value,
  //                                                                     APP: (input.value.APP ? input.value.APP : []).filter(app => app !== appID)
  //                                                                 }, appConfig.permissions, true)
  //                                                                 input.onChange(newValue);
  //                                                             }
  //                                                         },
  //                                                         {
  //                                                             text: "Rechte entfernen",
  //                                                             onClick: () => {
  //                                                                 input.onChange({
  //                                                                     ...input.value,
  //                                                                     APP: (input.value.APP ? input.value.APP : []).filter(app => app !== appID)
  //                                                                 });
  //                                                             }
  //                                                         }
  //                                                     ]
  //                                                 });
  //                                         }}
  //                                         appearance={"clear-on-white"}
  //                                     >
  //                                         <BfIcon data={"close"} />
  //                                     </BfButton>

  //                                 ) : null
  //                             }

  //                         </div>),
  //                 })
  //             }
  //         });

  //         return (

  //             <ActionSheetEntries data={data} className={"PermissionFormField-Modal-Appselection"} />
  //         )

  //     }

  // }

  render() {
    const {
      mandatorApps,
      assetTypes,
      properties,
      name,
      allProperties,
      formProps,
      i18n,
      style
    } = this.props;

    return (
      <div></div>
      // <GenericFormField
      //     allProperties={allProperties}
      //     formProps={formProps}
      //     name={name}
      //     jsonProperty={properties}
      //     render={(input, meta, name1, jsonProperty, currentValues) => {

      //         const value = input.value as Permissions;

      //         return (

      //             <div className={"permissions-form-field"} style={style}>
      //                 <div className={"app-permissions"}>
      //                     <div className={"header"}>
      //                         <div className={"title"}>
      //                             {i18n.t("BFComponents.PermissionsFormField.ApplicationPermission.Header")}
      //                         </div>

      //                         {
      //                             !this.props.properties.readonly && mandatorApps.filter(mandatorApp => !value.APP || value.APP.indexOf(mandatorApp._id) === -1).length !== 0 ? (

      //                                 <div className={"actions"}>
      //                                     <BfButton appearance={"clear-highlight"}
      //                                         onClick={() => this.setState({ appSelectionActive: true })}>{i18n.t("Global.Buttons.add")}</BfButton>
      //                                 </div>
      //                             ) : null
      //                         }
      //                     </div>
      //                     <div className={"content"}>
      //                         {
      //                             this.renderAppPermissions(input, value)
      //                         }
      //                     </div>

      //                     {
      //                         this.renderModal(value, input)
      //                     }
      //                 </div>

      //                 <AdministrativePermissionCardComp
      //                     readonly={this.props.properties.readonly}
      //                     input={input}
      //                     mandatorApps={mandatorApps} />

      //                 <AssetTypePermissionCardComp input={input} mandatorApps={mandatorApps} readonly={this.props.properties.readonly}
      //                     assetTypes={assetTypes} />
      //             </div>
      //         )
      //     }}
      // />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  mandatorApps:
    state.global.cache &&
    state.global.cache.mandatorApps &&
    state.global.cache.mandatorApps.data
      ? state.global.cache.mandatorApps.data.apps
      : [],
  assetTypes:
    state.global.cache &&
    state.global.cache.assetTypes &&
    state.global.cache.assetTypes
      ? state.global.cache.assetTypes.data
      : []
});

export default connect(mapStateToProps, { setUiConfig })(
  withTranslation()(PermissionFormField)
);
