import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { User } from "../../model/db/User";
import { AppState } from "../../redux/store";
import AppDrawer from "../AppDrawer/AppDrawer";
import ApplicationHeader from "../ApplicationHeader/ApplicationHeader";
import "./ApplicationBody.scss";

type Props = {
  user: User;
  children: React.ReactNode;
  location: any;
};
type States = {};

class ApplicationBody extends Component<Props, States> {
  componentDidMount() {}

  render() {
    if (!this.props.user) {
      return (
        <Redirect
          to={{
            pathname: `/login/${encodeURIComponent(
              this.props.location.pathname
            )}`,
            state: { from: this.props.location }
          }}
        />
      );
    } else {
      return (
        <>
          <div className="application-body">
            <ApplicationHeader />
            <div className="application-content">{this.props.children}</div>
          </div>

          {this.props.user.mandator_info.apps &&
          this.props.user.mandator_info.apps.length > 1 ? (
            <AppDrawer userAsOverlay={true} />
          ) : null}
        </>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  // loginUser: (username:string, password:string, onSuccess:(data:LoginResponse) => void, onError:(err:any) => void) => { dispatch(loginUser(username, password, onSuccess, onError) )}
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(GlobalActions.setUserData(user, apps, permissions)) }
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationBody);

// withRouter()withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ApplicationContainer);
