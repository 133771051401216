import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import ContextMenuComponent from "../../../components/ContextMenuComponent/ContextMenuComponent";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import BFUseTooltip from "../../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import ExpressionHelper from "../../../modules/generic-forms/util/ExpressionHelper";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import Tools from "../../../utils/Tools";
import "./LogEntry.scss";
import { LogInterface } from "./LogModel";

type Props = {
  appRoute: string;
  logEntry: LogInterface;
  parentNode: HTMLElement;
  userContextMenu?: {
    textKey: string;
    route: string;
    icon?: BfIconProps;
    params: { [key: string]: any };
    condition?: string;
  }[];
  targetContextMenu?: {
    textKey: string;
    route: string;
    icon?: BfIconProps;
    params: { [key: string]: any };
    condition?: string;
  }[];
  dataContextMenu?: {
    textKey: string;
    route: string;
    icon?: BfIconProps;
    params: { [key: string]: any };
    condition?: string;
  }[];
} & AbstractStylableProps &
  RouteComponentProps;

type States = {} & AbstractStylableStates;

let calendarFormat = null;

const getCalendarFormat = () => {
  if (calendarFormat) {
    return calendarFormat;
  }
  calendarFormat = {
    sameDay:
      "[" +
      (window as any).translate("Global.Date.today") +
      "] - " +
      (window as any).translate("Formats.timeFormat"),
    nextDay:
      "[" +
      (window as any).translate("Global.Date.tomorrow") +
      "] - " +
      (window as any).translate("Formats.timeFormat"),
    nextWeek: "dddd - " + (window as any).translate("Formats.timeFormat"),
    lastDay:
      "[" +
      (window as any).translate("Global.Date.yesterday") +
      "] - " +
      (window as any).translate("Formats.timeFormat"),
    lastWeek: "DD.MM.YYYY - " + (window as any).translate("Formats.timeFormat"),
    sameElse: "DD.MM.YYYY - " + (window as any).translate("Formats.timeFormat")
  };

  return calendarFormat;
};

class LogEntry extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  ref;

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //getSnapshotBeforeUpdate(prevProps:Props, prevState:States) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  getFields() {
    const { logEntry } = this.props;

    const user = logEntry.from.name
      ? logEntry.from.name
      : (window as any).translate("Log.Entries.Labels.deletedUser");
    const target = logEntry.info.name;
    let data = null;

    let translationKey = "default";
    let icon = "";

    switch (logEntry.oType) {
      case "asset":
        switch (logEntry.type) {
          case "update":
            translationKey = "Asset.update";
            break;
          case "create":
            translationKey = "Asset.create";
            break;
          case "delete":
            translationKey = "Asset.delete";
            break;
          default:
            translationKey = "Asset.default";
        }
        break;
      case "user":
        switch (logEntry.type) {
          case "update":
            translationKey = "User.update";
            icon = "single-man-actions-edit-1";
            break;
          case "create":
            translationKey = "User.create";
            icon = "single-man-actions-add";
            break;
          case "delete":
            translationKey = "User.delete";
            icon = "single-neutral-actions-subtract";
            break;
          case "addGroup":
            translationKey = "User.addGroup";
            icon = "single-man-actions-edit-1";
            data = logEntry.data.groupname;
            break;
          case "removeGroup":
            translationKey = "User.removeGroup";
            icon = "single-man-actions-edit-1";
            data = logEntry.data.groupname;
            break;
          case "registerUser":
            translationKey = "User.registerUser";
            icon = "single-man-actions-add";
            break;
          case "setStatus":
            translationKey = "User.setStatus";
            icon = "single-man-actions-edit-1";
            data = (window as any).translate(
              "User.Status." + logEntry.data.status
            );
            break;
          case "setPassword":
            translationKey = "User.setPassword";
            icon = "single-man-actions-edit-1";
            break;
          default:
            translationKey = "User.default";
        }
        break;
      case "group":
        switch (logEntry.type) {
          case "update":
            translationKey = "Group.update";
            icon = "multiple-actions-edit-1";
            break;
          case "create":
            translationKey = "Group.create";
            icon = "multiple-users-add";
            break;
          case "delete":
            translationKey = "Group.delete";
            icon = "multiple-actions-remove";
            break;
          case "addUser":
            translationKey = "Group.addUser";
            data = logEntry.data.displayname;
            icon = "multiple-actions-edit-1";
            break;
          case "removeUser":
            translationKey = "Group.removeUser";
            data = logEntry.data.displayname;
            icon = "multiple-actions-edit-1";
            break;
          default:
            translationKey = "Group.default";
        }
        break;
    }

    return {
      translationKey: "Log.Entries." + translationKey,
      icon: icon,
      data: data
    };
  }

  renderDescription(fields: {
    translationKey: string;
    icon: string;
    data?: any;
  }) {
    const { logEntry } = this.props;

    const user = logEntry.from.name
      ? logEntry.from.name
      : (window as any).translate("Log.Entries.Labels.deletedUser");
    const target = logEntry.info.name;
    const data = fields.data;

    return (
      <Trans i18nKey={fields.translationKey}>
        prefix
        <ContextMenuComponent
          disabled={this.props.logEntry.from.name === undefined}
          container={() => {
            return this.props.parentNode;
          }}
          contextEntries={this.getContextEntries(this.props.userContextMenu)}
          className={`user ${
            this.props.logEntry.from.name && this.props.userContextMenu
              ? "interactable"
              : ""
          }`}
        >
          {{ user }}
        </ContextMenuComponent>
        action+
        <ContextMenuComponent
          contextEntries={this.getContextEntries(this.props.targetContextMenu)}
          container={() => {
            return this.props.parentNode;
          }}
          className={`target ${
            this.props.targetContextMenu ? "interactable" : ""
          }`}
        >
          {{ target }}
        </ContextMenuComponent>
        postfix
        {data ? (
          <ContextMenuComponent
            contextEntries={this.getContextEntries(this.props.dataContextMenu)}
            container={() => {
              return this.props.parentNode;
            }}
            className={`data ${
              this.props.dataContextMenu ? "interactable" : ""
            }`}
          >
            {{ data }}
          </ContextMenuComponent>
        ) : null}
        postPostFix
      </Trans>
    );
  }

  goToRoute(routeString: string, params: { [key: string]: any }) {
    const usedParams = {
      ...params,
      from: this.props.logEntry.from,
      target: this.props.logEntry.info,
      appRoute: this.props.appRoute,
      data: this.props.logEntry.data,
      logEntry: this.props.logEntry
    };

    this.props.history.push(
      ExpressionHelper.evaluateExpression(routeString, usedParams)
    );
  }

  getContextEntries(entries) {
    const usedParams = {
      from: this.props.logEntry.from,
      target: this.props.logEntry.info,
      appRoute: this.props.appRoute,
      data: this.props.logEntry.data,
      logEntry: this.props.logEntry
    };

    if (entries) {
      return entries
        .filter(entry =>
          entry.condition
            ? ExpressionHelper.evaluateExpression(entry.condition, usedParams)
            : true
        )
        .map(entry => {
          return {
            textKey: entry.textKey,
            icon: entry.icon,
            onClick: () => this.goToRoute(entry.route, entry.params)
          };
        });
    } else {
      return undefined;
    }
  }

  render() {
    const { logEntry } = this.props;
    // logEntry.oType : group user asset
    //logEntry.type: update delete create - specific ones
    //logEntry.from -> .name .id
    //logEntry.info -> .name .id
    const timestamp = Tools.momentFromObjectId(logEntry._id);

    const fields = this.getFields();
    return (
      <div
        className={`log-entry`}
        ref={ref => {
          this.ref = ref;
        }}
      >
        <div className={`left-container`}>
          <AvatarComponent
            renderEmpty={true}
            avatar={logEntry.from.avatar}
            displayName={logEntry.from.name}
            size="md"
          />
          {fields.icon ? (
            <BfIcon data={fields.icon} type="bf" size="xl" />
          ) : null}
        </div>
        <div className={`content`}>
          <div className={`description`}>{this.renderDescription(fields)}</div>
          <BFUseTooltip
            placement="bottomStart"
            tooltip={timestamp.calendar(null, getCalendarFormat())}
            container={() => {
              return this.props.parentNode;
            }}
          >
            <div className={`timestamp`}>{timestamp.fromNow()}</div>
          </BFUseTooltip>
        </div>
        <div className={`actions`}>
          <DebugDataComponent data={logEntry} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  appRoute: state.uiConfig.activeApplication
    ? state.uiConfig.activeApplication.name
    : null,
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null
});

export default connect(mapStateToProps, {})(withRouter(LogEntry));
