import React, { Component } from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Loader } from "rsuite";
import "./TableLoader.scss";

type Props = {
  loading: boolean;
  loadingText: string;
} & RouteComponentProps &
  WithTranslation;

type States = {};

class TableLoader extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const { loading, loadingText } = this.props;
    return (
      <div className={`table-loader ${loading ? "active" : ""}`} style={{}}>
        <Loader inverse size="md" content={loadingText} />
      </div>
    );
  }
}

export default TableLoader;
