import setFieldData from "final-form-set-field-data";
import _ from "lodash";
import React, { CSSProperties } from "react";
import { Form, FormSpy } from "react-final-form";
import DebugDataComponent from "../../debug/DebugDataComponent";
import { SubmitMessage, SubmitResponse } from "../../services/SubmitService";
import { AbstractComponent } from "../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../utils/Constants";
import { Properties } from "../../utils/Properties";
import { BaseElement } from "./BaseElement";
import JsonValidation, { JsonProperties } from "./util/JsonValidation";
import JsonValidationException from "./util/JsonValidationException";

export interface FormDefinition {
	properties: JsonProperties;
	layout: { [key: string]: any };
}

type Props = {
	id?: string;
	formDefinition: FormDefinition;
	formValue?: any;
	additionalData?: any;
	onFormSubmit: (data) => void;
	onFormCancel: () => void;
	translateFunc: (key) => string;

	style?: CSSProperties;
};

type States = {
	warning: { [key: string]: string };
};

class GenericForms extends AbstractComponent<Props, States> {
	oldState = {
		hasValidationErrors: null
	};
	warningFields = [];
	formProps;

	constructor(props) {
		super(props);
		this.state = {
			warning: {}
		};
	}
	componentDidMount(): void {
		this.subscribe(DataBusSubKeys.SUBMIT, (data: SubmitMessage) => {
			this.populateButtonState("submit", {
				loading: true
			});
		});

		this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, (data: SubmitResponse) => {
			this.populateButtonState("submit", {
				loading: false
			});
		});

		this.subscribeActionEvent("submit", actionData => {
			this.callSubmit();
		});

		this.populateButtonState("submit", { hidden: false });
	}

	onSubmit(data) {
		this.props.onFormSubmit(data);
	}

	callSubmit() {
		(this.formProps as any).form.submit();
	}

	validate(values) {
		const { formDefinition } = this.props;

		let validationResult;
		try {
			validationResult = JsonValidation.validateJson(values, formDefinition.properties);
		} catch (error) {
			if (error instanceof JsonValidationException) {
				return {
					[(error as JsonValidationException).getKey()]: (error as JsonValidationException).getMessage()
				};
			}
			throw error;
		}

		if (!_.isEqual(this.state.warning, validationResult.warning)) {
			this.setState({ warning: validationResult.warning });
		}

		return validationResult.error;
	}

	mutateWarnings(props) {
		const warnedKeys = [];
		Object.keys(this.state.warning).forEach(key => {
			warnedKeys.push(key);
			props.form.mutators.setFieldData(key, {
				warning: this.state.warning[key]
			});
		});

		this.warningFields.forEach(key => {
			if (warnedKeys.indexOf(key) === -1) {
				props.form.mutators.setFieldData(key, {
					warning: undefined
				});
			}
		});
		this.warningFields = warnedKeys;

		// this.warningFields
		return null;
	}

	render() {
		const { formDefinition, formValue, translateFunc, style } = this.props;

		return (
			<Form
				mutators={{
					getIdentifier: () => {
						return this.props.identifier;
					},
					translateFunc,
					setFieldData,
					setValue: ([field, value], state, { changeValue }) => {
						if (state.formState.values) {
							changeValue(state, field, () => value);
						}
					}
				}}
				onSubmit={values => this.onSubmit(values)}
				validate={values => this.validate(values)}
				initialValues={
					formValue
						? {
								_id: formValue._id,
								...JsonValidation.reduceToValidInput(formValue, formDefinition.properties)
						  }
						: null
				}
				render={props => {
					this.formProps = props;
					return (
						<form
							className={`generic-forms`}
							style={{
								width: "100%",
								height: "fit-content",
								position: "relative",
								...style
							}}
							onSubmit={props.handleSubmit}
						>
							<>
								{Object.values(formDefinition.layout).map((item, index) => (
									<BaseElement key={index} {...item} allProperties={formDefinition.properties} formProps={props} />
								))}
								{this.mutateWarnings(props)}
								{Properties.debugMode ? (
									<div className="row-no-gutters">
										<FormSpy subscription={{ values: true }}>
											{({ values }) => <DebugDataComponent text={"form-data"} data={values} />}
										</FormSpy>
									</div>
								) : null}
							</>
						</form>
					);
				}}
			/>
		);
	}
}

export default GenericForms;
