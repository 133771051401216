import React, { Component } from "react";
import { connect } from "react-redux";
import { Popover } from "rsuite";
import { AppState } from "../../../../redux/store";
import BfIcon from "../../icon/BfIcon";
import BFWhisper from "../whisper/BFWhisper";
import "./ValidationPopover.scss";

type Props = {
  message?: string;
  level: "warning" | "error";
  marginTop?: number;
};

type States = {};

class ValidationPopover extends Component<Props, States> {
  ref;

  static defaultProps = {};

  readonly state: States = {};

  render() {
    return (
      <BFWhisper
        placement="bottomStart"
        open={this.props.level === "error" && this.props.message ? true : false}
        speaker={this.renderSpeaker()}
        container={this.ref}
      >
        <>
          <div
            style={{ position: "relative", width: "100%" }}
            ref={ref => {
              this.ref = ref;
              // let current = ref as HTMLElement;
              // if(current) {
              //
              //     while(current !== null && !current.classList.contains("app-layout-content") && (current.style.position !== "relative" || (current.style.overflow !== "auto" && current.style.overflowY !== "auto"))) {
              //         current = current.parentElement;
              //     }
              // }
              // this.ref = current || ref;
            }}
          >
            {this.props.children}
          </div>
          {this.props.level === "warning" && this.props.message ? (
            <div className={`validation-below ${this.props.level}`}>
              <div>
                <BfIcon size={"lg"} data={"exclamation-circle"} />
              </div>
              <div className={"message"}>{this.props.message}</div>
            </div>
          ) : null}
        </>
      </BFWhisper>
    );
  }

  renderSpeaker() {
    return (
      <Popover
        style={{ marginTop: this.props.marginTop }}
        className={`validation-popover ${this.props.level}`}
      >
        <div className={"message"}>{this.props.message}</div>
      </Popover>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});
export default connect(mapStateToProps, {})(ValidationPopover);
