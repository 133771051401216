import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { AppState } from "../../../redux/store";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { IComponent } from "../IComponent";

type Props = {
  emptyComponent: IComponent;
  detailsComponent: IComponent;
} & WithTranslation &
  RouteComponentProps;

type States = {};

class DetailsRouteComponent extends Component<Props, States> {
  static defaultProps = {};

  readonly state: States = {};

  render() {
    const {
      emptyComponent,
      detailsComponent,
      match,
      location,
      i18n
    } = this.props;

    return (
      <Switch>
        {emptyComponent ? (
          <Route exact path={`${match.url}`}>
            {ComponentsMapper.createElement(emptyComponent)}
          </Route>
        ) : null}
        <Route path={`${match.url}/:detailsId`}>
          {ComponentsMapper.createElement(detailsComponent)}
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});
export default connect(
  mapStateToProps,
  {}
)(withTranslation()(withRouter(DetailsRouteComponent)));
