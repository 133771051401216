import { FormDefinition } from "../../../modules/generic-forms/GenericForms";

export const StandardForms: { [key: string]: FormDefinition } = {
	resetPassword: {
		properties: {
			email: {
				type: "text",
				label: "Email",
				labelPosition: "top",
				submitType: "add",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "email",
						level: "error",
						text: "Keine gültige E-Mail Adresse"
					}
				]
			},
			mandator: {
				type: "text",
				submitType: "add",
				label: "Organisation",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			a1: {
				type: "div",
				style: {
					display: "flex",
					flexDirection: "column"
				},
				items: {
					title1: {
						type: "label",
						textStyle: "label",
						textKey: "views.forgotPassword.Title",
						style: {
							marginBottom: 10,
							color: "#333",
							fontSize: "1.4em"
						}
					},
					desc1: {
						type: "label",
						textStyle: "label",
						textKey: "views.forgotPassword.Description",
						style: {
							marginBottom: 10,
							color: "#999"
						}
					},
					a4: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "mandator"
							}
						}
					},
					a5: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "email"
							}
						}
					}
				}
			}
		}
	},
	setNewPasswordWithToken: {
		properties: {
			type: {
				type: "string",
				submitType: "ignore"
			},
			token: {
				type: "string",
				submitType: "add",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			mandator: {
				type: "string",
				submitType: "add",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			password: {
				type: "password",
				label: "Passwort",
				autoComplete: "new-password",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			passwordRetype: {
				type: "password",
				label: "Passwort wiederholen",
				autoComplete: "new-password",
				labelPosition: "top",
				submitType: "ignore",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "condition",
						param: "${password} === ${passwordRetype}",
						level: "error",
						text: "Passwörter stimmen nicht überein"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			a1: {
				type: "div",
				style: {
					display: "flex",
					flexDirection: "column"
				},
				items: {
					title1: {
						condition: "${type} !== 'reset_password'",
						type: "label",
						textStyle: "label",
						textKey: "views.completeRegistration.Title.added_displayname",
						style: {
							marginBottom: 10,
							color: "#333",
							fontSize: "1.4em"
						}
					},
					title2: {
						condition: "${type} === 'reset_password'",
						type: "label",
						textStyle: "label",
						textKey: "views.completeRegistration.Title.reset_password",
						style: {
							marginBottom: 10,
							color: "#333",
							fontSize: "1.4em"
						}
					},
					desc1: {
						condition: "${type} !== 'reset_password'",
						type: "label",
						textStyle: "label",
						textKey: "views.completeRegistration.Description.added_displayname",
						style: {
							marginBottom: 10,
							color: "#999"
						}
					},
					desc2: {
						condition: "${type} === 'reset_password'",
						type: "label",
						textStyle: "label",
						textKey: "views.completeRegistration.Description.reset_password",
						style: {
							marginBottom: 10,
							color: "#999"
						}
					},
					a4: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "password"
							}
						}
					},
					a5: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "passwordRetype"
							}
						}
					}
				}
			}
		}
	},
	setNewPassword: {
		properties: {
			password: {
				type: "password",
				label: "Passwort",
				autoComplete: "new-password",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			passwordRetype: {
				type: "password",
				label: "Passwort wiederholen",
				autoComplete: "new-password",
				labelPosition: "top",
				submitType: "ignore",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "condition",
						param: "${password} === ${passwordRetype}",
						level: "error",
						text: "Passwörter stimmen nicht überein"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			a1: {
				type: "div",
				style: {
					display: "flex",
					flexDirection: "column"
				},
				items: {
					desc: {
						type: "label",
						textStyle: "label",
						textKey: "UserService.SetPassword.description",
						style: {
							marginBottom: 10,
							color: "#999"
						}
					},
					a4: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "password"
							}
						}
					},
					a5: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "passwordRetype"
							}
						}
					}
				}
			}
		}
	},
	setUserEmail: {
		properties: {
			email: {
				type: "text",
				label: "Neue E-Mail Adresse",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			a1: {
				type: "div",
				style: {
					display: "flex",
					flexDirection: "column"
				},
				items: {
					desc: {
						type: "label",
						textStyle: "label",
						textKey: "UserService.SetUserEmail.description",
						style: {
							marginBottom: 10,
							color: "#999"
						}
					},
					a4: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "email"
							}
						}
					}
				}
			}
		}
	},
	selfProfile: {
		properties: {
			avatar: {
				type: "avatar",
				displaynameField: "displayname",
				size: "flex"
			},
			displayname: {
				type: "text",
				label: "Anzeigename",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			email: {
				type: "text",
				label: "Email",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "email",
						level: "error",
						text: "Keine gültige E-Mail Adresse"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			form: {
				type: "purposeChooser",
				id: "selfProfileFormPurpose",
				handleSubmitId: "self-profile-form",
				actionIdMapping: {
					submit: "selfProfileFormPurpose-submit",
					edit: "selfProfileFormPurpose-edit",
					abort: "selfProfileFormPurpose-abort"
				},
				layoutReadonly: {
					a1: {
						type: "div",
						style: [
							{
								display: "flex",
								alignItems: "center"
							}
						],
						items: {
							a1: {
								type: "div",
								style: [
									{
										paddingRight: 10,
										width: 100
									},
									{
										breakpoint: 400,
										width: 75
									}
								],
								items: {
									el: {
										type: "showAvatar",
										size: "flex",
										displaynameField: "displayname",
										avatarField: "avatar"
									}
								}
							},
							a2: {
								type: "div",
								style: [
									{
										flexGrow: 1
									}
								],
								items: {
									a1: {
										type: "col",
										items: {
											a0: {
												type: "field-table-view",
												items: ["displayname", "email"],
												style: {}
											}
										}
									}
								}
							}
						}
					}
				},
				layoutEdit: {
					a1: {
						type: "div",
						style: [
							{
								display: "flex",
								alignItems: "center"
							}
						],
						items: {
							a1: {
								type: "div",
								style: [
									{
										paddingRight: 10,
										width: 100
									},
									{
										breakpoint: 400,
										width: 75
									}
								],
								items: {
									el: {
										type: "property",
										name: "avatar"
									}
								}
							},
							a2: {
								type: "div",
								style: [
									{
										flexGrow: 1
									}
								],
								items: {
									a0: {
										type: "field-table-view",
										editable: ["displayname"],
										items: ["displayname", "email"],
										style: {}
									}
								}
							}
						}
					}
				}
			}
		}
	},
	userProfile: {
		properties: {
			status: {
				type: "select",
				label: "Status",
				submitType: "ignore",
				options: [
					{
						label: "active",
						value: 0
					},
					{
						label: "inactive",
						value: 1
					},
					{
						label: "locked",
						value: 2
					},
					{
						label: "waitingForActivation",
						value: 3
					},
					{
						label: "deactivated",
						value: 4
					}
				]
			},
			id: {
				type: "number",
				label: "ID",
				submitType: "ignore"
			},
			avatar: {
				type: "avatar",
				displaynameField: "displayname",
				size: "flex"
			},
			displayname: {
				type: "text",
				label: "Anzeigename",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			email: {
				type: "text",
				label: "Email",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "email",
						level: "error",
						text: "Keine gültige E-Mail Adresse"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			form: {
				type: "purposeChooser",
				id: "userProfileFormPurpose",
				handleSubmitId: "user-profile-form",
				actionIdMapping: {
					submit: "userProfileFormPurpose-submit",
					edit: "userProfileFormPurpose-edit",
					abort: "userProfileFormPurpose-abort"
				},
				layoutReadonly: {
					a1: {
						type: "div",
						style: [
							{
								display: "flex",
								alignItems: "center"
							}
						],
						items: {
							a1: {
								type: "div",
								style: [
									{
										paddingRight: 10,
										width: 100
									},
									{
										breakpoint: 400,
										width: 75
									}
								],
								items: {
									el: {
										type: "showAvatar",
										size: "flex",
										displaynameField: "displayname",
										avatarField: "avatar"
									}
								}
							},
							a2: {
								type: "div",
								style: [
									{
										flexGrow: 1
									}
								],
								items: {
									a1: {
										type: "col",
										items: {
											a0: {
												type: "field-table-view",
												items: ["id", "displayname", "email", "status"],
												style: {}
											}
										}
									}
								}
							}
						}
					}
				},
				layoutEdit: {
					a1: {
						type: "div",
						style: [
							{
								display: "flex",
								alignItems: "center"
							}
						],
						items: {
							a1: {
								type: "div",
								style: [
									{
										paddingRight: 10,
										width: 100
									},
									{
										breakpoint: 400,
										width: 75
									}
								],
								items: {
									el: {
										type: "property",
										name: "avatar"
									}
								}
							},
							a2: {
								type: "div",
								style: [
									{
										flexGrow: 1
									}
								],
								items: {
									a0: {
										type: "field-table-view",
										editable: ["displayname"],
										items: ["id", "displayname", "email", "status"],
										style: {}
									}
								}
							}
						}
					}
				}
			}
		}
	},
	userPublic: {
		properties: {
			name: {
				type: "text",
				label: "Vorname",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			lastname: {
				type: "text",
				label: "Nachname",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			department: {
				type: "text",
				label: "Abteilung",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			jobPosition: {
				type: "text",
				label: "Position",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			phone: {
				type: "text",
				label: "Telefonnummer",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			form: {
				type: "purposeChooser",
				id: "userPublicDataFormPurpose",
				handleSubmitId: "user-public-form",
				actionIdMapping: {
					submit: "userPublicDataFormPurpose-submit",
					edit: "userPublicDataFormPurpose-edit",
					abort: "userPublicDataFormPurpose-abort"
				},
				layoutReadonly: {
					a1: {
						type: "div",
						style: [
							{
								display: "flex"
							}
						],
						items: {
							a1: {
								type: "div",
								style: [
									{
										flexGrow: 1
									}
								],
								items: {
									a1: {
										type: "col",
										items: {
											a0: {
												type: "field-table-view",
												items: ["name", "lastname", "department", "jobPosition", "phone"],
												style: {}
											}
										}
									}
								}
							}
						}
					}
				},
				layoutEdit: {}
			}
		}
	},
	user: {
		properties: {
			avatar: {
				type: "text",
				label: "Anzeigename",
				labelPosition: "top"
			},
			displayname: {
				type: "text",
				label: "Anzeigename",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			email: {
				type: "text",
				label: "Email",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "email",
						level: "error",
						text: "Keine gültige E-Mail Adresse"
					}
				]
			},
			username: {
				type: "text",
				label: "Benutzername",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			firstame: {
				type: "text",
				label: "Vorname",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			lastname: {
				type: "text",
				label: "Nachname",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			}
		},
		layout: []
	},
	userCreation: {
		properties: {
			email: {
				type: "text",
				label: "Email",
				labelPosition: "top",
				autoCapitalize: "off",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "email",
						level: "error",
						text: "Keine gültige E-Mail Adresse"
					}
				]
			},
			displayname: {
				type: "text",
				label: "Anzeigename",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			withPassword: {
				type: "checkbox",
				label: "Passwort setzen",
				submitType: "ignore",
				labelPosition: "top"
			},
			password: {
				type: "password",
				label: "Passwort",
				autoComplete: "new-password",
				labelPosition: "top",
				condition: "${withPassword} === true",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			passwordRetype: {
				type: "password",
				label: "Passwort wiederholen",
				autoComplete: "new-password",
				labelPosition: "top",
				condition: "${withPassword} === true",
				submitType: "ignore",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "condition",
						param: "${password} === ${passwordRetype}",
						level: "error",
						text: "Passwörter stimmen nicht überein"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			a1: {
				type: "div",
				style: {
					display: "flex",
					flexDirection: "column"
				},
				items: {
					a1: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "email"
							}
						}
					},
					a2: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "displayname"
							}
						}
					},
					a3: {
						type: "div",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "withPassword"
							}
						}
					},
					desc: {
						type: "label",
						condition: "${withPassword} === true",
						textStyle: "label",
						textKey: "Application.Administration.User.Creation.describeSetPassword",
						style: {
							marginBottom: 10,
							color: "#999"
						}
					},
					a4: {
						type: "div",
						condition: "${withPassword} === true",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "password"
							}
						}
					},
					a5: {
						type: "div",
						condition: "${withPassword} === true",
						style: {
							marginBottom: 10
						},
						items: {
							a: {
								type: "property",
								name: "passwordRetype"
							}
						}
					}
				}
			}
		}
	},
	group: {
		properties: {
			_id: {
				type: "string",
				label: "internalId"
			},
			id: {
				type: "number",
				label: "Gruppen-ID"
			},
			name: {
				type: "text",
				label: "Gruppen-Name",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					},
					{
						type: "maxLength",
						level: "error",
						text: "Nicht mehr als 50 Zeichen erlaubt",
						param: 50
					}
				]
			},
			permissions: {
				type: "permissions",
				label: "Rechte"
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			form: {
				type: "purposeChooser",
				id: "groupFormPurpose",
				handleSubmitId: "group-form",

				actionIdMapping: {
					submit: "groupFormPurpose-submit",
					edit: "groupFormPurpose-edit",
					abort: "groupFormPurpose-abort"
				},

				layoutReadonly: {
					a0: {
						type: "field-table-view",
						items: ["id", "name"],
						style: {
							marginBottom: 15
						}
					},
					a1: {
						type: "row",
						items: {
							a1: {
								type: "col",
								items: {
									a1: {
										type: "property",
										name: "permissions",
										readonly: true
									}
								}
							}
						}
					}
				},
				layoutEdit: {
					warning: {
						condition: "(#{userProps}.groups.indexOf(${_id}) !== -1)",
						type: "message",
						messageType: "warning",
						textKey: "Application.Administration.Group.Form.warningUserGroup",
						showIcon: true,
						style: {
							marginBottom: 20
						}
					},
					a0: {
						type: "row",
						items: {
							a1: {
								type: "col",
								items: {
									a1: {
										type: "property",
										name: "name"
									}
								}
							}
						}
					},
					a1: {
						type: "row",
						items: {
							a1: {
								type: "col",
								items: {
									a1: {
										type: "property",
										name: "permissions"
									}
								}
							}
						}
					}
				}
			}
		}
	},
	groupCreation: {
		properties: {
			name: {
				type: "text",
				label: "",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			a0: {
				type: "row",
				items: {
					a1: {
						type: "col",
						items: {
							a1: {
								type: "label",
								textStyle: "p",
								text: "Bitte geben Sie den Namen der Gruppe ein, die Sie hinzufügen wollen:"
							}
						}
					}
				}
			},
			a1: {
				type: "row",
				items: {
					a1: {
						type: "col",
						items: {
							a1: {
								type: "property",
								name: "name"
							}
						}
					}
				}
			}
		}
	},
	organizationView: {
		properties: {
			"publicData|name": {
				type: "text",
				label: "Firmenname",
				labelPosition: "top",
				validators: [
					{
						type: "required",
						level: "error",
						text: "Pflichtfeld"
					}
				]
			},
			"privateData|street": {
				type: "text",
				label: "Straße/Hnr.",
				labelPosition: "top"
			},
			"privateData|logo": {
				type: "image"
			},
			"privateData|postalcode": {
				type: "text",
				label: "Postleitzahl",
				labelPosition: "top"
			},
			"privateData|city": {
				type: "text",
				label: "Stadt",
				labelPosition: "top"
			},
			"privateData|phoneNumber": {
				type: "text",
				label: "Telefonnummer",
				labelPosition: "top"
			},
			"privateData|faxNumber": {
				type: "text",
				label: "Fax",
				labelPosition: "top"
			},
			"privateData|email": {
				type: "mail",
				label: "Email",
				labelPosition: "top"
			},
			"privateData|website": {
				type: "website",
				label: "Website",
				labelPosition: "top"
			},
			"privateData|supportHotline": {
				type: "text",
				label: "Hotline",
				labelPosition: "top"
			},
			"privateData|supportEmail": {
				type: "text",
				label: "Email",
				labelPosition: "top"
			},
			"privateData|quickLinks": {
				type: "objects",
				label: "Quicklinks",
				labelPosition: "top"
			}
		},
		layout: {
			prompt: {
				type: "prompt"
			},
			form: {
				type: "purposeChooser",
				id: "userPublicDataFormPurpose",
				handleSubmitId: "user-public-form",
				actionIdMapping: {
					submit: "userPublicDataFormPurpose-submit",
					edit: "userPublicDataFormPurpose-edit",
					abort: "userPublicDataFormPurpose-abort"
				},
				layoutReadonly: {
					a1: {
						type: "div",
						style: [
							{
								display: "flex"
							}
						],
						items: {
							a1: {
								type: "div",
								style: [
									{
										flexGrow: 1
									}
								],
								items: {
									a0: {
										type: "fieldValue",
										name: "publicData|name",
										style: {
											fontSize: "1.3em"
										}
									},
									a1: {
										type: "fieldValue",
										name: "privateData|street"
									},
									a2: {
										type: "div",
										style: {
											display: "flex"
										},
										items: {
											a0: {
												type: "fieldValue",
												name: "privateData|postalcode",

												style: {
													marginRight: "5px"
												}
											},
											a1: {
												type: "fieldValue",
												name: "privateData|city"
											}
										}
									},
									a3: {
										type: "fieldValue",
										name: "privateData|email"
									},
									a4: {
										type: "fieldValue",
										name: "privateData|website"
									}
								}
							}
						}
					}
				},
				layoutEdit: {}
			}
		}
	}
};
