import { CSSProperties } from "@emotion/serialize";
import { css } from "emotion";
import React, { Component, SyntheticEvent } from "react";
import { Input, InputGroup } from "rsuite";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";
import LabeledInput from "../LabeledInput";
import "./BFInput.scss";

type Props = {
	label?: string;
	labelPosition?: "top" | "left";
	tabIndex?: number;
	name?: string;
	type?: string;
	size?: "lg" | "md" | "sm" | "xs";
	onChange?: (value: string | number, ev: Event) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	disabled?: boolean;
	defaultValue?: string;
	className?: string;
	placeholder?: string;
	prefix?: React.ReactNode | string;
	suffix?: React.ReactNode | string;
	prefixOnClick?: () => void;
	suffixOnClick?: () => void;
	addonsInside?: boolean;
	value?: string;
	decimalFixed?: number;
	max?: number;
	min?: number;
	validation?: {
		message: string;
		level: "error" | "warning";
	};
	style?: CSSProperties;
	focusOnMount?: boolean;
	autoComplete?: string;
	autoCorrect?: string;
	autoCapitalize?: string;
	spellCheck?: string;
};

type States = {};

class BFInput extends Component<Props, States> {
	inputRef;

	componentDidMount() {
		if (this.props.focusOnMount) {
			this.inputRef.focus();
		}
	}

	render() {
		const {
			label,
			labelPosition,
			type,
			size,
			onChange,
			disabled,
			defaultValue,
			className,
			placeholder,
			prefix,
			suffix,
			suffixOnClick,
			prefixOnClick,
			addonsInside,
			value,
			decimalFixed,
			min,
			max,
			validation,
			onBlur,
			onFocus,
			tabIndex,
			style,
			autoComplete,
			name,
			autoCorrect,
			autoCapitalize,
			spellCheck
		} = this.props;

		let step = undefined;
		if (decimalFixed) {
			step = Math.pow(0.1, decimalFixed);
		}

		return (
			<LabeledInput label={label} labelPosition={labelPosition}>
				<ValidationPopover
					level={validation ? validation.level : "error"}
					message={validation ? validation.message : null}
					marginTop={0}
				>
					<InputGroup
						className={`bf-input ${className ? className : ""} ${type} ${style ? css(style as any) : ""}`}
						size={size}
						disabled={disabled}
						inside={addonsInside}
					>
						{prefix && prefixOnClick ? (
							<InputGroup.Button onClick={prefixOnClick}>{prefix}</InputGroup.Button>
						) : prefix && !prefixOnClick ? (
							<InputGroup.Addon>{prefix}</InputGroup.Addon>
						) : null}
						{
							// type !== "number" ? (
							<Input
								autoComplete={autoComplete}
								autoCorrect={autoCorrect}
								autoCapitalize={autoCapitalize}
								spellCheck={spellCheck}
								inputRef={input => (this.inputRef = input)}
								tabIndex={tabIndex}
								type={type}
								name={name}
								placeholder={placeholder}
								value={value}
								onChange={(value: string, event: SyntheticEvent<HTMLElement, Event>) => {
									if (type === "number") {
										if (value === "") {
											onChange("", event.nativeEvent);
										} else {
											let number = Number(value);
											if (decimalFixed !== undefined) {
												number = Number(number.toFixed(decimalFixed));
											}
											onChange(number, event.nativeEvent);
										}
									} else {
										onChange(value, event.nativeEvent);
									}
									// onChange ? onChange(Number(value), event.nativeEvent) : null
								}}
								disabled={disabled}
								defaultValue={defaultValue}
								step={step}
								min={min}
								max={max}
								onBlur={onBlur}
								onFocus={onFocus}
							/>
							// ) : (
							//     <InputNumber
							//         placeholder={placeholder}
							//         value={value}
							//         onChange={(value: string, event: SyntheticEvent<HTMLElement, Event>) => onChange ? onChange(value, event.nativeEvent) : null}
							//         disabled={disabled}
							//         defaultValue={defaultValue}
							//         step={step}
							//         min={min}
							//         max={max}
							//         onBlur={onBlur}
							//         onFocus={onFocus}
							//         />
							// )
						}
						{suffix && suffixOnClick ? (
							<InputGroup.Button onClick={suffixOnClick}>{suffix}</InputGroup.Button>
						) : suffix && !suffixOnClick ? (
							<InputGroup.Addon>{suffix}</InputGroup.Addon>
						) : null}
					</InputGroup>
				</ValidationPopover>
			</LabeledInput>
		);
	}
}

export default BFInput;
