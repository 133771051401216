import { SurveyConf } from "./SurveyView";

export const surveys: { [key: string]: SurveyConf } = {
  ma9910872: {
    title: "Software für Immobilien-Makler",
    subtitle:
      "Umfrage zur Nutzung und Zufriedenheit mit eingesetzter Maklersoftware in ihrem Betrieb",
    formDefinition: {
      properties: {
        usingSoftware: {
          type: "radio",
          options: [
            {
              label: "Ja",
              value: true
            },
            {
              label: "Nein",
              value: false
            }
          ]
        },
        software: {
          type: "radio",
          condition: "${usingSoftware} === true",
          options: [
            {
              label: "OnOffice",
              value: "onoffice"
            },
            {
              label: "FLOWFACT",
              value: "flowfact"
            },
            {
              label: "Fio Makler",
              value: "fiomakler"
            },
            {
              label: "andere",
              value: "misc"
            }
          ]
        },
        softwareMisc: {
          type: "text",
          placeholder: "Name der Software",
          condition: "${software} === 'misc'"
        },
        softwareSatisfaction: {
          type: "rating",
          condition: "${usingSoftware} === true",
          colorRated: "#F3C90F",
          colorHover: "#DFB910",
          colorEmpty: "#888",
          starDimension: "30px",
          align: "center"
        },
        softwareSatisfactionDescr: {
          type: "text",
          condition: "${softwareSatisfaction} < 4",
          placeholder: "Was stört Sie an der Software?"
        },
        functions: {
          type: "gridcheck",
          condition: "${usingSoftware} === true",
          columns: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3
          },
          maxCheckedCount: 3,
          options: [
            {
              label: "Mobile App",
              value: "mobile",
              icon: {
                size: "2x",
                type: "bf",
                data: "mobile-phone-1"
              }
            },
            {
              label: "intergrierter E-Mail Client",
              value: "email",
              icon: {
                size: "2x",
                type: "bf",
                data: "email-action-unread"
              }
            },
            {
              label: "Anfragen aus Mails automatisiert bearbeiten",
              value: "automateMailrequests",
              icon: {
                size: "2x",
                type: "bf",
                data: "science-fiction-robot"
              }
            },
            {
              label: "Schnittstellen zu Immobilien Portalen",
              value: "immoApis",
              icon: {
                size: "2x",
                type: "bf",
                data: "real-estate-update-house-sync"
              }
            },
            {
              label: "Terminkalender",
              value: "calender",
              icon: {
                size: "2x",
                type: "bf",
                data: "calendar-1"
              }
            },
            {
              label: "Vertrags-Archiv / Dokumentenablage",
              value: "documents",
              icon: {
                size: "2x",
                type: "bf",
                data: "common-file-stack"
              }
            },
            {
              label: "Exposéerstellung",
              value: "expose",
              icon: {
                size: "2x",
                type: "bf",
                data: "real-estate-deal-document"
              }
            },
            {
              label: "Kartendarstellung",
              value: "gis",
              icon: {
                size: "2x",
                type: "bf",
                data: "maps-pin-1"
              }
            },
            {
              label: "Online-Präsenz",
              value: "website",
              icon: {
                size: "2x",
                type: "bf",
                data: "earth-3"
              }
            },
            {
              label: "Auswertungen",
              value: "evaluations",
              icon: {
                size: "2x",
                type: "bf",
                data: "pie-line-graph"
              }
            },
            {
              label: "Virtueller Rundgang",
              value: "virt360",
              icon: {
                size: "2x",
                type: "bf",
                data: "video-game-360-vr"
              }
            },
            {
              label: "Digitaler Vertragsabschluss",
              value: "digitalContract",
              icon: {
                size: "2x",
                type: "bf",
                data: "business-contract-handshake-sign"
              }
            }
          ]
        },

        changePossibility: {
          type: "rating",
          condition: "${usingSoftware} === true",
          colorRated: "#F3C90F",
          colorHover: "#DFB910",
          colorEmpty: "#888",
          starDimension: "30px",
          starSpacing: "0px",
          align: "center",
          svgIconPath: `M 100, 100
                                    m -75, 0
                                    a 75,75 0 1,0 150,0
                                    a 75,75 0 1,0 -150,0`,
          svgIconViewBox: "0 0 200 200",
          minIcon: {
            data: "dislike-1",
            type: "bf",
            size: "2x",
            style: {
              marginRight: 10
            }
          },
          maxIcon: {
            data: "like-1",
            type: "bf",
            size: "2x",
            style: {
              marginTop: -10,
              marginLeft: 10
            }
          }
        },

        changePossibilityReason: {
          type: "text",
          condition: "${changePossibility} < 4",
          placeholder: "Warum nicht?"
        },

        contact: {
          type: "checkbox-group",
          condition: "${usingSoftware} !== undefined",
          options: [
            {
              label:
                "Ich würde gerne über Neuigkeiten bezüglich des Produktes informiert werden.",
              value: "mews"
            },
            {
              label:
                "Ich möchte das Produkt bei Markteinführung <strong>kostenlos für 2 Monate</strong> testen.",
              value: "release"
            },
            {
              label:
                "Ich würde gerne unverbindlich an der Entwicklung des Produktes mitwirken, damit alle meine Anforderungen erfüllt werden.",
              value: "pilot"
            }
          ]
        },
        email: {
          type: "text",
          condition: "${contact} !== undefined && ${contact}.length !== 0",
          placeholder: "E-Mail",
          validators: [
            {
              type: "email",
              level: "error",
              text: "Dies ist keine gültige E-Mail Adresse"
            }
          ]
        },

        reasons: {
          type: "radio",
          condition: "${usingSoftware} === false",
          options: [
            {
              label: "Zu teuer",
              value: "expensive"
            },
            {
              label: "Zu kompliziert",
              value: "complex"
            },
            {
              label: "Sehe keinen Mehrwert",
              value: "noValue"
            }
          ]
        }
      },
      layout: {
        a1: {
          type: "div",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",

                  text:
                    "Benutzen Sie aktuell eine Software für Ihre Maklertätigkeiten?"
                },
                index1: {
                  type: "label",
                  textStyle: "label",
                  className: "index",
                  condition: "${usingSoftware} === false",
                  text: "Frage 1/3"
                },
                index2: {
                  type: "label",
                  textStyle: "label",
                  className: "index",
                  condition: "${usingSoftware} === true",
                  text: "Frage 1/6"
                }
              }
            },
            a: {
              type: "property",
              name: "usingSoftware"
            }
          }
        },
        a2: {
          type: "div",
          condition: "${usingSoftware} === true",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",

                  text: "Welche Software benutzen Sie dafür?"
                },
                index: {
                  type: "label",
                  textStyle: "label",
                  className: "index",

                  text: "Frage 2/6"
                }
              }
            },
            a: {
              type: "property",
              name: "software"
            },
            b: {
              type: "property",
              name: "softwareMisc",
              containerStyleProps: {
                marginTop: 10
              }
            }
          }
        },
        a3: {
          type: "div",
          condition: "${usingSoftware} === true",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",

                  text: "Wie zufrieden sind Sie mit der eingesetzten Software?"
                },
                index: {
                  type: "label",
                  textStyle: "label",
                  className: "index",

                  text: "Frage 3/6"
                }
              }
            },
            a: {
              type: "property",
              name: "softwareSatisfaction"
            },
            b: {
              type: "property",
              name: "softwareSatisfactionDescr",
              containerStyleProps: {
                marginTop: 10
              }
            }
          }
        },
        a4: {
          type: "div",
          condition: "${usingSoftware} === true",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",

                  text: "Welche 3 Funktionen sind für Sie am wichtigsten?"
                },
                index: {
                  type: "label",
                  textStyle: "label",
                  className: "index",

                  text: "Frage 4/6"
                }
              }
            },
            a: {
              type: "property",
              name: "functions"
            }
          }
        },
        a5: {
          type: "div",
          condition: "${usingSoftware} === true",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",
                  text:
                    "Würden Sie Ihre Maklersoftware wechseln, falls ein Produkt auf den Markt käme, welches Ihren Ansprüchen gerecht wird?"
                },
                index: {
                  type: "label",
                  textStyle: "label",
                  className: "index",
                  text: "Frage 5/6"
                }
              }
            },
            a: {
              type: "property",
              name: "changePossibility"
            },
            b: {
              type: "property",
              name: "changePossibilityReason",
              containerStyleProps: {
                marginTop: 10
              }
            }
          }
        },

        b1: {
          type: "div",
          condition: "${usingSoftware} === false",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",

                  text: "Warum nutzen Sie keine Software?"
                },
                index2: {
                  type: "label",
                  textStyle: "label",
                  className: "index",
                  text: "Frage 2/3"
                }
              }
            },
            a: {
              type: "property",
              name: "reasons"
            }
          }
        },

        end: {
          type: "div",
          condition: "${usingSoftware} !== undefined",
          className: "survey-box",
          items: {
            titleContainer: {
              type: "div",
              className: "question-header",

              items: {
                title: {
                  type: "label",
                  textStyle: "label",
                  className: "title",

                  text:
                    "Wir entwickeln aktuell eine Software für Immobilienmakler, welche Ihnen durch Simplizität und Automatisierung viel Arbeit abnimmt und erleichtert."
                },
                index1: {
                  type: "label",
                  textStyle: "label",
                  className: "index",
                  condition: "${usingSoftware} === false",
                  text: "Frage 3/3"
                },
                index2: {
                  type: "label",
                  textStyle: "label",
                  className: "index",
                  condition: "${usingSoftware} === true",
                  text: "Frage 6/6"
                }
              }
            },
            a: {
              type: "property",
              name: "contact"
            },
            b: {
              type: "property",
              name: "email",
              containerStyleProps: {
                marginTop: 10
              }
            }
          }
        },

        actions: {
          type: "div",
          condition: "${usingSoftware} !== undefined",
          className: "actions",
          items: {
            action: {
              type: "action",
              actionId: "survey-submit",
              appearance: "primary",
              text: "Absenden",
              icon: {
                data: "send-email",
                type: "bf"
              }
            }
          }
        }
      }
    }
  }
};
