import flatten from "flat";
import {
  SET_ACTIVE_APPLICATION,
  SET_UI_CONFIG,
  UIConfigAction
} from "../../actions/ui-config/ui-config-actions-types";
import { AppState } from "../../store";
import { UiConfigReducer } from "./UiConfigInterface";

export const DefaultUIConfigs = {
  NOTIFICATION_OVERLAY_ACTIVE: "notificationsOverlayActive",
  HEADER_TRANSPARENT: "headerTrasparent",
  HEADER_APP_DRAWER: "headerApplicationDrawer",
  APP_DRAWER_OVERLAY_ACTIVE: "appDrawerOverlayActive",
  VIEWPORT_WIDTH: "viewportWidth",
  VIEWPORT_HEIGHT: "viewportHeight",
  VIEWPORT_SIZE_SELECTOR: "viewportSizeSelector",
  SHOW_HEADER_LEFT_NODE: "showHeaderLeftNode",
  APPLICATION_IS_INITIALIZING: "applicationIsInitializing",
  BLOCK_UI_INPUT: "blockUiInput",
  HELP_DRAWER_ACTIVE: "helpDrawerActive",
  PROFILE_MODAL_ACTIVE: "ProfileModalActive",
  MOBILE_HEADER: "MobileHeader",
  DEBUG: "Debug",
  DEBUG_COMPONENTS: "DebugComponents",
  DEBUG_COLOR_THEME_EDITOR_ACTIVE: "DebugColorThemeEditorActive",
  AVAILABLE_LANGUAGES: "AvailableLanguages",
  SELECTED_LANGUAGE: "SelectedLanguage"
};

const initialState: UiConfigReducer = {
  general: {
    [DefaultUIConfigs.NOTIFICATION_OVERLAY_ACTIVE]: false,
    [DefaultUIConfigs.HEADER_TRANSPARENT]: false,
    [DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE]: false,
    [DefaultUIConfigs.HEADER_APP_DRAWER]: null,
    [DefaultUIConfigs.APPLICATION_IS_INITIALIZING]: null,
    [DefaultUIConfigs.BLOCK_UI_INPUT]: false,
    [DefaultUIConfigs.HELP_DRAWER_ACTIVE]: false,
    [DefaultUIConfigs.MOBILE_HEADER]: null,
    [DefaultUIConfigs.DEBUG]: false,
    [DefaultUIConfigs.DEBUG_COMPONENTS]: false,
    [DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE]: false,
    [DefaultUIConfigs.AVAILABLE_LANGUAGES]: [
      {
        selector: "de",
        flag: "DE",
        name: "Deutsch"
      },
      {
        selector: "en",
        flag: "GB",
        name: "English"
      }
    ]
  },
  activeApplication: null
};

export default function(
  state = initialState,
  action: UIConfigAction,
  root: AppState
): UiConfigReducer {
  switch (action.type) {
    case SET_UI_CONFIG:
      return {
        ...state,
        general: {
          ...state.general,
          [action.key]: action.value
        }
      };
    case SET_ACTIVE_APPLICATION:
      if (!action.application) {
        return {
          ...state,
          activeApplication: null
        };
      } else {
        return {
          ...state,
          activeApplication: {
            ...action.application,
            permissions: action.application
              ? flatten(
                  root.global.user.permissions.find(
                    app => app.APP === action.application._id
                  ),
                  { safe: true }
                )
              : {}
          }
        };
      }

    default:
      return state;
  }
}
