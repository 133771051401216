import { Notification } from "../../../model/notifications/Notification";

export const REGISTER_NOTIFICATION = `REGISTER_NOTIFICATION`;
export const TOGGLE_EXPAND_STATE = `TOGGLE_EXPAND_STATE`;
export const REMOVE_NOTIFICATION = `REMOVE_NOTIFICATION`;
export const CLEAR_NOTIFICATIONS = `CLEAR_NOTIFICATIONS`;

export const SEEN_ALL_NOTIFICATIONS = `SEEN_ALL_NOTIFICATIONS`;

interface RemoveNotificationAction {
	type: typeof REMOVE_NOTIFICATION;
	id: string;
}

interface ClearNotificationsAction {
	type: typeof CLEAR_NOTIFICATIONS;
}
interface SeenAllNotificationsAction {
	type: typeof SEEN_ALL_NOTIFICATIONS;
}

interface RegisterNotificationAction {
	type: typeof REGISTER_NOTIFICATION;
	notification: Notification;
}

interface NotificationToggleExpandAction {
	type: typeof TOGGLE_EXPAND_STATE;
	id: string;
	toggled: boolean;
}

export type NotificationsAction =
	| ClearNotificationsAction
	| SeenAllNotificationsAction
	| RemoveNotificationAction
	| RegisterNotificationAction
	| NotificationToggleExpandAction;

// setTimeout(() => {
//   DataBus.subscribeReduxActions([SET_USER_DATA]);
// });
