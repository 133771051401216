export const REGEX_WEBSITE = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i;

class ValidatorsUtilsClass {
  isWebsiteString(toTest: string) {
    return REGEX_WEBSITE.test(toTest);
  }
}

const ValidatorsUtils = new ValidatorsUtilsClass();

export default ValidatorsUtils;
