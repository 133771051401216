import React, { Component } from "react";

import "./BFRadioGroup.scss";
import { RadioGroup } from "rsuite";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";
import LabeledInput from "../LabeledInput";

type Props = {
  label?: string;
  labelPosition?: "top" | "left";

  appearance?: "default" | "picker";
  defaultValue?: any;
  inline?: boolean;
  name?: string;
  onChange?: (value: any, event: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value?: any;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
};

type States = {};

class BFRadioGroup extends Component<Props, States> {
  render() {
    let { label, labelPosition, validation, ...rest } = this.props;
    if (!labelPosition) {
      labelPosition = "top";
    }
    return (
      <LabeledInput label={label} labelPosition={labelPosition}>
        <ValidationPopover
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
        >
          <RadioGroup {...rest}>{this.props.children}</RadioGroup>
        </ValidationPopover>
      </LabeledInput>
    );
  }
}

export default BFRadioGroup;
