import React, { Component, SyntheticEvent } from "react";
import BfIcon from "./../../icon/BfIcon";
import { Animation } from "rsuite";
import "./BFAccordion.scss";

const { Collapse } = Animation;

type Props = {
  initialOpen?: boolean;
  title: string | React.ReactNode;
};

type States = {
  open: boolean;
};

class BFAccordion extends Component<Props, States> {
  constructor(props) {
    super(props);
    this.state = {
      open: props.initialOpen ? props.initialOpen : false
    };
  }

  render() {
    return (
      <div className="bf-accordion">
        <div
          className="accordion-header"
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <div className="title">{this.props.title}</div>
          <div className="indicator">
            <BfIcon
              className={`${this.state.open ? "rotate-180" : ""}`}
              data={"angle-down"}
            />
          </div>
        </div>
        <Collapse in={this.state.open}>
          <div>
            <div className="accordion-content">{this.props.children}</div>
          </div>
        </Collapse>
      </div>
    );
  }
}
// dirty fix: changing the display name in order to get the allProperties set by the checkbox group as intended ( https://github.com/rsuite/rsuite/blob/6095d1f61c8d014349d7e0e3d3e639f202e5fed5/src/CheckboxGroup.js#L67 )
(BFAccordion as any).displayName = "Checkbox";

export default BFAccordion;
