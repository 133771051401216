import { css } from "emotion";
import React, { CSSProperties, ReactNode, SyntheticEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import { AbstractComponent } from "../../../../utils/abstracts/AbstractComponent";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFButton.scss";

type Props = {
  active?: boolean;
  appearance?:
    | "default"
    | "primary"
    | "link"
    | "clear"
    | "outline"
    | "clear-on-white"
    | "clear-highlight"
    | "transparent-dark"
    | "transparent-light"
    | "debug";
  block?: boolean;
  children: ReactNode;
  componentClass?: React.ElementType;
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
  onClick?: (e: SyntheticEvent, params?: any) => void;
  className?: string;
  type?: string;
  circle?: boolean;
  textKey?: string;
  icon?: BfIconProps;
  iconPosition?: "left" | "right" | "top" | "bottom";
  tabIndex?: string;
  style?: CSSProperties;
  text: string;
} & WithTranslation;

type States = {};

class BFButton extends AbstractComponent<Props, States> {
  getAppearance(): "default" | "primary" | "link" | "subtle" {
    if (
      this.props.appearance === "clear" ||
      this.props.appearance === "clear-on-white" ||
      this.props.appearance === "clear-highlight" ||
      this.props.appearance === "transparent-dark" ||
      this.props.appearance === "transparent-light" ||
      this.props.appearance === "debug"
    ) {
      return "subtle";
    }
    if (this.props.appearance === "outline") {
      return "subtle";
    }
    return this.props.appearance;
  }

  renderContent() {
    const { i18n, children, text, textKey, icon, iconPosition } = this.props;
    if (children) {
      return children;
    }

    let iconEl = null;
    if (icon) {
      iconEl = React.createElement(BfIcon, icon);
    }
    if (textKey) {
      return (
        <div
          className={`label ${
            iconEl ? (iconPosition ? "icon-" + iconPosition : "icon-left") : ""
          }`}
        >
          {iconEl ? iconEl : null}
          {i18n.t(textKey)}
        </div>
      );
    } else if (text) {
      return (
        <div
          className={`label ${
            iconEl ? (iconPosition ? "icon-" + iconPosition : "icon-left") : ""
          }`}
        >
          {iconEl ? iconEl : null}
          {text}
        </div>
      );
    } else {
      return iconEl;
    }
  }

  render() {
    const {
      stateSubscriptions,
      tReady,
      i18n,
      textKey,
      icon,
      iconPosition,
      style,
      ...buttonProperties
    } = this.props;
    const buttonProps = {
      ...buttonProperties,
      className: `bf-button ${
        this.props.className ? this.props.className : ""
      }  ${this.props.appearance} ${style ? css(style as any) : ""}`,
      appearance: this.getAppearance(),
      href: undefined
    };
    (buttonProps as any).t = undefined;

    const onlyIcon =
      icon && !textKey && !this.props.text && !this.props.children;
    if (buttonProps.className) {
      buttonProps.className = buttonProps.className + " only-icon";
    } else {
      buttonProps.className = "only-icon";
    }
    const href = this.props.href;
    if (href) {
      const { className, ...restProps } = buttonProps;

      return (
        <Link
          to={href}
          style={{ width: "fit-content" }}
          innerRef={ref => {
            if (ref) {
              ref.tabIndex = -1;
            }
          }}
        >
          <Button
            {...restProps}
            className={className}
            onClick={e =>
              this.props.onClick
                ? this.props.onClick(
                    e,
                    this.state && this.state.params
                      ? this.state.params
                      : undefined
                  )
                : undefined
            }
          >
            {this.renderContent()}
          </Button>
        </Link>
      );
    } else {
      return (
        <Button
          {...buttonProps}
          onClick={e =>
            this.props.onClick
              ? this.props.onClick(
                  e,
                  this.state && this.state.params
                    ? this.state.params
                    : undefined
                )
              : undefined
          }
        >
          {this.renderContent()}
        </Button>
      );
    }
  }
}

export default withTranslation()(BFButton);
