import flatten from "flat";
import _ from "lodash";
import React from "react";
import { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Application } from "../../../model/db/Application";
import { UserConfig } from "../../../model/db/User";
import BFTable, {
	ColumnAction,
	ColumnProperties,
	ColumnState,
	PagingProperties,
	TableAddons,
	TableProperties
} from "../../../modules/abstract-ui/data/table/BFTable";
import ExpressionHelper from "../../../modules/generic-forms/util/ExpressionHelper";
import { TABLE_EVENT_TYPES } from "../../../redux/actions/application/application-action-types";
import { setTableEvent, setTableFilter, setTableSort } from "../../../redux/actions/application/application-actions";
import { updateUserConfig } from "../../../redux/actions/global/global-actions";
import { TableEvents } from "../../../redux/reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { emptyListData, Filter, MatchQuery, requestListData, RequestListOpts } from "../../../services/DataService";
import { SubmitResponse } from "../../../services/SubmitService";
import { SendEvent } from "../../../utils/abstracts/AbstractComponent";
import {
	AbstractStylableComponent,
	AbstractStylableProps,
	AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";
import { DataBusSubKeys } from "../../../utils/Constants";
import { getErrorLocalized } from "../../../utils/ErrorCodes";
import { IComponent } from "../../layouts/IComponent";
import "./TableComponent.scss";

interface ColumnConfig {
	className?: string;
	orderIndex: number;
	columnHeaderTextKey?: string;
	columnHeaderText?: string;

	// className?:string,
	width?: number;
	minWidth?: number;
	flexGrow?: number;
	fixed?: boolean | "left" | "right";
	resizable?: boolean;
	align?: "left" | "center" | "right";
	verticalAlign?: "top" | "middle" | "bottom";
	sortable?: boolean;
	hidden?: boolean;
	actions?: ColumnAction[];
	// onResize?: (columnWidth: number, dataKey:string) => void
}

type Props = {
	addons?: TableAddons;
	onDoubleClickEvents?: SendEvent[];
	prerequestCondition?: string;
	fulltextSearch: string;
	rowClassNameConditions?: { [className: string]: string };
	appearance: "clear" | "default";
	actionIdMapping: { [actionId: string]: string };
	stateSubscriptions?: string[];
	headerComponents: { [key: string]: IComponent };
	hideSelectionControls?: boolean;
	keyField?: string;
	reloadOnSubmitId?: string;
	detailIdentifier?: string;
	selectionLinkHref?: string;
	activeApplication: Application;
	displayNameKey?: string;
	hideTitlebar?: boolean;
	hideColumnHeaders?: boolean;
	dataUrl: string;
	pageSize: number;
	limit: number;
	skip: number;
	striped?: boolean;
	hideConfigMenu?: boolean;
	filters: { [dataKey: string]: Filter };
	columnsSortable?: boolean;

	useEndlessScrolling?: boolean;

	columns: { [dataKey: string]: ColumnConfig };
	initialSort?: {
		dataKey: string;
		sortType: "asc" | "desc";
	};
	events: TableEvents;
	selection?: "multiple" | "single" | "none";

	userConfig: UserConfig;
	data: Object[];
	total: number;
	sort?: {
		dataKey: string;
		sortType: "asc" | "desc";
	};
	setTableEvent: (identifier: string, event: TABLE_EVENT_TYPES, data: Object) => void;
	requestListData: (opts: RequestListOpts, cancelObj: { cancel?: () => void }) => void;
	setTableFilter: (identifier: string, dataKey: string, filter: Filter) => void;
	setTableSort: (identifier: string, dataKey: string, sortType: "asc" | "desc") => void;
	emptyListData: (identifier: string) => void;

	updateUserConfig: (config: UserConfig) => void;
	additionalParams?: MatchQuery;
	footer?: IComponent;
	subHeader?: IComponent;
} & WithTranslation &
	RouteComponentProps &
	AbstractStylableProps;

type States = {
	loading: boolean;
	pageLoading: number;
	errorMessage: string;
	activePage: number;

	realPageSize: number;

	realColumns: { [dataKey: string]: ColumnConfig };
} & AbstractStylableStates;

export interface ReloadMessage {
	identifiers: string[];
}

class TableComponent extends AbstractStylableComponent<Props, States> {
	cancelObj: { cancel?: () => void } = {};

	readonly state: States = {
		loading: false,
		pageLoading: null,
		errorMessage: null,
		activePage: 1,
		realPageSize: 30,
		realColumns: null
	};
	delayTimeoutID = null;

	componentWillMount(): void {
		super.componentWillMount();

		this.calculateColumnConfiguration();
		this.setState({
			realPageSize: this.props.data.length !== 0 ? this.props.limit : this.props.pageSize, //for the first request, the pageSize is one given into the component
			activePage: this.props.data.length !== 0 ? this.props.skip / this.props.limit + 1 : 1
		});
	}

	componentDidMount(): void {
		this.subscribe(DataBusSubKeys.RELOAD, (msg: ReloadMessage) => {
			if (msg.identifiers.indexOf(this.props.identifier) !== -1) {
				this.reload();
			}
		});

		if (this.props.reloadOnSubmitId) {
			this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, (data: SubmitResponse) => {
				if (data.id === this.props.reloadOnSubmitId && data.success) {
					this.reload();
				}
			});
		}

		if (this.props.data.length === 0) {
			if (!this.props.sort && this.props.initialSort) {
				// this.handleSortChanged(this.props.initialSort.dataKey, this.props.initialSort.sortType);
				this.props.setTableSort(this.props.identifier, this.props.initialSort.dataKey, this.props.initialSort.sortType);
				setTimeout(() => this.requestPageData(1)); // via timeout, so that tableSOrt will not be overset by requestPageData
			} else {
				this.requestPageData(1);
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.userConfig !== this.props.userConfig) {
			this.calculateColumnConfiguration();
		}

		// ignore undefined/null/"" changes, do not refresh the data on these changes
		const prevFulltextSearch =
			!prevProps.fulltextSearch || prevProps.fulltextSearch.trim() === "" ? "" : prevProps.fulltextSearch;
		const fulltextSearch =
			!this.props.fulltextSearch || this.props.fulltextSearch.trim() === "" ? "" : this.props.fulltextSearch;

		if (prevFulltextSearch !== fulltextSearch) {
			this.refreshSideDelayed();
		}
	}

	calculateColumnConfiguration() {
		const { columns, userConfig, identifier, activeApplication } = this.props;

		let userColumnsConf = {};
		if (
			userConfig &&
			userConfig.application &&
			userConfig.application[activeApplication.name] &&
			userConfig.application[activeApplication.name].tableConfigs &&
			userConfig.application[activeApplication.name].tableConfigs[identifier]
		) {
			userColumnsConf = userConfig.application[activeApplication.name].tableConfigs[identifier].columns || {};
		}

		const realColumns = _.merge(columns, userColumnsConf) as any; // ObjectTools.mergeObjects(columns, userColumnsConf);
		Object.keys(realColumns).forEach(key => {
			realColumns[key].onResize = (columnWidth: number, dataKey: string) => this.handleColumnResize(columnWidth, dataKey);
		});

		this.setState({
			realColumns
		});
	}

	requestPageData(page: number, reset?: boolean) {
		const { prerequestCondition } = this.props;
		if (this.state.loading && this.state.pageLoading === page) {
			return;
		}
		this.setState({
			loading: true,
			errorMessage: null,
			pageLoading: page
		});
		this.cancelObj = {};

		if (prerequestCondition && !this.evaluateExpression(prerequestCondition)) {
			this.setState({
				loading: false,
				activePage: 1,
				pageLoading: null,
				realPageSize: this.props.limit
			});
			this.props.emptyListData(this.props.identifier);
		} else {
			this.props.requestListData(
				{
					append: reset ? false : this.props.useEndlessScrolling,
					url: this.props.dataUrl,
					limit: this.state.realPageSize,
					skip: (page - 1) * this.state.realPageSize,
					tableIdentifier: this.props.identifier,
					filters: this.props.filters,
					sort: this.props.sort,
					additionalParams: this.convertAdditionalParams(this.props.additionalParams),
					textQuery:
						this.props.fulltextSearch && this.props.fulltextSearch.trim() !== "" ? this.props.fulltextSearch : undefined,
					// sort: {
					//     fieldName: "",
					//     sortKey: "asc"
					// },
					onSuccess: result => {
						this.setState({
							pageLoading: null,
							loading: false,
							activePage: this.props.skip / this.props.limit + 1,
							realPageSize: this.props.limit
						});
					},
					onError: err => {
						this.setState({
							pageLoading: null,
							loading: false,
							errorMessage: getErrorLocalized(err),
							activePage: 1
						});
					}
				},
				this.cancelObj
			);
		}
	}

	convertAdditionalParams(param: MatchQuery | string) {
		if (!param) {
			return;
		}
		if (typeof param === "string") {
			return this.evaluateExpression(param);
		}
		const current = { ...param };
		if (current.type === "op") {
			if (current.op === "in" || current.op === "nin") {
				let values = [];
				if (typeof current.value === "string") {
					values = this.evaluateExpression(current.value);
				} else {
					values = current.value
						? current.value.map(value => {
								if (typeof current.value === "string") {
									return this.replaceStringVariables(current.value);
								} else {
									return value;
								}
						  })
						: [];
				}
				current.value = values;
			} else if (typeof current.value === "string") {
				current.value = this.evaluateExpression(current.value);
			}
		} else {
			const query = current.query.map(value => this.convertAdditionalParams(value));
			current.query = query;
		}

		return current;
	}

	handlePageChanged(page: number) {
		this.requestPageData(page);
	}

	handleSortChanged(dataKey: string, sortType: "asc" | "desc") {
		this.props.setTableSort(this.props.identifier, dataKey, sortType);
		this.refreshSideDelayed();
	}

	handleRowClicked(rowData: Object[]) {
		// this.props.setTableEvent(this.props.identifier, "SELECTION", rowData);

		if (this.props.selection === "single" && this.props.selectionLinkHref) {
			// href structure = "/${appRoute}/group/${selectedId}"
			const appName = this.props.activeApplication.name;
			const params = {
				appRoute: appName,
				selectedId: rowData.length === 1 ? rowData[0]["_id"] : "",
				location: this.props.location.pathname
			};
			const link = ExpressionHelper.evaluateExpression(this.props.selectionLinkHref, params);
			// setApplicationCacheData(`${this.props.identifier}#selection`, rowData[0], Number(new Date()), -1);

			this.props.history.push(link);
		} else {
			this.props.setTableEvent(this.props.identifier, "SELECTION", rowData);
		}
		this.emitComponentEvent({ type: "SELECTION", data: rowData });
	}

	handleRowDoubleClicked(rowData: Object) {
		this.emitComponentEvent({ type: "DOUBLE_CLICK", data: rowData });
		this.props.setTableEvent(this.props.identifier, "DOUBLE_CLICK", rowData);

		if (this.props.onDoubleClickEvents) {
			this.handleEvents(this.props.onDoubleClickEvents, { row: rowData });
		}
	}

	handleColumnResize(columnWidth: number, dataKey: string) {
		const columnProps: ColumnProperties = {
			width: columnWidth
		};

		this.updateColumn(dataKey, columnProps);
	}

	handleFilterColumnChanged(dataKey: string, filter: Filter) {
		this.props.setTableFilter(this.props.identifier, dataKey, filter);
		this.refreshSideDelayed();
	}

	handleColumnStateChanged(dataKey: string, columnState: ColumnState, orderIndex?: number) {
		const columnProps: ColumnProperties = {};

		if (columnState === "hidden") {
			columnProps.hidden = true;
			this.handleFilterColumnChanged(dataKey, null);
		}
		if (columnState === "visible") {
			columnProps.hidden = false;
			if (orderIndex === undefined) {
				const allSorted = Object.values(this.state.realColumns)
					.filter(a => a.orderIndex >= 0 && a.orderIndex < 100000)
					.sort((a, b) => b.orderIndex - a.orderIndex);
				orderIndex = allSorted.length > 0 ? allSorted[0].orderIndex + 1 : 0;
			}
		}
		if (orderIndex !== undefined) {
			columnProps.orderIndex = orderIndex;
		}

		this.updateColumn(dataKey, columnProps);
	}

	updateColumn(dataKey: string, columnProps: ColumnProperties) {
		this.props.updateUserConfig({
			application: {
				[this.props.activeApplication.name]: {
					tableConfigs: {
						[this.props.identifier]: {
							columns: {
								[dataKey]: columnProps
							}
						}
					}
				}
			}
		});
	}

	refreshSideDelayed() {
		if (!this.state.loading) {
			this.setState({
				loading: true,
				errorMessage: null
			});
		}
		if (this.cancelObj.cancel) {
			this.cancelObj.cancel();
		}
		if (this.delayTimeoutID) {
			clearTimeout(this.delayTimeoutID);
		}
		this.delayTimeoutID = setTimeout(() => {
			this.requestPageData(1, true);
		}, 500);
	}

	reload() {
		const { activePage } = this.state;
		if (this.props.useEndlessScrolling) {
			this.requestPageData(1, true);
		} else {
			this.requestPageData(activePage);
		}
	}

	render() {
		if (!this.shoudBeRendered()) {
			return null;
		}
		const { loading, activePage, realPageSize, errorMessage, realColumns } = this.state;

		const {
			keyField,
			hideConfigMenu,
			data,
			total,
			columns,
			userConfig,
			identifier,
			displayNameKey,
			hideTitlebar,
			filters,
			events,
			selection,
			sort,
			striped,
			columnsSortable,
			hideSelectionControls,
			headerComponents,
			stateSubscriptions,
			actionIdMapping,
			appearance,
			rowClassNameConditions,
			addons,
			hideColumnHeaders,
			useEndlessScrolling,
			footer,
			subHeader
		} = this.props;

		const dataFlattened = data.map(row =>
			flatten(row, {
				safe: true,
				delimiter: "|"
			})
		);

		const tableProps: TableProperties = {
			hideColumnHeaders,
			addons,
			headerComponents,
			hideSelectionControls,
			keyField: keyField ? keyField : "_id",
			hideConfigMenu,
			tableIdentifier: identifier,
			data: dataFlattened,
			onSortColumn: (dataKey, sortType) => this.handleSortChanged(dataKey, sortType),
			onRowClick: rowDatas => this.handleRowClicked(rowDatas),
			onRowDoubleClick: rowData => this.handleRowDoubleClicked(rowData),
			onReload: () => this.reload(),
			onColumnStateChanged: (dataKey: string, state: ColumnState, orderIndex?: number) =>
				this.handleColumnStateChanged(dataKey, state, orderIndex),
			onFilterChange: (dataKey: string, filter: Filter) => this.handleFilterColumnChanged(dataKey, filter),
			filtersObject: filters,
			loading,
			title: displayNameKey ? (window as any).translate(displayNameKey) : null,
			hideTitlebar: hideTitlebar,
			selectedRows: events && events.SELECTION ? events.SELECTION : [],
			selection: selection ? selection : "multiple",
			columnsSortable: columnsSortable ? columnsSortable : false,
			striped,
			errorMessage
		};
		if (sort) {
			tableProps.sortColumn = sort.dataKey;
			tableProps.sortType = sort.sortType;
		}

		const pagingProps: PagingProperties = {
			activePage,
			total,
			pageSize: realPageSize,
			onChangePage: eventKey => this.handlePageChanged(eventKey),
			disabled: loading || errorMessage !== null,
			useEndlessScrolling
		};

		return (
			<BFTable
				rowClassNameConditions={rowClassNameConditions}
				appearance={appearance}
				style={this.state.usedStyle}
				actionIdMapping={actionIdMapping}
				stateSubscriptions={stateSubscriptions}
				columnProps={realColumns}
				tableProps={tableProps}
				pagingProps={pagingProps}
				subHeader={subHeader}
				footer={footer}
			/>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({
	activeApplication: state.uiConfig.activeApplication,
	userConfig: state.global.user.config,
	data:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].data
			? state.application.tables[props.identifier].data
			: [],
	total:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].total
			? state.application.tables[props.identifier].total
			: 0,
	limit:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].limit
			? state.application.tables[props.identifier].limit
			: 0,
	skip:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].skip
			? state.application.tables[props.identifier].skip
			: 0,
	filters:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].filters
			? state.application.tables[props.identifier].filters
			: {},
	fulltextSearch:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].filters
			? state.application.tables[props.identifier].fulltextSearch
			: "",
	events:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].events
			? state.application.tables[props.identifier].events
			: {},
	sort:
		state.application.tables[props.identifier] && state.application.tables[props.identifier].sort
			? state.application.tables[props.identifier].sort
			: null,
	viewportWidth: Array.isArray(props.style) ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH] : null
});

export default connect(mapStateToProps, {
	setTableEvent,
	setTableFilter,
	requestListData,
	updateUserConfig,
	setTableSort,
	emptyListData
})(withRouter(TableComponent));
