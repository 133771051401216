import React, { Component } from "react";
import { connect } from "react-redux";
import { CheckPicker, InputPicker, SelectPicker, TagPicker } from "rsuite";
import uuid from "uuid";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import { Placement } from "../../common/Placements";
import ValidationPopover from "../../general/ValidationPopover/ValidationPopover";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import LabeledInput from "../LabeledInput";
import "./BFSelect.scss";

interface CommonProps {
  label?: string;
  labelPosition?: "top" | "left";

  viewportHeight: number;

  cleanable?: boolean;
  // container?:HTMLElement|(() => HTMLElement),
  data: any[];
  defaultValue?: any;
  disabled?: boolean;
  disabledItemValues?: string[];
  groupBy?: string;
  labelKey?: string;
  icon?: BfIconProps;
  // menuClassName:any,
  // menuStyle:any,
  maxHeight?: number;
  onClose?: () => void;
  onClean?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onGroupTitleClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onOpen?: () => void;
  onSearch?: (keyword: string, ev: React.SyntheticEvent<HTMLElement>) => void;
  onSelect?: (
    value: string,
    item: any,
    event: React.SyntheticEvent<HTMLElement>
  ) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  placement?: Placement;
  // renderMenuGroup?:(groupTitle:React.ReactNode, item:any)=>React.ReactNode,
  // renderMenuItem:(label:React.ReactNode, item: any)=>React.ReactNode	,
  // renderValue:(value: any[], items: any[], selectedElement:React.ReactNode) => React.ReactNode,
  searchable?: boolean;
  sort?: (isGroup: boolean) => (a: any, b: any) => number;
  // toggleComponentClass:any,
  value?: any;
  valueKey?: string;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
}

interface NormalProps extends CommonProps {
  type: "normal";
  creatable?: boolean;
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  onChange?: (value: string, event: React.SyntheticEvent<HTMLElement>) => void;
}

interface SearchableProps extends CommonProps {
  type: "search";
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  onChange?: (value: string, event: React.SyntheticEvent<HTMLElement>) => void;
}

interface TagsProps extends CommonProps {
  type: "tags";
  creatable?: boolean;
  cacheData?: any[];
  onChange?: (
    value: string[],
    event: React.SyntheticEvent<HTMLElement>
  ) => void;
}

interface MultipleProps extends CommonProps {
  type: "multiple";
  renderMenu?: (menu: React.ReactNode) => React.ReactNode;
  renderExtraFooter?: () => React.ReactNode;
  onChange?: (value: any[], event: React.SyntheticEvent<HTMLElement>) => void;
}

type Props = NormalProps | SearchableProps | TagsProps | MultipleProps;

type States = {};

class BFSelect extends Component<Props, States> {
  genId: string;
  container;

  static defaultProps = {
    type: "normal",
    placeholder: null
  };

  readonly state: States = {};

  componentWillMount(): void {
    this.genId = uuid();
  }

  render() {
    const { label, labelPosition, placeholder, validation } = this.props;

    return (
      <LabeledInput label={label} labelPosition={labelPosition}>
        <ValidationPopover
          level={validation ? validation.level : "error"}
          message={validation ? validation.message : null}
          marginTop={17}
        >
          <div
            className={`bf-select ${
              !placeholder || placeholder.trim() === "" ? "no-placeholder" : ""
            }`}
            id={this.genId}
            style={{ position: "relative" }}
            ref={ref => {
              this.container = ref;
            }}
          >
            {this.renderSelect()}
          </div>
        </ValidationPopover>
      </LabeledInput>
    );
  }

  renderSelect() {
    const {
      viewportHeight,
      label,
      labelPosition,
      validation,
      ...rest
    } = this.props;

    if (!rest.placeholder || rest.placeholder.trim() === "") {
      rest.placeholder = "-";
    }

    const propsToGo: any = {
      maxHeight: this.props.viewportHeight - 100,
      ...rest,
      block: true,
      container: () => {
        return document.getElementById(this.genId); //this.container;
      },
      onClean: (event: React.SyntheticEvent<HTMLElement>) => {
        if (this.props.onClean) {
          this.props.onClean(event);
        }

        if (this.props.onBlur) {
          this.props.onBlur();
        }
      },
      renderValue: (value: string, item, selectedElement) => {
        return (
          <div className={`value-presentation-container`}>
            {this.props.icon ? (
              <div className={`icon`}>
                <BfIcon {...this.props.icon} />
              </div>
            ) : null}
            <div className={`value-presentation`}>{item.label}</div>
          </div>
        );
      }
    };

    switch (this.props.type) {
      case "multiple":
        return React.createElement(CheckPicker, propsToGo);
      case "normal":
        return React.createElement(InputPicker, propsToGo);
      case "search":
        return React.createElement(SelectPicker, propsToGo);
      case "tags":
        return React.createElement(TagPicker, propsToGo);
    }
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  viewportHeight: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_HEIGHT]
});
export default connect(mapStateToProps, {})(BFSelect);
