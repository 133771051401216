import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Application } from "../../model/db/Application";
import { ContextData } from "../../model/db/ContextData";
import { User } from "../../model/db/User";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import { setUiConfig } from "./../../redux/actions/ui-config/ui-config-actions";
import "./AppSelectionView.scss";

type Props = {
  user: User;
  apps: Application[];
  context: ContextData;
  setUiConfig: (key: string, value: any) => void;
};
type States = {};

class AppSelectionView extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    this.props.setUiConfig(DefaultUIConfigs.HEADER_TRANSPARENT, true);

    setTimeout(() => {
      this.props.setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, true);
    }, 50);
  }

  componentWillUnmount(): void {
    this.props.setUiConfig(DefaultUIConfigs.HEADER_TRANSPARENT, false);
  }

  render() {
    const { context } = this.props;
    if (this.props.apps.length === 1) {
      return <Redirect to={"/" + this.props.apps[0].name} />;
    }
    return (
      <div className={`view-application-select`}>
        <div
          className={`background`}
          style={{
            backgroundImage:
              context && context.login ? context.login.background : undefined
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
  apps:
    state.global.user && state.global.user.mandator_info
      ? state.global.user.mandator_info.apps
      : null,
  context: state.global.context
});
// const mapDispatchToProps = (dispatch: ThunkDispatch<{},{},any>, ownProps:any) => ({
//     setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(setUserData(user, apps, permissions)) }
// })

export default withTranslation()(
  connect(mapStateToProps, { setUiConfig })(AppSelectionView)
);
